export const onlyNums = value => (value ? value.replace(/[^\d]/g, "") : "");

export const digits = numb => value => {
  if (!value) {
    return value;
  }

  return onlyNums(value).slice(0, numb);
};

export const KZPhoneRegex = /^\+7\s\(7\d{2}\)\s\d{3}\s\d{2}\s\d{2}$/;
