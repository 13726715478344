import React, { useContext } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { UserContext } from "../../globalContexts/UserContext";
import {
  createToast,
  MessagesContext,
} from "../../globalContexts/MessagesContext";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { ChangePasswordPage } from "./ChangePasswordPage";
import {
  getUserDataByUserName,
  updateUserData,
} from "../../api/registrationApi";
import { useTranslation } from "react-i18next";
import { MobileMaskInput } from "../../components/FormComponents";
import {
  HeaderLeftTeleporter,
  HeaderRightTeleporter,
} from "../../components/Header";
import { Link, useHistory } from "react-router-dom";
import SupportTelephoneNumber from "../../components/SupportTelephoneNumber";
import CostomInput, { CostomPhoneInput } from "../../components/CostomInput";

const useStyles = makeStyles((theme) => ({
  root: {
    with: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  block: { maxWidth: "1200px", width: "100%", padding: "50px 40px" },

  title: {
    fontSize: "20px",
    fontWeight: "500",
    paddingBottom: "20px",
  },
  submit__button: {
    marginTop:"40px",
    width: "100%",
    display:"flex",
    justifyContent:"center"
    // alignItems: "center",
  },
}));

export function PersonalAreaPage() {
  const classes = useStyles();
  const history = useHistory();
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const { user } = useContext(UserContext);
  const { t } = useTranslation("registrationPage");
  const dispatchMessage = useContext(MessagesContext)[1];
  const {
    data: ophthalmologistData,
    isLoading: ophthalmologistLoading,
    isError: ophthalmologistError,
  } = useQuery(["getUserDataForRegistration", user.name], () =>
    getUserDataByUserName({ userName: user.name })
  );
  const { mutate } = useMutation(
    async ({ id, data }) => {
      await updateUserData({ id, data });
    },
    {
      onSuccess: () => {
        dispatchMessage(createToast(t("SUCCESS"), "success"));
      },
    }
  );
  const { register, handleSubmit, errors } = useForm();
  async function onSubmit(data) {
    mutate({ id: user.id, data: data });
  }
  return (
    <>
      <HeaderLeftTeleporter.Source>
        <Typography
          variant={isMd ? "body1" : "h6"}
          color="inherit"
          component={Link}
          to="/"
        >
          {isSm ? t("SYSTEM_UDDR_SHORTENED") : t("SYSTEM_UDDR")}
        </Typography>
      </HeaderLeftTeleporter.Source>
      <HeaderRightTeleporter.Source>
      <div style={{display:"flex"}}>
        <SupportTelephoneNumber/>
        {!isMd &&
          <Button
            className="text-white border-white"
            variant="outlined"
            onClick={history.goBack}
            >
              {t("BACK")}
            </Button>
          }
      </div>
      </HeaderRightTeleporter.Source>
      <div className={classes.root} >
        <Paper className={classes.block} style={{}}>
          <Typography className={classes.title}>
            {t("USER_INFORMATION")}
          </Typography>
          {ophthalmologistLoading ? (
            <CircularProgress />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} id="userData">
              <CostomInput
                readOnly
                label={t("IIN")}
                value={ophthalmologistData.username}
                inputRef={register({ required: true })}
                name={"iinUser"}
                error={Boolean(errors.iinUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <CostomInput
                label={t("SURNAME")}
                value={ophthalmologistData.lastName}
                inputRef={register({ required: true })}
                name={"surnameUser"}
                error={Boolean(errors.surnameUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <CostomInput
                label={t("NAME")}
                value={ophthalmologistData.firstName}
                inputRef={register({ required: true })}
                name={"nameUser"}
                error={Boolean(errors.nameUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <CostomInput
                label={t("MIDDLE_NAME")}
                value={""}
                inputRef={register()}
                name={"middleNameUser"}
                error={Boolean(errors.middleNameUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <CostomPhoneInput
                label={t("PHONE")}
                value={ophthalmologistData?.attributes?.phone}
                inputRef={register({ required: true })}
                name={"phoneUser"}
                error={Boolean(errors.phoneUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <CostomInput
                label={t("EMAIL")}
                value={ophthalmologistData?.email}
                inputRef={register({
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                name={"emailUser"}
                error={Boolean(errors.emailUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <Grid className={classes.submit__button}>
                <Button
                  style={{width: "100%", maxWidth:"250px"}}
                  type="submit"
                  variant="contained"
                  color="primary"
                  form="userData"
                >
                  {t("REFRESH")}
                </Button>
              </Grid>
            </form>
          )}
        </Paper>
        <ChangePasswordPage />
      </div>
    </>
  );
}
