import {Box, Button, Grid, makeStyles, Modal, Paper, TextField, Typography} from "@material-ui/core";
import React, {useContext, useEffect, useMemo} from "react";
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {Close} from "@material-ui/icons";
import {
    createAutorefractomy,
    createDiseaseHistory, createExophthalmometry,
    editAutorefractomy,
    editDiseaseHistory, editExophthalmometry
} from "../../../../../api/patientRegistry";
import {createToast, MessagesContext} from "../../../../../globalContexts/MessagesContext";
import {queryClient} from "../../../../../globalContexts/queryContext";
import {useTranslation} from "react-i18next";
import {autorefractomyServiceSchema} from "./validationSchemas";

const useStyles = makeStyles(() => ({
    field: {
        marginLeft: '25px',
        width: '70%'
    },
    table: {
        '& td': {
            padding: '5px'
        },
        '& .space': {
            width: '50px'
        },
        '& .header': {
            textAlign: 'center'
        }
    },
    input: {
        display: 'flex',
        minWidth: '100px'
    }
}));

const CreateFormModal = React.forwardRef(({onClose, chosen, editingItem}, ref) => {
    const classes = useStyles();
    const {id: researchId} = useParams();
    const dispatchMessage = useContext(MessagesContext)[1];
    const initialValues = useMemo(() => ({
        odSph: undefined,
        odCyl: undefined,
        odAx: undefined,
        odR1Mm: undefined,
        odR1D: undefined,
        odR1Ax: undefined,
        odR2Mm: undefined,
        odR2D: undefined,
        odR2Ax: undefined,
        odAveMm: undefined,
        odAveD: undefined,
        odAveAx: undefined,
        odCylMm: undefined,
        odCylD: undefined,
        odCylAx: undefined,
        osSph: undefined,
        osCyl: undefined,
        osAx: undefined,
        osR1Mm: undefined,
        osR1D: undefined,
        osR1Ax: undefined,
        osR2Mm: undefined,
        osR2D: undefined,
        osR2Ax: undefined,
        osAveMm: undefined,
        osAveD: undefined,
        osAveAx: undefined,
        osCylMm: undefined,
        osCylD: undefined,
        osCylAx: undefined,
    }), [])
    const {handleSubmit, control, reset, watch, errors } = useForm({
        defaultValues: initialValues,
        reValidateMode: 'onSubmit',
        validationSchema: autorefractomyServiceSchema
    });
    const {t} = useTranslation(['patient-registry', 'serviceNamesDict']);
    const values = watch();

    useEffect(() => {
        if(chosen) {
            reset(chosen.service);
        }
        if(editingItem) {
            reset(editingItem.service);
        }
        if(!chosen && !editingItem) {
            reset(initialValues);
        }
    }, [chosen, editingItem, reset])

    const handleSubmitCreateForm = handleSubmit(async (value) => {
        try {
            await createAutorefractomy({...value, researchId});
            onClose();
            dispatchMessage(createToast(t('SERVICE_SUCCESSFULLY_CREATED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return
        } catch(error) {
            console.error(error);
        }
        onClose();
    })

    const handleSubmitEditForm = handleSubmit(async(value) => {
        try {
            await editAutorefractomy(editingItem.service.id, value);
            onClose();
            dispatchMessage(createToast(t('SERVICE_SUCCESSFULLY_EDITED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return
        } catch(error) {
            console.error(error);
        }
        onClose();
    })
    return <form ref={ref} onSubmit={Boolean(editingItem) ? handleSubmitEditForm : handleSubmitCreateForm}>
                <span>
                    {t('serviceNamesDict:AUTOREFRACTOMY')}:
                </span>
                <Box height={'15px'} />
                <Grid container justify={'center'}>
                    <table className={classes.table}>
                        <tbody>
                            <tr>
                                <td />
                                <td className={'header'} colSpan={3}>
                                    OD
                                </td>
                                <td className={'space'} />
                                <td className={'header'} colSpan={3}>
                                    OS
                                </td>
                            </tr>
                            <tr>
                                <td />
                                <td>sph</td>
                                <td>cyl</td>
                                <td>ax (°)</td>
                                <td />
                                <td>sph</td>
                                <td>cyl</td>
                                <td>ax (°)</td>
                            </tr>
                            <tr>
                                <td />
                                <td>
                                    <Controller name={'odSph'} control={control} as={
                                        <TextField error={!!errors['odSph']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "1", step: 0.25, readOnly: chosen, min: -25, max: 25}} type={'number'}
                                                   size={'small'} variant={'outlined'} InputProps={{startAdornment: values['odSph'] > 0 ? '+' : ''}}  />
                                    }/>
                                </td>
                                <td>
                                    <Controller name={'odCyl'} control={control} as={
                                        <TextField error={!!errors['odCyl']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "2", step: 0.25, readOnly: chosen, min: -25, max: 25}} type={'number'}
                                                   size={'small'} variant={'outlined'} InputProps={{startAdornment: values['odCyl'] > 0 ? '+' : ''}} />
                                    } />
                                </td>
                                <td>
                                    <Controller name={'odAx'} control={control} as={
                                        <TextField error={!!errors['odAx']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "3", step: 1, readOnly: chosen, min: 0, max: 180}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td />
                                <td>
                                    <Controller name={'osSph'} control={control} as={
                                        <TextField error={!!errors['osSph']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "16", step: 0.25, readOnly: chosen, min: -25, max: 25}} type={'number'}
                                                   size={'small'} variant={'outlined'} InputProps={{startAdornment: values['osSph'] > 0 ? '+' : ''}}/>
                                    }/>
                                </td>
                                <td>
                                    <Controller name={'osCyl'} control={control} as={
                                        <TextField error={!!errors['osCyl']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "17", step: 0.25, readOnly: chosen, min: -25, max: 25}} type={'number'}
                                                   size={'small'} variant={'outlined'} InputProps={{startAdornment: values['osCyl'] > 0 ? '+' : ''}}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'osAx'} control={control} as={
                                        <TextField error={!!errors['osAx']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "18", step: 1, readOnly: chosen, min: 0, max: 180}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                            </tr>
                            <tr>
                                <td />
                                <td>mm</td>
                                <td>D</td>
                                <td>ax (°)</td>
                                <td />
                                <td>mm</td>
                                <td>D</td>
                                <td>ax (°)</td>
                            </tr>
                            <tr>
                                <td>R1</td>
                                <td>
                                    <Controller name={'odR1Mm'} control={control} as={
                                        <TextField error={!!errors['odR1Mm']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "4", step: 0.25, readOnly: chosen}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'odR1D'} control={control} as={
                                        <TextField error={!!errors['odR1D']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "5", step: 0.25, readOnly: chosen}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'odR1Ax'} control={control} as={
                                        <TextField error={!!errors['odR1Ax']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "6", step: 1, readOnly: chosen, min: 0, max: 180}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td />
                                <td>
                                    <Controller name={'osR1Mm'} control={control} as={
                                        <TextField error={!!errors['osR1Mm']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "19", step: 0.25, readOnly: chosen}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'osR1D'} control={control} as={
                                        <TextField error={!!errors['osR1D']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "20", step: 0.25, readOnly: chosen}}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'osR1Ax'} control={control} as={
                                        <TextField error={!!errors['osR1Ax']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "21", step: 1, readOnly: chosen, min: 0, max: 180}}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                            </tr>
                            <tr>
                                <td>R2</td>
                                <td>
                                    <Controller name={'odR2Mm'} control={control} as={
                                        <TextField error={!!errors['odR2Mm']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "7", step: 0.25, readOnly: chosen}}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'odR2D'} control={control} as={
                                        <TextField error={!!errors['odR2D']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "8", step: 0.25, readOnly: chosen}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'odR2Ax'} control={control} as={
                                        <TextField error={!!errors['odR2Ax']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "9", step: 1, readOnly: chosen, min: 0, max: 180}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td />
                                <td>
                                    <Controller name={'osR2Mm'} control={control} as={
                                        <TextField error={!!errors['osR2Mm']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "22", step: 0.25, readOnly: chosen}}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'osR2D'} control={control} as={
                                        <TextField error={!!errors['osR2D']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "23", step: 0.25, readOnly: chosen}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'osR2Ax'} control={control} as={
                                        <TextField error={!!errors['osR2Ax']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "24", step: 1, readOnly: chosen, min: 0, max: 180}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                            </tr>
                            <tr>
                                <td>Ave</td>
                                <td>
                                    <Controller name={'odAveMm'} control={control} as={
                                        <TextField error={!!errors['odAveMm']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "10", step: 0.25, readOnly: chosen }} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'odAveD'} control={control} as={
                                        <TextField error={!!errors['odAveD']} className={classes.input}  inputProps={{className:"arrow-toggle", tabIndex: "11", step: 0.25, readOnly: chosen }} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'odAveAx'} control={control} as={
                                        <TextField error={!!errors['odAveAx']} className={classes.input}  inputProps={{className:"arrow-toggle", tabIndex: "12", step: 1, readOnly: chosen,  min: 0, max: 180 }} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td />
                                <td>
                                    <Controller name={'osAveMm'} control={control} as={
                                        <TextField error={!!errors['osAveMm']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "25", step: 0.25, readOnly: chosen }} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'osAveD'} control={control} as={
                                        <TextField error={!!errors['osAveD']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "26", step: 0.25, readOnly: chosen }}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'osAveAx'} control={control} as={
                                        <TextField error={!!errors['osAveAx']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "27", step: 1, readOnly: chosen, min: 0, max: 180}}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                            </tr>
                            <tr>
                                <td>Cyl</td>
                                <td>
                                    <Controller name={'odCylMm'} control={control} as={
                                        <TextField error={!!errors['odCylMm']} className={classes.input}  inputProps={{className:"arrow-toggle", tabIndex: "13", step: 0.25, readOnly: chosen }}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'odCylD'} control={control} as={
                                        <TextField error={!!errors['odCylD']} className={classes.input}  inputProps={{className:"arrow-toggle", tabIndex: "14", step: 0.25, readOnly: chosen }}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'odCylAx'} control={control} as={
                                        <TextField error={!!errors['odCylAx']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "15", step: 0.25, readOnly: chosen, min: 0, max: 180 }}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td />
                                <td>
                                    <Controller name={'osCylMm'} control={control} as={
                                        <TextField error={!!errors['osCylMm']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "28", step: 0.25, readOnly: chosen }}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'osCylD'} control={control} as={
                                        <TextField error={!!errors['osCylD']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "29", step: 0.25, readOnly: chosen }}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'osCylAx'} control={control} as={
                                        <TextField error={!!errors['osCylAx']} className={classes.input} inputProps={{className:"arrow-toggle", tabIndex: "30", step: 1, readOnly: chosen, min: 0, max: 180 }}  type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
            </form>
});

export default CreateFormModal;