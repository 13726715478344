import {
  Box,
  Button,
  Grid,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { DictionariesContext } from "../../../../globalContexts/DictionariesContext";
import { RESEARCH_SERVICE_NAME } from "../../../ResearchesPage/constants";
import ExophthalmometryForm from "./Forms/ExophthalmometryForm";
import NonContactTonometryForm from "./Forms/NonContactTonometryForm";
import SchirmerTestForm from "./Forms/SchirmerTestForm";
import MaklakovTestForm from "./Forms/MaklakovTestForm";
import AutorefractomyForm from "./Forms/AutorefractomyForm";
import UniversalServiceForm from "./Forms/UniversalServiceForm";
import { Autocomplete } from "@material-ui/lab";
import { OcularFunducPhotography } from "./Forms/OcularFunducPhotography";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "60vw",
    padding: "24px",
    position: "relative",
  },
  bold: {
    fontWeight: "bold",
  },
  field: {
    marginLeft: "25px",
    width: "70%",
  },
  closeIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
  },
  input: {
    display: "flex",
  },
}));

const ServiceForms = {
  [RESEARCH_SERVICE_NAME.EXOPHTHALMOMETRY]: ExophthalmometryForm,
  [RESEARCH_SERVICE_NAME.NON_CONTACT_TONOMETRY]: NonContactTonometryForm,
  [RESEARCH_SERVICE_NAME.SCHIRMER_TEST]: SchirmerTestForm,
  [RESEARCH_SERVICE_NAME.MAKLAKOV_TEST]: MaklakovTestForm,
  [RESEARCH_SERVICE_NAME.AUTOREFRACTOMY]: AutorefractomyForm,
  [RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY]: OcularFunducPhotography,
};

const ServiceFormModal = ({ open, onClose, chosen, editingItem, research }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(["patient-registry", "serviceNamesDict"]);
  const [{ serviceNames }] = useContext(DictionariesContext);
  const serviceNamesOptions =
    serviceNames
      ?.filter(
        ({ codename }) =>
          codename !== RESEARCH_SERVICE_NAME.OPHTHALMOLOGIST_CONSULTATION
      )
      .map(({ codename }) => ({
        value: codename,
        label: t("serviceNamesDict:" + codename),
      })) || [];
  const [serviceChosen, setServiceChosen] = useState(null);
  const ref = useRef();

  useEffect(() => {
    const handleKeyDown = function(e) {
      const elements = Array.from(
        document.getElementsByClassName("arrow-toggle")
      ).sort((a, b) => a.tabIndex - b.tabIndex);
      switch (e.key) {
        case "ArrowLeft":
          elements[elements.indexOf(e.target) - 1]?.focus();
          break;
        case "ArrowRight":
          elements[elements.indexOf(e.target) + 1]?.focus();
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [serviceChosen]);

  // useEffect(() => {
  //   if (serviceNamesOptions.length && !serviceChosen) {
  //     setServiceChosen(serviceNamesOptions[0]);
  //   }
  // }, [serviceNamesOptions, serviceChosen]);

  useEffect(() => {
    if (serviceChosen) {
      setServiceChosen({
        ...serviceChosen,
        label: t("serviceNamesDict:" + serviceChosen.value),
      });
    }
  }, [i18n.language]);

  useEffect(() => {
    if (chosen) {
      const serviceName = serviceNamesOptions.find(
        ({ value }) => value === chosen.serviceName
      );
      setServiceChosen(serviceName);
    }
    if (editingItem) {
      const serviceName = serviceNamesOptions.find(
        ({ value }) => value === editingItem.serviceName
      );
      setServiceChosen(serviceName);
    }
  }, [chosen, editingItem]);

  const FormComponent =
    ServiceForms[serviceChosen?.value] || UniversalServiceForm;
  return (
    <Modal
      open={open}
      onClose={
        !chosen
          ? () => window.confirm(t("CONFIRM_CLOSE")) && onClose()
          : onClose
      }
      className={classes.modal}
    >
      <Paper className={classes.modalContent}>
        <Close className={classes.closeIcon} onClick={onClose} />
        <Typography className={classes.bold}>
          {t("SERVICE_RENDERED")}
        </Typography>
        <Autocomplete
          value={serviceChosen}
          disabled={chosen || editingItem}
          options={serviceNamesOptions}
          getOptionLabel={(option) => option.label}
          onChange={(event, option) => setServiceChosen(option)}
          renderInput={(params) => (
            <TextField {...params} label={t("CHOOSE_SERVICE")} />
          )}
        />
        <Box height={"24px"} />
        {FormComponent && (
          <FormComponent
            ref={ref}
            onClose={onClose}
            chosen={chosen}
            editingItem={editingItem}
            serviceChosen={serviceChosen}
            research={research}
          />
        )}
        {!chosen &&
          serviceChosen?.value !==
            RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY && (
            <>
              <Box height={"24px"} />
              <Grid container justify={"flex-end"}>
                <Button
                  onClick={() => {
                    ref.current.reportValidity() &&
                      ref.current.dispatchEvent(new Event("submit"));
                  }}
                  variant={"contained"}
                  color={"primary"}
                >
                  {t("SAVE")}
                </Button>
                <Box width={"6px"} />
                <Button
                  onClick={onClose}
                  variant={"contained"}
                  color={"secondary"}
                >
                  {t("CANCEL")}
                </Button>
              </Grid>
            </>
          )}
      </Paper>
    </Modal>
  );
};

export default ServiceFormModal;
