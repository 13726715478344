import React, { useRef } from "react";
import { Grid, Typography } from "@material-ui/core";
import { FastField } from "formik";
import { renderTextField } from "../../../components/FormComponents";
import { useEffect } from "react";

export default function ConfirmationCode({ code, codeLabel }) {
  const submitRef = useRef();
  useEffect(() => {
    if (code && code.length === 4) {
      submitRef.current.click();
    }
  }, [code]);
  // console.log(code);
  return (
    <Grid>
      <Typography style={{ fontSize: "12px", width: "100%" }}>
        {codeLabel}
      </Typography>
      <FastField
        style={{ fontSize: "12px", width: "100%" }}
        className="mr-4 ml-4"
        name="code"
        variant="outlined"
        size="small"
        margin="dense"
        autoFocus
        component={renderTextField}
      />
      <button type="submit" ref={submitRef} hidden />
    </Grid>
  );
}
