import {Button} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";

const SaveButton = ({watch}) => {
    const {t} = useTranslation();
    const values = watch();
    return <Button disabled={!Object.values(values).reduce((accum, value) => accum || value?.length, false)} type={'submit'} variant={'contained'} color={'primary'}>
        {t('SAVE')}
    </Button>
}

export default SaveButton;