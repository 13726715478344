import { KZPhoneRegex } from "../../components/FormComponents/form_normalizers";

export function normalizePatientFieldsValues(values) {
  const normalized = {
    ...values,
    phone: KZPhoneRegex.test(values.phone) ? values.phone : "",
    referrerId: values.referrerId.value,
  };
  if (!values.refractionRight) {
    delete normalized.refractionRight;
  }
  if (!values.refractionLeft) {
    delete normalized.refractionLeft;
  }
  if (!values.receptionDoctorId || !values.receptionDoctorId.value) {
    delete normalized.receptionDoctorId;
  } else {
    normalized.receptionDoctorId = values.receptionDoctorId.value;
  }
  return normalized;
}
