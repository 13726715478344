import React, { useState } from "react";
import { Button, CircularProgress } from "@material-ui/core";

const AsyncButton = React.forwardRef(({onClick, children, rest}, ref) => {
  const [loading, setLoading] = useState(false);

  async function handleClick(e) {
    try {
      setLoading(true);
      await onClick(e);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  return (
      <Button onClick={handleClick} ref={ref} {...rest}>
        {children} &nbsp;
        {loading && <CircularProgress size={20} color="inherit" />}
      </Button>
  );
});

export default AsyncButton;
