import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import {useTranslation} from "react-i18next";

function Fallback({ error, componentStack }) {
  const {t} = useTranslation('common');
  return (
    <div role="alert">
      <p>{t('ERROR')}</p>
      <pre>{error.message}</pre>
      <pre>{componentStack}</pre>
      <button onClick={() => window.location.reload()}>
          {t('TRY_AGAIN')}
      </button>
    </div>
  );
}

export default function CustomErrorBoundary({ children }) {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
}
