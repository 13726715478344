import React, {useContext} from "react";
import useStyle from "./slaCardStyles";
import {useHistory} from "react-router-dom";
import {useMutation} from "react-query";
import {updateConciliumStatus} from "../../api/conciliumApi";
import {queryClient} from "../../globalContexts/queryContext";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import {answerPatientInfoPermissionAsk} from "../../api";
import {createToast, MessagesContext} from "../../globalContexts/MessagesContext";
import {useTranslation} from "react-i18next";

const SlaCardPatientCardAsk = React.forwardRef(({ notification, handleClose, t }, ref) => {
  const classes = useStyle();
  const messageDispatch = useContext(MessagesContext)[1]
  const {mutate} = useMutation(async ({accessObjId, allow, notificationId}) => {
    await answerPatientInfoPermissionAsk(accessObjId, {allow, notificationId})
    await queryClient.invalidateQueries('notifications');
    if(allow) {
      messageDispatch(createToast(t("PATIENT_CARD_ACCESS_ACCEPTED")))
    } else {
      messageDispatch(createToast(t("PATIENT_CARD_ACCESS_REJECTED")))
    }
  });

  if(!notification.payload) {
    return null;
  }
  return (
    <Paper className={classes.card} ref={ref} id={notification.object_id}>
      <Typography variant="caption" className={classes.cardTitle} title={
        t(notification.notification_type, {doctorFullName: notification.payload.doctorFullName})
      }>
        {t(notification.notification_type, {doctorFullName: notification.payload.doctorFullName})}
      </Typography>
      <Grid className={classes.cardInfo}>
        <Button
          size="small"
          variant="outlined"
          className={classes.cardButton}
          onClick={() => mutate({accessObjId: notification.object_id, allow: true, notificationId: notification.id})}
        >
          {t("ACCEPT")}
        </Button>
        <Button
          size="small"
          variant="outlined"
          className={`${classes.cardButton} ${classes.redButton}`}
          onClick={() => mutate({accessObjId: notification.object_id, allow: false, notificationId: notification.id})}
        >
          {t("REJECT")}
        </Button>
      </Grid>
    </Paper>
  );
})

export default SlaCardPatientCardAsk;