import React from "react";
import { Helmet } from "react-helmet";
import { Typography, Grid, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import JournalReestrPicker from "../ResearchesPage/ResearchesList/JournalReestrPicker";
import {
  HeaderLeftTeleporter,
  HeaderRightTeleporter,
} from "../../components/Header";
import { PatientJournal } from "./PatientsJournal";
import SupportTelephoneNumber from "../../components/SupportTelephoneNumber";

export default function PatientsList() {
  const { t } = useTranslation(["research", "common"]);
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");

  return (
    <div className="relative">
      <HeaderLeftTeleporter.Source>
        <Typography
          variant={isMd ? "body1" : "h6"}
          color="inherit"
          component={Link}
          to="/"
        >
          {isSm ? t("SYSTEM_UDDR_SHORTENED") : t("SYSTEM_UDDR")}
        </Typography>
      </HeaderLeftTeleporter.Source>
      <HeaderRightTeleporter.Source>
        <SupportTelephoneNumber />
      </HeaderRightTeleporter.Source>
      <Helmet>
        <title>{t("PATIENTS_REGISTRY")}</title>
        <meta name="description" content="Description of ResearchJournal" />
      </Helmet>
      <Grid
        className="pt-10 pr-10 pl-10"
        style={{ display: isMd ? "none" : "flex", justify: "space-between" }}
      >
        <JournalReestrPicker chosen="patients" />
      </Grid>
      <PatientJournal />
    </div>
  );
}
