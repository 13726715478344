import React, { memo, useContext } from "react";
// import PropTypes from 'prop-types';
import Message from "./Message";
import {
  MessagesContext,
  destroyToast
} from "../../globalContexts/MessagesContext";

function Messages() {
  const [state, dispatch] = useContext(MessagesContext);
  if (!state) return null;
  const { id, open, message, status } = state;
  function handleClose(id, reason) {
    if (reason === "clickaway") {
      return;
    }
    dispatch(destroyToast(id));
  }
  return (
    <Message
      key={id}
      open={open}
      status={status}
      message={message}
      handleClose={handleClose}
    />
  );
}

Messages.propTypes = {};

export default memo(Messages);
