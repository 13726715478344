import { useRef, useEffect } from "react";
import cornerstone from "cornerstone-core";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import differenceInYears from "date-fns/differenceInYears";
import MaskUtils from "./maskUtils";

export function parseIIN(val) {
  if (!val) throw new Error("iin cannot be empty");
  const firstTwo = val.slice(0, 2);
  let year;
  const month = Number(val.slice(2, 4)) - 1;
  const date = Number(val.slice(4, 6));
  switch (val[6]) {
    case "1":
    case "2":
      year = `18${firstTwo}`;
      break;
    case "3":
    case "4":
      year = `19${firstTwo}`;
      break;
    case "5":
    case "6":
      year = `20${firstTwo}`;
      break;
    default:
      if (Number(firstTwo) > 25) {
        year = `19${firstTwo}`;
      } else {
        year = `20${firstTwo}`;
      }
  }
  const birthday = `${val.slice(4, 6)}.${val.slice(2, 4)}.${year}`;
  const age = differenceInYears(new Date(), new Date(year, month, date));
  const gender = Number(val[6]) % 2 === 0 ? "Ж" : "М";
  return { age, gender, birthday };
}

export function getDataURLFromBytes(blob) {
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.onloadend = function onLoad() {
      resolve(fr.result);
    };
    fr.readAsDataURL(blob);
  });
}

export async function getBase64FromBlob(blob) {
  const dataUrl = await getDataURLFromBytes(blob);
  return dataUrl.substr(dataUrl.indexOf(",") + 1);
}

export function downloadFileObject(file) {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(file);
  link.download = file.name;
  link.type = file.type;
  document.body.appendChild(link);
  link.click();
  setTimeout(function() {
    document.body.removeChild(link);
  }, 1000);
}

export function downloadFileBase64(base64, fileName) {
  const link = document.createElement("a");
  link.href = base64;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  setTimeout(function() {
    document.body.removeChild(link);
  }, 1000);
}

export function getFileFromDataUrl(dataUrl, filename) {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    n -= 1;
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export async function dicomToFile(dicom) {
  const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(dicom);
  const image = await cornerstone.loadImage(imageId);
  const canvas = image.getCanvas();
  return new Promise((res) => {
    canvas.toBlob((blob) => {
      const file = new File([blob], "image.jpeg", {
        type: "image/jpeg",
        lastModified: new Date(),
      });
      res(file);
    });
  });
}

// helper for getting nested value
export const getNested = (p, o) =>
  p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function nullObject(obj) {
  if (!obj) return true;
  return Object.values(obj).every((value) => value === null);
}

export function falsyObject(obj) {
  if (!obj) return true;
  return Object.values(obj).every((value) => !Boolean(value));
}

export function falsyAndEmptyObject(obj) {
  return Object.values(obj).every(
    (value) =>
      !Boolean(value) || (value.constructor === Array && value.length === 0)
  );
}

export function reducerEnhancer(reducer, ...args) {
  return (state, action) => reducer(state, action, ...args);
}

export function mapDictionionaryForSelect(data, lang = "ru") {
  let property = "nameRu";
  if (lang.includes("en")) {
    property = "nameEn";
  } else if (lang.includes("kk")) {
    property = "nameKz";
  }
  return data.map((item) => ({
    value: item.codename,
    label: item[property],
  }));
}

export const mobileMaskUtils = new MaskUtils({ mask: "+7 (799) 999 99 99" });

export function lastNameDotFirstName(firstName, lastName) {
  const fullName = [];
  const fam = lastName?.split(' ')[0];
  const pat = lastName?.split(' ')[1];

  if (fam) {
    fullName.push(fam);
  }
  if (pat) {
    fullName.push(pat.split('')[0]);
  }
  if (firstName) {
    fullName.push(firstName.split('')[0]);
  }
  return fullName.join('.');
}


export * from "./formatters";
