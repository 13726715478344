import React, { memo, useContext } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  IconButton,
  TextField,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { AddCircleOutlined, Edit } from "@material-ui/icons";
import RenderCurrentLang from "../../../components/RenderCurrentLang";
import { descriptionConfig, conclusionConfig } from "../formConfigs";
import { nullObject } from "../../../utils";
import { DictionariesContext } from "../../../globalContexts/DictionariesContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "90%",
    padding: "10px",
    marginBottom: "10px",
    marginTop: "5px",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "36px",
  },
  content: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1.5),
    background: theme.palette.grey[200],
    marginBottom: theme.spacing(1.5),
  },
  select: {
    paddingTop: 0,
  },
}));

function normalizeData(acc, item) {
  acc[item.codename] = { ...item };
  if (item.dictionary) {
    acc[item.codename].dictionary = item.dictionary.reduce(
      (dictAcc, dictItem) => {
        dictAcc[dictItem.codename] = dictItem;
        return dictAcc;
      },
      {}
    );
  }
  return acc;
}

function ConclusionFormToShow({
  label,
  editMode,
  openConclusionDialog,
  description,
  conclusionResult,
}) {
  const classes = useStyles();
  const { t } = useTranslation("research");
  const { structuredConclusionResults, structuredDescriptions } = useContext(
    DictionariesContext
  )[0];
  const normalizedDescriptions = structuredDescriptions.reduce(
    normalizeData,
    {}
  );
  const normalizedConclusions = structuredConclusionResults.reduce(
    normalizeData,
    {}
  );
  return (
    <>
      <Paper className={classes.paper} square elevation={3}>
        {/* CONCLUSION */}
        <div className={classes.heading}>
          <Typography variant="button">
            {t("CONCLUSION")} - {label}:
          </Typography>
          <IconButton disabled={!editMode} onClick={openConclusionDialog}>
            <Edit color="primary" />
          </IconButton>
        </div>
        {!nullObject(conclusionResult) ? (
          <>
            {Object.entries(conclusionResult)
              .filter(([key, value]) => !!value && key !== "OTHER")
              .map(([key, value]) => {
                return (
                  normalizedConclusions[key] && (
                    <Paper key={key} elevation={6} className={classes.content}>
                      <Typography variant="subtitle1">
                        <RenderCurrentLang
                          textObj={normalizedConclusions[key].name}
                        />
                        {conclusionConfig[key].type === "object" &&
                          normalizedConclusions[key].dictionary[value] && (
                            <>
                              :{" "}
                              <RenderCurrentLang
                                textObj={
                                  normalizedConclusions[key].dictionary[value]
                                    .name
                                }
                              />
                            </>
                          )}
                        {conclusionConfig[key].type === "array" && (
                          <ul className="pl-20">
                            {value.map((val) => {
                              return (
                                <li>
                                  -{" "}
                                  <RenderCurrentLang
                                    key={val}
                                    textObj={
                                      normalizedConclusions[key].dictionary[val]
                                        .name
                                    }
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </Typography>
                    </Paper>
                  )
                );
              })}
            {conclusionResult.IMPOSSIBLE && conclusionResult.textImpossible && (
              <TextField
                className="mt-20"
                label={t("IMPOSSIBLE_TEXT")}
                multiline
                disabled
                rows="5"
                fullWidth
                variant="outlined"
                value={conclusionResult.textImpossible}
              />
            )}
            {conclusionResult.OTHER && conclusionResult.textOther && (
              <TextField
                className="mt-20"
                label={t("OTHER_TEXT")}
                multiline
                disabled
                rows="5"
                fullWidth
                variant="outlined"
                value={conclusionResult.textOther}
              />
            )}
          </>
        ) : (
          <Paper elevation={6} className={classes.content}>
            <Typography variant="subtitle1">Не заполнено</Typography>
          </Paper>
        )}
        <Divider />
        {/* DESCRIPTION */}
        <div className={classes.heading}>
          <Typography variant="button">
            {t("DESCRIPTION")} - {label}:
          </Typography>
        </div>
        {/* smells need to refactor */}
        {!nullObject(description) ? (
          <>
            {Object.entries(description)
              // filter out OTHER and falsy values
              .filter(([_key, value]) => !!value)
              .map(([key, value]) => {
                return (
                  normalizedDescriptions[key] && (
                    <Paper key={key} elevation={6} className={classes.content}>
                      <Typography variant="subtitle1">
                        <RenderCurrentLang
                          textObj={normalizedDescriptions[key].name}
                        />
                        :{" "}
                        {descriptionConfig[key].type === "object" && (
                          <RenderCurrentLang
                            textObj={
                              normalizedDescriptions[key].dictionary[value]
                                ?.name
                            }
                          />
                        )}
                        {descriptionConfig[key].type === "array" && (
                          <ul className="pl-20">
                            {value.map((val) => {
                              return (
                                <li key={val}>
                                  -{" "}
                                  <RenderCurrentLang
                                    textObj={
                                      normalizedDescriptions[key].dictionary[
                                        val
                                      ]?.name
                                    }
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </Typography>
                    </Paper>
                  )
                );
              })}
            {description.text && (
              <TextField
                className="mt-20"
                label={t("DESCRIPTION_TEXT")}
                multiline
                disabled
                rows="5"
                fullWidth
                variant="outlined"
                value={description.text}
              />
            )}
          </>
        ) : (
          <Paper elevation={6} className={classes.content}>
            <Typography variant="subtitle1">Не заполнено</Typography>
          </Paper>
        )}
      </Paper>
    </>
  );
}

ConclusionFormToShow.propTypes = {
  label: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,
  openConclusionDialog: PropTypes.func.isRequired,
};

export default memo(ConclusionFormToShow);
