import instance from "../config/request";

async function getRecommendations() {
  return instance.get("/dictionaries/recommendationsWithServiceNames").then((res) => res.data);
}

async function getStructuredDescriptions() {
  return instance
    .get("/dictionaries/structuredDescriptions")
    .then((res) => res.data);
}

async function getStructuredConclusionResults() {
  return instance
    .get("/dictionaries/structuredConclusionResults")
    .then((res) => res.data);
}

export async function getAuthUserConfig() {
  return instance
    .get("/dictionaries/authUserConfig")
    .then((res) => ({
      ...res.data,
      notificationDisabled: !res.data.notificationEnabled,
    }));
}

export async function getInitialDictionaries() {
  const [
    recommendations,
    structuredConclusionResults,
    structuredDescriptions,
  ] = await Promise.all([
    getRecommendations(),
    getStructuredConclusionResults(),
    getStructuredDescriptions(),
  ]);
  return {
    recommendations,
    structuredConclusionResults,
    structuredDescriptions,
  };
}

export async function getServiceNameDictionaries() {
  return instance
    .get("/dictionaries/serviceNames")
    .then((res) => res.data);
}

export async function getMkb10Dictionary() {
  return instance
      .get("/dictionaries/mkb10")
      .then((res) => res.data);
}

export async function getOphthalmologyStatusFields() {
  return instance
      .get("/dictionaries/ophthalmologyStatusFields")
      .then((res) => res.data);
}

export async function getDrugsDictionary() {
  return instance
      .get("/dictionaries/drugs")
      .then((res) => res.data);
}