import { Hidden, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HeaderLeftTeleporter, HeaderRightTeleporter } from "../../components/Header";
import { MyResearchesPageDesktop } from "./desktop/MyResearchesPageDesktop";
import ResearchesList from "../ResearchesPage/ResearchesList";
import ResearchContextProvider from "../ResearchesPage/ResearchContext";
import SupportTelephoneNumber from "../../components/SupportTelephoneNumber";

export function MyResearchPage() {
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const { t } = useTranslation("research");
  return (
    <>
      <HeaderLeftTeleporter.Source>
        <Typography
          variant={isMd ? "body1" : "h6"}
          color="inherit"
          component={Link}
          to="/"
        >
          {isSm ? t("SYSTEM_UDDR_SHORTENED") : t("SYSTEM_UDDR")}
        </Typography>
      </HeaderLeftTeleporter.Source>
      <HeaderRightTeleporter.Source>
            <SupportTelephoneNumber/>
        </HeaderRightTeleporter.Source>
      <Hidden only="xs">
        <MyResearchesPageDesktop />
      </Hidden>
      <Hidden smUp>
        <ResearchContextProvider>
          <ResearchesList />
        </ResearchContextProvider>
      </Hidden>
    </>
  );
}
