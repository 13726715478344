import React from 'react';
import {makeStyles, Popover} from "@material-ui/core";
import {useContext} from "react";
import {ResearchContext, toggleConciliumMenu} from "../ResearchContext";
import ConciliumMenu from "./ConciliumMenu";

const useStyles = makeStyles(() => ({
    conciliumMenu: {
        width: '500px',
        padding: '24px'
    }
}));
const ConciliumMenuPopover = ({conciliumInfo, isConclusionOpened, toggleConclusionOpened, anchorEl}) => {
    const classes = useStyles();
    const [{isConciliumMenuOpen}, dispatch] = useContext(ResearchContext);
    return <Popover open={isConciliumMenuOpen} anchorEl={anchorEl} onClose={() => dispatch(toggleConciliumMenu())}>
        <div className={classes.conciliumMenu}>
            <ConciliumMenu conciliumInfo={conciliumInfo} isConclusionOpened={isConclusionOpened} toggleConclusionOpened={toggleConclusionOpened} />
        </div>
    </Popover>
}

export default ConciliumMenuPopover;