import {
  Box,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import React, { useState } from "react";
import subMonths from "date-fns/subMonths";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { DashboardMixBarCharts } from "./components/DashboardMixBarCharts";
import { formatTimeZero } from "../../../../utils";
import { DashboardBarCharts } from "./components/DashboardBarCharts";
import { DPicker } from "../../../../components/FormComponents";
import {
  getDoctorsData,
  getOrganizationsData,
} from "../../../../api/dashboard";
import { DashboardLineCharts } from "./components/DashboardLineCharts";
import { useRouterQuery } from "../../../../hooks/useRouterQuery";
import { CalendarToday } from "@material-ui/icons";
import subDays from "date-fns/subDays";
import subYears from "date-fns/subYears";
import endOfQuarter from "date-fns/endOfQuarter";
import startOfQuarter from "date-fns/startOfQuarter";

const useStyle = makeStyles({
  root: {
    padding: "10px 30px 10px 20px",
    margin: "20px 10px",
  },
  date: {
    width: "120px",
    border: "1px solid #EEEEEE",
    borderRadius: "4px",
    marginRight: "20px",
  },
  autocomplete: {
    width: 250,
    "&&& $input": {
      padding: "1px",
    },
  },
  diagnos: {
    width: 500,
    "&&& $input": {
      padding: "1px",
    },
  },

  mL: {
    marginLeft: "100px",
  },
  mT: {
    marginTop: "15px",
  },
  toggleButton: {
    margin: "20px 0 0 70px",
  },
  graph: {
    marginTop: "20px",
  },
  barChart: {
    width: 1000,
    height: 500,
    marginTop: "25px",
    marginLeft: "25%",
  },
});

export function ResultLineAndBarChart() {
  const classes = useStyle();
  let defaultFromDate = subMonths(new Date(), 1);
  let todayDate = new Date();
  const [fromDate, setFromDate] = useState(formatTimeZero(defaultFromDate));
  const [beforeDate, setBeforeDate] = useState(todayDate);
  const [conclusionType, setConclusionType] = useState("AUTOCONCLUSION");
  const [organizationFilterData, setOrganizationFilterData] = useState(null);
  const [doctorFilterData, setDoctorFilterData] = useState(null);
  const [dayType, setDayType] = useState("MONTH");
  const [gender, setGender] = useState(null);
  const [diagnos, setDiagnos] = useState(null);

  const handleConclusionType = (event, newAlignment) => {
    if (newAlignment !== null) {
      setConclusionType(newAlignment);
    }
  };
  const handleDayType = (event, newAlignment) => {
    if (newAlignment === "TODAY") {
      setDayType(newAlignment);
      setFromDate(formatTimeZero(todayDate));
      setBeforeDate(todayDate);
    }
    if (newAlignment === "WEEK") {
      setDayType(newAlignment);
      setFromDate(formatTimeZero(subDays(todayDate, 7)));
      setBeforeDate(todayDate);
    }
    if (newAlignment === "MONTH") {
      setDayType(newAlignment);
      setFromDate(defaultFromDate);
      setBeforeDate(todayDate);
    }
    if (newAlignment === "QUARTER") {
      setDayType(newAlignment);
      setFromDate(startOfQuarter(todayDate));
      setBeforeDate(endOfQuarter(todayDate));
    }
    if (newAlignment === "YEAR") {
      setDayType(newAlignment);
      setFromDate(formatTimeZero(subYears(todayDate, 1)));
      setBeforeDate(todayDate);
    }
  };
  const {
    data: doctorsData,
    isLoading: loagingDoctorsQuery,
    error: errorDoctorsQuery,
  } = useQuery(["doctorsData"], () => getDoctorsData());

  const {
    data: organizationsData,
    isLoading: loagingOrganizationsQuery,
    error: errorOrganizationsQuery,
  } = useQuery(["organizationsData"], () => getOrganizationsData());

  const { t } = useTranslation("scientificAnalytics");

  const doctors = doctorsData || [];
  const organizations = organizationsData || [];

  const namePathology = [
    {
      value: "PREPROLIFERATIVE",
      title: "Диабетическая ретинопатия: Препролиферативная",
    },
    {
      value: "NON_PROLIFERATIVE",
      title: "Диабетическая ретинопатия: Непролиферативная",
    },
    {
      value: "PROLIFERATIVE",
      title: "Диабетическая ретинопатия: Пролиферативная",
    },
  ];
  const genderType = [
    {
      value: "FEMALE",
      title: "Женщина",
    },
    {
      value: "MALE",
      title: "Мужчина",
    },
  ];
  const query = {
    fromDate: fromDate,
    beforeDate: beforeDate,
    organizationFilterData: organizationFilterData,
    doctorFilterData: doctorFilterData,
    gender: gender,
    diagnos: diagnos,
    type: conclusionType,
  };
  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item>
          <ToggleButtonGroup
            value={conclusionType}
            onChange={handleConclusionType}
            exclusive
            size="small"
          >
            <ToggleButton value="AUTOCONCLUSION">
              {t("AUTOCONCLUSION")}
            </ToggleButton>
            <ToggleButton value="DOCTOR">{t("DOCTOR")}</ToggleButton>
          </ToggleButtonGroup>
          <DPicker
            placeholder={t("PERIOD_FROM")}
            value={fromDate}
            okLabel={t("OK")}
            clearLabel={t("CLEAR")}
            className={classes.mL}
            cancelLabel={t("CANCEL_LABEL")}
            iconProps={<CalendarToday />}
            shouldDisableDate={beforeDate ? (day) => day > beforeDate : false}
            onChange={(date) => {
              setFromDate(date);
              setDayType(null);
            }}
          />
          <DPicker
            placeholder={t("PERIOD_TO")}
            okLabel={t("OK")}
            clearLabel={t("CLEAR")}
            cancelLabel={t("CANCEL_LABEL")}
            className={classes.mL}
            value={beforeDate}
            shouldDisableDate={fromDate ? (day) => day < fromDate : false}
            onChange={(date) => {
              setBeforeDate(date);
              setDayType(null);
            }}
          />
          <ToggleButtonGroup
            value={dayType}
            onChange={handleDayType}
            exclusive
            size="small"
            className={classes.mL}
          >
            <ToggleButton value="TODAY">{t("TODAY")}</ToggleButton>
            <ToggleButton value="WEEK">{t("WEEK")}</ToggleButton>
            <ToggleButton value="MONTH">{t("MONTH")}</ToggleButton>
            <ToggleButton value="QUARTER">{t("QUARTER")}</ToggleButton>
            <ToggleButton value="YEAR">{t("YEAR")}</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid container className={classes.mT}>
          <Grid item>
            <Typography>{t("DIAGNOSIS")}:</Typography>
            <Autocomplete
              options={namePathology}
              getOptionLabel={(option) => option.title}
              onChange={(event, value) => {
                if (!value) {
                  setDiagnos(null);
                } else {
                  setDiagnos(value.value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("DIAGNOSIS")}
                  variant="outlined"
                  size="small"
                  className={classes.diagnos}
                />
              )}
            />
          </Grid>
          <Grid item className={classes.mL}>
            <Typography>{t("GENDER")}:</Typography>
            <Autocomplete
              id="autocomplete"
              options={genderType}
              getOptionLabel={(option) => option.title}
              onChange={(event, value) => {
                if (!value) {
                  setGender(null);
                } else {
                  setGender(value.title);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  className={classes.autocomplete}
                  label={t("GENDER")}
                />
              )}
            />
          </Grid>
          <Grid item className={classes.mL}>
            <Typography>{t("CHOOSE_ORGANIZATION")}:</Typography>
            <Autocomplete
              id="autocomplete"
              options={organizations}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                if (!value) {
                  setOrganizationFilterData(null);
                } else {
                  setOrganizationFilterData(value.id);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  className={classes.autocomplete}
                  label={t("CHOOSE_ORGANIZATION")}
                />
              )}
            />
          </Grid>
          <Grid item className={classes.mL}>
            <Typography>{t("CHOOSE_DOCTOR")}:</Typography>
            <Autocomplete
              id="autocomplete"
              options={doctors}
              getOptionLabel={(option) => option.fullName}
              onChange={(event, value) => {
                if (!value) {
                  setDoctorFilterData(null);
                } else {
                  setDoctorFilterData(value.username);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  className={classes.autocomplete}
                  label={t("CHOOSE_DOCTOR")}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="space-around" className={classes.graph}>
        <Paper elevation={3} style={{ width: 900, height: 500 }}>
          <DashboardMixBarCharts query={query} />
        </Paper>
        <Paper elevation={3} style={{ width: 800, height: 500 }}>
          <DashboardLineCharts query={query} />
        </Paper>
      </Grid>
      <Paper elevation={3} className={classes.barChart}>
        <DashboardBarCharts query={query} />
      </Paper>
    </Paper>
  );
}
