// @ts-check
import React, { useState, memo, useRef } from "react";
import { Field } from "formik";
import { SizeMe } from "react-sizeme";
import Camera from "./Camera";
import useSocket from "./useSocket";
import { getFileFromDataUrl } from "../../../utils";
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import { FUNDUS_VERSION } from "../../../config/constants";

const flex100 = {
  flex: "0 0 100vw",
  overflow: "hidden",
};

function MobilePatientFields({
  isCreated,
  t,
  isDetails,
  children,
  formikProps,
}) {
  const [socketData, setSocketData] = useState({ data: null, cb: null });
  const valuesRef = useRef(formikProps.values);
  valuesRef.current = formikProps.values;
  function handleAddedFiles(data, cb) {
    if (valuesRef.current.iin && valuesRef.current.iin != data.iin) {
      return setSocketData({ data, cb });
    }
    updateEyes(data);
    cb({ success: true });
  }
  /**
   * @param {"DEFAULT" | "OPTIMA"} fundusVersion
   */
  function handleFundusVersionSet(fundusVersion) {
    if (fundusVersion === FUNDUS_VERSION.OPTIMA) {
      formikProps.values.fundusVersion = FUNDUS_VERSION.OPTIMA;
    }
  }
  function updateEyes(data) {
    const rightEyes = data.rightEyes.map((dataURL) =>
      getFileFromDataUrl(dataURL, "rightEyes")
    );
    const leftEyes = data.leftEyes.map((dataURL) =>
      getFileFromDataUrl(dataURL, "leftEyes")
    );
    formikProps.setValues({
      ...valuesRef.current,
      rightEyes: [...valuesRef.current.rightEyes, ...rightEyes],
      leftEyes: [...valuesRef.current.leftEyes, ...leftEyes],
    });
  }
  // если это детали проекта, то покажи камеры только если его статус CREATED
  const showCameras = !isDetails || isCreated;

  const { status } = useSocket(
    showCameras,
    handleAddedFiles,
    handleFundusVersionSet
  );
  return (
    <>
      <div style={flex100} />
      <div style={flex100}>{children}</div>
      {showCameras && (
        <div style={flex100}>
          <Field
            name="rightEyes"
            render={({ field, form: { setFieldValue } }) => (
              <SizeMe>
                {({ size }) => (
                  <Camera
                    setFieldValue={setFieldValue}
                    name={field.name}
                    label={t("RIGHT") + " (OD)"}
                    value={field.value}
                    size={size}
                    socketStatus={status}
                  />
                )}
              </SizeMe>
            )}
          />
        </div>
      )}
      {showCameras && (
        <div style={flex100}>
          <Field
            name="leftEyes"
            render={({ field, form: { setFieldValue } }) => (
              <SizeMe>
                {({ size }) => (
                  <Camera
                    setFieldValue={setFieldValue}
                    name={field.name}
                    value={field.value}
                    label={t("LEFT") + " (OS)"}
                    size={size}
                    socketStatus={status}
                  />
                )}
              </SizeMe>
            )}
          />
        </div>
      )}
      <Dialog open={Boolean(socketData.data && socketData.cb)}>
        <DialogContent>
          <Typography variant="h6" color="secondary" paragraph align="center">
            {t("SOCKET_IIN_ERROR_MAIN")}
          </Typography>
          <Typography paragraph align="center">
            {t("SOCKET_IIN_ERROR_DESCRIPTION")}
          </Typography>
          <Grid container justify="space-between">
            <Button
              className="mb-8"
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => {
                updateEyes(socketData.data);
                socketData.cb({ success: true });
                setSocketData({ data: null, cb: null });
              }}
            >
              {t("LOAD_ANYWAY")}
            </Button>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              onClick={() => {
                socketData.cb({ success: false });
                setSocketData({ data: null, cb: null });
              }}
            >
              {t("CANCEL_LOAD")}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default memo(MobilePatientFields);
