import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Close, ImportExport } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { RESEARCH_SERVICE_NAME } from "../constants";
import {
  getAutorefractomy,
  getExophthalmometry,
  getMaklakovTest,
  getNonContactTonometry,
  getRefferers,
  getSchirmerTest,
} from "../../../api";
import { DictionariesContext } from "../../../globalContexts/DictionariesContext";
import { parseIIN } from "../../../utils";
import { OtherResearchServiceForm } from "./Forms/OtherResearchServiceForm";
import { useQuery } from "react-query";
import { AutorefractomyServiceForm } from "./Forms/AutorefractomyServiceForm";

const useStyles = makeStyles(() => ({
  paper: {
    padding: "50px 24px",
    position: "relative",
    width: "80vw",
    maxHeight: "90vh",
  },
  closeIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  formWrapper: {
    overflow: "auto",
    maxHeight: "80vh",
  },
  field: {
    marginLeft: "25px",
    marginTop: "10px",
    width: "70%",
  },
  hidden: {
    display: "none",
  },
}));

export function ResearchServiceDetails({ onClose, research }) {
  const { t } = useTranslation(["research", "serviceNamesDict"]);

  function getResearchServiceDetails() {
    if (research.serviceName === RESEARCH_SERVICE_NAME.NON_CONTACT_TONOMETRY) {
      return getNonContactTonometry(research.id);
    }
    if (research.serviceName === RESEARCH_SERVICE_NAME.SCHIRMER_TEST) {
      return getSchirmerTest(research.id);
    }
    if (research.serviceName === RESEARCH_SERVICE_NAME.MAKLAKOV_TEST) {
      return getMaklakovTest(research.id);
    }
    if (research.serviceName === RESEARCH_SERVICE_NAME.EXOPHTHALMOMETRY) {
      return getExophthalmometry(research.id);
    }
    if (research.serviceName === RESEARCH_SERVICE_NAME.AUTOREFRACTOMY) {
      return getAutorefractomy(research.id);
    }
  }

  const { data: otherResearchData, isLoading, error } = useQuery(
    ["OtherResearchServiceDetails", research.id],
    () => getResearchServiceDetails()
  );
  const { data: allRefferers, isLoading: allRefferersLoading } = useQuery(
    ["allRefferersData"],
    () => getRefferers()
  );

  const referrerData = allRefferersLoading ? [] : allRefferers;
  const researchData = isLoading ? [] : otherResearchData;

  const classes = useStyles();

  const {
    authUserConfig: { smsEnabled },
  } = useContext(DictionariesContext)[0];

  const { register, control, setValue, watch, errors } = useForm({
    reValidateMode: "onBlur",
  });
  const [showRows, setShowRows] = useState(false);

  useEffect(() => {
    const { age, gender } = parseIIN(research.iin);
    setValue("agePatient", age);
    setValue("genderPatient", gender);
  }, [research.iin, setValue, showRows]);
  return (
    <Paper className={classes.paper}>
      <Close className={classes.closeIcon} onClick={onClose} />
      <div className={classes.formWrapper}>
        <form className={classes.form}>
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"space-between"}
          >
            <span>{t("PATIENT_IIN")}</span>
            <Grid container direction={"column"} className={classes.field}>
              <TextField
                value={research?.iin}
                inputProps={{ readOnly: true }}
                size={"small"}
                variant={"outlined"}
              />
            </Grid>
          </Grid>
          <Box height={"12px"} />
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"space-between"}
          >
            <span>{t("PATIENT_FULL_NAME")}</span>
            <Grid container direction={"column"} className={classes.field}>
              <TextField
                size={"small"}
                variant={"outlined"}
                value={research.fullName}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
          <Box height={"12px"} />
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"space-between"}
          >
            <span>{t("SERVICE_NAME")}</span>
            <Grid container direction={"column"} className={classes.field}>
              <TextField
                value={t(`serviceNamesDict:${research.serviceName}`)}
                inputProps={{ readOnly: true }}
                size="small"
                variant="standard"
              />
            </Grid>
          </Grid>
          <Box height={"12px"} />
          {research.serviceName !==
            RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY &&
            research.serviceName !==
              RESEARCH_SERVICE_NAME.OPHTHALMOLOGIST_CONSULTATION &&
            research.serviceName !== RESEARCH_SERVICE_NAME.AUTOREFRACTOMY && (
              <OtherResearchServiceForm
                register={register}
                serviceName={research.serviceName}
                data={{ odMm: researchData.odMm, osMm: researchData.osMm }}
                details={true}
              />
            )}
          {research.serviceName === RESEARCH_SERVICE_NAME.AUTOREFRACTOMY && (
            <AutorefractomyServiceForm
              register={register}
              details={true}
              watch={watch}
              errors={errors}
              data={researchData}
            />
          )}
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"space-between"}
            className={!showRows && !smsEnabled ? classes.hidden : ""}
          >
            <span>{t("PHONE")}</span>
            <Grid container direction={"column"} className={classes.field}>
              <TextField
                size={"small"}
                inputProps={{ readOnly: true }}
                value={research.phone}
                variant={"outlined"}
              />
            </Grid>
          </Grid>
          <Box height={"12px"} />
          <Grid className={!showRows ? classes.hidden : ""}>
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("AGE")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <Controller
                  name={"agePatient"}
                  control={control}
                  as={
                    <TextField
                      type={"number"}
                      size={"small"}
                      variant={"outlined"}
                      inputProps={{ readOnly: true }}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("GENDER")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <Controller
                  name={"genderPatient"}
                  control={control}
                  as={
                    <TextField
                      size={"small"}
                      variant={"outlined"}
                      inputProps={{ readOnly: true }}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("SERVICE_TYPE")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <TextField
                  value={t(`${researchData?.serviceType}`)}
                  inputProps={{ readOnly: true }}
                  size="small"
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("REFERRER")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <TextField
                  size="small"
                  variant="standard"
                  inputProps={{ readOnly: true }}
                  value={
                    referrerData?.referrers?.length > 0
                      ? referrerData.referrers.find(
                          (item) => item.id === `${research?.referrerId}`
                        )?.fullName
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("RECEPTION_DOCTOR")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <TextField
                  size="small"
                  variant="standard"
                  value={research.receptionDoctorFullName}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("COMPLAINTS")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <TextField
                  size={"small"}
                  variant={"outlined"}
                  inputProps={{ readOnly: true }}
                  value={research?.complaints}
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <Grid>
                <span>{t("DIABETES")}</span>
                <Checkbox checked={research.diabetes} />
              </Grid>
              <Grid>
                <span>{t("ARTERIAL_HYPERTENSION")}</span>
                <Checkbox checked={research.arterialHypertension} />
              </Grid>
            </Grid>
          </Grid>
          <Box height={"12px"} />
          <Grid container direction={"row"} justify={"flex-end"}>
            {research.serviceName !==
              RESEARCH_SERVICE_NAME.NON_CONTACT_TONOMETRY && (
              <IconButton
                color="primary"
                onClick={() => {
                  setShowRows(!showRows);
                }}
              >
                <ImportExport />
              </IconButton>
            )}
          </Grid>
        </form>
      </div>
    </Paper>
  );
}
