import React, { useContext } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { UserContext } from "../../globalContexts/UserContext";
import {
  createToast,
  MessagesContext,
} from "../../globalContexts/MessagesContext";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { updateUserPassword } from "../../api/registrationApi";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    maxWidth: "1200px",
    width: "100%",
    padding: "50px 40px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "500",
  },
  typography: {
    width: "200px",
    "@media (max-width: 600px)": { display: "none" },
    display:"flex",
    alignItems:"center",
  },
  root__box: {
    display: "flex",
    marginTop: "24px",
  },
  submit__button: {
    marginTop: "40px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export function ChangePasswordPage() {
  const classes = useStyles();
  const { t } = useTranslation("registrationPage");
  const { user } = useContext(UserContext);
  const dispatchMessage = useContext(MessagesContext)[1];
  const sixHundred = useMediaQuery("(min-width:600px)");

  const [oldPass, setOldPass] = React.useState(false);
  const [newPass, setNewPass] = React.useState(false);
  const handleOldPass = () => setOldPass(!oldPass);
  const handleNewPass = () => setNewPass(!newPass);

  const { mutate } = useMutation(
    async ({ userName, data }) => {
      await updateUserPassword({ userName, data });
    },
    {
      onSuccess: () => {
        dispatchMessage(
          createToast(t("PASSWORD_SUCCESSFULLY_CHANGED"), "success")
        );
      },
    }
  );
  const { register, handleSubmit, errors, watch } = useForm();
  const newPassword = watch("newPassword");
  async function onSubmit(data) {
    const passwords = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    };
    mutate({ userName: user.name, data: passwords });
  }
  return (
    <Paper className={classes.root}>
      <Typography className={classes.title}>{t("CHANGE_PASSWORD")}</Typography>
      <form onSubmit={handleSubmit(onSubmit)} id="changePassword">
        <div className={classes.root__box}>
          <Typography className={classes.typography}>
            {t("OLD_PASSWORD")}*:
          </Typography>
          <TextField
            type="password"
            name="oldPassword"
            inputRef={register({ required: true })}
            variant="outlined"
            size="small"
            fullWidth
            label={sixHundred ? "" : t("OLD_PASSWORD")}
            error={Boolean(errors.password)}
            helperText={Boolean(errors.password) ? t("REQUIRED_FIELD") : ""}
          />
        </div>
        <div className={classes.root__box}>
          <Typography className={classes.typography}>
            {t("NEW_PASSWORD")}*:
          </Typography>
          <TextField
            type={oldPass ? "text" : "password"}
            name="newPassword"
            inputRef={register({
              required: true,
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*?]{8,}$/,
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleOldPass} edge="end">
                    {oldPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            fullWidth
            label={sixHundred ? "" : t("NEW_PASSWORD")}
            error={Boolean(errors.newPassword)}
            helperText={Boolean(errors.newPassword) ? t("RULE_PASSWORD") : ""}
          />
        </div>
        <div className={classes.root__box}>
          <Typography className={classes.typography}>
            {t("REPEAT_NEW_PASSWORD")}*:
          </Typography>
          <TextField
            type={newPass ? "text" : "password"}
            name="repeatNewPassword"
            inputRef={register({
              required: true,
              validate: (value) => value === newPassword,
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleNewPass} edge="end">
                    {newPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            fullWidth
            label={sixHundred ? "" : t("REPEAT_NEW_PASSWORD")}
            error={Boolean(errors.repeatNewPassword)}
            helperText={Boolean(errors.repeatNewPassword) ? t("PASSWORD_DOES_NOT_MATCH") : ""}
          />
        </div>

        <Grid className={classes.submit__button}>
          <Button
            style={{ width: "100%", maxWidth: "250px" }}
            type="submit"
            variant="contained"
            color="primary"
            form="changePassword"
          >
            {t("CHANGED_PASSWORD")}
          </Button>
        </Grid>
      </form>
    </Paper>
  );
}
