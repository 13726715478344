import {Box, Button, Grid, makeStyles, Modal, Paper, TextField, Typography} from "@material-ui/core";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Close} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import Select from "../../../../components/MuiSelect";
import {RESEARCH_SERVICE_NAME, TREATMENT_TYPES} from "../../../ResearchesPage/constants";
import MedicalTreatmentForm from "./Forms/MedicalTreatmentForm";

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        width: '60vw',
        padding: '24px',
        maxHeight: '90vh',
        overflowY: 'auto',
        position: 'relative'
    },
    bold: {
        fontWeight: 'bold'
    },
    field: {
        marginLeft: '25px',
        width: '70%'
    },
    closeIcon: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        cursor: 'pointer',
    },
    input: {
        display: 'flex'
    }
}));

const TreatmentForms = {
    [TREATMENT_TYPES.MEDICAL_TREATMENT]: MedicalTreatmentForm,
}



const CreateFormModal = ({open, onClose, currentTreatments}) => {
    const classes = useStyles();
    const {t, i18n} = useTranslation(['patient-registry']);
    const treatmentOptions = Object.values(TREATMENT_TYPES).map((value) => ({value, label: t( value)}))
    const [treatmentChosen, setTreatmentChosen] = useState(treatmentOptions[0]);
    const ref = useRef();
    const isTreatmentChosen = Number.isInteger(currentTreatments?.chosenDrugIndex);
    const isTreatmentEditing = Number.isInteger(currentTreatments?.editingDrugIndex);

    const FormComponent = TreatmentForms[treatmentChosen?.value] || null;

    useEffect(() => {
        if(treatmentChosen) {
            setTreatmentChosen({...treatmentChosen, label: t(treatmentChosen.value)})
        }
    }, [i18n.language])

    return <Modal open={open} onClose={!isTreatmentChosen ? () => window.confirm(t('CONFIRM_CLOSE')) && onClose() : onClose} className={classes.modal}>
        <Paper className={classes.modalContent}>
                <Close className={classes.closeIcon} onClick={onClose} />
                <Typography className={classes.bold}>
                    {t('TREATMENT')}
                </Typography>
                <Select isDisabled={isTreatmentChosen} options={treatmentOptions} value={treatmentChosen} onChange={(service) => setTreatmentChosen(service)} />
                <Box height={'24px'} />
                {
                    FormComponent && <FormComponent currentTreatments={currentTreatments} ref={ref} onClose={onClose} />
                }
                {
                    isTreatmentChosen && (
                        <>
                            <Box height={'24px'} />
                            <Grid container justify={'flex-end'}>
                                <Button onClick={onClose} variant={'contained'} color={'secondary'}>
                                    {t('CLOSE')}
                                </Button>
                            </Grid>
                        </>
                    )
                }
                {
                    isTreatmentEditing && (
                        <>
                            <Box height={'24px'} />
                            <Grid container justify={'flex-end'}>
                                <Button onClick={() => ref.current.reportValidity() && ref.current.dispatchEvent(new Event("submit"))} variant={'contained'} color={'secondary'}>
                                    {t('SAVE')}
                                </Button>
                            </Grid>
                        </>
                    )
                }
                {
                    !isTreatmentChosen && !isTreatmentEditing && <>
                        <Box height={'24px'} />
                        <Grid container justify={'flex-end'}>
                            <Button onClick={() => ref.current.reportValidity() && ref.current.dispatchEvent(new Event("submit"))} variant={'contained'} color={'primary'}>
                                {t('ADD_MORE')}
                            </Button>
                            <Box width={'6px'}/>
                            <Button onClick={() => ref.current.reportValidity() && ref.current.dispatchEvent(new Event("submit")) && onClose()} variant={'contained'} color={'secondary'}>
                                {t('SAVE_AND_CLOSE')}
                            </Button>
                        </Grid>
                    </>
                }
            </Paper>
    </Modal>
}

export default CreateFormModal;