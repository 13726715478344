import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Brightness1 } from "@material-ui/icons";
import React from "react";
import { useRouterQuery } from "../../../../../hooks/useRouterQuery";
import { RESEARCH_STATUS } from "../../../constants";
import { useParams } from "react-router-dom";
import { getStatusIcon } from "../../../ResearchesList/ResearchJournalFilters";
import { formatDateAndTime } from "../../../../../utils";
import { useResearchDetails } from "../../queryHooks";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 76px)",
    margin: "6px",
    width: "auto",
  },
  progressBar: {
    width: "100%",
  },
  imageGridContainer: {
    overflowX: "auto",
  },
  imageGridItem: {
    flex: "0 0 33.3%",

    "& > img": {
      display: "block",
    },

    "&[data-active=true]": {
      border: "1px solid red",
    },
  },
  padding10: {
    padding: "10px",
  },
  active: {
    background: theme.palette.grey.A200,
  },
}));

export function AppointmentCardContentMulti(props) {
  const { research, t } = props;
  const classes = useStyles();
  const [query, handleReplace] = useRouterQuery();
  const activeImg = query.get("activeImg");
  const { id } = useParams();

  const [{ data, isLoading }] = useResearchDetails(research.id);
  function getStatusClassName(side) {
    if (RESEARCH_STATUS.FINISHED === research.status) {
      return "text-green";
    }
    if (RESEARCH_STATUS.CANCELLED === research.status) {
      return "text-red";
    }
    return "text-orange";
  }

  if (isLoading || !data) {
    return (
      <Grid
        container
        alignItems={"center"}
        justifyContent={"center"}
        className={classes.padding10}
      >
        <CircularProgress />
      </Grid>
    );
  }
  const { leftEyes, rightEyes } = data;

  return (
    <>
      {rightEyes?.length > 0 && (
        <div
          className={`${Number(id) === Number(research.id) && classes.active}`}
        >
          <Grid
            container
            justify="space-between"
            className="pl-4 pr-4"
            title={t(`STATUS_${research.status}`)}
          >
            <Typography>OD 1/{rightEyes.length}</Typography>
            <Brightness1 className={getStatusClassName("rightEyeConclusion")} />
          </Grid>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            className={classes.imageGridContainer}
          >
            {rightEyes.map(({ id, smallUrl, contentType }) => (
              <Grid
                key={id}
                item
                xs={4}
                onClick={() => handleReplace("activeImg", id, research.id)}
                data-active={activeImg === id}
                className={classes.imageGridItem}
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData("researchId", research.id);
                  e.dataTransfer.setData("id", id);
                  e.dataTransfer.setData("side", "rightEyes");
                }}
              >
                <img
                  width="100%"
                  src={`${smallUrl}`}
                  alt="rightEyes"
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {leftEyes?.length > 0 && (
        <div
          className={`${Number(id) === Number(research.id) && classes.active}`}
        >
          <Grid
            container
            justify="space-between"
            className="pl-4 pr-4"
            title={t(`STATUS_${research.status}`)}
          >
            <Typography>OS 1/{leftEyes.length}</Typography>
            <Brightness1 className={getStatusClassName("leftEyeConclusion")} />
          </Grid>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            className={classes.imageGridContainer}
          >
            {leftEyes.map(({ id, smallUrl, contentType }) => (
              <Grid
                key={id}
                item
                xs={4}
                data-active={activeImg === id}
                onClick={() => handleReplace("activeImg", id, research.id)}
                className={classes.imageGridItem}
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData("researchId", research.id);
                  e.dataTransfer.setData("id", id);
                  e.dataTransfer.setData("side", "leftEyes");
                }}
              >
                <img
                  width="100%"
                  src={`${smallUrl}`}
                  alt="leftEyes"
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
}

export function AppointmentCardHeaderMulti(props) {
  const { research, t } = props;
  const { id } = useParams();
  const classes = useStyles();

  return (
    <Grid
      className={`pointer pt-4 pl-8 pr-8 ${Number(id) === Number(research.id) &&
        classes.active}`}
      container
      alignItems="center"
      justify="space-between"
      wrap="nowrap"
    >
      {getStatusIcon(RESEARCH_STATUS.FINISHED, { color: "primary" })}
      <div>
        <Typography align="center">
          {formatDateAndTime(research.createdTimestamp)}
        </Typography>
        <Typography align="center">
          {t("STATUS")}: {t(`STATUS_${research.status}`)}
        </Typography>
      </div>
    </Grid>
  );
}
