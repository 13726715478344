import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHeadComponent from "./TableHeadComponent";
import Paper from "@material-ui/core/Paper";
import TableBodyComponent from "./TableBodyComponent";
import {
  TableContext,
  setTableData,
  handleRequestSort,
  handleSelectAll,
  handleSelectRow,
} from "../../globalContexts/TableContext";
import TableFilters from "./TableFilters";
import { CircularProgress, useMediaQuery } from "@material-ui/core";
import { usePrevious } from "../../utils";
import TablePaginationComponent from "./TablePaginationComponent";
import MobileTable from "../MobileTable";

const DEBOUNCE_TIME = 500;

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    position: "relative",
    boxShadow: theme.shadows[2],
  },
  table: ({ minWidth }) => ({
    minWidth: minWidth || 600,
  }),
  loader: {
    position: "absolute",
    height: "100%",
    zIndex: 100,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, .12)",
    alignItems: "center",
  },
}));

export default function MuiRemoteTable(props) {
  const [state, dispatch] = useContext(TableContext);
  const isMobile = useMediaQuery(`(max-width:${props.mobileWidth || 600}px)`);
  const {
    filters,
    initialState,
    headerProps,
    bodyProps,
    paginationProps,
    tableProps,
    mobileProps,
    fetchData,
    tableName,
    isBordered,
    isSelectable,
    minWidth,
    columns,
  } = props;
  const {
    isLoading,
    data,
    meta,
    mobileData,
    total,
    isAllSelected,
    isIndeterminated,
    order,
    orderBy,
    propFilters,
    selectedRows,
    search,
    page,
    per_page,
  } = state[tableName] || initialState;

  // build props for components
  const _headerProps = {
    ...headerProps,
    isAllSelected,
    isSelectable,
    isBordered,
    columns,
    isIndeterminated,
    onSelectAllClick: () => dispatch(handleSelectAll(tableName)),
    onRequestSort: (field) => dispatch(handleRequestSort(tableName, field)),
    order,
    orderBy,
  };
  const _filterProps = {
    search,
    propFilters,
    handleSearch: (e) => {
      dispatch(setTableData(tableName, { search: e.target.value }));
    },
  };
  const _bodyProps = {
    ...bodyProps,
    data,
    selectedRows,
    isSelectable,
    handleSelectRow: ({ target: { name, checked } }) =>
      dispatch(handleSelectRow(tableName, name, checked)),
    columns,
    meta,
    total,
    isBordered,
  };
  const _paginationProps = {
    ...paginationProps,
    page,
    total,
    per_page,
    handleChangePage: (page) => dispatch(setTableData(tableName, { page })),
    handleChangeRowsPerPage: (e) =>
      dispatch(
        setTableData(tableName, { per_page: parseInt(e.target.value, 10) })
      ),
  };

  const classes = useStyles({ minWidth });
  // build query
  const query = {
    order,
    orderBy,
    propFilters,
    search,
    page,
    per_page,
  };

  const _mobileProps = {
    ...mobileProps,
    mobileData,
    fetchData: () => request(query),
    total,
  };
  async function request(query) {
    try {
      if (isMobile) {
        query.offset = query.resetMobile ? "0" : String(mobileData.length);
        const { data, total } = await fetchData(query);
        dispatch(
          setTableData(tableName, {
            mobileData: query.resetMobile ? data : [...mobileData, ...data],
            total,
          })
        );
        // scroll to y if there is one
        const scrollY = localStorage.getItem(`${tableName}_scrollY`);
        scrollY && window.scroll(0, scrollY);
        // cleaning
        scrollY == window.scrollY &&
          localStorage.removeItem(`${tableName}_scrollY`);
      } else {
        const res = await fetchData(query);
        dispatch(setTableData(tableName, res));
      }
      dispatch(setTableData(tableName, { isLoading: false }));
    } catch (error) {
      console.error(error);
      dispatch(setTableData(tableName, { isLoading: false }));
    }
  }

  const searchPrevValue = usePrevious(search);
  const pagePrevValue = usePrevious(page);

  useEffect(() => {
    if (isMobile) {
      query.resetMobile = true;
    }
    if (pagePrevValue === page) {
      query.page = 0;
    }
    if (searchPrevValue !== search) {
      const timerid = setTimeout(() => {
        dispatch(setTableData(tableName, { isLoading: true }));
        request(query);
      }, DEBOUNCE_TIME);
      return () => clearTimeout(timerid);
    }
    dispatch(setTableData(tableName, { isLoading: true }));
    request(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page, per_page, order, orderBy, propFilters, isMobile]);
  return (
    <Paper className={classes.paper}>
      {isLoading && (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      )}
      <TableFilters {..._filterProps} filters={filters} />
      {isMobile ? (
        <MobileTable {..._mobileProps} />
      ) : (
        <>
          <Table className={classes.table} size="small" {...tableProps}>
            <TableHeadComponent {..._headerProps} />
            <TableBodyComponent {..._bodyProps} />
          </Table>
          <TablePaginationComponent {..._paginationProps} />
        </>
      )}
    </Paper>
  );
}
