import * as Yup from "yup";

export const simpleServiceSchema = Yup.object().shape({
  odMm: Yup.number().required(),
  osMm: Yup.number().required(),
});
export const autorefractomyServiceSchemaShape = {
  odSph: Yup.number().required(),
  odCyl: Yup.number().required(),
  odAx: Yup.number().required(),
  odR1Mm: Yup.number().required(),
  odR1D: Yup.number().required(),
  odR1Ax: Yup.number().required(),
  odR2Mm: Yup.number().required(),
  odR2D: Yup.number().required(),
  odR2Ax: Yup.number().required(),
  odAveMm: Yup.number().required(),
  odAveD: Yup.number().required(),
  odAveAx: Yup.number().required(),
  odCylMm: Yup.number().required(),
  odCylD: Yup.number().required(),
  odCylAx: Yup.number().required(),
  osSph: Yup.number().required(),
  osCyl: Yup.number().required(),
  osAx: Yup.number().required(),
  osR1Mm: Yup.number().required(),
  osR1D: Yup.number().required(),
  osR1Ax: Yup.number().required(),
  osR2Mm: Yup.number().required(),
  osR2D: Yup.number().required(),
  osR2Ax: Yup.number().required(),
  osAveMm: Yup.number().required(),
  osAveD: Yup.number().required(),
  osAveAx: Yup.number().required(),
  osCylMm: Yup.number().required(),
  osCylD: Yup.number().required(),
  osCylAx: Yup.number().required(),
};
export const autorefractomyServiceSchema = Yup.object().shape(
  autorefractomyServiceSchemaShape
);

export const recommendationSchema = Yup.object().shape({
  codenames: Yup.array()
    .min(1, "REQUIRED")
    .typeError("REQUIRED"),
  text: Yup.string().required(),
});
