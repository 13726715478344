import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import {
  CONCILIUM_STATUS,
  CONCLUSION_TYPE,
  RESEARCH_STATUS,
} from "../constants";
import { useTranslation } from "react-i18next";
import {
  ResearchContext,
  setAnnotationPreconclusion,
  setAutoPreconclusion,
  setConciliumPreconclusion,
  setSharedPreconclusion,
} from "../ResearchContext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    position: "absolute",
    width: "90%",
    right: 0,
    zIndex: 1000,
    top: "100%",
    background: theme.palette.background.paper,
    border: "2px solid #e0e0e0",
    maxHeight: "500px",
    flexWrap: "nowrap",
    overflowY: "auto",
    overflowX: "hidden",
  },
  row: {
    width: "100%",
    padding: "10px 15px",
    cursor: "pointer",
    "&:hover": {
      background: "#e0e0e0",
    },
  },
  inactive: {
    "&:hover": {
      background: theme.palette.background.paper,
    },
  },
  [CONCILIUM_STATUS.PRECONCLUSION_GIVEN]: {
    background: "green",
  },
  [CONCILIUM_STATUS.REJECTED]: {
    background: "darkred",
  },
  [CONCILIUM_STATUS.ACCEPTED]: {
    background: "orange",
  },
  [CONCILIUM_STATUS.PENDING]: {
    background: "black",
  },
  active: {
    background: "#e0e0e0",
  },
}));

function useOutsideAlerter(ref, setShowMemberList) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowMemberList(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const PreconclusionPicker = ({
  conciliumMembers,
  sharedMembers,
  setShowMemberList,
  annotationMembers,
  status,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("research");
  const ref = useRef(null);
  useOutsideAlerter(ref, setShowMemberList);
  const [{ preConclusionType }, dispatch] = useContext(ResearchContext);

  return (
    <Grid ref={ref} container direction={"column"} className={classes.root}>
      {(status === RESEARCH_STATUS.FINISHED ||
        status === RESEARCH_STATUS.CANCELLED) && (
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justify={"space-between"}
          className={`${classes.row} ${
            preConclusionType.type === CONCLUSION_TYPE.AUTO_PRECONCLUSION
              ? classes.active
              : ""
          } `}
          onClick={() => {
            dispatch(setAutoPreconclusion(t("AUTO_CONCLUSION")));
            setShowMemberList(false);
          }}
        >
          <Typography>{t("AUTO_CONCLUSION")}</Typography>
        </Grid>
      )}
      <Typography>
        {conciliumMembers?.length > 0 && `${t("CONSILIUM")}:`}
      </Typography>
      {conciliumMembers?.map(({ username, fullName, status }) => (
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justify={"space-between"}
          key={username}
          className={`${classes.row}  ${status !==
            CONCILIUM_STATUS.PRECONCLUSION_GIVEN && classes.inactive} ${
            username === preConclusionType.chooseConcilium.username
              ? classes.active
              : ""
          } `}
          onClick={() => {
            if (status === CONCILIUM_STATUS.PRECONCLUSION_GIVEN) {
              dispatch(setConciliumPreconclusion(username, fullName));
              setShowMemberList(false);
            }
          }}
          title={t(status)}
        >
          <Typography>{fullName}</Typography>
          <Box
            width={"20px"}
            height={"20px"}
            borderRadius={"50%"}
            className={classes[status]}
          />
        </Grid>
      ))}
      <Typography>{sharedMembers?.length > 0 && `${t("SHARED")}:`}</Typography>
      {sharedMembers.map(({ id }, index) => (
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justify={"space-between"}
          key={id}
          className={`${classes.row} ${
            id === preConclusionType.chooseShared.id ? classes.active : ""
          } `}
          onClick={() => {
            dispatch(
              setSharedPreconclusion(id, `${t("PRE_CONCLUSION")} ${index + 1}`)
            );
            setShowMemberList(false);
          }}
        >
          <Typography>
            {t("PRE_CONCLUSION")} {index + 1}
          </Typography>
        </Grid>
      ))}
      <Typography>
        {annotationMembers?.length > 0 && `${t("ANNOTATOR")}:`}
      </Typography>
      {annotationMembers.map(({ username, annotatorFullName }) => (
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justify={"space-between"}
          key={username}
          className={`${classes.row} ${
            username === preConclusionType.chooseAnnotator.username
              ? classes.active
              : ""
          } `}
          onClick={() => {
            dispatch(setAnnotationPreconclusion(username, annotatorFullName));
            setShowMemberList(false);
          }}
        >
          <Typography>{annotatorFullName}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

PreconclusionPicker.propTypes = {
  conciliumMembers: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    })
  ).isRequired,
  sharedMembers: PropTypes.array.isRequired,
  annotationMembers: PropTypes.array.isRequired,
};

export default PreconclusionPicker;
