import { Hidden, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import AnnotatorMobilePage from "./AnnotatorMobilePage";
import AnnotatorDesctopPage from "./AnnotatorDesctopPage";
import { HeaderLeftTeleporter } from "../../components/Header";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function AnnotatorPage() {
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const { t } = useTranslation("research");
  return (
    <>
      <HeaderLeftTeleporter.Source>
        <Typography
          variant={isMd ? "body1" : "h6"}
          color="inherit"
          component={Link}
          to="/"
        >
          {isSm ? t("SYSTEM_UDDR_SHORTENED") : t("SYSTEM_UDDR")}
        </Typography>
      </HeaderLeftTeleporter.Source>
      <Hidden only="xs">
        <AnnotatorDesctopPage />
      </Hidden>
      <Hidden smUp>
        <AnnotatorMobilePage />
      </Hidden>
    </>
  );
}
