import React, { useState } from "react";
import {
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DashboardRadialBars } from "./components/DashboardRadialBars";
import { useTranslation } from "react-i18next";
import { useCubeQuery } from "@cubejs-client/react";

const useStyle = makeStyles({
  root: {
    padding: "10px 30px 10px 20px",
    margin: "20px 10px",
    height: "150px",
  },
  buttonGroup: {
    margin: "10px 0",
  },
  info: {
    display: "flex",
  },
});
const dataRadialBars = {
  MALE: { title: "MALE", color: "#7DB3FF" },
  FEMALE: { title: "FEMALE", color: "#49457B" },
};

export function ResultDashboardRadialBar() {
  const classes = useStyle();
  const [togglebutton, setToggleButton] = useState("Today");
  const handleToggleButton = (event, newAlignment) => {
    if (newAlignment !== null) {
      setToggleButton(newAlignment);
    }
  };
  const { t } = useTranslation("scientificAnalytics");
  const { resultSet, isLoading, error, progress } = useCubeQuery({
    measures: ["Research.count"],
    timeDimensions: [
      {
        dimension: "Research.createdTimestamp",
        dateRange: togglebutton || undefined,
      },
    ],
    order: {
      "Research.count": "desc",
    },
    dimensions: ["Research.gender"],
  });

  if (isLoading) {
    return <div>{progress?.stage?.stage || <CircularProgress />}</div>;
  }

  if (error) {
    return <div>{error.toString()}</div>;
  }

  const dataResult = [];
  let conutAllData = 0;
  resultSet?.tablePivot().forEach((row) => {
    if (["Женщина"].includes(row["Research.gender"])) {
      dataResult.push({
        title: "FEMALE",
        value: Number(row["Research.count"]),
      });
    }
    if (["Мужчина"].includes(row["Research.gender"])) {
      dataResult.push({
        title: "MALE",
        value: Number(row["Research.count"]),
      });
    }

    conutAllData += Number(row["Research.count"]);
  });

  return (
    <Paper className={classes.root}>
      <ToggleButtonGroup
        value={togglebutton}
        onChange={handleToggleButton}
        exclusive
        size="small"
        className={classes.buttonGroup}
      >
        <ToggleButton value="Today">{t("TODAY")}</ToggleButton>
        <ToggleButton value="">{t("ALL_TIME")}</ToggleButton>
      </ToggleButtonGroup>
      <Grid container justify="space-around">
        <div>
          <Typography
            style={{
              color: "#F07978",
              fontSize: "18px",
            }}
          >
            {t("TOTAL_PATIENTS")}
          </Typography>
          <Typography
            align="center"
            style={{
              color: "#F07978",
              fontSize: "23px",
            }}
          >
            {conutAllData}
          </Typography>
        </div>
        {dataResult.map((row) => {
          const percent =
            conutAllData === 0
              ? 0
              : ((row.value / conutAllData) * 100).toFixed(0);
          return (
            <Grid className={classes.info} key={row.title}>
              <div>
                <Typography
                  style={{
                    color: `${dataRadialBars[row.title].color}`,
                    fontSize: "18px",
                  }}
                >
                  {t(dataRadialBars[row.title].title)}
                </Typography>
                <Typography
                  align="center"
                  style={{
                    color: `${dataRadialBars[row.title].color}`,
                    fontSize: "23px",
                  }}
                >
                  {row.value}
                </Typography>
              </div>
              <DashboardRadialBars
                data={[
                  {
                    name: t(dataRadialBars[row.title].title),
                    value: percent,
                    fill: dataRadialBars[row.title].color,
                  },
                ]}
              />
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
}
