import {Box, Grid, makeStyles, Modal, Paper, Typography} from "@material-ui/core";
import React from 'react';
import {Close, Edit, Visibility} from "@material-ui/icons";
import {formatDate} from "../../../../utils";
import {useTranslation} from "react-i18next";
import DiagnosisTable from "./DiagnosisTable";
import DiagnosisTableRow from "./DiagnosisTableRow";

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        padding: '24px',
        position: 'relative',
        minWidth: '50vw',
    },
    closeIcon: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        cursor: 'pointer',
    },
    bold: {
        fontWeight: 'bold'
    },
    overflow: {
        overflowY: 'auto',
        maxHeight: '70vh',
    }
}))

const DiagnosisListModal = ({diagnoses, open, onClose}) => {
    const {t} = useTranslation('patient-registry');
    const classes = useStyles();
    return <Modal open={open} className={classes.modal} onClose={onClose}>
        <Paper className={classes.modalContent}>
            <Close className={classes.closeIcon} onClick={onClose} />
            <Typography className={classes.bold}>
                {t('PREVIOUS_DIAGNOSES')}:
            </Typography>
            <Box height={'12px'} />
            <div className={classes.overflow}>
                <DiagnosisTable>
                    {diagnoses?.map(item => <DiagnosisTableRow showFullName={true} diagnosis={item} key={item.researchId} />)}
                </DiagnosisTable>
            </div>
        </Paper>
    </Modal>
}

export default DiagnosisListModal;