import React, {useContext} from "react";
import useStyle from "./slaCardStyles";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import {format} from "date-fns";
import {useMutation} from "react-query";
import {updateConciliumStatus} from "../../api/conciliumApi";
import {useHistory} from "react-router-dom";
import {queryClient} from "../../globalContexts/queryContext";

const SlaCardConciliumCreated = React.forwardRef(({ notification, handleClose, t }, ref) => {
    const classes = useStyle();
    const history = useHistory();
    const {mutate} = useMutation(async ({ conciliumId, accepted }) => {
        await updateConciliumStatus({ conciliumId, accepted });
        if(accepted) {
            history.push(`/researches/concilium/${notification.payload.conciliumId}`);
            handleClose();
        }

        await queryClient.invalidateQueries('notifications');
    });
    if(!notification.payload) {
        return null;
    }
    return (
        <Paper className={classes.card} ref={ref} id={notification.object_id}>
            <Typography variant="caption" className={classes.cardTitle} title={
                t(notification.notification_type, {researchId: notification.payload.researchId})
            }>
                {t(notification.notification_type, {researchId: notification.payload.researchId})}
            </Typography>
            <Grid className={classes.cardInfo}>
                {
                    !notification.payload.responded && <>
                        <Button
                            size="small"
                            variant="outlined"
                            className={classes.cardButton}
                            onClick={() => mutate({conciliumId: notification.payload.conciliumId, accepted: true})}
                        >
                            {t("ACCEPT")}
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            className={`${classes.cardButton} ${classes.redButton}`}
                            onClick={() => mutate({conciliumId: notification.payload.conciliumId, accepted: false})}
                        >
                            {t("REJECT")}
                        </Button>
                    </>
                }
                {
                    notification.payload.responded && notification.payload.accepted &&
                        <>
                            <Button
                                size="small"
                                variant="outlined"
                                className={classes.cardButton}
                                onClick={() => history.push(`/researches/concilium/${notification.payload.conciliumId}`)}
                            >
                                {t("DETAILS")}
                            </Button>
                        </>
                }

                {
                    notification.payload.responded && !notification.payload.accepted &&
                    <>
                        <Typography variant="caption" className={classes.deadline} >{t('CONCILIUM_MEMBERSHIP_REJECTED')}</Typography>
                    </>
                }

                <Typography
                    variant="caption"
                    className={notification.deadline < 600000 ? classes.deadline : ""}
                >
                    {format(new Date(notification.deadline), "HH:mm:ss")}
                </Typography>
            </Grid>
        </Paper>
    );
})

export default SlaCardConciliumCreated;