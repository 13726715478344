import React, { Suspense, useContext } from "react";
import "./App.css";
import {
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline,
  LinearProgress,
  responsiveFontSizes,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import Header from "./components/Header";
import { Switch, Route } from "react-router-dom";
import HomePage from "./modules/HomePage";
import UserRoute from "./components/UserRoute";
import NotFoundPage from "./modules/NotFoundPage";
import Messages from "./components/Messages";
import MuiDrawer from "./components/MuiDrawer";
import { UserContext } from "./globalContexts/UserContext";
import ResearchesPage from "./modules/ResearchesPage";
import MuiDialog from "./components/MuiDialog";
import ReportsPage from "./modules/ReportsPage";
import CustomErrorBoundary from "./components/CustomErrorBoundary";
import Dashboard from "./modules/Dashboard";
import AxiosInterceptorWizard from "./AxiosInterceptorWizard";
import PatientRegistry from "./modules/PatientRegistry";
import SchedulePage from "./modules/SchedulePage";
import PriceListPage from "./modules/PriceListPage";
import { FormRegistraton } from "./modules/RegistrationPage/FormRegistration";
import { PersonalAreaPage } from "./modules/RegistrationPage/PersonalAreaPage";
import { UserModerationPage } from "./modules/UserModeration/UserModerationPage";
import { UserModerationForm } from "./modules/UserModeration/UserModerationForm";
import { ScientificRegistryPage } from "./modules/ScientificAnalyticsPage/ScientificRegistryPage";
import { ScientificDashboardPage } from "./modules/ScientificAnalyticsPage/ScientificDashboard/ScientificDashboard";
import PatientInfoPage from "./modules/PatientInfoPage";
import AnnotatorDetails from "./modules/AnnotatorPage/AnnotatorDetails";
import { AnnotatorPage } from "./modules/AnnotatorPage";
import AutoconclusionScientificDetails from "./modules/ScientificAnalyticsPage/ScientificTable/AutoconclusionScientificDetails";
import DoctorScientificDetails from "./modules/ScientificAnalyticsPage/ScientificTable/DoctorScientificDetails";
import AnnotatorScientificDetails from "./modules/ScientificAnalyticsPage/ScientificTable/AnnotatorScientificDetails";
import PatientsList from "./modules/PatientsPage/PatinetsList";
import { MyResearchPage } from "./modules/MyResearchesPage/MyResearchPage";
import { MyResearchDetails } from "./modules/MyResearchesPage/MyResearchDetails";
import BookingPage from "./modules/BookingPage";
// import useTheme from './hooks/useTheme';

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      // TODO: TEMP
      ...(window.location.origin === "https://eyesee.kazdev.tech"
        ? { primary: blue }
        : {}),
      success: green,
      common: {
        black: "#373435",
      },
    },
    typography: {
      useNextVariants: true,
    },
  })
);

function App() {
  // const { theme, toggler } = useTheme();
  const { user } = useContext(UserContext);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={null}>
        <AxiosInterceptorWizard />
      </Suspense>
      <Suspense
        fallback={<LinearProgress className="progress" color="secondary" />}
      >
        <Header />
      </Suspense>
      <main>
        <Suspense
          fallback={<LinearProgress className="progress" color="secondary" />}
        >
          {user && <MuiDrawer user={user} />}
        </Suspense>
        <Suspense
          fallback={<LinearProgress className="progress" color="secondary" />}
        >
          <CustomErrorBoundary>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/researches" component={ResearchesPage} />
              <UserRoute
                exact
                path="/my-researches"
                component={MyResearchPage}
              />
              <UserRoute
                exact
                path="/my-researches/:id"
                component={MyResearchDetails}
              />
              <UserRoute
                path="/registrationPage"
                component={FormRegistraton}
              />
              <UserRoute
                exact
                path="/personialArea"
                component={PersonalAreaPage}
              />
              <UserRoute path="/reports" component={ReportsPage} />
              <UserRoute path="/dashboard" component={Dashboard} />
              <UserRoute
                path="/patientRegistry/:id"
                component={PatientRegistry}
              />
              <UserRoute exact path="/patients" component={PatientsList} />
              <UserRoute path="/schedule" component={SchedulePage} />
              <UserRoute path="/priceList" component={PriceListPage} />
              <UserRoute
                exact
                path="/scientificRegistry/Autoconclusion/:id"
                component={AutoconclusionScientificDetails}
              />
              <UserRoute
                exact
                path="/scientificRegistry/Doctor/:id"
                component={DoctorScientificDetails}
              />
              <UserRoute
                exact
                path="/scientificRegistry/Annotator/:id"
                component={AnnotatorScientificDetails}
              />
              <UserRoute
                exact
                path="/scientificRegistry"
                component={ScientificRegistryPage}
              />
              <UserRoute
                path="/scientificDashboard"
                component={ScientificDashboardPage}
              />
              <UserRoute
                exact
                path="/annotatorDetails/:id"
                component={AnnotatorDetails}
              />
              <UserRoute exact path="/annotator" component={AnnotatorPage} />
              <UserRoute
                exact
                path="/moderation/:userName"
                component={UserModerationForm}
              />
              <UserRoute
                exact
                path="/moderation"
                component={UserModerationPage}
              />
              <UserRoute
                exact
                path="/patientInfo/:iin"
                component={PatientInfoPage}
              />
              <UserRoute
                exact
                path="/booking"
                component={BookingPage}
              />
              <Route component={NotFoundPage} />
            </Switch>
          </CustomErrorBoundary>
        </Suspense>
        <CustomErrorBoundary>
          <Messages />
          <MuiDialog />
        </CustomErrorBoundary>
      </main>
    </MuiThemeProvider>
  );
}

export default App;
