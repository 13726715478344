import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import ScientificPreconclusionPicker from "./ScientificPreconclusionPicker";
import { useTranslation } from "react-i18next";
import { CONCLUSION_TYPE } from "../../ResearchesPage/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#e0e0e0",
    padding: "2px",
    width: "100%",
    borderRadius: "5px",
    position: "relative",
  },
  element: {
    cursor: "pointer",
    padding: "10px",
    borderRadius: "5px",
  },
  chosen: {
    background: theme.palette.background.paper,
  },
}));

const ScientificConclusionTypePicker = ({
  setConclusionType,
  conclusionType,
  annotatorConclusionInfo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("research");
  const [showMemberList, setShowMemberList] = useState(false);

  return (
    <Grid container direction={"row"} className={classes.root}>
      <Box
        className={`${classes.element}  ${
          conclusionType.type === CONCLUSION_TYPE.AUTO_PRECONCLUSION
            ? classes.chosen
            : ""
        }`}
        flex={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        onClick={() => {
          setConclusionType({
            type: CONCLUSION_TYPE.AUTO_PRECONCLUSION,
            chooseAnnotator: {
              idx: null,
              title: null,
            },
          });
          setShowMemberList(false);
        }}
      >
        {<Typography>{t("AUTO_CONCLUSION")}</Typography>}
      </Box>

      <Box
        className={`${classes.element} ${
          conclusionType.type === CONCLUSION_TYPE.ANNOTATOR_CONCLUSION
            ? classes.chosen
            : ""
        }`}
        flex={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        onClick={() => {
          setConclusionType((state) => ({
            ...state,
            type: CONCLUSION_TYPE.ANNOTATOR_CONCLUSION,
          }));
          setShowMemberList(!showMemberList);
        }}
      >
        <Typography>
          {`${t("ANNOTATOR")} ${
            conclusionType.chooseAnnotator.title
              ? conclusionType.chooseAnnotator.idx + 1
              : ""
          }`}
        </Typography>
      </Box>
      {showMemberList ? (
        <ScientificPreconclusionPicker
          setConclusionType={setConclusionType}
          conclusionType={conclusionType}
          setShowMemberList={setShowMemberList}
          annotatorConclusionInfo={annotatorConclusionInfo}
        />
      ) : null}
    </Grid>
  );
};

export default ScientificConclusionTypePicker;
