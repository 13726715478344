import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import {LANGUAGE_LS_KEY} from "./config/constants";
import LanguageDetector from 'i18next-browser-languagedetector';

function getPreferredLang() {
    const langFromLS = localStorage.getItem(LANGUAGE_LS_KEY);
    const langFromNavigator = navigator.language?.split('-')[0];
    return langFromLS || langFromNavigator || 'en';
}

i18n
  .use(XHR)
  .use(initReactI18next)
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "ru",
    ns: ["common"],
    defaultNS: "common",
    fallbackNS: "common",
    nonExplicitWhitelist: true,
    // debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    detection: {
      order: ['localStorage', 'navigator', 'subdomain'],
      lookupLocalStorage: LANGUAGE_LS_KEY,
      caches: ['localStorage'],
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json"
    }
  });
