import {
  RESEARCH_DETAILS_LOADING,
  LOAD_RESEARCH_SUCCESS,
  CLEAN_RESEARCH_DETAILS,
  LOAD_RESEARCH_ERROR,
  SET_FILTER,
  UPDATE_RESEARCH_DETAILS_DATA,
  TOGGLE_CONCILIUM_DRAWER,
  SET_PRECONCLUSION_TYPE,
  TOGGLE_CONCILIUM_MENU,
  TOGGLE_SHARED_MODAL,
  CORNERSTONE_SET_VIEWPORT_IMG,
  CORNERSTONE_SET_LAYOUT,
  CORNERSTONE_SET_ACTIVE_VIEWPORT_INDEX,
  CORNERSTONE_SET_VIEWPORT_ENABLED, CORNERSTONE_SET_ACTIVE_TOOL
} from "./researchActions";
import { parseIIN } from "../../../utils";
import {
  getDefaultDescriptions,
  getDefaultRecommendations,
} from "../ConclusionForm/defaultDescriptionSetterUtils";

function researchReducer(state, action, initialState) {
  switch (action.type) {
    case RESEARCH_DETAILS_LOADING:
      return {
        ...state,
        researchDetails: {
          ...state.researchDetails,
          loading: true,
          error: null,
        },
      };
    case LOAD_RESEARCH_SUCCESS: {
      const {
        leftEyeConclusionAuto,
        leftEyeConclusion,
        rightEyeConclusion,
        rightEyeConclusionAuto,
        iin,
        referrerId,
        referrerFullName,
        receptionDoctorId,
        receptionDoctorFullName,
      } = action.research;

      const details = {
        ...state.researchDetails,
      };
      details.loading = false;

      if (iin) {
        try {
          const { age, gender } = parseIIN(iin);
          details.research.age = age;
          details.research.gender = gender;
        } catch (error) {
          console.error(error);
        }
      }
      details.research = {
        ...action.research,
        referrerId: {
          value: referrerId,
          label: referrerFullName,
        },
        receptionDoctorId: action.research.receptionDoctorId
          ? {
              value: receptionDoctorId,
              label: receptionDoctorFullName,
            }
          : null,
        rightEyeConclusion: state.researchDetails.research.rightEyeConclusion,
        leftEyeConclusion: state.researchDetails.research.leftEyeConclusion,
      };

      // for setting default recommendations;
      const autoConclusionResults = [];

      // add leftEyeConclusion and rightEyeConclusion to result from server
      // if there is auto-conclusion or just conclusion change defaults for rightEye
      const defaultConclusionResultStructure =
        initialState.researchDetails.research.rightEyeConclusion
          .conclusionResult;
      const defaultDescriptionStructure =
        initialState.researchDetails.research.rightEyeConclusion.description;
      if (rightEyeConclusion) {
        details.research.rightEyeConclusion = {
          id: rightEyeConclusion.id,
          conclusionResult:
            rightEyeConclusion.conclusionResult ||
            defaultConclusionResultStructure,
          description:
            rightEyeConclusion.description || defaultDescriptionStructure,
        };
      } else if (rightEyeConclusionAuto) {
        const conclusionResultAuto =
          rightEyeConclusionAuto.conclusionResult ||
          defaultConclusionResultStructure;
        details.research.rightEyeConclusion = {
          id: null,
          conclusionResult: conclusionResultAuto,
          description: getDefaultDescriptions(conclusionResultAuto),
        };
        autoConclusionResults.push(conclusionResultAuto);
      }

      // if there is auto-conclusion or just conclusion change defaults for leftEye
      if (leftEyeConclusion) {
        details.research.leftEyeConclusion = {
          id: leftEyeConclusion.id,
          conclusionResult:
            leftEyeConclusion.conclusionResult ||
            defaultConclusionResultStructure,
          description:
            leftEyeConclusion.description || defaultDescriptionStructure,
        };
      } else if (leftEyeConclusionAuto) {
        const conclusionResultAuto =
          leftEyeConclusionAuto.conclusionResult ||
          defaultConclusionResultStructure;
        details.research.leftEyeConclusion = {
          id: null,
          conclusionResult: conclusionResultAuto,
          description: getDefaultDescriptions(conclusionResultAuto),
        };
        autoConclusionResults.push(conclusionResultAuto);
      }
      if (!leftEyeConclusion && !rightEyeConclusion) {
        const {
          recommendationCodenames,
          recommendationText,
        } = getDefaultRecommendations(autoConclusionResults);
        // this sets initial values so form will not be dirty
        details.research.recommendationCodenames = recommendationCodenames;
        details.research.recommendationText = recommendationText;
      }
      return { ...state, researchDetails: details };
    }
    case UPDATE_RESEARCH_DETAILS_DATA: {
      return {
        ...state,
        researchDetails: {
          ...state.researchDetails,
          research: {
            ...state.researchDetails.research,
            ...action.updatedData,
          },
        },
      };
    }
    case CLEAN_RESEARCH_DETAILS:
      return {
        ...state,
        researchDetails: initialState.researchDetails,
      };
    case LOAD_RESEARCH_ERROR:
      return {
        ...state,
        researchDetails: {
          ...state.researchDetails,
          loading: false,
          error: action.error,
        },
      };
    case SET_FILTER:
      return {
        ...state,
        researchFilter: action.filter,
      };
    case TOGGLE_CONCILIUM_DRAWER:
      return {
        ...state,
        isConciliumDrawerOpen: !state.isConciliumDrawerOpen
      }
    case TOGGLE_CONCILIUM_MENU:
      return {
        ...state,
        isConciliumMenuOpen: !state.isConciliumMenuOpen
      }
    case SET_PRECONCLUSION_TYPE:
      return {
        ...state,
        preConclusionType: action.preConclusionType
      }
    case TOGGLE_SHARED_MODAL:
      return {
        ...state,
        isSharedModalOpen: !state.isSharedModalOpen
      }
    default:
      return state;
  }
}

export default researchReducer;
