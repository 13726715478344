import { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import { DRIVER_URL } from "../../../config/request";
import { SOCKET_STATUS } from "../constants";

export default function useSocket(
  active,
  handleFileAdd,
  handleFundusVersionSet
) {
  const [state, setState] = useState({
    status: SOCKET_STATUS.INITIAL_CONNECTING,
  });
  const socket = socketIOClient(`ws://${DRIVER_URL}`, { autoConnect: false });
  useEffect(() => {
    if (active) {
      socket.connect();
      socket.on("connect", () => {
        setState({ status: SOCKET_STATUS.CONNECTED });
        socket.on("files_added", handleFileAdd);
        socket.on("FUNDUS_VERSION", handleFundusVersionSet);
      });
      socket.on("connect_error", (_error) => {
        setState({ status: SOCKET_STATUS.ERROR });
      });
      return () => {
        socket.close();
      };
    } else {
      socket.close();
    }
  }, [active]);
  return state;
}
