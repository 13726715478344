import { useState, useCallback } from "react";
import { getPatientsFromMis } from "../api";
import axios from "axios";

let source = null;
export function usePatientsFromMisRequest(timeout = 6000) {
  const [state, setState] = useState({
    data: null,
    loading: false,
    error: null,
  });
  const request = useCallback(async (iin) => {
    source = axios.CancelToken.source();
    try {
      setState({
        error: null,
        loading: true,
        data: null,
      });
      const data = await getPatientsFromMis(iin, source.token, timeout);
      setState({
        error: null,
        loading: false,
        data,
      });
    } catch (error) {
      setState({
        error,
        loading: false,
        data: null,
      });
    }
  }, []);
  return [state, request, source && source.cancel];
}
