import makeStyles from "@material-ui/core/styles/makeStyles";
import { formatDateAndTime } from "../../../utils";
import { useRouterQuery } from "../../../hooks/useRouterQuery";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { MessagesContext } from "../../../globalContexts/MessagesContext";
import {
  ResearchContext,
  toggleConciliumDrawer,
} from "../../ResearchesPage/ResearchContext";
import { useDisclosure } from "../../../hooks/useDisclosure";
import {
  ANNOTATION_STATUS,
  RESEARCH_STATUS,
} from "../../ResearchesPage/constants";
import Grid from "@material-ui/core/Grid";
import { CornerstoneImageViewerMobile } from "../../../components/CornerstoneImageViewer/mobile";
import JSZip from "jszip";
import {
  fetchReport,
  getImagesPdf,
} from "../../ResearchesPage/ResearchesList/ResearchJournalTable";
import { Button } from "@material-ui/core";
import {
  Assignment,
  DragHandle,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import { Resizable } from "re-resizable";
import { ConclusionSubForm } from "../../ResearchesPage/ResearchDetails/ResearchDetailsForDoctor";
import FileSaver from "file-saver";
import { temporaryFormSchema } from "../../ResearchesPage/ConclusionForm/ConclusionForm";
import { Formik } from "formik";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "85vh",
    position: "relative",
    width: "100%",
  },
  viewerWrapper: {
    position: "relative",
    width: "100%",
  },
  conclusionToggleButton: {
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    position: "absolute",
    fontSize: "14px",
    color: "#ff9800",
    top: "12px",
    right: "12px",
  },
  viewer: {
    height: "70%",
  },
  form: {
    position: "absolute!important",
    width: "100%!important",
    bottom: 0,
    background: theme.palette.common.white,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  handler: {
    width: "100px",
    margin: "0 auto",
    textAlign: "center",
  },
  conclusionPickerContainer: {
    width: "100%",
    padding: "24px",
  },
}));

export function CornerstoneOverlayMobile(props) {
  const {
    imageId,
    scale,
    windowWidth,
    windowCenter,
    side,
    date,
    t,
    imageIndex,
    stackSize,
    image: { rows, columns },
  } = props;
  const classes = useStyles();

  if (!imageId) {
    return null;
  }

  const zoomPercentage = parseFloat(scale * 100).toFixed(0);
  const wwwc = `W: ${windowWidth.toFixed(0)} L: ${windowCenter.toFixed(0)}`;

  return (
    <div className={"ViewportOverlay"}>
      <div className="top-left overlay-element">
        {side === "rightEyes" && <div>OD</div>}
        {side === "leftEyes" && <div>OS</div>}
        <div>{formatDateAndTime(date)}</div>
        <div>
          {columns} x {rows}
        </div>
      </div>
      <div className="bottom-left overlay-element">
        <div>
          {imageIndex} / {stackSize}
        </div>
        <div>
          {t("ZOOM")}: {zoomPercentage}%
        </div>
        <div>{wwwc}</div>
      </div>
    </div>
  );
}

function ConclusionsDetails({
  t,
  editMode,
  handleSubmitConclusions,
  patientInfo,
  conclusionInfo,
}) {
  const { leftEyes, rightEyes } = patientInfo;
  const hasEyes = rightEyes?.length > 0 || leftEyes?.length > 0;
  const [query, handleReplace] = useRouterQuery();
  const activeImg = query.get("activeImg");
  const dispatchMessage = useContext(MessagesContext)[1];
  // const dispatchResearch = useContext(ResearchContext)[1];
  const { isOpen, toggle } = useDisclosure(true);
  const isFinished = patientInfo.status === ANNOTATION_STATUS.ANNOTATED;
  const [approvedConclusions, setApprovedConclusions] = useState({
    rightEyeConclusion: false,
    leftEyeConclusion: false,
  });

  let activeSide = "";
  if (
    patientInfo.rightEyes?.find((eye) => Number(eye.id) === Number(activeImg))
  ) {
    activeSide = "rightEye";
  }
  if (
    patientInfo.leftEyes?.find((eye) => Number(eye.id) === Number(activeImg))
  ) {
    activeSide = "leftEye";
  }

  const classes = useStyles();

  useEffect(() => {
    if (!activeImg) {
      if (rightEyes?.length) {
        handleReplace("activeImg", rightEyes[0].id);
      } else if (leftEyes?.length) {
        handleReplace("activeImg", leftEyes[0].id);
      }
    }
  }, [rightEyes?.length, leftEyes?.length, activeImg]);

  return (
    <Grid container className={classes.root}>
      {hasEyes && activeImg && (
        <>
          <CornerstoneImageViewerMobile
            eyes={{
              rightEyes,
              leftEyes,
            }}
            handleDownloadPicture={() => {
              const zip = new JSZip();
              rightEyes?.forEach((img) => {
                const name = `rightEye_${img.id}.${
                  img.contentType.split("/")[1]
                }`;
                zip.file(name, img.base64, { base64: true });
              });
              leftEyes?.forEach((img) => {
                const name = `leftEye_${img.id}.${
                  img.contentType.split("/")[1]
                }`;
                zip.file(name, img.base64, { base64: true });
              });
              zip.generateAsync({ type: "blob" }).then((zipFile) => {
                FileSaver.saveAs(zipFile, "Снимки.zip");
              });
            }}
            handleDownloadImagesPdf={() =>
              getImagesPdf(patientInfo.id, dispatchMessage, t)
            }
            handleDownloadConclusion={
              isFinished
                ? () => fetchReport(patientInfo.id, dispatchMessage, t)
                : undefined
            }
            showDownloadButton={false}
            activeImg={activeImg}
            setActiveImg={(id) => handleReplace("activeImg", id)}
            isConclusionOpen={isOpen}
            handleConcilium={() => null}
            showConciliumButton={false}
            showSharedButton={false}
            CustomOverlay={(props) => (
              <CornerstoneOverlayMobile
                {...props}
                t={t}
                date={patientInfo.appointmentTimestamp}
              />
            )}
          />
          <Button
            onClick={toggle}
            title={t("FORM_CONCLUSION")}
            className={classes.conclusionToggleButton}
          >
            <Assignment />
            {isOpen && <KeyboardArrowUp />}
            {!isOpen && <KeyboardArrowDown />}
          </Button>
        </>
      )}
      {isOpen && (
        <Resizable
          // bounds="window"
          handleComponent={{
            top: (
              <div className={classes.handler}>
                <DragHandle />
              </div>
            ),
          }}
          maxHeight="95%"
          enable={{
            top: true,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          defaultSize={{ height: "20%" }}
          minHeight="20%"
          className={classes.form}
        >
          <Formik
            onSubmit={handleSubmitConclusions}
            validationSchema={temporaryFormSchema}
            enableReinitialize
            initialValues={conclusionInfo}
          >
            {(subForm) => (
              <ConclusionSubForm
                subForm={subForm}
                editMode={editMode}
                conclusionName={`${activeSide}Conclusion`}
                approvedConclusions={approvedConclusions}
                setApprovedConclusions={setApprovedConclusions}
                dispatchMessage={dispatchMessage}
                shared={true}
                replaceActiveImg={(conclusionName) => {
                  if (conclusionName === "rightEyeConclusion") {
                    handleReplace("activeImg", patientInfo.rightEyes[0].id);
                  }
                  if (conclusionName === "leftEyeConclusion") {
                    handleReplace("activeImg", patientInfo.leftEyes[0].id);
                  }
                }}
              />
            )}
          </Formik>
        </Resizable>
      )}
    </Grid>
  );
}

export default memo(ConclusionsDetails);
