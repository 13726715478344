import React, { useContext, useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import {
  CONCILIUM_STATUS,
  CONCLUSION_TYPE,
  RESEARCH_STATUS,
} from "../constants";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import PreconclusionPicker from "./PreconclusionPicker";
import { useTranslation } from "react-i18next";
import {
  ResearchContext,
  setAutoPreconclusion,
  setFinalConclusion,
  setPreconclusionType,
} from "../ResearchContext";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#e0e0e0",
    padding: "2px",
    width: "100%",
    borderRadius: "5px",
    position: "relative",
  },
  element: {
    cursor: "pointer",
    padding: "10px",
    borderRadius: "5px",
  },
  chosen: {
    background: theme.palette.background.paper,
  },
}));

const ConclusionTypePicker = ({
  sharedMembers,
  conciliumMembers,
  annotationMembers,
  status,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("research");
  const [showMemberList, setShowMemberList] = useState(false);
  const [{ preConclusionType }, dispatch] = useContext(ResearchContext);

  return (
    <Grid container direction={"row"} className={classes.root}>
      <Box
        className={`${classes.element} ${
          (preConclusionType.type === CONCLUSION_TYPE.CONCLUSION &&
            (status === RESEARCH_STATUS.FINISHED ||
              status === RESEARCH_STATUS.CANCELLED)) ||
          (preConclusionType.type === CONCLUSION_TYPE.AUTO_PRECONCLUSION &&
            status === RESEARCH_STATUS.TAKEN_TO_WORK)
            ? classes.chosen
            : ""
        }`}
        flex={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        onClick={() => {
          if (status === RESEARCH_STATUS.TAKEN_TO_WORK) {
            dispatch(setAutoPreconclusion());
          } else {
            dispatch(setFinalConclusion());
          }
          setShowMemberList(false);
        }}
      >
        {status === RESEARCH_STATUS.FINISHED ||
        status === RESEARCH_STATUS.CANCELLED ? (
          <Typography>{t("CONCLUSION")}</Typography>
        ) : (
          <Typography>{t("AUTO_CONCLUSION")}</Typography>
        )}
      </Box>

      <Box
        className={`${classes.element} ${
          preConclusionType.type === CONCLUSION_TYPE.CONCILIUM_PRECONCLUSION ||
          preConclusionType.type === CONCLUSION_TYPE.SHARED_PRECONCLUSION ||
          preConclusionType.type === CONCLUSION_TYPE.ANNOTATOR_CONCLUSION ||
          ((status === RESEARCH_STATUS.FINISHED ||
            status === RESEARCH_STATUS.CANCELLED) &&
            preConclusionType.type === CONCLUSION_TYPE.AUTO_PRECONCLUSION)
            ? classes.chosen
            : ""
        }`}
        flex={1}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        onClick={() => setShowMemberList(!showMemberList)}
      >
        <Typography>
          {preConclusionType.chooseConcilium?.fullName ||
            preConclusionType.chooseShared?.title ||
            preConclusionType.chooseAuto?.title ||
            preConclusionType.chooseAnnotator?.fullName ||
            t("PRE_CONCLUSIONS")}
        </Typography>
      </Box>
      {showMemberList ? (
        <PreconclusionPicker
          setShowMemberList={setShowMemberList}
          sharedMembers={sharedMembers}
          annotationMembers={annotationMembers}
          conciliumMembers={conciliumMembers}
          status={status}
        />
      ) : null}
    </Grid>
  );
};

export default ConclusionTypePicker;

ConclusionTypePicker.propTypes = {
  conciliumMembers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      status: PropTypes.string,
    })
  ).isRequired,
  status: PropTypes.string.isRequired,
  sharedMembers: PropTypes.array.isRequired,
  annotationMembers: PropTypes.array.isRequired,
};
