import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Close, Edit, Visibility } from "@material-ui/icons";
import { formatDate } from "../../../../utils";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { ROLES } from "../../../../config/constants";
import Roled from "../../../../components/Roled";

const useStyle = makeStyles(() => ({
  root: {
    padding: "5px 10px",
  },
}));

const RecommendationList = ({
  recommendation,
  onChooseRecommendation,
  onEditRecommendation,
  onDeleteRecommendation,
  isFinished
}) => {
  const { t } = useTranslation("patient-registry");
  const classes = useStyle();
  const { id: researchId } = useParams();
  const { isFetching } = useQuery(["researchDetails", researchId], {
    enabled: false,
  });
  if (isFetching) {
    return (
      <Grid container alignItems={"center"} justify={"center"}>
        <Box height={"10px"} />
        <CircularProgress />;
      </Grid>
    );
  }
  if (!recommendation) {
    return null;
  }
  return (
    <Paper elevation={3} className={classes.root}>
      <Grid container direction={"column"}>
        <Grid
          container
          direction={"row"}
          justify={"space-between"}
          alignItems={"center"}
        >
          <div>{formatDate(recommendation.recommendationGivenTime)}</div>
          <span className={"clickable"} onClick={onChooseRecommendation}>
            {t("RECOMMENDATION")}
          </span>
          {
            !isFinished && (
                <div>
                  <Roled roles={[ROLES.DOCTOR]}>
                    <Edit
                        cursor={"pointer"}
                        onClick={onEditRecommendation}
                        color={"primary"}
                    />
                  </Roled>
                  <Roled roles={[ROLES.DOCTOR]}>
                    <Close
                        cursor={"pointer"}
                        onClick={onDeleteRecommendation}
                        color={"primary"}
                    />
                  </Roled>
                </div>
            )
          }
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RecommendationList;
