import React, { memo } from "react";
import { Snackbar, makeStyles, IconButton, Slide } from "@material-ui/core";
import { CheckCircle, Close, Error as ErrorIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(1) / 2,
  },
  message: ({ status }) => {
    let bgc = theme.palette.success[400];
    if (status === "error") bgc = theme.palette.error.dark;
    if (status === "info") bgc = theme.palette.info.main;
    return {
      backgroundColor: bgc,
      display: "flex",
      alignItems: "center",
    };
  },
  icon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
    fontSize: 20,
  },
}));

function Message({ open, message, status, handleClose, id }) {
  const classes = useStyles({ status });
  let Icon = CheckCircle;
  if (status === "error") Icon = ErrorIcon;
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      autoHideDuration={5000}
      onClose={(_e, reason) => handleClose(id, reason)}
      ContentProps={{
        "aria-describedby": "message-id",
        className: classes.message,
      }}
      TransitionComponent={Slide}
      message={
        <span id="message-id" className={classes.message}>
          <Icon className={classes.icon} />
          {message}
        </span>
      }
      action={[
        <IconButton
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={() => handleClose(id)}
        >
          <Close />
        </IconButton>,
      ]}
    />
  );
}

export default memo(Message);
