import {
  Avatar,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { CalendarToday, Cancel, EventNote } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { getAnnotations } from "../../api/annotationApi";
import { DPicker } from "../../components/FormComponents";
import { useRouterQuery } from "../../hooks/useRouterQuery";
import { formatDateAndTime } from "../../utils";
import { ANNOTATION_STATUS } from "../ResearchesPage/constants";

const useStyles = makeStyles((theme) => ({
  list: {
    listStyleType: "none !important",
  },
  toggleButton: {
    width: "100%",
    background: theme.palette.primary.main,
    borderRadius: 0,
  },
  secondaryText: {
    maxWidth: "65px",
    userSelect: "none",
    fontSize: "14px",
  },
  textWight: {
    color: "#fff",
  },
  periodFrom: {
    marginRight: "15px",
    width: "150px",
  },
  timeFilter: {
    paddingLeft: "20px",
    margin: "10px 0",
  },
  filters: {
    position: "sticky",
    top: 50,
    zIndex: 2,
    background: theme.palette.common.white,
  },
}));

const AvatarStatusColor = {
  [ANNOTATION_STATUS.ANNOTATED]: "bg-green",
  [ANNOTATION_STATUS.NOT_ANNOTATED]: "bg-red",
};

function AnnotatorMobilePage() {
  const classes = useStyles();
  const history = useHistory();
  const { ref, inView } = useInView();
  const { t } = useTranslation(["annotator", "serviceNamesDict", "research"]);
  const [query, handleReplace] = useRouterQuery();
  const periodStart = query.get("periodStart") || null;
  const periodEnd = query.get("periodEnd") || null;
  const page = query.get("page") || 0;
  const limit = 20;
  const [togglebutton, setToggleButton] = useState("ALL");
  const [order, serOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("appointmentTimestamp");

  const annotationQuery = {
    limit: limit,
    periodStart: periodStart,
    periodEnd: periodEnd,
    page: page,
    status: togglebutton,
    order: order,
    orderBy: orderBy,
  };

  const {
    data,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [
      "annotatorMobileList",
      [page, togglebutton, limit, periodStart, periodEnd, order, orderBy],
    ],
    ({ pageParam = 0 }) =>
      getAnnotations({ ...annotationQuery, page: pageParam }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pageNum * limit > lastPage.count) {
          return undefined;
        }
        return lastPage.pageNum;
      },
    }
  );
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const handleToggleButton = (event, newAlignment) => {
    if (newAlignment !== null) {
      setToggleButton(newAlignment);
    }
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <Grid className={classes.filters}>
        <ToggleButtonGroup
          value={togglebutton}
          onChange={handleToggleButton}
          exclusive
          className={classes.toggleButton}
          size="small"
        >
          <ToggleButton value="ALL" className={classes.textWight}>
            {t("ALL")}
          </ToggleButton>
          <ToggleButton value="ANNOTATED" className={classes.textWight}>
            {t("ANNOTATED")}
          </ToggleButton>
          <ToggleButton value="NOT_ANNOTATED" className={classes.textWight}>
            {t("NOT_ANNOTATED")}
          </ToggleButton>
        </ToggleButtonGroup>
        <Grid container className={classes.timeFilter}>
          <DPicker
            className={classes.periodFrom}
            placeholder={t("PERIOD_FROM")}
            value={periodStart}
            okLabel={t("OK")}
            clearLabel={t("CLEAR")}
            cancelLabel={t("CANCEL_LABEL")}
            iconProps={<CalendarToday />}
            onChange={(date) =>
              handleReplace("periodStart", date?.toISOString())
            }
          />
          <DPicker
            placeholder={t("PERIOD_TO")}
            className={classes.periodFrom}
            okLabel={t("OK")}
            clearLabel={t("CLEAR")}
            cancelLabel={t("CANCEL_LABEL")}
            value={periodEnd}
            onChange={(date) => handleReplace("periodEnd", date?.toISOString())}
          />
        </Grid>
      </Grid>
      <List className={classes.list}>
        {isLoading && <CircularProgress />}
        {data.pages.map((page) =>
          page.researches.map((item) => {
            return (
              <ListItem
                key={item.id}
                button
                divider
                dense
                onClick={() => history.push(`/annotatorDetails/${item.id}`)}
              >
                <ListItemAvatar>
                  <Avatar className={AvatarStatusColor[item.status]}>
                    {item.status === ANNOTATION_STATUS.ANNOTATED ? (
                      <EventNote />
                    ) : (
                      <Cancel />
                    )}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={`№: ${item.id}`} secondary={item.iin} />
                <ListItemSecondaryAction className={classes.secondaryText}>
                  {formatDateAndTime(item.appointmentTimestamp)}
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        )}
        <div ref={ref} onClick={() => fetchNextPage()}>
          {isFetchingNextPage ? (
            <CircularProgress />
          ) : hasNextPage ? (
            <CircularProgress />
          ) : (
            <div></div>
          )}
        </div>
        <div>
          {isFetching && !isFetchingNextPage ? "Background Updating..." : null}
        </div>
      </List>
    </>
  );
}

export default AnnotatorMobilePage;
