import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  formatDateAndTime,
  getAgeInBirthDay,
  getPeriodDateWithAge,
} from "../../utils";
import history from "../../config/history";
import { DPicker } from "../../components/FormComponents";
import { useRouterQuery } from "../../hooks/useRouterQuery";
import { CalendarToday, Search } from "@material-ui/icons";
import { useCubeQuery } from "@cubejs-client/react";
import { SCIENTIFIC_CODENAME } from "../../config/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 15px",
  },
  toggleButton: {
    margin: "15px",
  },
  center: {
    textAlign: "center",
    marginLeft: "100px",
  },
  select: {
    width: 100,
    border: "2px solid #f2f2f2",
    borderRadius: "6px",
    padding: 0,
    margin: "0 10px",
  },
  selectGender: {
    width: 200,
    border: "2px solid #f2f2f2",
    borderRadius: "6px",
    padding: 0,
    margin: "0 10px",
  },
  age: {
    margin: "0 10px 0 25px",
  },
  date: {
    marginLeft: "50px",
  },
  rightFilters: {
    paddingTop: "44px",
    alignItems: "center",
  },
  equalRow: {
    cursor: "pointer",
  },
}));

const ageList = Array(120)
  .fill()
  .map((_, idx) => idx + 1);

export function ScientificRegistryTable() {
  const { t } = useTranslation("scientificAnalytics");
  const classes = useStyles();
  const [query, handleReplace] = useRouterQuery();
  const [typePathology, setTypePathology] = useState("");
  const periodStart = query.get("periodStart") || null;
  const periodEnd = query.get("periodEnd") || null;
  const [hasPathalogy, setHasPathalogy] = useState("all");
  const [hasDiabets, setHasDiabets] = useState("all");
  const conclusionType = query.get("conclusionType") || "Autoconclusion";
  const [gender, getGender] = useState("all");
  const [fromAge, setFromAge] = useState(1);
  const [beforeAge, setBeforeAge] = useState(120);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleConclusionType = (event, newAlignment) => {
    if (newAlignment !== null) {
      handleReplace("conclusionType", newAlignment);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [conclusionType]);

  const birthDayPeriod = getPeriodDateWithAge(fromAge, beforeAge);

  const filters = [
    {
      member: "Research.researchStatus",
      operator: "equals",
      values: ["FINISHED"],
    },
    {
      member: "Research.birthday",
      operator: "afterDate",
      values: [birthDayPeriod.fromYear],
    },
    {
      member: "Research.birthday",
      operator: "beforeDate",
      values: [birthDayPeriod.beforeYear],
    },
  ];
  if (typePathology) {
    filters.push({
      member: "ConclusionResult.resultMainCodename",
      operator: "equals",
      values: [typePathology],
    });
  }
  if (periodStart) {
    filters.push({
      member: "Research.appointmentTimestamp",
      operator: "afterDate",
      values: [periodStart],
    });
  }
  if (periodEnd) {
    filters.push({
      member: "Research.appointmentTimestamp",
      operator: "beforeDate",
      values: [periodEnd],
    });
  }
  if (hasDiabets !== "all") {
    filters.push({
      member: "Research.diabetes",
      operator: "equals",
      values: [hasDiabets],
    });
  }
  if (hasPathalogy !== "all") {
    filters.push({
      member: "Research.arterialHypertension",
      operator: "equals",
      values: [hasPathalogy],
    });
  }
  if (gender != "all") {
    filters.push({
      member: "Research.gender",
      operator: "equals",
      values: [gender],
    });
  }

  const measures = [];
  const codeNames = [];

  if (conclusionType === "Autoconclusion") {
    measures.push(
      "Research.count",
      "AutoConclusion.count",
      "PreConclusion.count",
      "PreConclusionBlock.count",
      "PreConclusionDiagnosis.count"
    );
    filters.push({
      member: "PreConclusionDiagnosis.resultMainCodename",
      operator: "notEquals",
      values: ["IMPOSSIBLE", "HEALTHY"],
    });
    codeNames.push(
      "PreConclusionDiagnosis.resultMainCodename",
      "PreConclusionDiagnosisToRetinopathy.retinopathyCodename"
    );
  }

  if (conclusionType === "Doctor") {
    measures.push(
      "Research.count",
      "Conclusion.count",
      "ConclusionResult.count"
    );
    filters.push({
      member: "ConclusionResult.resultMainCodename",
      operator: "notEquals",
      values: ["HEALTHY", "IMPOSSIBLE"],
    });
    codeNames.push(
      "ConclusionResult.resultMainCodename",
      "ConclusionResultToHypertonic.hypertonicCodename",
      "ConclusionResultToDegeneration.degenerationCodename",
      "ConclusionResultToRetinopathy.retinopathyCodename",
      "ConclusionResult.other"
    );
  }

  if (conclusionType === "Annotator") {
    measures.push("Research.count", "Annotation.count");
    filters.push({
      member: "PreConclusionDiagnosis.resultMainCodename",
      operator: "notEquals",
      values: ["IMPOSSIBLE", "HEALTHY"],
    });
    codeNames.push(
      "PreConclusionDiagnosis.resultMainCodename",
      "PreConclusionDiagnosisToDegeneration.degenerationCodename",
      "PreConclusionDiagnosisToHypertonic.hypertonicCodename",
      "PreConclusionDiagnosisToRetinopathy.retinopathyCodename",
      "PreConclusionDiagnosis.other"
    );
  }

  const {
    resultSet: resultTableData,
    isLoading,
    error,
    progress,
  } = useCubeQuery({
    measures: measures,
    timeDimensions: [
      {
        dimension: "Research.createdTimestamp",
      },
    ],
    order: [
      ["Research.count", "desc"],
      ["Research.id", "desc"],
    ],
    filters: filters,
    dimensions: [
      "Research.id",
      "Research.gender",
      "Research.birthday",
      "Research.appointmentTimestamp",
      "Research.diabetes",
      ...codeNames,
      "Research.arterialHypertension",
    ],
    limit: Number(rowsPerPage),
    offset: Number(page) > 0 ? Number(page) * Number(rowsPerPage) : 0,
  });

  const {
    resultSet: countAll,
    isLoading: countLoading,
    error: errorCount,
    progress: countProgress,
  } = useCubeQuery({
    measures: measures,
    timeDimensions: [
      {
        dimension: "Research.createdTimestamp",
      },
    ],
    filters: filters,
    dimensions: [
      "Research.id",
      "Research.gender",
      "Research.birthday",
      "Research.appointmentTimestamp",
      "Research.diabetes",
      ...codeNames,
      "Research.arterialHypertension",
    ],
  });

  const countAllData = countLoading ? 0 : countAll?.tablePivot();

  const { resultSet: resultPathology } = useCubeQuery({
    measures: ["DResultMain.count"],
    timeDimensions: [],
    order: {
      "DResultMain.count": "desc",
    },
    filters: [
      {
        member: "DResultMain.codename",
        operator: "notEquals",
        values: ["IMPOSSIBLE", "HEALTHY"],
      },
    ],
    dimensions: ["DResultMain.codename", "DResultMain.nameRu"],
  });

  if (error) {
    return <div>{error.toString()}</div>;
  }

  const tableData = resultTableData?.tablePivot() || [];
  const namePathology =
    resultPathology?.tablePivot().map((result) => {
      return {
        value: result["DResultMain.codename"],
        title: result["DResultMain.nameRu"],
      };
    }) || [];

  function handleOnTableRow(id) {
    history.push(`/scientificRegistry/${conclusionType}/${id}`);
  }

  function getNamePathologyForDoctorAndAnnotator(research) {
    if (research[codeNames[0]] === SCIENTIFIC_CODENAME.DEGENERATION) {
      const innerNamePathology =
        conclusionType === "Annotator"
          ? t(
              research[
                "PreConclusionDiagnosisToDegeneration.degenerationCodename"
              ]
            )
          : t(research["ConclusionResultToDegeneration.degenerationCodename"]);
      return (
        namePathology.filter(
          (pathology) => pathology.value === research[codeNames[0]]
        )[0]?.title +
        ": " +
        innerNamePathology
      );
    } else if (research[codeNames[0]] === SCIENTIFIC_CODENAME.OTHER) {
      const innerNamePathology =
        conclusionType === "Annotator"
          ? t(research["PreConclusionDiagnosis.other"])
          : t(research["ConclusionResult.other"]);
      return (
        namePathology.filter(
          (pathology) => pathology.value === research[codeNames[0]]
        )[0]?.title +
        ": " +
        innerNamePathology
      );
    } else if (research[codeNames[0]] === SCIENTIFIC_CODENAME.HYPERTONIC) {
      const innerNamePathology =
        conclusionType === "Annotator"
          ? t(research["PreConclusionDiagnosisToHypertonic.hypertonicCodename"])
          : t(research["ConclusionResultToHypertonic.hypertonicCodename"]);
      return (
        namePathology.filter(
          (pathology) => pathology.value === research[codeNames[0]]
        )[0]?.title +
        ": " +
        innerNamePathology
      );
    } else if (research[codeNames[0]] === SCIENTIFIC_CODENAME.RETINOPATHY) {
      const innerNamePathology =
        conclusionType === "Annotator"
          ? t(
              research[
                "PreConclusionDiagnosisToRetinopathy.retinopathyCodename"
              ]
            )
          : t(research["ConclusionResultToRetinopathy.retinopathyCodename"]);
      return (
        namePathology.filter(
          (pathology) => pathology.value === research[codeNames[0]]
        )[0]?.title +
        ": " +
        innerNamePathology
      );
    } else {
      return namePathology.filter(
        (pathology) => pathology.value === research[codeNames[0]]
      )[0]?.title;
    }
  }
  function getNamePathologyForAutoconclusion(research) {
    if (research[codeNames[0]] === SCIENTIFIC_CODENAME.RETINOPATHY) {
      return (
        namePathology.filter(
          (pathology) => pathology.value === research[codeNames[0]]
        )[0]?.title +
        ": " +
        t(research["PreConclusionDiagnosisToRetinopathy.retinopathyCodename"])
      );
    } else {
      return namePathology.filter(
        (pathology) => pathology.value === research[codeNames[0]]
      )[0]?.title;
    }
  }
  return (
    <>
      <Paper className={classes.root}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <ToggleButtonGroup
              value={conclusionType}
              onChange={handleConclusionType}
              exclusive
              size="small"
            >
              <ToggleButton value="Autoconclusion">
                {t("AUTOCONCLUSION")}
              </ToggleButton>
              <ToggleButton value="Doctor">{t("DOCTOR")}</ToggleButton>
              <ToggleButton value="Annotator">{t("ANNOTATOR")}</ToggleButton>
            </ToggleButtonGroup>
            <DPicker
              placeholder={t("PERIOD_FROM")}
              value={periodStart}
              okLabel={t("OK")}
              clearLabel={t("CLEAR")}
              className={classes.date}
              cancelLabel={t("CANCEL_LABEL")}
              iconProps={<CalendarToday />}
              onChange={(date) =>
                handleReplace("periodStart", date?.toISOString())
              }
            />
            <DPicker
              placeholder={t("PERIOD_TO")}
              okLabel={t("OK")}
              clearLabel={t("CLEAR")}
              cancelLabel={t("CANCEL_LABEL")}
              className={classes.date}
              value={periodEnd}
              onChange={(date) =>
                handleReplace("periodEnd", date?.toISOString())
              }
            />
            <Autocomplete
              id="namePathology"
              options={namePathology}
              getOptionLabel={(option) => option.title}
              style={{ width: 500, marginTop: "10px" }}
              onChange={(e, value) => {
                setTypePathology(value?.value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t("WRITE_NAME_PATHOLOGY")}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Grid container>
              <Grid className={classes.center}>
                <Typography>{t("DIABETES")}: </Typography>
                <Select
                  native
                  value={hasDiabets}
                  className={classes.select}
                  size="small"
                  onChange={(e) => setHasDiabets(e.target.value)}
                >
                  <option value="all">{t("ALL")}</option>
                  <option value={true}>{t("YES")}</option>
                  <option value={false}>{t("NO")}</option>
                </Select>
              </Grid>
              <Grid className={classes.center}>
                <Typography>{t("ARTERIAL_HYPERTENSION")}: </Typography>
                <Select
                  native
                  value={hasPathalogy}
                  className={classes.select}
                  size="small"
                  onChange={(e) => setHasPathalogy(e.target.value)}
                >
                  <option value="all">{t("ALL")}</option>
                  <option value={true}>{t("YES")}</option>
                  <option value={false}>{t("NO")}</option>
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container className={classes.rightFilters}>
              <Typography>{t("GENDER")}: </Typography>
              <Select
                native
                value={gender}
                className={classes.selectGender}
                size="small"
                onChange={(e) => getGender(e.target.value)}
              >
                <option value="all">{t("ALL")}</option>
                <option value={"Женщина"}>{t("FEMALE")}</option>
                <option value={"Мужчина"}>{t("MALE")}</option>
              </Select>

              <Typography className={classes.age}>{t("AGE")}:</Typography>
              <Select
                native
                value={fromAge}
                className={classes.select}
                size="small"
                onChange={(e) => setFromAge(e.target.value)}
              >
                {ageList
                  .filter((value) => value <= beforeAge)
                  .map((age) => {
                    return <option value={age}>{"от " + age}</option>;
                  })}
              </Select>
              <Select
                native
                value={beforeAge}
                className={classes.select}
                size="small"
                onChange={(e) => setBeforeAge(e.target.value)}
              >
                {ageList
                  .filter((value) => value >= fromAge)
                  .map((age) => {
                    return <option value={age}>{"до " + age}</option>;
                  })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Table className={classes.table} size="small">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>{t("PATHOLOGY")}</TableCell>
                  <TableCell>{t("GENDER")}</TableCell>
                  <TableCell>{t("AGE")}</TableCell>
                  <TableCell>{t("DATE")}</TableCell>
                  <TableCell>{t("AVAILABILITY_SD")}</TableCell>
                  <TableCell>{t("AVAILABILITY_AG")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((research) => {
                  return (
                    <TableRow
                      key={research["Research.id"]}
                      className={classes.equalRow}
                      onClick={() => handleOnTableRow(research["Research.id"])}
                    >
                      <TableCell>{research["Research.id"]}</TableCell>
                      <TableCell>
                        {conclusionType === "Autoconclusion"
                          ? getNamePathologyForAutoconclusion(research)
                          : getNamePathologyForDoctorAndAnnotator(research)}
                      </TableCell>
                      <TableCell>{research["Research.gender"]}</TableCell>
                      <TableCell>
                        {getAgeInBirthDay(research["Research.birthday"])}
                      </TableCell>
                      <TableCell>
                        {formatDateAndTime(
                          research["Research.appointmentTimestamp"]
                        )}
                      </TableCell>
                      <TableCell>
                        {research["Research.diabetes"] === false
                          ? t("NO")
                          : t("YES")}
                      </TableCell>
                      <TableCell>
                        {research["Research.arterialHypertension"] === false
                          ? t("NO")
                          : t("YES")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              labelRowsPerPage={`${t("ROWS_PER_PAGE")}:`}
              count={countAllData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </>
  );
}
