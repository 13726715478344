import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const style = (isFullscreen) => ({
  display: "flex",
  position: isFullscreen ? "fixed" : "absolute",
  top: 0,
  left: 0,
  justifyContent: "center",
  zIndex: 1000,
  alignItems: "center",
  height: "100%",
  width: "100%",
  backgroundColor: "rgba(0,0,0,0.1)",
});

export default function Loader({ isFullscreen = false }) {
  return (
    <div style={style(isFullscreen)}>
      <CircularProgress />
    </div>
  );
}
