import instance from "../config/request";

export function getUserDataByUserName({ userName }) {
  return instance
    .get(`/moderation/getUser/${userName}`)
    .then((res) => res.data);
}

export function getAllOphthalmologystData() {
  return instance
    .get(`/moderation/getAllOphthalmologystData`)
    .then((res) => res.data);
}

export function getAllUsersData(moderationQuery) {
  const limit = `limit=${moderationQuery.limit}`;
  const offset =
    moderationQuery.offset || Number(moderationQuery.page) > 0
      ? `&offset=${moderationQuery.offset ||
          Number(moderationQuery.page) * Number(moderationQuery.per_page)}`
      : "";
  const date = moderationQuery.date ? `&date=${moderationQuery.date}` : "";
  return instance
    .get(`/moderation/getAllUsers?${limit}${offset}${date}`)
    .then((res) => res.data);
}

export function getDocumentById({ documentId }) {
  return instance
    .get(`/moderation/getDocument/${documentId}`)
    .then((res) => res.data);
}

export function confirmOphthalmologist(id) {
  return instance
    .put(`/moderation/confirmDoctor/${id}`)
    .then((res) => res.data);
}

export function rejectOphthalmologist(id, data) {
  return instance
    .put(`/moderation/rejectDoctor/${id}`, data)
    .then((res) => res.data);
}
