import { CONCLUSION_TYPE, RESEARCH_STATUS } from "../constants";

export const RESEARCH_DETAILS_LOADING =
  "app/ResearchDetails/RESEARCH_DETAILS_LOADING";
export const LOAD_RESEARCH_SUCCESS =
  "app/ResearchDetails/LOAD_RESEARCH_SUCCESS";
export const LOAD_RESEARCH_ERROR = "app/ResearchDetails/LOAD_RESEARCH_ERROR";
export const CLEAN_RESEARCH_DETAILS =
  "app/ResearchDetails/CLEAN_RESEARCH_DETAILS";
export const UPDATE_RESEARCH_DETAILS_DATA =
  "app/ResearchDetails/UPDATE_RESEARCH_DETAILS_DATA";
export const SET_FILTER = "SET_FILTER";
export const TOGGLE_CONCILIUM_DRAWER =
  "app/ResearchDetails/TOGGLE_CONCILIUM_DRAWER";
export const TOGGLE_CONCILIUM_MENU =
  "app/ResearchDetails/TOGGLE_CONCILIUM_MENU";
export const SET_PRECONCLUSION_TYPE =
  "app/ResearchDetails/SET_PRECONCLUSION_TYPE";
export const TOGGLE_SHARED_MODAL = "app/ResearchDetails/OPEN_SHARED_MODAL";

export function researchDetailsLoading() {
  return {
    type: RESEARCH_DETAILS_LOADING,
  };
}
export function loadResearchSuccess(research) {
  return {
    type: LOAD_RESEARCH_SUCCESS,
    research,
  };
}
export function cleanResearchDetails() {
  return {
    type: CLEAN_RESEARCH_DETAILS,
  };
}
export function loadResearchError(error) {
  return {
    type: LOAD_RESEARCH_ERROR,
    error,
  };
}
export function setResearchesFilter(filter) {
  return {
    type: SET_FILTER,
    filter,
  };
}
export function setTakenToWorkAndResponsibleDoctorId(id) {
  return {
    type: UPDATE_RESEARCH_DETAILS_DATA,
    updatedData: {
      status: RESEARCH_STATUS.TAKEN_TO_WORK,
      responsibleDoctorId: id,
    },
  };
}

export function toggleConciliumDrawer() {
  return {
    type: TOGGLE_CONCILIUM_DRAWER,
  };
}

export function toggleConciliumMenu() {
  return {
    type: TOGGLE_CONCILIUM_MENU,
  };
}

export function setConciliumPreconclusion(username, fullName) {
  return {
    type: SET_PRECONCLUSION_TYPE,
    preConclusionType: {
      type: CONCLUSION_TYPE.CONCILIUM_PRECONCLUSION,
      chooseConcilium: {
        username,
        fullName,
      },
      chooseAnnotator: {
        username: null,
        fullName: null,
      },
      chooseShared: {
        id: null,
        title: null,
      },
      chooseAuto: {
        title: null,
      },
    },
  };
}

export function setAnnotationPreconclusion(username, fullName) {
  return {
    type: SET_PRECONCLUSION_TYPE,
    preConclusionType: {
      type: CONCLUSION_TYPE.ANNOTATOR_CONCLUSION,
      chooseConcilium: {
        username: null,
        fullName: null,
      },
      chooseShared: {
        id: null,
        title: null,
      },
      chooseAuto: {
        title: null,
      },
      chooseAnnotator: {
        username,
        fullName,
      },
    },
  };
}

export function setSharedPreconclusion(id, title) {
  return {
    type: SET_PRECONCLUSION_TYPE,
    preConclusionType: {
      type: CONCLUSION_TYPE.SHARED_PRECONCLUSION,
      chooseConcilium: {
        username: null,
        fullName: null,
      },
      chooseShared: {
        id,
        title,
      },
      chooseAuto: {
        title: null,
      },
      chooseAnnotator: {
        username: null,
        fullName: null,
      },
    },
  };
}

export function setAutoPreconclusion(title) {
  return {
    type: SET_PRECONCLUSION_TYPE,
    preConclusionType: {
      type: CONCLUSION_TYPE.AUTO_PRECONCLUSION,
      chooseConcilium: {
        username: null,
        fullName: null,
      },
      chooseShared: {
        id: null,
        title: null,
      },
      chooseAuto: {
        title,
      },
      chooseAnnotator: {
        username: null,
        fullName: null,
      },
    },
  };
}

export function setFinalConclusion() {
  return {
    type: SET_PRECONCLUSION_TYPE,
    preConclusionType: {
      type: CONCLUSION_TYPE.CONCLUSION,
      chooseConcilium: {
        username: null,
        fullName: null,
      },
      chooseShared: {
        id: null,
        title: null,
      },
      chooseAuto: {
        title: null,
      },
      chooseAnnotator: {
        username: null,
        fullName: null,
      },
    },
  };
}

export function toggleSharedModal() {
  return {
    type: TOGGLE_SHARED_MODAL,
  };
}
