import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import { UserContext } from "../globalContexts/UserContext";
import {
  initKeycloak,
  KeycloakContext,
} from "../globalContexts/KeycloakContext";

const UserRoute = ({ roles, component: Component, ...rest }) => {
  const { user, handleLogin } = useContext(UserContext);
  const [keycloak, setKeycloak] = useContext(KeycloakContext);
  try {
    if (!user || keycloak.isTokenExpired()) {
      initKeycloak("login-required", setKeycloak, handleLogin);
      return null;
    }
  } catch (error) {
    console.log("initKeycloak error");
    console.error({ error });
  }
  if (roles && !roles.includes(user.role)) return null;
  return <Route {...rest} component={Component} />;
};

UserRoute.propTypes = {
  component: PropTypes.object.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
};

export default UserRoute;
