import instance from "../config/request";

export function getDataSla() {
  return instance.get("/notifications").then((res) => res.data);
}

export function postToggleSla(disabled) {
  return disabled
    ? instance.post("/keycloak/notification/disable")
    : instance.post("/keycloak/notification/enable");
}

export function postReadSla(data) {
  if (data) {
    return instance.post("/notifications/read", {
      notificationIds: data,
    });
  }
}
