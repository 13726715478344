import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles({
  card: {
    width: "290px",
    height: "115px",
    background: "#4158B5",
    padding: "10px 20px",
    boxSizing: "border-box",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "20px 24px 24px 20px",
  },
  cardTitle: {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    lineClamp: "4",
    boxOrient: "vertical",
  },
  cardButton: {
    border: "1px solid #fff!important",
    borderRadius: "100px!important",
    textTransform: "none!important",
    color: "#fff!important",
    fontSize: "11px!important",
  },
  redButton: {
    border: "1px solid red!important",
    color: "red!important",
  },
  cardInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  deadline: {
    color: "red",
  },
  "@media (max-width: 600px)": {
    card: {
      padding: "5px 10px",
      margin: "3px 6px 3px 6px",
    },
    cardTitle: {
      fontSize: "11px!important",
    },
    cardButton: {
      fontSize: "9px!important",
    },
  },
});

export default useStyle;
