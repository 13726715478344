import React, { useState } from "react";
import { DPicker } from "../../components/FormComponents";
import { useTranslation } from "react-i18next";
import { CalendarToday, MoreHoriz } from "@material-ui/icons";
import {
  Grid,
  makeStyles,
  TablePagination,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  useMediaQuery,
} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { getAllUsersData } from "../../api/moderationApi";
import { formatDateAndTime, formatDateForBackend } from "../../utils";
import {
  HeaderLeftTeleporter,
  HeaderRightTeleporter,
} from "../../components/Header";
import SupportTelephoneNumber from "../../components/SupportTelephoneNumber";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: "15px 20px 0 20px",
    textAlign: "center",
    alignItems: "center",
  },
  date: {
    width: "150px",
    margin: "4px 20px 0 0",
  },
  select: {
    width: "150px",
  },
  filters: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    gap: "10px",
  },
  tableTitle: {
    backgroundColor: theme.palette.grey[100],
    top: "64px",
    fontWeight: "500",
    fontSize: "14px",
  },
  table: {
    marginTop: "20px",
  },
  hideLastBorder: {
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  },
}));
export function useRouterQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}
export function UserModerationPage() {
  const { t } = useTranslation("registrationPage");
  const [date, setDate] = useState(null);
  const classes = useStyle();
  const history = useHistory();
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const query = useRouterQuery();

  const page = query.get("page") || 0;
  const limit = query.get("limit") || 20;

  const handleChangePage = (event, newPage) => {
    query.set("page", newPage);
    history.push({ search: query.toString() });
  };

  const handleChangeRowsPerPage = (event) => {
    query.set("page", 0);
    query.set("limit", +event.target.value);
    history.push({ search: query.toString() });
  };
  const moderationQuery = {
    page: page,
    limit: limit,
    date: formatDateForBackend(date),
  };
  const {
    data,
    isLoading: loagingAllUsersData,
    error: errorAllUsersData,
  } = useQuery(
    ["allUsersData", page, limit, date],
    () => getAllUsersData(moderationQuery),
    {
      refetchOnWindowFocus: false,
    }
  );

  const tableData = loagingAllUsersData ? [] : data.allUserData;
  return (
    <>
      <HeaderLeftTeleporter.Source>
        <Typography
          variant={isMd ? "body1" : "h6"}
          color="inherit"
          component={Link}
          to="/"
        >
          {isSm ? t("SYSTEM_UDDR_SHORTENED") : t("SYSTEM_UDDR")}
        </Typography>
      </HeaderLeftTeleporter.Source>
      <HeaderRightTeleporter.Source>
      <div style={{display:"flex"}}>
            <SupportTelephoneNumber/>
            <Button
              className="text-white border-white"
              variant="outlined"
              onClick={history.goBack}
              >
                {t("BACK")}
              </Button>
          </div>
      </HeaderRightTeleporter.Source>
      <Grid container justifyContent="space-between" className={classes.root}>
        <Typography variant="h5">{t("USERS")}</Typography>
        <Grid item className={classes.filters}>
          <Typography>{t("DATE")}:</Typography>
          <DPicker
            className={classes.date}
            onChange={(value) => setDate(value)}
            value={date}
            iconProps={<CalendarToday />}
          />
        </Grid>
      </Grid>
      <Table stickyHeader size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.tableTitle}>
              №
            </TableCell>
            <TableCell align="center" className={classes.tableTitle}>
              {t("DATE_AND_TIME")}
            </TableCell>
            <TableCell align="center" className={classes.tableTitle}>
              {t("IIN")}
            </TableCell>
            <TableCell align="center" className={classes.tableTitle}>
              {t("NUMBER_PHONE")}
            </TableCell>
            <TableCell align="center" className={classes.tableTitle}>
              {t("EMAIL")}
            </TableCell>
            <TableCell align="center" className={classes.tableTitle}>
              {t("STATUS")}
            </TableCell>
            <TableCell align="center" className={classes.tableTitle}>
              {t("DETAILS")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loagingAllUsersData ? (
            <CircularProgress />
          ) : (
            tableData?.map((rowData) => {
              return (
                <TableRow key={rowData.id} className={classes.hideLastBorder}>
                  <TableCell align="center">{rowData.id}</TableCell>
                  <TableCell align="center">
                    {formatDateAndTime(rowData.createdTimestamp)}
                  </TableCell>
                  <TableCell align="center">{rowData.username}</TableCell>
                  <TableCell align="center">
                    {rowData?.attributes?.phone}
                  </TableCell>
                  <TableCell align="center">{rowData.email}</TableCell>
                  <TableCell align="center">
                    {t(rowData.ophthalmologist_status)}
                  </TableCell>
                  <TableCell align="center">
                    {
                      <Button
                        variant="contained"
                        className="mr-10"
                        color="primary"
                        size="small"
                        title={t("DETAILS")}
                        component={Link}
                        to={`/moderation/${rowData.username}`}
                      >
                        <MoreHoriz />
                      </Button>
                    }
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={data?.count}
        rowsPerPage={limit}
        labelRowsPerPage={`${t("ROWS_PER_PAGE")}:`}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
