import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  Add,
  Close,
  Edit,
  ExpandLess,
  ExpandMore,
  Person,
  VisibilityOffRounded,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useDisclosure } from "../../../hooks/useDisclosure";
import DiseaseHistory from "./DiseaseHistory";
import Diagnosis from "./Diagnosis";
import Service from "./Service";
import Recommendation from "./Recommendation";
import OphthalmologyStatus from "./OpthalmologyStatus";
import { VisitHistory } from "./VisitHistory";
import Treatment from "./Treatment";

const menu = [
  {
    label: "HISTORY_OF_APPOINTMENTS",
    Component: VisitHistory,
  },
  {
    label: "HISTORY_OF_DISEASES",
    Component: DiseaseHistory,
  },
  {
    label: "SERVICE_RENDERED",
    Component: Service,
  },
  {
    label: "OPHTHALMOLOGY_STATUS",
    Component: OphthalmologyStatus,
  },
  {
    label: "DIAGNOSIS",
    Component: Diagnosis,
  },
  {
    label: "RECOMMENDATION",
    Component: Recommendation,
  },
  {
    label: "TREATMENT",
    Component: Treatment,
  },
];

const useStyles = makeStyles((theme) => ({
  accordion: {
    background: theme.palette.grey.A100,
  },
  icon: {
    border: "1px solid black",
    color: theme.palette.primary.main,
  },
}));

function PatientInfoCard(props) {
  const { patientInfo } = props;
  const { t } = useTranslation("research");
  const { isOpen, toggle } = useDisclosure();
  return (
    <Paper className="h-100 relative overflow-auto pl-8 pr-8">
      <div className="pt-4 pl-8 pr-8">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <Person color="primary" fontSize="large" />
          <Typography
            variant="h6"
            style={{ lineHeight: "24px" }}
            align="center"
          >
            {patientInfo.fullName}
          </Typography>
          <IconButton onClick={toggle} size="small">
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">{t("BIRTHDAY")}:</Typography>
          <Typography className="text-gray">{patientInfo.birthday}</Typography>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">{t("DIABETES")}:</Typography>
          <Checkbox size="small" disabled checked={patientInfo.diabetes} />
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">
            {t("ARTERIAL_HYPERTENSION")}:
          </Typography>
          <Checkbox
            size="small"
            disabled
            checked={patientInfo.arterialHypertension}
          />
        </Grid>
        <Collapse in={isOpen}>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("IIN")}:</Typography>
            <Typography className="text-gray">{patientInfo.iin}</Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("AGE")}:</Typography>
            <Typography className="text-gray">{patientInfo.age}</Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("GENDER")}:</Typography>
            <Typography className="text-gray">{patientInfo.gender}</Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("PHONE")}:</Typography>
            <Typography className="text-gray">{patientInfo.phone}</Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("REFERRER")}:</Typography>
            <Typography className="text-gray">
              {patientInfo.referrerFullName}
            </Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">
              {t("RECEPTION_DOCTOR")}:
            </Typography>
            <Typography className="text-gray">
              {patientInfo.receptionDoctorFullName}
            </Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("SERVICE_TYPE")}:</Typography>
            <Typography className="text-gray">
              {t(patientInfo.serviceType)}
            </Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("COMPLAINTS")}:</Typography>
            <Typography className="text-gray">
              {patientInfo.complaints}
            </Typography>
          </Grid>
        </Collapse>
      </div>
    </Paper>
  );
}

const SideMenu = ({ patientInfo }) => {
  const { t } = useTranslation("patient-registry");
  const classes = useStyles();
  return (
    <Grid container direction={"column"}>
      <PatientInfoCard patientInfo={patientInfo} />
      {menu.map(({ label, Component }) => (
        <Accordion key={label}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className={classes.accordion}
          >
            <Typography>{t(label)}</Typography>
          </AccordionSummary>
          <AccordionDetails>{Component && <Component />}</AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

export default SideMenu;
