// @ts-check
import {
  isDate,
  isValid,
  format,
  differenceInDays,
  subDays,
  differenceInHours,
  subHours,
  differenceInMinutes,
  set,
  startOfYear,
  setYear,
  endOfDay,
  differenceInMonths,
  differenceInYears,
  getDay,
} from "date-fns";

export function formatDateAndTime(timestamp) {
  const date = timestamp && new Date(timestamp);
  return isDate(date) && isValid(date) ? format(date, "dd.MM.yyyy HH:mm") : "";
}

export function formatDate(timestamp) {
  const date = timestamp && new Date(timestamp);
  return isDate(date) && isValid(date) ? format(date, "dd.MM.yyyy") : "";
}
export function formatDateForBackend(timestamp) {
  const date = timestamp && new Date(timestamp);
  return isDate(date) && isValid(date) ? format(date, "yyyy-MM-dd") : "";
}
export function formatTimeZero(timestamp) {
  const date = timestamp && new Date(timestamp);
  return isDate(date) && isValid(date)
    ? set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    : "";
}

export function formatTime(timestamp) {
  const date = timestamp && new Date(timestamp);
  return isDate(date) && isValid(date) ? format(date, "HH:mm") : "";
}

export function getTimeFromMilliseconds(millisecond, t) {
  let time;
  const getMillisecund = millisecond < 0 ? millisecond * -1 : millisecond;
  const seconds = Math.floor((getMillisecund / 1000) % 60)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((getMillisecund / (1000 * 60)) % 60)
    .toString()
    .padStart(2, "0");
  const hours = Math.floor((getMillisecund / (1000 * 60 * 60)) % 24)
    .toString()
    .padStart(2, "0");
  const days = Math.floor(getMillisecund / (1000 * 60 * 60 * 24)).toString();

  if (days > 0) {
    time = days + t("DAYS") + hours + ":" + minutes + ":" + seconds;
  } else {
    time = hours + ":" + minutes + ":" + seconds;
  }

  return millisecond < 0 ? `- ${time}` : time;
}

export function formatTotal(n, withCurrency) {
  if (withCurrency) {
    return new Intl.NumberFormat("ru-RU", {
      currency: "KZT",
      style: "currency",
      maximumFractionDigits: 2,
    }).format(n);
  }
  return new Intl.NumberFormat("ru-RU", { maximumFractionDigits: 2 }).format(n);
}

export function differenceBetweenDates(from, to) {
  const days = differenceInDays(from, to);
  from = subDays(from, days);
  const hours = differenceInHours(from, to);
  from = subHours(from, hours);
  const minutes = differenceInMinutes(from, to);
  return { days, hours, minutes };
}

export function composeFullName(firstName, lastName) {
  const fullName = [];
  const fam = lastName?.split(/\s+/)[0];
  const pat = lastName?.split(/\s+/)[1];

  if (fam) {
    fullName.push(fam);
  }

  if (firstName) {
    fullName.push(firstName);
  }

  if (pat) {
    fullName.push(pat);
  }

  return fullName.join(" ");
}

export function getAgeInBirthDay(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = differenceInYears(today, birthDate);
  var m = differenceInMonths(today, birthDate);
  if (m < 0 || (m === 0 && getDay(today) < getDay(birthDate))) {
    age--;
  }
  return age;
}

export function getPeriodDateWithAge(fromDate, beforeAge) {
  const fromYear = format(
    startOfYear(
      setYear(new Date(), Number(new Date().getFullYear()) - beforeAge)
    ),
    "yyyy-MM-dd"
  );
  const beforeYear = format(
    endOfDay(setYear(new Date(), Number(new Date().getFullYear()) - fromDate)),
    "yyyy-MM-dd"
  );

  return { fromYear, beforeYear };
}
