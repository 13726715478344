import React, { useReducer, useContext } from "react";
import researchReducer from "./researchReducer";
import { DictionariesContext } from "../../../globalContexts/DictionariesContext";
import { reducerEnhancer } from "../../../utils";
import { CONCLUSION_TYPE, RESEARCH_STATUS } from "../constants";
export * from "./researchActions";

export function createInitialState({
  structuredConclusionResults,
  structuredDescriptions,
}) {
  const descriptionFormStructure = structuredDescriptions.reduce(
    (acc, item) => {
      acc[item.codename] = null;
      return acc;
    },
    {}
  );

  const conclusionResultFormStructure = structuredConclusionResults.reduce(
    (acc, item) => {
      acc[item.codename] = null;
      return acc;
    },
    {}
  );
  return {
    researchDetails: {
      loading: false,
      error: null,
      research: {
        referrerId: "",
        iin: "",
        fullName: "",
        gender: "",
        age: "",
        complaints: "",
        diabetes: false,
        arterialHypertension: false,
        leftEyes: [],
        rightEyes: [],
        status: "",
        responsibleDoctorId: null,
        appointmentTimestamp: "",
        serviceType: "",
        refractionRight: "",
        refractionLeft: "",
        rightEyeConclusion: {
          id: null,
          conclusionResult: conclusionResultFormStructure,
          description: descriptionFormStructure,
        },
        leftEyeConclusion: {
          id: null,
          conclusionResult: conclusionResultFormStructure,
          description: descriptionFormStructure,
        },
        recommendationCodenames: null,
        recommendationText: "",
      },
    },
    isConciliumDrawerOpen: false,
    isSharedModalOpen: false,
    researchFilter: {
      status: "",
    },
    isConciliumMenuOpen: false,
    preConclusionType: {
      type: CONCLUSION_TYPE.AUTO_PRECONCLUSION,
      chooseConcilium: {
        username: null,
        fullName: null,
      },
      chooseShared: {
        id: null,
        title: null,
      },
      chooseAuto: {
        title: null,
      },
      chooseAnnotator: {
        username: null,
        fullName: null,
      },
    },
  };
}

export const ResearchContext = React.createContext();

export default function ResearchContextProvider({ children }) {
  const { structuredConclusionResults, structuredDescriptions } = useContext(
    DictionariesContext
  )[0];
  const [state, dispatch] = useReducer(
    reducerEnhancer(
      researchReducer,
      createInitialState({
        structuredConclusionResults,
        structuredDescriptions,
      })
    ),
    createInitialState({ structuredConclusionResults, structuredDescriptions })
  );
  return (
    <ResearchContext.Provider value={[state, dispatch]}>
      {children}
    </ResearchContext.Provider>
  );
}

export function useIsInProgress() {
  const details = useContext(ResearchContext)[0];

  return (
    details.researchDetails.research.status === RESEARCH_STATUS.IN_PROGRESS
  );
}
