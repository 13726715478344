import instance from "../config/request";

export async function getShareToken(id) {
  return instance
    .post("/share", { researchId: Number(id) })
    .then((res) => res.data);
}

export async function getShareData(token) {
  return instance.get(`/v3/share/${token}`).then((res) => res.data);
}

export async function createConclusionsShare(token, data) {
  return instance.post(`/preconclusion/${token}`, data);
}
