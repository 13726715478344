import React from "react";
import clsx from "clsx";
import Select, { createFilter } from "react-select";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import CancelIcon from "@material-ui/icons/Cancel";
import { FixedSizeList as List } from "react-window";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: ({ isMulti }) => ({
    display: "flex",
    flexWrap: isMulti ? "wrap" : "nowrap",
    whiteSpace: "nowrap",
    flex: 1,
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
  }),
  multiValueContainer: {
    display: "flex",
    flexWrap: "wrap",
    whiteSpace: "nowrap",
    flex: 1,
    position: "relative",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
    "& .MuiChip-label": {
      whiteSpace: "normal !important",
    },
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: "absolute",
    zIndex: 1010,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function MenuList(props) {
  const height = 40;
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
}

const components = {
  Control,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const stringify = (option) => option.label;
const customFilter = createFilter({
  ignoreCase: true,
  trim: false,
  stringify,
});

const VIRTUALIZE_LENGTH = 200;

export default function MuiSelect(props) {
  const { textFieldProps, options, value, isVirtual, ...rest } = props;
  const classes = useStyles({ isMulti: rest.isMulti });
  const theme = useTheme();

  let selectValue = value;

  // virtualized by prop or automatically based on options length > VIRTUALIZE_LENGTH
  const virtualize =
    isVirtual || (options && options.length > VIRTUALIZE_LENGTH);

  // normalize value to object form if it is a string(id) or array[id1, id2]
  if (options && options.length) {
    if (value && (typeof value === "string" || typeof value === "number")) {
      const newValue = options.find((option) => option.value === value);
      rest.onChange(newValue);
      selectValue = null;
    }

    if (
      value &&
      value.constructor === Array &&
      value.length > 0 &&
      value.every((val) => typeof val === "string")
    ) {
      const newValues = options.filter((option) =>
        value.includes(option.value)
      );
      rest.onChange(newValues);
      selectValue = null;
    }
  }

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div className={classes.root}>
      <Select
        classes={classes}
        styles={selectStyles}
        menuPortalTarget={document.body}
        TextFieldProps={{
          InputLabelProps: {
            shrink: true,
          },
          ...textFieldProps,
        }}
        components={virtualize ? { ...components, MenuList } : components}
        options={options}
        value={selectValue}
        filterOption={customFilter}
        isClearable={true}
        {...rest}
      />
    </div>
  );
}
