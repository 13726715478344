import {useTranslation} from "react-i18next";
import instance from "./config/request";
import {useContext, useEffect} from "react";
import {createToast, MessagesContext} from "./globalContexts/MessagesContext";

const AxiosInterceptorWizard = () => {
    const {t} = useTranslation();
    const messageDispatch = useContext(MessagesContext)[1];
    useEffect(() => {
        instance.interceptors.response.use((response) => response, (error) => {
            if(!navigator.onLine) {
                messageDispatch(createToast(t('ERR_NO_INTERNET'), 'error'));
                return Promise.reject(error);
            }
            if(error?.response?.status === 502 || error?.response?.status === 503) {
                messageDispatch(createToast(t('ERR_SERVER_NO_ACCESS'), 'error'))
            }
            if(error?.response?.status === 500) {
                messageDispatch(createToast(t('ERR_SERVER') + ' ' + error?.response?.data?.error?.message, 'error'))
            }
            return Promise.reject(error);
        });
    }, [])
    return null;
}

export default AxiosInterceptorWizard;