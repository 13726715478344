import React, { useContext, useEffect, useRef, useState } from "react";
import { useRouterQuery } from "../../../../hooks/useRouterQuery";
import {
  Button,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Assignment,
  Brightness1,
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
  Person,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FileSaver from "file-saver";
import { Formik } from "formik";
import JSZip from "jszip";
import { useDisclosure } from "../../../../hooks/useDisclosure";
import { formatDate, formatDateAndTime } from "../../../../utils";
import {
  createToast,
  MessagesContext,
} from "../../../../globalContexts/MessagesContext";

import {
  ConclusionSubForm,
  CornerstoneOverlay,
} from "../../../ResearchesPage/ResearchDetails/ResearchDetailsForDoctor";
import { CornerstoneImageViewer } from "../../components/CornerstoneImageViewer";
import { getImagesPdf } from "../../../ResearchesPage/ResearchesList/ResearchJournalTable";
import { temporaryFormSchema } from "../../../ResearchesPage/ConclusionForm/ConclusionForm";
import ScientificConclusionTypePicker from "../ScientificConclusionTypePicker";
import { RESEARCH_STATUS } from "../../../ResearchesPage/constants";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 76px)",
    margin: "6px",
    width: "auto",
  },
  progressBar: {
    width: "100%",
  },
  imageGridContainer: {
    overflowX: "auto",
  },
  imageGridItem: {
    flex: "0 0 33.3%",

    "& > img": {
      display: "block",
    },

    "&[data-active=true]": {
      border: "1px solid red",
    },
  },
}));

function PatientInfoCard(props) {
  const { patientInfo, approvedConclusions } = props;
  const { t } = useTranslation("research");
  const { isOpen, toggle } = useDisclosure(true);

  return (
    <Paper className="h-100 relative overflow-auto pl-8 pr-8">
      <div className="pt-4 pl-8 pr-8">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <Person color="primary" fontSize="large" />

          <IconButton onClick={toggle} size="small">
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">{t("BIRTHDAY")}:</Typography>
          <Typography className="text-gray">
            {formatDate(patientInfo.birthday)}
          </Typography>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">{t("DIABETES")}:</Typography>
          <Checkbox size="small" disabled checked={patientInfo.diabetes} />
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">
            {t("ARTERIAL_HYPERTENSION")}:
          </Typography>
          <Checkbox
            size="small"
            disabled
            checked={patientInfo.arterialHypertension}
          />
        </Grid>
        <Collapse in={isOpen}>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("COMPLAINTS")}:</Typography>
            <Typography className="text-gray">
              {patientInfo.complaints}
            </Typography>
          </Grid>
        </Collapse>
      </div>
      <Paper elevation={8} key={patientInfo.id} className="mt-4 mb-4">
        <AppointmentCardHeader t={t} research={patientInfo} isOpen={isOpen} />
        <Collapse in={isOpen}>
          {isOpen && (
            <AppointmentCardContent
              t={t}
              research={patientInfo}
              rightEyes={patientInfo.rightEyes}
              leftEyes={patientInfo.leftEyes}
              approvedConclusions={approvedConclusions}
            />
          )}
        </Collapse>
      </Paper>
    </Paper>
  );
}

function AppointmentCardHeader(props) {
  const { research } = props;

  return (
    <Grid
      className="pointer pt-4 pl-8 pr-8"
      container
      alignItems="center"
      justify="space-between"
      wrap="nowrap"
    >
      <div>
        <Typography align="center">
          {formatDateAndTime(research.createdTimestamp)}
        </Typography>
      </div>
    </Grid>
  );
}

export function AppointmentCardContent(props) {
  const { research, rightEyes, leftEyes, t, approvedConclusions } = props;
  const classes = useStyles();
  const [query, handleReplace] = useRouterQuery();
  const activeImg = query.get("activeImg");
  function getStatusClassName(side) {
    if (RESEARCH_STATUS.FINISHED === research.status) {
      return "text-green";
    }
    if (RESEARCH_STATUS.CANCELLED === research.status) {
      return "text-red";
    }
    if (approvedConclusions[side]) return "text-blue";
    return "text-orange";
  }

  // on appointmentId change set activeImg according to existence of rightEyes or leftEyes
  useEffect(() => {
    if (!activeImg) {
      if (rightEyes?.length) {
        handleReplace("activeImg", rightEyes[0].id);
        return;
      }
      if (leftEyes?.length) {
        handleReplace("activeImg", leftEyes[0].id);
      }
    }
  }, [leftEyes, rightEyes]);

  return (
    <>
      {rightEyes?.length > 0 && (
        <div>
          <Grid
            container
            justify="space-between"
            className="pl-4 pr-4"
            title={t(`STATUS_${research.status}`)}
          >
            <Typography>OD 1/{rightEyes.length}</Typography>
            <Brightness1 className={getStatusClassName("rightEyeConclusion")} />
          </Grid>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            className={classes.imageGridContainer}
          >
            {rightEyes.map(({ id, base64, contentType }) => (
              <Grid
                key={id}
                item
                xs={4}
                data-active={activeImg === id}
                onClick={() => handleReplace("activeImg", id, research.id)}
                className={classes.imageGridItem}
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData("researchId", research.id);
                  e.dataTransfer.setData("id", id);
                  e.dataTransfer.setData("side", "rightEyes");
                }}
              >
                <img
                  width="100%"
                  src={`data:${contentType};base64,${base64}`}
                  alt="rightEyes"
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {leftEyes?.length > 0 && (
        <div>
          <Grid
            container
            justify="space-between"
            className="pl-4 pr-4"
            title={t(`STATUS_${research.status}`)}
          >
            <Typography>OS 1/{leftEyes.length}</Typography>
            <Brightness1 className={getStatusClassName("leftEyeConclusion")} />
          </Grid>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            className={classes.imageGridContainer}
          >
            {leftEyes.map(({ id, base64, contentType }) => (
              <Grid
                key={id}
                item
                xs={4}
                onClick={() => {
                  handleReplace("activeImg", id, research.id);
                }}
                data-active={activeImg === id}
                className={classes.imageGridItem}
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData("researchId", research.id);
                  e.dataTransfer.setData("id", id);
                  e.dataTransfer.setData("side", "leftEyes");
                }}
              >
                <img
                  width="100%"
                  src={`data:${contentType};base64,${base64}`}
                  alt="leftEyes"
                />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
}

function ScientificDetailsDesktop({
  patientInfo,
  conclusionInfo,
  showAnnotatorPicker,
  annotatorConclusionInfo,
  autoConclusionInfo,
  conclusionType,
  setConclusionType,
  handleSubmitConclusions,
}) {
  const history = useHistory();
  const [query, handleReplace] = useRouterQuery();
  const activeImg = query.get("activeImg");

  const { isOpen, toggle } = useDisclosure();
  const dispatchMessage = useContext(MessagesContext)[1];
  const { t } = useTranslation(["research", "image-viewer"]);
  const classes = useStyles();

  let activeSide = "";
  const hasEyes =
    patientInfo.rightEyes?.length > 0 || patientInfo.leftEyes?.length > 0;
  if (patientInfo.rightEyes?.find((eye) => eye.id == activeImg)) {
    activeSide = "rightEye";
  }
  if (patientInfo.leftEyes?.find((eye) => eye.id == activeImg)) {
    activeSide = "leftEye";
  }
  function handleToggle() {
    if (activeSide) {
      toggle();
    }
  }
  const [approvedConclusions, setApprovedConclusions] = useState({
    rightEyeConclusion: false,
    leftEyeConclusion: false,
  });
  useEffect(() => {
    setApprovedConclusions({
      rightEyeConclusion: false,
      leftEyeConclusion: false,
    });
  }, [history.location.pathname]);

  return (
    <Grid container item xs className={classes.root} spacing={1}>
      <Grid className="h-100" item xs={3} lg={2}>
        <PatientInfoCard
          patientInfo={patientInfo}
          approvedConclusions={approvedConclusions}
        />
      </Grid>
      <Grid className="h-100" item xs={isOpen ? 6 : 9} lg={isOpen ? 7 : 10}>
        <Paper className="h-100 pl-8 pr-8 relative">
          {/* // this solves some strange bug related to image load error */}
          {hasEyes && activeImg && (
            <CornerstoneImageViewer
              eyes={{
                rightEyes: patientInfo.rightEyes,
                leftEyes: patientInfo.leftEyes,
              }}
              handleDownloadPicture={() => {
                const zip = new JSZip();
                patientInfo.rightEyes?.forEach((img) => {
                  const name = `rightEye_${img.id}.${
                    img.contentType.split("/")[1]
                  }`;
                  zip.file(name, img.base64, { base64: true });
                });
                patientInfo.leftEyes?.forEach((img) => {
                  const name = `leftEye_${img.id}.${
                    img.contentType.split("/")[1]
                  }`;
                  zip.file(name, img.base64, { base64: true });
                });
                zip.generateAsync({ type: "blob" }).then((zipFile) => {
                  FileSaver.saveAs(zipFile, "Снимки.zip");
                });
              }}
              handleDownloadImagesPdf={() =>
                getImagesPdf(patientInfo.id, dispatchMessage, t)
              }
              showConciliumButton={false}
              showSharedButton={false}
              dontShowDownload={true}
              activeImg={activeImg}
              setActiveImg={(id) => handleReplace("activeImg", id)}
              isConclusionOpen={isOpen}
              CustomOverlay={(props) => (
                <CornerstoneOverlay
                  {...props}
                  t={t}
                  date={patientInfo.appointmentTimestamp}
                />
              )}
            />
          )}
          <Button
            onClick={handleToggle}
            title="Сформировать заключение"
            className="absolute t-8 r-8 text-orange"
          >
            {!isOpen && <ChevronLeft />}
            <Assignment />
            {isOpen && <ChevronRight />}
          </Button>
        </Paper>
      </Grid>
      {isOpen && (
        <Grid className="h-100" item xs={3}>
          {showAnnotatorPicker && (
            <ScientificConclusionTypePicker
              annotatorConclusionInfo={annotatorConclusionInfo}
              autoConclusionInfo={autoConclusionInfo}
              conclusionType={conclusionType}
              setConclusionType={setConclusionType}
            />
          )}
          <Formik
            onSubmit={handleSubmitConclusions}
            validationSchema={temporaryFormSchema}
            enableReinitialize
            initialValues={conclusionInfo}
          >
            {(subForm) => {
              return (
                <ConclusionSubForm
                  subForm={subForm}
                  conclusionName={`${activeSide}Conclusion`}
                  approvedConclusions={approvedConclusions}
                  setApprovedConclusions={setApprovedConclusions}
                  dispatchMessage={dispatchMessage}
                  editMode={false}
                  shared={true}
                  replaceActiveImg={(conclusionName) => {
                    if (conclusionName === "rightEyeConclusion") {
                      handleReplace("activeImg", patientInfo.rightEyes[0].id);
                    }
                    if (conclusionName === "leftEyeConclusion") {
                      handleReplace("activeImg", patientInfo.leftEyes[0].id);
                    }
                  }}
                />
              );
            }}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
}

export default ScientificDetailsDesktop;
