import {useTranslation} from "react-i18next";
import eyePlaceholder from "../../assets/images/eye-placeholder.jpg";
import {Typography} from "@material-ui/core";
import React from "react";

export default function LoadingImage() {
    const { t } = useTranslation("research");
    return (
        <>
            <img width="100%" src={eyePlaceholder} alt="placeholder" />
            <Typography align="center" variant="h6">
                {t("LOADING")}
            </Typography>
        </>
    );
}
