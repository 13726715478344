import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Close, Edit, Visibility } from "@material-ui/icons";
import { formatDate } from "../../../../utils";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ROLES } from "../../../../config/constants";
import Roled from "../../../../components/Roled";

const useStyle = makeStyles(() => ({
  root: {
    padding: "0 10px",
  },
}));

const OphthalmologyStatusList = ({
  ophthalmologyStatuses,
  onChooseOphthalmologyStatus,
  onEditOphthalmologyStatus,
  onDeleteOphthalmologyStatus,
  isFinished
}) => {
  const { t } = useTranslation("patient-registry");
  const classes = useStyle();
  const { id: researchId } = useParams();
  const { isFetching } = useQuery(["researchDetails", researchId], {
    enabled: false,
  });
  if (isFetching) {
    return (
      <Grid container alignItems={"center"} justify={"center"}>
        <Box height={"10px"} />
        <CircularProgress />;
      </Grid>
    );
  }
  if (!ophthalmologyStatuses) {
    return null;
  }
  return (
    <Paper elevation={3} className={classes.root}>
      <Grid container direction={"column"}>
        {ophthalmologyStatuses.map((item) => (
          <Grid
            className={"item"}
            key={item.id}
            container
            direction={"row"}
            justify={"space-between"}
            alignItems={"center"}
          >
            <div>{formatDate(item.createdAt)}</div>
            <span
              className={"clickable"}
              onClick={() => onChooseOphthalmologyStatus(item)}
            >
              {t("OPHTHALMOLOGY_STATUS")}
            </span>
            <div>
              {item.isCurrent && !isFinished && (
                <Roled roles={[ROLES.DOCTOR]}>
                  <Edit
                    cursor={"pointer"}
                    onClick={() => onEditOphthalmologyStatus(item)}
                    color={"primary"}
                  />
                </Roled>
              )}
              {item.isCurrent && !isFinished && (
                <Roled roles={[ROLES.DOCTOR]}>
                  <Close
                    cursor={"pointer"}
                    onClick={() => onDeleteOphthalmologyStatus(item)}
                    color={"primary"}
                  />
                </Roled>
              )}
            </div>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default OphthalmologyStatusList;
