import React, {
  memo,
  createRef,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { Button, Grid, makeStyles, Modal, Typography } from "@material-ui/core";
import SideMenu from "./SideMenu";
import { useResearchDetails } from "../ResearchesPage/ResearchDetails/queryHooks";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { Breadcrumb } from "./Breadcrumb";
import {
  HeaderLeftTeleporter,
  HeaderRightTeleporter,
} from "../../components/Header";
import { useTranslation } from "react-i18next";
import TextEditor from "./TextEditor";
import { useMutation, useQuery } from "react-query";
import {
  createConsultationConclusion,
  getConsultationConclusion,
  getDrugsDictionary,
  getMkb10Dictionary,
  getOphthalmologyStatusFields,
} from "../../api";
import AsyncButton from "../../components/AsyncButton";
import { downloadFileObject, getFileFromDataUrl } from "../../utils";
import {
  createToast,
  MessagesContext,
} from "../../globalContexts/MessagesContext";
import { queryClient } from "../../globalContexts/queryContext";
import {
  RESEARCH_SERVICE_NAME,
  RESEARCH_STATUS,
} from "../ResearchesPage/constants";
import {
  renderDiagnosis,
  renderDiseaseHistory,
  renderOphthalmologyStatus,
  renderRecommendations,
  renderResearchInfo,
  renderServices,
  renderTemplateBegin,
  renderTemplateEnd,
  renderTreatments,
  renderUniversalServices,
} from "./renderFuncs";
import { DictionariesContext } from "../../globalContexts/DictionariesContext";
import { addDictionary } from "../../globalContexts/DictionariesContext/dictionariesActions";
import { getEditorTemplates } from "../../api/patientRegistry";
import { UserContext } from "../../globalContexts/UserContext";
import { ROLES } from "../../config/constants";
import SupportTelephoneNumber from "../../components/SupportTelephoneNumber";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 64px)",
    "& .item": {
      padding: "6px 0",
      borderBottom: "1px solid " + theme.palette.divider,
    },
    "& .clickable": {
      cursor: "pointer",
    },
  },
  left: {
    flex: 1,
    maxHeight: "100vh",
    overflowY: "auto",
  },
  right: {
    flex: 2,
    padding: "6px",
    "& .ck-content": {
      background: "white",
      flex: 1,
      border: "1px solid " + theme.palette.grey.A200,
    },
  },
  buttonContainer: {
    padding: "12px",
  },
  downloadButton: {
    background: theme.palette.secondary,
    color: "white",
  },
}));
export async function downloadConclusionConclusion(id, dispatchMessage, t) {
  try {
    const res = await getConsultationConclusion(id);
    const file = getFileFromDataUrl(
      `data:application/pdf;base64,${res.consultationConclusionPDF}`,
      `Заключение_${id}`
    );
    downloadFileObject(file);
  } catch (error) {
    dispatchMessage(createToast(t("DOWNLOAD_FILE_ERROR"), "error"));
  }
}

const PatientRegistry = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [editor, setEditor] = useState(null);
  const history = useHistory();
  const dispatchMessage = useContext(MessagesContext)[1];
  const [{ data: research }] = useResearchDetails(id, false);
  const { t, i18n } = useTranslation([
    "patient-registry",
    "research",
    "serviceNamesDict",
  ]);
  const { mutate, isLoading } = useMutation(
    async ({ id, data }) => await createConsultationConclusion(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["researchDetails", id]);
      },
    }
  );
  const [{ mkb10, recommendations, drugs }, dispatch] = useContext(
    DictionariesContext
  );
  const { data: mkb10Dict } = useQuery("mkb10", () => getMkb10Dictionary(), {
    initialData: [],
    enabled: !Boolean(mkb10),
  });
  const { data: drugsDict } = useQuery(
    ["dictionary", "drugs"],
    () => getDrugsDictionary(),
    {
      initialData: [],
      enabled: !Boolean(mkb10),
    }
  );
  const { data: editorTemplates } = useQuery(
    "editorTemplates",
    () => getEditorTemplates(),
    {
      initialData: [],
    }
  );
  const { user } = useContext(UserContext);
  const { data: fields } = useQuery(
    "ophthalmologyStatusFields",
    () => getOphthalmologyStatusFields(),
    {
      enabled: true,
    }
  );

  useEffect(() => {
    if (mkb10Dict.length) {
      dispatch(addDictionary("mkb10", mkb10Dict));
    }
  }, [dispatch, mkb10Dict]);

  useEffect(() => {
    if (drugsDict.length) {
      dispatch(addDictionary("drugs", drugsDict));
    }
  }, [drugsDict]);

  useEffect(() => {
    if (research && editor && t && mkb10Dict) {
      let renderHtml = "";
      renderHtml += renderTemplateBegin(editorTemplates);
      renderHtml += renderResearchInfo(research, t);
      renderHtml += renderDiseaseHistory(
        research.diseaseHistories,
        research.id,
        t
      );
      renderHtml += renderServices(research.services, t);
      renderHtml += renderOphthalmologyStatus(
        research.ophthalmologyStatuses,
        t,
        fields,
        i18n.language
      );
      renderHtml += renderDiagnosis(
        research.diagnoses,
        research.id,
        t,
        mkb10Dict,
        i18n.language
      );
      renderHtml += renderRecommendations(
        research,
        recommendations,
        t,
        i18n.language
      );
      renderHtml += renderTreatments(
        research.medicalTreatments,
        research.id,
        drugs,
        t
      );
      renderHtml += renderTemplateEnd(
        `${user.firstName || ""} ${user.lastName || ""}`
      );
      editor.setData(renderHtml);
    }
  }, [research, editor, fields, t, mkb10Dict, editorTemplates]);

  useEffect(() => {
    if (
      editor &&
      (research.status === RESEARCH_STATUS.FINISHED ||
        user.role === ROLES.NURSE)
    ) {
      editor.isReadOnly = true;
    }
  }, [editor, research]);

  if (!research) {
    return <Loader />;
  }
  const patientInfo = {
    id: research.id,
    referrerId: research.referrerId,
    referrerFullName: research.referrerFullName,
    iin: research.iin,
    fullName: research.fullName,
    birthday: research.birthday,
    gender: research.gender,
    age: research.age,
    phone: research.phone,
    complaints: research.complaints,
    diabetes: research.diabetes,
    arterialHypertension: research.arterialHypertension,
    appointmentTimestamp: research.appointmentTimestamp,
    receptionDoctorId: research.receptionDoctorId,
    receptionDoctorFullName: research.receptionDoctorFullName,
    refractionRight: research.refractionRight,
    refractionLeft: research.refractionLeft,
    serviceType: research.serviceType,
  };

  return (
    <Grid container className={classes.root} wrap={"nowrap"}>
      <HeaderLeftTeleporter.Source>
        <Breadcrumb />
      </HeaderLeftTeleporter.Source>
      <HeaderRightTeleporter.Source>
      <div style={{display:"flex"}}>
        <SupportTelephoneNumber/>
        <Button
          className="text-white border-white"
          variant="outlined"
          onClick={history.goBack}
          >
            {t("BACK")}
          </Button>
      </div>
      </HeaderRightTeleporter.Source>
      <Grid className={classes.left}>
        <SideMenu patientInfo={patientInfo} />
      </Grid>
      <Grid container className={classes.right} direction={"column"}>
        <Grid
          className={classes.buttonContainer}
          container
          direction={"row"}
          justifyContent={"flex-end"}
        >
          {research.status === RESEARCH_STATUS.FINISHED ? (
            <AsyncButton
              onClick={() =>
                downloadConclusionConclusion(research.id, dispatchMessage, t)
              }
              className={classes.downloadButton}
              fullWidth
            >
              <Typography className="text-transform-none">
                {t("research:DOWNLOAD_CONCLUSION")}
              </Typography>
            </AsyncButton>
          ) : (
            <Button
              variant={"contained"}
              color={"primary"}
              disabled={isLoading}
              onClick={() =>
                mutate({ id: research.id, data: editor.getData() })
              }
            >
              {t("SAVE")}
            </Button>
          )}
        </Grid>
        <TextEditor setEditor={setEditor} editor={editor} />
      </Grid>
    </Grid>
  );
};

export default memo(PatientRegistry);
