import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import CreateFormModal from "./CreateFormModal";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import MedicalTreatmentList from "./MedicalTreatmentList";
import { DictionariesContext } from "../../../../globalContexts/DictionariesContext";
import { getServiceNameDictionaries } from "../../../../api";
import { addDictionary } from "../../../../globalContexts/DictionariesContext/dictionariesActions";
import {
  deleteAutorefractomy,
  deleteExophthalmometry,
  deleteMaklakovTest,
  deleteMedicalTreatment,
  deleteMedicalTreatments,
  deleteNonContactTonometry,
  deleteSchirmerTest,
} from "../../../../api/patientRegistry";
import ConfirmActionModal from "../../../../components/ConfirmActionModal";
import {RESEARCH_SERVICE_NAME, RESEARCH_STATUS} from "../../../ResearchesPage/constants";
import {
  createToast,
  MessagesContext,
} from "../../../../globalContexts/MessagesContext";
import { queryClient } from "../../../../globalContexts/queryContext";
import { useTranslation } from "react-i18next";
import { useResearchDetails } from "../../../ResearchesPage/ResearchDetails/queryHooks";
import { ROLES } from "../../../../config/constants";
import Roled from "../../../../components/Roled";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "300px",
  },
  icon: {
    border: "1px solid black",
    color: theme.palette.primary.main,
  },
}));

const Treatment = () => {
  const classes = useStyles();
  const [deletingDrugId, setDeletingTreatmentId] = useState(null);
  const [isDeletingAll, setIsDeletingAll] = useState(false);
  const [currentTreatments, setCurrentTreatments] = useState(null);
  const { t } = useTranslation("patient-registry");
  const { id: researchId } = useParams();
  const dispatchMessage = useContext(MessagesContext)[1];

  const [{ data: research }] = useResearchDetails(researchId);
  const isFinished = research.status === RESEARCH_STATUS.FINISHED;
  const treatmentsOfResearch = research.medicalTreatments.find(
    (item) => Number(item.researchId) === Number(researchId)
  );

  const handleCloseModal = () => {
    setCurrentTreatments(null);
  };

  const handleDeleteTreatment = async () => {
    try {
      await deleteMedicalTreatment(deletingDrugId);
      setDeletingTreatmentId(null);
      dispatchMessage(createToast(t("TREATMENT_SUCCESSFULLY_DELETED")));
      await queryClient.invalidateQueries(["researchDetails", researchId]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteTreatments = async () => {
    try {
      await deleteMedicalTreatments(
        treatmentsOfResearch.treatments.map(({ id }) => id)
      );
      setIsDeletingAll(false);
      dispatchMessage(createToast(t("TREATMENTS_SUCCESSFULLY_DELETED")));
      await queryClient.invalidateQueries(["researchDetails", researchId]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(currentTreatments) {
      setCurrentTreatments(prev => {
        const treatment = research.medicalTreatments.find(
            (item) => Number(item.researchId) === Number(researchId)
        );
        return {...prev, ...treatment}
      })
    }
  }, [research])

  return (
    <Grid container direction={"column"}>
      {
        !isFinished && (
            <Roled roles={[ROLES.DOCTOR]}>
              <Grid container>
                <Add
                    className={classes.icon}
                    cursor={"pointer"}
                    onClick={() =>
                        setCurrentTreatments(treatmentsOfResearch || { treatments: [] })
                    }
                />
              </Grid>
            </Roled>
        )
      }
      <Box height={"12px"} />
      <Paper className={classes.root}>
        <MedicalTreatmentList
          allTreatments={research.medicalTreatments}
          setCurrentTreatments={(treatment) => setCurrentTreatments(treatment)}
          setDeletingTreatmentId={(id) => setDeletingTreatmentId(id)}
          setIsDeletingAll={(value) => setIsDeletingAll(value)}
          isFinished={isFinished}
        />
      </Paper>
      <CreateFormModal
        open={Boolean(currentTreatments)}
        onClose={handleCloseModal}
        currentTreatments={currentTreatments}
      />
      <ConfirmActionModal
        open={Boolean(deletingDrugId)}
        label={t("CONFIRM_DELETION")}
        onClose={() => setDeletingTreatmentId(null)}
        onConfirm={handleDeleteTreatment}
      />
      <ConfirmActionModal
        open={Boolean(isDeletingAll)}
        label={t("CONFIRM_DELETION_ALL")}
        onClose={() => setIsDeletingAll(false)}
        onConfirm={handleDeleteTreatments}
      />
    </Grid>
  );
};

export default Treatment;
