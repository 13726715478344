import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  Assignment,
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
  Person,
} from "@material-ui/icons";
import FileSaver from "file-saver";
import { Formik } from "formik";
import { useRouterQuery } from "../../../hooks/useRouterQuery";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDate, formatDateAndTime } from "../../../utils";
import {
  createToast,
  MessagesContext,
} from "../../../globalContexts/MessagesContext";

import {
  AppointmentCardContent,
  ConclusionSubForm,
  CornerstoneOverlay,
} from "../../ResearchesPage/ResearchDetails/ResearchDetailsForDoctor";
import { CornerstoneImageViewer } from "../../../components/CornerstoneImageViewer/v1";
import JSZip from "jszip";
import { getImagesPdf } from "../../ResearchesPage/ResearchesList/ResearchJournalTable";
import { temporaryFormSchema } from "../../ResearchesPage/ConclusionForm/ConclusionForm";
import { useDisclosure } from "../../../hooks/useDisclosure";
import { ANNOTATION_STATUS } from "../../ResearchesPage/constants";
import axios from "axios";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 76px)",
    margin: "6px",
    width: "auto",
  },
  progressBar: {
    width: "100%",
  },
  imageGridContainer: {
    overflowX: "auto",
  },
  imageGridItem: {
    flex: "0 0 33.3%",

    "& > img": {
      display: "block",
    },

    "&[data-active=true]": {
      border: "1px solid red",
    },
  },
}));

function PatientInfoCard(props) {
  const { patientInfo, approvedConclusions } = props;
  const { t } = useTranslation("research");
  const { isOpen, toggle } = useDisclosure(true);

  return (
    <Paper className="h-100 relative overflow-auto pl-8 pr-8">
      <div className="pt-4 pl-8 pr-8">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <Person color="primary" fontSize="large" />

          <IconButton onClick={toggle} size="small">
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">{t("AGE")}:</Typography>
          <Typography className="text-gray">{patientInfo.age}</Typography>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">{t("DIABETES")}:</Typography>
          <Checkbox size="small" disabled checked={patientInfo.diabetes} />
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">
            {t("ARTERIAL_HYPERTENSION")}:
          </Typography>
          <Checkbox
            size="small"
            disabled
            checked={patientInfo.arterialHypertension}
          />
        </Grid>
        <Collapse in={isOpen}>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("COMPLAINTS")}:</Typography>
            <Typography className="text-gray">
              {patientInfo.complaints}
            </Typography>
          </Grid>
        </Collapse>
      </div>
      <Paper elevation={8} key={patientInfo.id} className="mt-4 mb-4">
        <AppointmentCardHeader t={t} research={patientInfo} isOpen={isOpen} />
        <Collapse in={isOpen}>
          {isOpen && (
            <AppointmentCardContent
              t={t}
              research={patientInfo}
              rightEyes={patientInfo.rightEyes}
              leftEyes={patientInfo.leftEyes}
              approvedConclusions={approvedConclusions}
            />
          )}
        </Collapse>
      </Paper>
    </Paper>
  );
}

function AppointmentCardHeader(props) {
  const { research } = props;

  return (
    <Grid
      className="pointer pt-4 pl-8 pr-8"
      container
      alignItems="center"
      justify="space-between"
      wrap="nowrap"
    >
      <div>
        <Typography align="center">
          {formatDateAndTime(research.createdTimestamp)}
        </Typography>
      </div>
    </Grid>
  );
}

function AnnotatorDetailsDesktop({
  patientInfo,
  conclusionInfo,
  handleSubmitConclusions,
}) {
  const history = useHistory();
  const [query, handleReplace] = useRouterQuery();
  const activeImg = query.get("activeImg");

  const { isOpen, toggle } = useDisclosure();
  const dispatchMessage = useContext(MessagesContext)[1];
  const { t } = useTranslation(["research", "image-viewer"]);
  const classes = useStyles();

  let activeSide = "";
  const hasEyes =
    patientInfo.rightEyes?.length > 0 || patientInfo.leftEyes?.length > 0;
  if (patientInfo.rightEyes?.find((eye) => eye.id == activeImg)) {
    activeSide = "rightEye";
  }
  if (patientInfo.leftEyes?.find((eye) => eye.id == activeImg)) {
    activeSide = "leftEye";
  }
  function handleToggle() {
    if (activeSide) {
      toggle();
    }
  }
  const [approvedConclusions, setApprovedConclusions] = useState({
    rightEyeConclusion: false,
    leftEyeConclusion: false,
  });
  useEffect(() => {
    setApprovedConclusions({
      rightEyeConclusion: false,
      leftEyeConclusion: false,
    });
  }, [history.location.pathname]);

  return (
    <Grid container item xs className={classes.root} spacing={1}>
      <Grid className="h-100" item xs={3} lg={2}>
        <PatientInfoCard
          patientInfo={patientInfo}
          approvedConclusions={approvedConclusions}
        />
      </Grid>
      <Grid className="h-100" item xs={isOpen ? 6 : 9} lg={isOpen ? 7 : 10}>
        <Paper className="h-100 pl-8 pr-8 relative">
          {/* // this solves some strange bug related to image load error */}
          {hasEyes && activeImg && (
            <CornerstoneImageViewer
              eyes={{
                rightEyes: patientInfo.rightEyes,
                leftEyes: patientInfo.leftEyes,
              }}
              handleDownloadPicture={async () => {
                const zip = new JSZip();
                const rightEyesPromises = patientInfo.rightEyes?.map(
                  async (img) => {
                    const name = `rightEye_${img.id}.${
                      img.contentType.split("/")[1]
                    }`;
                    const file = await axios.get(img.fullUrl, {
                      responseType: "blob",
                    });
                    zip.file(name, file.data, { binary: true });
                  }
                );
                const leftEyesPromises = patientInfo.leftEyes?.map(
                  async (img) => {
                    const name = `leftEye_${img.id}.${
                      img.contentType.split("/")[1]
                    }`;
                    const file = await axios.get(img.fullUrl, {
                      responseType: "blob",
                    });
                    zip.file(name, file.data, { binary: true });
                  }
                );
                await Promise.all([...rightEyesPromises, ...leftEyesPromises]);
                zip.generateAsync({ type: "blob" }).then((zipFile) => {
                  FileSaver.saveAs(zipFile, "Снимки.zip");
                });
              }}
              handleDownloadImagesPdf={() =>
                getImagesPdf(patientInfo.id, dispatchMessage, t)
              }
              showDownloadButton={false}
              showConciliumButton={false}
              showSharedButton={false}
              activeImg={activeImg}
              setActiveImg={(id) => handleReplace("activeImg", id)}
              isConclusionOpen={isOpen}
              CustomOverlay={(props) => (
                <CornerstoneOverlay
                  {...props}
                  t={t}
                  date={patientInfo.appointmentTimestamp}
                />
              )}
            />
          )}
          <Button
            onClick={handleToggle}
            title="Сформировать заключение"
            className="absolute t-90 r-24 text-orange"
          >
            {!isOpen && <ChevronLeft />}
            <Assignment />
            {isOpen && <ChevronRight />}
          </Button>
        </Paper>
      </Grid>
      {isOpen && (
        <Grid className="h-100" item xs={3}>
          <Formik
            onSubmit={handleSubmitConclusions}
            validationSchema={temporaryFormSchema}
            enableReinitialize
            initialValues={conclusionInfo}
          >
            {(subForm) => (
              <ConclusionSubForm
                subForm={subForm}
                conclusionName={`${activeSide}Conclusion`}
                approvedConclusions={approvedConclusions}
                setApprovedConclusions={setApprovedConclusions}
                dispatchMessage={dispatchMessage}
                editMode={
                  patientInfo.status === ANNOTATION_STATUS.NOT_ANNOTATED
                }
                shared={true}
                replaceActiveImg={(conclusionName) => {
                  if (conclusionName === "rightEyeConclusion") {
                    handleReplace("activeImg", patientInfo.rightEyes[0].id);
                  }
                  if (conclusionName === "leftEyeConclusion") {
                    handleReplace("activeImg", patientInfo.leftEyes[0].id);
                  }
                }}
              />
            )}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
}

export default AnnotatorDetailsDesktop;
