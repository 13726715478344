import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Prompt } from "react-router-dom";
import PatientFields from "./PatientFields";
import Cameras from "./Cameras";
import MobilePatientFormTabs from "./MobilePatientFormTabs";
import { RESEARCH_STATUS } from "../constants";
import Loader from "../../../components/Loader";
import { KZPhoneRegex } from "../../../components/FormComponents/form_normalizers";
import { DictionariesContext } from "../../../globalContexts/DictionariesContext";
import ConciliumTimer from "../Concilium/ConciliumTimer";
import { Close, Person } from "@material-ui/icons";
import { SaveTeleporter } from "../ResearchDetails/Breadcrumbs";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  fields: {
    position: "absolute",
    width: "100%",
    zIndex: 1006,
    top: 0,
  },
  infoButton: {
    display: "flex",
    width: "300px",
    flexDirection: "row",
    padding: "12px",
    borderRadius: "5px",
    alignItems: "center",
    border: "1px solid " + theme.palette.primary.main,
    color: theme.palette.primary.main,
    marginLeft: "12px",
    cursor: "pointer",
  },
  closeIcon: {
    position: "absolute",
    right: "15px",
    top: "5px",
    cursor: "pointer",
  },
  saveWrapper: {
    textAlign: "end",
  },
}));

// mobileFormStyle - for mobile
function PatientForm({
  t,
  initialValues,
  handleSubmit,
  isDetails,
  mobileFormStyle,
  conciliumInfo,
  handleTakeResearch,
}) {
  const classes = useStyles();
  const [showFields, setShowFields] = useState(false);
  const {
    authUserConfig: { smsEnabled },
  } = useContext(DictionariesContext)[0];
  const ResearchSchema = Yup.object().shape({
    serviceType: Yup.string().required(t("REQUIRED")),
    iin: Yup.string()
      .min(12, t("IIN_SHOULD_BE_12"))
      .max(12, t("IIN_SHOULD_BE_12"))
      .required(t("REQUIRED")),
    referrerId: Yup.mixed().required(t("CHOOSE_REFERRER")),
    phone: smsEnabled
      ? Yup.string()
          .matches(KZPhoneRegex, t("REQUIRED"))
          .required(t("REQUIRED"))
      : null,
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={isDetails}
      validationSchema={ResearchSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const isCreated = formikProps.values.status === RESEARCH_STATUS.CREATED;
        const isInProgress =
          formikProps.values.status === RESEARCH_STATUS.IN_PROGRESS;
        // если это детали проекта, то покажи камеры только если его статус CREATED
        const showCameras = !isDetails || isCreated;

        const Fields = (
          <PatientFields
            t={t}
            isDetails={isDetails}
            isCreated={isCreated}
            isInProgress={isInProgress}
            iin={formikProps.values.iin}
            setFieldValue={formikProps.setFieldValue}
            setStatus={formikProps.setStatus}
            dirty={formikProps.dirty}
            isSubmitting={formikProps.isSubmitting}
            initialValues={initialValues}
            handleTakeResearch={handleTakeResearch}
          />
        );
        return (
          <form
            onSubmit={(e) => {
              formikProps.handleSubmit(e);
              if (Object.keys(formikProps.errors).length > 0) {
                setShowFields(true);
              }
            }}
            style={mobileFormStyle}
            id="patientForm"
            className={classes.root}
          >
            <Prompt
              when={!isDetails && formikProps.dirty}
              message={t("WILL_NOT_BE_SAVED")}
            />
            <Hidden only="xs" implementation="js">
              <Grid
                className={classes.infoButton}
                onClick={() => setShowFields(true)}
              >
                <Person color={"primary"} fontSize={"large"} />
                <Box width={"12px"} />
                <Typography>
                  {initialValues.fullName} {initialValues.iin}
                </Typography>
              </Grid>
              {showFields && (
                <div className={classes.fields}>
                  <Close
                    className={classes.closeIcon}
                    onClick={() => setShowFields(false)}
                  />
                  {Fields}
                </div>
              )}
              <SaveTeleporter.Source>
                <div className={classes.saveWrapper}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    form="patientForm"
                    type="submit"
                    disabled={!formikProps.dirty || formikProps.isSubmitting}
                  >
                    {t("SAVE")}
                  </Button>
                </div>
              </SaveTeleporter.Source>
              <br />
              {showCameras && <Cameras formikProps={formikProps} t={t} />}
            </Hidden>
            <Hidden smUp implementation="js">
              <MobilePatientFormTabs
                t={t}
                isDetails={isDetails}
                isCreated={isCreated}
                formikProps={formikProps}
              >
                {Fields}
                {conciliumInfo?.deadline && (
                  <Box paddingX={"12px"} marginTop={"12px"}>
                    <Typography>{t("CONCILIUM")}:</Typography>
                    <ConciliumTimer concilium={conciliumInfo} />
                    <Box marginBottom={"10px"} />
                    <Grid container justify="space-between" alignItems="center">
                      <Typography className="text-gray">
                        {t("NOTE_FOR_CASE")}:
                      </Typography>
                      <Box
                        width={"100%"}
                        padding={"24px"}
                        border={"1px solid black"}
                      >
                        <Typography className="text-gray">
                          {conciliumInfo.comment}
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                )}
              </MobilePatientFormTabs>
            </Hidden>
            {formikProps.isSubmitting && <Loader />}
          </form>
        );
      }}
    </Formik>
  );
}

export default PatientForm;
