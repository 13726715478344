export const RESEARCH_STATUS = {
  CREATED: "CREATED",
  IN_PROGRESS: "IN_PROGRESS",
  TAKEN_TO_WORK: "TAKEN_TO_WORK",
  FINISHED: "FINISHED",
  CANCELLED: "CANCELLED",
};

export const CONCILIUM_STATUS = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  PRECONCLUSION_GIVEN: "PRECONCLUSION_GIVEN",
};

export const RESEARCH_SERVICE_NAME = {
  OCULAR_FUNDUS_PHOTOGRAPHY: "OCULAR_FUNDUS_PHOTOGRAPHY",
  OPHTHALMOLOGIST_CONSULTATION: "OPHTHALMOLOGIST_CONSULTATION",
  NON_CONTACT_TONOMETRY: "NON_CONTACT_TONOMETRY",
  EXOPHTHALMOMETRY: "EXOPHTHALMOMETRY",
  SCHIRMER_TEST: "SCHIRMER_TEST",
  MAKLAKOV_TEST: "MAKLAKOV_TEST",
  AUTOREFRACTOMY: "AUTOREFRACTOMY",
};

export const TREATMENT_TYPES = {
  MEDICAL_TREATMENT: "MEDICAL_TREATMENT",
  SURGICAL_TREATMENT: "SURGICAL_TREATMENT",
  LASER_TREATMENT: "LASER_TREATMENT",
};

export const DIAGNOSIS_TYPE = {
  CLINICAL: "CLINICAL",
  PRELIMINARY: "PRELIMINARY",
};

export const CONCLUSION_TYPE = {
  CONCLUSION: "CONCLUSION",
  AUTO_PRECONCLUSION: "AUTO_PRECONCLUSION",
  CONCILIUM_PRECONCLUSION: "CONCILIUM_PRECONCLUSION",
  SHARED_PRECONCLUSION: "SHARE_PRECONCLUSION",
  ANNOTATOR_CONCLUSION: "ANNOTATOR_CONCLUSION",
};

export const SOCKET_STATUS = {
  INITIAL_CONNECTING: "INITIAL_CONNECTING",
  CONNECTED: "CONNECTED",
  ERROR: "ERROR",
};

export const WEEKDAYS = {
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
  SUNDAY: "SUNDAY",
};

export const ANNOTATION_STATUS = {
  ANNOTATED: "ANNOTATED",
  NOT_ANNOTATED: "NOT_ANNOTATED",
};
