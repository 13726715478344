import { useCubeQuery } from "@cubejs-client/react";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import groupBy from "lodash/groupBy";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../../utils";

export function DashboardMixBarCharts({ query }) {
  const { t } = useTranslation("scientificAnalytics");
  const filters = [
    {
      member: "Research.researchStatus",
      operator: "equals",
      values: ["FINISHED"],
    },
  ];

  if (query.organizationFilterData) {
    filters.push({
      member: "Research.groupId",
      operator: "equals",
      values: [query.organizationFilterData],
    });
  }
  if (query.doctorFilterData) {
    filters.push({
      member: "Research.responsibleDoctorId",
      operator: "equals",
      values: [query.doctorFilterData],
    });
  }
  if (query.gender) {
    filters.push({
      member: "Research.gender",
      operator: "equals",
      values: [query.gender],
    });
  }
  if (query.doctorFilterData) {
    filters.push({
      member: "Research.responsibleDoctorId",
      operator: "equals",
      values: [query.doctorFilterData],
    });
  }
  const doctorFilter = [];
  if (query.fromDate) {
    doctorFilter.push({
      member: "Conclusion.conclusionTimestamp",
      operator: "afterDate",
      values: [query.fromDate],
    });
  }
  if (query.beforeDate) {
    doctorFilter.push({
      member: "Conclusion.conclusionTimestamp",
      operator: "beforeDate",
      values: [query.beforeDate],
    });
  }
  if (query.diagnos) {
    doctorFilter.push({
      member: "ConclusionResultToRetinopathy.retinopathyCodename",
      operator: "equals",
      values: [query.diagnos],
    });
  }
  const autoConclusionFilter = [];
  if (query.fromDate) {
    autoConclusionFilter.push({
      member: "PreConclusion.createdAt",
      operator: "afterDate",
      values: [query.fromDate],
    });
  }
  if (query.beforeDate) {
    autoConclusionFilter.push({
      member: "PreConclusion.createdAt",
      operator: "beforeDate",
      values: [query.beforeDate],
    });
  }
  if (query.diagnos) {
    autoConclusionFilter.push({
      member: "PreConclusionDiagnosisToRetinopathy.retinopathyCodename",
      operator: "equals",
      values: [query.diagnos],
    });
  }
  const { resultSet, isLoading, error, progress } = useCubeQuery({
    measures: [
      "Research.count",
      "Conclusion.count",
      "ConclusionResult.count",
      "ConclusionResultToRetinopathy.count",
    ],
    order: [["Conclusion.conclusionTimestamp", "asc"]],
    dimensions: [
      "ConclusionResultToRetinopathy.retinopathyCodename",
      "Conclusion.conclusionTimestamp",
    ],
    filters: [...filters, ...doctorFilter],
  });

  const {
    resultSet: autoResultSet,
    isLoading: autoIsLoading,
    error: autoError,
  } = useCubeQuery({
    measures: [
      "Research.count",
      "AutoConclusion.count",
      "PreConclusion.count",
      "PreConclusionBlock.count",
      "PreConclusionDiagnosis.count",
      "PreConclusionDiagnosisToRetinopathy.count",
    ],
    order: {
      "PreConclusion.createdAt": "asc",
    },
    dimensions: [
      "PreConclusionDiagnosisToRetinopathy.retinopathyCodename",
      "PreConclusion.createdAt",
    ],
    filters: [...filters, ...autoConclusionFilter],
  });

  if (autoIsLoading || isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (autoError || error) {
    return <div>{autoError.toString() || error.toString()}</div>;
  }

  let resultSetCopy = [];

  if (query.type === "DOCTOR") {
    resultSetCopy = resultSet?.tablePivot().map((data) => {
      return {
        value: Number(data["ConclusionResult.count"]),
        date: formatDate(data["Conclusion.conclusionTimestamp"]),
        name: data["ConclusionResultToRetinopathy.retinopathyCodename"],
      };
    });
  }

  if (query.type === "AUTOCONCLUSION") {
    resultSetCopy = autoResultSet?.tablePivot().map((data) => {
      return {
        value: Number(data["PreConclusion.count"]),
        date: formatDate(data["PreConclusion.createdAt"]),
        name: data["PreConclusionDiagnosisToRetinopathy.retinopathyCodename"],
      };
    });
  }

  const resultSetGroupBy = groupBy(resultSetCopy, "date");

  const barData = Object.entries(resultSetGroupBy).map(([key, value]) =>
    value.reduce(
      (acc, curr) => {
        if (curr.name === "PREPROLIFERATIVE") {
          acc.preproliferative += curr.value;
        }
        if (curr.name === "PROLIFERATIVE") {
          acc.proliferative += curr.value;
        }
        if (curr.name === "NON_PROLIFERATIVE") {
          acc.non_proliferative += curr.value;
        }
        return acc;
      },
      { date: key, preproliferative: 0, proliferative: 0, non_proliferative: 0 }
    )
  );
  return (
    <BarChart
      width={900}
      height={500}
      data={barData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar
        name={t("PREPROLIFERATIVE_STAGE")}
        dataKey="preproliferative"
        stackId="a"
        fill="#7DB3FF"
      />
      <Bar
        name={t("PROLIFERATIVE_STAGE")}
        dataKey="proliferative"
        stackId="a"
        fill="#49457B"
      />
      <Bar
        name={t("NON_PROLIFERATIVE_STAGE")}
        dataKey="non_proliferative"
        stackId="a"
        fill="#F07978"
      />
    </BarChart>
  );
}
