export const kk = {
  "%0 of %1": "%0 из %1",
  "Align cell text to the bottom": "Ұяшық мәтінін төменге туралау",
  "Align cell text to the center": "Ұяшық мәтінін ортаға туралау",
  "Align cell text to the left": "Ұяшық мәтінін солға туралау",
  "Align cell text to the middle": "Ұяшық мәтінін ортаға туралау",
  "Align cell text to the right": "Ұяшық мәтінін оңға туралау",
  "Align cell text to the top": "Ұяшық мәтінін жоғарыға туралау",
  "Align center": "Ортаға туралау",
  "Align left": "Солға туралау",
  "Align right": "Оңға туралау",
  "Align table to the left": "Таблицаны солға туралау",
  "Align table to the right": "Таблицаны оңға туралау",
  Alignment: "Туралау",
  Aquamarine: "Аквамарин",
  Background: "Фон",
  Big: "Үлкен",
  Black: "Қара",
  "Block quote": "Цитата",
  Blue: "Көк",
  Bold: "Қалың",
  Border: "Сызық",
  "Bulleted List": "Белгіленген тізім",
  "Bulleted list styles toolbar": "Белгіленген тізім стильдер панелі",
  Cancel: "Болдырмау",
  "Cannot upload file:": "Файлды жүктеу мүмкін емес:",
  "Cell properties": "Ұяшық қасиеттері",
  "Center table": "Таблицаны ортаға туралау",
  "Centered image": "Суретті ортаға туралау",
  "Change image text alternative": "Суреттің альтернативті мәтінін жөндеу",
  "Choose heading": "Стиль таңдау",
  Circle: "Дөңгелек",
  Color: "Түс",
  "Color picker": "Түс таңдау",
  Column: "Баған",
  Dashed: "Штрих",
  Decimal: "Ондық",
  "Decimal with leading zero": "Ондық жаңарту белгісі",
  "Decrease indent": "Шегіністі азайту",
  Default: "Қалыпты",
  "Delete column": "Бағанды жою",
  "Delete row": "Жолды жою",
  "Dim grey": "Қара сұр",
  Dimensions: "Өлшемдері",
  Disc: "Диск",
  "Document colors": "Беттің түсі",
  Dotted: "Нүктелі",
  Double: "Қос",
  Downloadable: "Жүктеуге болады",
  "Dropdown toolbar": "Ашылмалы құралдар тақтасы",
  "Edit block": "Блокты өңдеу",
  "Edit link": "Сілтемені өңдеу",
  "Editor toolbar": "Редактор құралдарының тақтасы",
  "Enter image caption": "Суреттің атауын енгізіңіз",
  "Font Background Color": "Шрифттің фон түсі",
  "Font Color": "Шрифттің түсі",
  "Font Family": "Шрифттің түрі",
  "Font Size": "Шрифттің өлшемі",
  "Full size image": "Кескіннің бастапқы өлшемі",
  Green: "Жасыл",
  Grey: "Cұр",
  Groove: "Ойық",
  "Header column": "Бағандардың бастапқы бөлігі",
  "Header row": "Жолдардың бастапқы бөлігі",
  Heading: "Стиль",
  "Heading 1": "1-тақырып",
  "Heading 2": "2-тақырып",
  "Heading 3": "3-тақырып",
  "Heading 4": "4-тақырып",
  "Heading 5": "5-тақырып",
  "Heading 6": "6-тақырып",
  Height: "Биіктігі",
  "Horizontal text alignment toolbar":
    "Көлденең мәтінді туралау құралдар тақтасы",
  Huge: "Өте үлкен",
  "Image resize list": "Өлшемдер тізімі",
  "Image toolbar": "Сурет құралдар тақтасы",
  "image widget": "Сурет құралы",
  "Increase indent": "Шегіністі арттыру",
  "Insert column left": "Сол бағанды енгізу",
  "Insert column right": "Оң бағанды енгізу",
  "Insert image": "Суретті енгізу",
  "Insert media": "Медиа енгізу",
  "Insert paragraph after block": "Блоктан кейін абзац енгізу",
  "Insert paragraph before block": "Блоктың алдына абзац енгізу",
  "Insert row above": "Жоғарына жол енгізу",
  "Insert row below": "Астына жол енгізу",
  "Insert table": "Таблицаны енгізу",
  Inset: "Кескін",
  Italic: "Курсив",
  Justify: "Ені бойынша түзету",
  "Justify cell text": "Мәтінді ені бойынша түзету",
  "Left aligned image": "Солға туралау",
  "Light blue": "Ашық көк",
  "Light green": "Ашық жасыл",
  "Light grey": "Ашық сұр",
  Link: "Сілтеме",
  "Link URL": "Сілтеме URL",
  "Lower-latin": "Кіші латин",
  "Lower–roman": "Кіші римдік",
  "Media URL": "Медиа URL",
  "media widget": "Медиа құралы",
  "Merge cell down": "Төмендегі ұяшықпен біріктіру",
  "Merge cell left": "Cол ұяшықпен біріктіру",
  "Merge cell right": "Оң ұяшықпен біріктіру",
  "Merge cell up": "Үстінгі ұяшықпен біріктіру",
  "Merge cells": "Ұяшықтарды біріктіру",
  Next: "Келесі",
  None: "Ешқайсысы",
  "Numbered List": "Нөмірленген тізім",
  "Numbered list styles toolbar": "Нөмірленген тізім стилі құралдар тақтасы",
  "Open in a new tab": "Жаңа бетте ашу",
  "Open link in new tab": "Сілтемені жаңа бетте ашу",
  Orange: "Қызғылт",
  Original: "Түпнұсқа",
  Outset: "Кескіндер",
  Padding: "Шегініс",
  Paragraph: "Параграф",
  "Paste the media URL in the input.": "Медиа URL енгізіңіз.",
  Previous: "Алдыңғы",
  Purple: "Күлгін",
  Red: "Қызғыл",
  Redo: "Қайта орындау",
  "Remove color": "Түсін алып тастау",
  "Resize image": "Өлшемді өзгерту",
  "Resize image to %0": "Өлшемді %0 жағына өзгерту",
  "Resize image to the original size": "Өлшемді түпнұсқаға қайтару",
  "Restore default": "Бастапқыға оралу",
  "Rich Text Editor": "Толық мәтіндік редактор",
  "Rich Text Editor, %0": "Толық мәтіндік редактор, %0",
  Ridge: "Қырлы",
  "Right aligned image": "Оңға туралау",
  Row: "Жол",
  Save: "Сақтау",
  "Select all": "Барлығын таңдау",
  "Select column": "Бағанды таңдау",
  "Select row": "Жолды таңдау",
  "Show more items": "Басқа элементтерді көрсету",
  "Side image": "Сол суреті",
  Small: "Кішкентай",
  Solid: "Тұтас",
  "Split cell horizontally": "Ұяшықты көлденеңінен бөлу",
  "Split cell vertically": "Ұяшықты тігінен бөлу",
  Square: "Квадрат",
  Strikethrough: "Сызылған",
  Style: "Стиль",
  "Table alignment toolbar": "Кестені туралау құралдар тақтасы",
  "Table cell text alignment": "Мәтінді кесте ұяшығына туралау",
  "Table properties": "Кестенің қасиеттері",
  "Table toolbar": "Кесте құралдар тақтасы",
  "Text alignment": "Мәтінді туралау",
  "Text alignment toolbar": "Мәтінді туралау құралдар тақтасы",
  "Text alternative": "Альтернативалық мәтін",
  'The color is invalid. Try "#FF0000" or "rgb(255,0,0)" or "red".':
    'Қате түс. "#FF0000" немесе "rgb(255,0,0)" немесе "red" көріңіз.',
  "The URL must not be empty.": "URL толтырылу тиіс.",
  'The value is invalid. Try "10px" or "2em" or simply "2".':
    'Қате мән. "10px", "2em" немесе "2" көріңіз.',
  "This link has no URL": "Бұл сілтемеде URL жоқ",
  "This media URL is not supported.": "Бұл медиа URL пайдаланылмайды.",
  Tiny: "Кішкентай",
  "Tip: Paste the URL into the content to embed faster.":
    "Анықтама: Жылдамдату үшін URL енгізіңіз.",
  "To-do List": "Істеу керек тізімі",
  "Toggle the circle list style": "Нүкте тізім стилін қосу/өшіру",
  "Toggle the decimal list style": "Ондық тізім стилін қосу/өшіру",
  "Toggle the decimal with leading zero list style":
    "Ондық тізім стилін қосу/өшіру",
  "Toggle the disc list style": "Диск тізім стилін қосу/өшіру",
  "Toggle the lower–latin list style": "Кіші латин тізім стилін қосу/өшіру",
  "Toggle the lower–roman list style": "Кіші рим тізім стилін қосу/өшіру",
  "Toggle the square list style": "Шаршы тізім стилін қосу/өшіру",
  "Toggle the upper–latin list style": "Жоғары латин тізім стилін қосу/өшіру",
  "Toggle the upper–roman list style": "Жоғары рим тізім стилін қосу/өшіру",
  Turquoise: "Көгілдір",
  Underline: "Асты сызылған",
  Undo: "Кері қайтару",
  Unlink: "Сілтемені алып тастау",
  "Upload failed": "Жүктеу жасау мүмкін емес",
  "Upload in progress": "Жүктеу жүргізілуде",
  "Upper-latin": "Жоғары латин",
  "Upper-roman": "Жоғары рим",
  "Vertical text alignment toolbar": "Мәтінді тік туралау құралдар тақтасы",
  White: "Ақ",
  "Widget toolbar": "Виджет құралдар тақтасы",
  Width: "Ені",
  Yellow: "Сары",
};

export const ru = {
  "%0 of %1": "%0 из %1",
  "Align cell text to the bottom": "Выровнять текст ячейки по нижнему краю",
  "Align cell text to the center": "Выровнять текст по центру",
  "Align cell text to the left": "Выровнять текст по левому краю",
  "Align cell text to the middle": "Выровнять текст ячейки по центру",
  "Align cell text to the right": "Выровнять текст по правому краю",
  "Align cell text to the top": "Выровнять текст ячейки по верхнему краю",
  "Align center": "Выравнивание по центру",
  "Align left": "Выравнивание по левому краю",
  "Align right": "Выравнивание по правому краю",
  "Align table to the left": "Выровнять таблицу по левому краю",
  "Align table to the right": "Выровнять таблицу по правому краю",
  Alignment: "Выравнивание",
  Aquamarine: "Аквамариновый",
  Background: "Фон",
  Big: "Крупный",
  Black: "Чёрный",
  "Block quote": "Цитата",
  Blue: "Синий",
  Bold: "Жирный",
  Border: "Граница",
  "Bulleted List": "Маркированный список",
  "Bulleted list styles toolbar": "Стили маркированного списка",
  Cancel: "Отмена",
  "Cannot upload file:": "Невозможно загрузить файл",
  "Cell properties": "Свойства ячейки",
  "Center table": "Выровнять таблицу по центру",
  "Centered image": "Выравнивание по центру",
  "Change image text alternative": "Редактировать альтернативный текст",
  "Choose heading": "Выбор стиля",
  Circle: "Окружность",
  Color: "Цвет",
  "Color picker": "Выбор цвета",
  Column: "Столбец",
  Dashed: "Пунктирная",
  Decimal: "Десятичный",
  "Decimal with leading zero": "Десятичный с ведущим нулем",
  "Decrease indent": "Уменьшить отступ",
  Default: "По умолчанию",
  "Delete column": "Удалить столбец",
  "Delete row": "Удалить строку",
  "Dim grey": "Тёмно-серый",
  Dimensions: "Размеры",
  Disc: "Диск",
  "Document colors": "Цвет страницы",
  Dotted: "Точечная",
  Double: "Двойная",
  Downloadable: "Загружаемые",
  "Dropdown toolbar": "Выпадающая панель инструментов",
  "Edit block": "Редактировать блок",
  "Edit link": "Редактировать ссылку",
  "Editor toolbar": "Панель инструментов редактора",
  "Enter image caption": "Подпись к изображению",
  "Font Background Color": "Цвет фона",
  "Font Color": "Цвет шрифта",
  "Font Family": "Семейство шрифтов",
  "Font Size": "Размер шрифта",
  "Full size image": "Оригинальный размер изображения",
  Green: "Зелёный",
  Grey: "Серый",
  Groove: "Желобчатая",
  "Header column": "Столбец заголовков",
  "Header row": "Строка заголовков",
  Heading: "Стиль",
  "Heading 1": "Заголовок 1",
  "Heading 2": "Заголовок 2",
  "Heading 3": "Заголовок 3",
  "Heading 4": "Заголовок 4",
  "Heading 5": "Заголовок 5",
  "Heading 6": "Заголовок 6",
  Height: "Высота",
  "Horizontal text alignment toolbar":
    "Панель инструментов горизонтального выравнивания текста",
  Huge: "Очень крупный",
  "Image resize list": "Список размеров",
  "Image toolbar": "Панель инструментов изображения",
  "image widget": "Виджет изображений",
  "Increase indent": "Увеличить отступ",
  "Insert column left": "Вставить столбец слева",
  "Insert column right": "Вставить столбец справа",
  "Insert image": "Вставить изображение",
  "Insert media": "Вставить медиа",
  "Insert paragraph after block": "Вставить параграф после блока",
  "Insert paragraph before block": "Вставить параграф перед блоком",
  "Insert row above": "Вставить строку выше",
  "Insert row below": "Вставить строку ниже",
  "Insert table": "Вставить таблицу",
  Inset: "Вдавленная",
  Italic: "Курсив",
  Justify: "Выравнивание по ширине",
  "Justify cell text": "Выровнять текст по ширине",
  "Left aligned image": "Выравнивание по левому краю",
  "Light blue": "Голубой",
  "Light green": "Салатовый",
  "Light grey": "Светло-серый",
  Link: "Ссылка",
  "Link URL": "Ссылка URL",
  "Lower-latin": "Малые латинские",
  "Lower–roman": "Малые римские",
  "Media URL": "URL медиа",
  "media widget": "медиа-виджет",
  "Merge cell down": "Объединить с ячейкой снизу",
  "Merge cell left": "Объединить с ячейкой слева",
  "Merge cell right": "Объединить с ячейкой справа",
  "Merge cell up": "Объединить с ячейкой сверху",
  "Merge cells": "Объединить ячейки",
  Next: "Следующий",
  None: "Нет",
  "Numbered List": "Нумерованный список",
  "Numbered list styles toolbar": "Стили нумерованного списка",
  "Open in a new tab": "Открыть в новой вкладке",
  "Open link in new tab": "Открыть ссылку в новой вкладке",
  Orange: "Оранжевый",
  Original: "Оригинальный",
  Outset: "Выпуклая",
  Padding: "Отступ",
  Paragraph: "Параграф",
  "Paste the media URL in the input.": "Вставьте URL медиа в поле ввода.",
  Previous: "Предыдущий",
  Purple: "Фиолетовый",
  Red: "Красный",
  Redo: "Повторить",
  "Remove color": "Убрать цвет",
  "Resize image": "Изменить размер изображения",
  "Resize image to %0": "Изменить размер изображения до %0",
  "Resize image to the original size":
    "Вернуть размер изображения к оригинальному",
  "Restore default": "",
  "Rich Text Editor": "Редактор",
  "Rich Text Editor, %0": "Редактор, %0",
  Ridge: "Ребристая",
  "Right aligned image": "Выравнивание по правому краю",
  Row: "Строка",
  Save: "Сохранить",
  "Select all": "Выбрать все",
  "Select column": "Выбрать столбец",
  "Select row": "Выбрать строку",
  "Show more items": "Другие инструменты",
  "Side image": "Боковое изображение",
  Small: "Мелкий",
  Solid: "Сплошная",
  "Split cell horizontally": "Разделить ячейку горизонтально",
  "Split cell vertically": "Разделить ячейку вертикально",
  Square: "Квадрат",
  Strikethrough: "Зачеркнутый",
  Style: "Стиль",
  "Table alignment toolbar": "Панель инструментов выравнивания таблицы",
  "Table cell text alignment": "Выравнивание текста в ячейке таблицы",
  "Table properties": "Свойства таблицы",
  "Table toolbar": "Панель инструментов таблицы",
  "Text alignment": "Выравнивание текста",
  "Text alignment toolbar": "Выравнивание",
  "Text alternative": "Альтернативный текст",
  'The color is invalid. Try "#FF0000" or "rgb(255,0,0)" or "red".':
    'Неверный цвет. Попробуйте "#FF0000" или "rgb(255,0,0)" или "red".',
  "The URL must not be empty.": "URL не должен быть пустым.",
  'The value is invalid. Try "10px" or "2em" or simply "2".':
    'Неверное значение. Попробуйте "10px" или "2em" или просто "2".',
  "This link has no URL": "Для этой ссылки не установлен адрес URL",
  "This media URL is not supported.": "Этот URL медиа не поддерживается.",
  Tiny: "Очень мелкий",
  "Tip: Paste the URL into the content to embed faster.":
    "Подсказка: Вставьте URL в контент для быстрого включения.",
  "To-do List": "Список задач",
  "Toggle the circle list style": "",
  "Toggle the decimal list style": "",
  "Toggle the decimal with leading zero list style": "",
  "Toggle the disc list style": "",
  "Toggle the lower–latin list style": "",
  "Toggle the lower–roman list style": "",
  "Toggle the square list style": "",
  "Toggle the upper–latin list style": "",
  "Toggle the upper–roman list style": "",
  Turquoise: "Бирюзовый",
  Underline: "Подчеркнутый",
  Undo: "Отменить",
  Unlink: "Убрать ссылку",
  "Upload failed": "Загрузка не выполнена",
  "Upload in progress": "Идёт загрузка",
  "Upper-latin": "Большие латинские",
  "Upper-roman": "Большие римские",
  "Vertical text alignment toolbar":
    "Панель инструментов вертикального выравнивания текста",
  White: "Белый",
  "Widget toolbar": "Панель инструментов виджета",
  Width: "Ширина",
  Yellow: "Жёлтый",
};

export const en = {
  "%0 of %1": "%0 of %1",
  "Align cell text to the bottom": "Align cell text to the bottom",
  "Align cell text to the center": "Align cell text to the center",
  "Align cell text to the left": "Align cell text to the left",
  "Align cell text to the middle": "Align cell text to the middle",
  "Align cell text to the right": "Align cell text to the right",
  "Align cell text to the top": "Align cell text to the top",
  "Align center": "Align center",
  "Align left": "Align left",
  "Align right": "Align right",
  "Align table to the left": "Align table to the left",
  "Align table to the right": "Align table to the right",
  Alignment: "Alignment",
  Aquamarine: "Aquamarine",
  Background: "Background",
  Big: "Big",
  Black: "Black",
  "Block quote": "Block quote",
  Blue: "Blue",
  Bold: "Bold",
  Border: "Border",
  "Bulleted List": "Bulleted List",
  "Bulleted list styles toolbar": "Bulleted list styles toolbar",
  Cancel: "Cancel",
  "Cannot upload file:": "Cannot upload file:",
  "Cell properties": "Cell properties",
  "Center table": "Center table",
  "Centered image": "Centered image",
  "Change image text alternative": "Change image text alternative",
  "Choose heading": "Choose heading",
  Circle: "Circle",
  Color: "Color",
  "Color picker": "Color picker",
  Column: "Column",
  Dashed: "Dashed",
  Decimal: "Decimal",
  "Decimal with leading zero": "Decimal with leading zero",
  "Decrease indent": "Decrease indent",
  Default: "Default",
  "Delete column": "Delete column",
  "Delete row": "Delete row",
  "Dim grey": "Dim grey",
  Dimensions: "Dimensions",
  Disc: "Disc",
  "Document colors": "Document colors",
  Dotted: "Dotted",
  Double: "Double",
  Downloadable: "Downloadable",
  "Dropdown toolbar": "Dropdown toolbar",
  "Edit block": "Edit block",
  "Edit link": "Edit link",
  "Editor toolbar": "Editor toolbar",
  "Enter image caption": "Enter image caption",
  "Font Background Color": "Font Background Color",
  "Font Color": "Font Color",
  "Font Family": "Font Family",
  "Font Size": "Font Size",
  "Full size image": "Full size image",
  Green: "Green",
  Grey: "Grey",
  Groove: "Groove",
  "Header column": "Header column",
  "Header row": "Header row",
  Heading: "Heading",
  "Heading 1": "Heading 1",
  "Heading 2": "Heading 2",
  "Heading 3": "Heading 3",
  "Heading 4": "Heading 4",
  "Heading 5": "Heading 5",
  "Heading 6": "Heading 6",
  Height: "Height",
  "Horizontal text alignment toolbar": "Horizontal text alignment toolbar",
  Huge: "Huge",
  "Image resize list": "Image resize list",
  "Image toolbar": "Image toolbar",
  "image widget": "image widget",
  "Increase indent": "Increase indent",
  "Insert column left": "Insert column left",
  "Insert column right": "Insert column right",
  "Insert image": "Insert image",
  "Insert media": "Insert media",
  "Insert paragraph after block": "Insert paragraph after block",
  "Insert paragraph before block": "Insert paragraph before block",
  "Insert row above": "Insert row above",
  "Insert row below": "Insert row below",
  "Insert table": "Insert table",
  Inset: "Inset",
  Italic: "Italic",
  Justify: "Justify",
  "Justify cell text": "Justify cell text",
  "Left aligned image": "Left aligned image",
  "Light blue": "Light blue",
  "Light green": "Light green",
  "Light grey": "Light grey",
  Link: "Link",
  "Link URL": "Link URL",
  "Lower-latin": "Lower-latin",
  "Lower–roman": "Lower–roman",
  "Media URL": "Media URL",
  "media widget": "media widget",
  "Merge cell down": "Merge cell down",
  "Merge cell left": "Merge cell left",
  "Merge cell right": "Merge cell right",
  "Merge cell up": "Merge cell up",
  "Merge cells": "Merge cells",
  Next: "Next",
  None: "None",
  "Numbered List": "Numbered List",
  "Numbered list styles toolbar": "Numbered list styles toolbar",
  "Open in a new tab": "Open in a new tab",
  "Open link in new tab": "Open link in new tab",
  Orange: "Orange",
  Original: "Original",
  Outset: "Outset",
  Padding: "Padding",
  Paragraph: "Paragraph",
  "Paste the media URL in the input.": "Paste the media URL in the input.",
  Previous: "Previous",
  Purple: "Purple",
  Red: "Red",
  Redo: "Redo",
  "Remove color": "Remove color",
  "Resize image": "Resize image",
  "Resize image to %0": "Resize image to %0",
  "Resize image to the original size": "Resize image to the original size",
  "Restore default": "Restore default",
  "Rich Text Editor": "Rich Text Editor",
  "Rich Text Editor, %0": "Rich Text Editor, %0",
  Ridge: "Ridge",
  "Right aligned image": "Right aligned image",
  Row: "Row",
  Save: "Save",
  "Select all": "Select all",
  "Select column": "Select column",
  "Select row": "Select row",
  "Show more items": "Show more items",
  "Side image": "Side image",
  Small: "Small",
  Solid: "Solid",
  "Split cell horizontally": "Split cell horizontally",
  "Split cell vertically": "Split cell vertically",
  Square: "Square",
  Strikethrough: "Strikethrough",
  Style: "Style",
  "Table alignment toolbar": "Table alignment toolbar",
  "Table cell text alignment": "Table cell text alignment",
  "Table properties": "Table properties",
  "Table toolbar": "Table toolbar",
  "Text alignment": "Text alignment",
  "Text alignment toolbar": "Text alignment toolbar",
  "Text alternative": "Text alternative",
  'The color is invalid. Try "#FF0000" or "rgb(255,0,0)" or "red".':
    'The color is invalid. Try "#FF0000" or "rgb(255,0,0)" or "red".',
  "The URL must not be empty.": "The URL must not be empty.",
  'The value is invalid. Try "10px" or "2em" or simply "2".':
    'The value is invalid. Try "10px" or "2em" or simply "2".',
  "This link has no URL": "This link has no URL",
  "This media URL is not supported.": "This media URL is not supported.",
  Tiny: "Tiny",
  "Tip: Paste the URL into the content to embed faster.":
    "Tip: Paste the URL into the content to embed faster.",
  "To-do List": "To-do List",
  "Toggle the circle list style": "Toggle the circle list style",
  "Toggle the decimal list style": "Toggle the decimal list style",
  "Toggle the decimal with leading zero list style":
    "Toggle the decimal with leading zero list style",
  "Toggle the disc list style": "Toggle the disc list style",
  "Toggle the lower–latin list style": "Toggle the lower–latin list style",
  "Toggle the lower–roman list style": "Toggle the lower–roman list style",
  "Toggle the square list style": "Toggle the square list style",
  "Toggle the upper–latin list style": "Toggle the upper–latin list style",
  "Toggle the upper–roman list style": "Toggle the upper–roman list style",
  Turquoise: "Turquoise",
  Underline: "Underline",
  Undo: "Undo",
  Unlink: "Unlink",
  "Upload failed": "Upload failed",
  "Upload in progress": "Upload in progress",
  "Upper-latin": "Upper-latin",
  "Upper-roman": "Upper-roman",
  "Vertical text alignment toolbar": "Vertical text alignment toolbar",
  White: "White",
  "Widget toolbar": "Widget toolbar",
  Width: "Width",
  Yellow: "Yellow",
};
