import React, { useContext, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { NOTIFICATION_TYPES } from "./constants";
import SlaCardResearch from "./SlaCardResearch";
import SlaCardConciliumCreated from "./SlaCardConciliumCreated";
import SlaCardConcilium from "./SlaCardConcilium";
import SlaCardShare from "./SlaCardShare";
import SlaCardPatientCardAsk from "./SlaCardPatientCardAsk";
import SlaCardPatientCardAnswer from "./SlaCardPatientCardAnswer";

const slaCardTypesToComponent = {
  [NOTIFICATION_TYPES.RESEARCH_CREATED]: SlaCardResearch,
  [NOTIFICATION_TYPES.CONCILIUM_CREATED]: SlaCardConciliumCreated,
  [NOTIFICATION_TYPES.CONCILIUM_ACCEPTED]: SlaCardConcilium,
  [NOTIFICATION_TYPES.CONCILIUM_REJECTED]: SlaCardConcilium,
  [NOTIFICATION_TYPES.CONCILIUM_PRECONCLUSION_GIVEN]: SlaCardConcilium,
  [NOTIFICATION_TYPES.PRECONCLUSION_CREATED]: SlaCardShare,
  [NOTIFICATION_TYPES.PATIENT_CARD_ACCESS_ASKED]: SlaCardPatientCardAsk,
  [NOTIFICATION_TYPES.PATIENT_CARD_ACCESS_REJECTED]: SlaCardPatientCardAnswer,
  [NOTIFICATION_TYPES.PATIENT_CARD_ACCESS_GIVEN]: SlaCardPatientCardAnswer

};

export function SlaCard({ notification, handleClose, open, t, viewedEntries }) {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && open && notification.is_read === false) {
      viewedEntries.add(notification.id);
    }
  }, [open, inView, notification.id]);

  const SlaCardComponent =
    slaCardTypesToComponent[notification.notification_type];
  if (SlaCardComponent) {
    return (
      <SlaCardComponent
        notification={notification}
        handleClose={handleClose}
        t={t}
        ref={ref}
      />
    );
  }
  return (
    <SlaCardResearch
      notification={notification}
      handleClose={handleClose}
      t={t}
      ref={ref}
    />
  );
}
