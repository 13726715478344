//@ts-check
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import { useQuery } from "react-query";
import { getShareToken } from "../../api/shareApi";
import {
  createToast,
  MessagesContext,
} from "../../globalContexts/MessagesContext";
import { FileCopy, Share } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  sliderWrapper: {
    width: "220px",
    padding: "0 4px",
    height: "64px",
  },
  shareWrapper: {
    padding: "15px",
  },
  copyButton: {
    marginLeft: "10px",
  },
});
export function SharedButton() {
  const idShare = useParams();
  const classes = useStyles();
  const { t } = useTranslation(["research", "common"]);
  const [shareAnchor, setShareAnchor] = useState(null);
  const {
    data: shareToken,
    isLoading: tokenLoading,
    error: tokenError,
  } = useQuery(["shareToken"], () => getShareToken(idShare.id), {
    enabled: !!idShare.id && Boolean(shareAnchor),
    refetchOnWindowFocus: false,
  });
  const token = shareToken?.length ? shareToken[shareToken.length - 1].token : null;
  const messageDispatch = useContext(MessagesContext)[1];
  const shareHref =
    token && `${window.location.origin}/researches/share?token=${token}`;

  useEffect(() => {
    if (shareAnchor && shareHref) {
      navigator?.clipboard?.writeText(shareHref);
      messageDispatch(createToast(t('LINK_COPIED'), "success"));
    }
  }, [shareAnchor, shareHref]);

  return (
    <>
      <div onClick={(el) => setShareAnchor(el.currentTarget)}>
        <Share />
        <Typography>{t("SHARE")}</Typography>
      </div>
      <Popover
        anchorEl={shareAnchor}
        open={Boolean(shareAnchor)}
        onClose={() => setShareAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Grid className={classes.shareWrapper}>
          <Typography>{t("LINK_TO_SERVEY")}</Typography>
          <Grid>
            {shareHref ? (
                <>
                  <TextField
                      InputProps={{ readOnly: true }}
                      variant="outlined"
                      size="small"
                      value={shareHref}
                  />
                  <Button
                      variant="contained"
                      color="primary"
                      title={t("COPY")}
                      className={classes.copyButton}
                      onClick={() => {
                        navigator?.clipboard?.writeText(shareHref);
                        messageDispatch(createToast(t("LINK_COPIED"), "success"));
                      }}
                  >
                    <FileCopy />
                  </Button>
                </>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
