import React, { useEffect, useMemo, useRef, useState } from "react";
import CornerstoneViewport from "react-cornerstone-viewport";
import cornerstone from "cornerstone-core";
import { Grid, IconButton, makeStyles, Menu, MenuItem, Popover, Slider, Typography } from "@material-ui/core";
import {
  ArrowDropDown,
  Brightness6,
  CloudDownload,
  Create,
  Layers,
  Opacity,
  Pageview,
  PanTool,
  PeopleAlt,
  PlayArrow,
  Restore,
  RotateLeft,
  Stop,
  Storage,
  YouTube,
  ZoomIn
} from "@material-ui/icons";
import AsyncButton from "../../AsyncButton";
import { SharedButton } from "../../../modules/SharePage/SharedButton";
import { useTranslation } from "react-i18next";

const tools = [
  // Mouse
  {
    name: "Wwwc",
    mode: "active",
    modeOptions: { mouseButtonMask: 1 },
  },
  {
    name: "Zoom",
    mode: "active",
    modeOptions: { mouseButtonMask: 2 },
  },
  {
    name: "Pan",
    mode: "active",
    modeOptions: { mouseButtonMask: 4 },
  },
  "Length",
  "Angle",
  "Bidirectional",
  "FreehandRoi",
  "Eraser",
  "Magnify",
  "Rotate",
  {
    name: "TextMarker",
    mode: "active",
    props: {
      configuration: {
        markers: ["F5", "F4", "F3", "F2", "F1"],
        current: "F5",
        ascending: true,
        loop: true,
      },
    },
  },
  { name: "ArrowAnnotate", mode: "active" },
  // Scroll
  { name: "StackScrollMouseWheel", mode: "active" },
  // Touch
  { name: "PanMultiTouch", mode: "active" },
  { name: "ZoomTouchPinch", mode: "active" },
  { name: "StackScrollMultiTouch", mode: "active" },
];

const CHANNELS = {
  DEFAULT: "",
  RED: "RED",
  GREEN: "GREEN",
  BLUE: "BLUE",
  RED_GREEN: "RED_GREEN",
  RED_BLUE: "RED_BLUE",
  GREEN_BLUE: "GREEN_BLUE",
};

const toolsBtnGroup = [
  {
    name: "Pan",
    label: "Панорамирование",
    icon: <PanTool />,
  },
  {
    name: "Wwwc",
    label: "Уровни",
    icon: <Brightness6 />,
  },

  { name: "Zoom", label: "Масштаб", icon: <ZoomIn /> },
  { name: "Magnify", label: "Лупа", icon: <Pageview /> },
  { name: "Rotate", label: "Изменить", icon: <RotateLeft /> },
];

const useStyles = makeStyles(() => {
  const TOOLS_HEIGHT = "84px";
  return {
    tools: {
      height: TOOLS_HEIGHT,
      padding: "0 8px",
      flexWrap: "nowrap",
      overflowX: "auto",
      overflowY: "hidden",
      "& .active-tool": {
        border: "1px solid red",
      },
      "& .tool": {
        cursor: "pointer",
      },
    },
    viewer: {
      height: `calc(100% - ${TOOLS_HEIGHT})`,
      padding: "4px",

      "& .active-vp": {
        border: "2px solid #d32f2f",
      },
      "& .nonactive-vp": {
        border: "1px solid #901a1a",
      },
    },
    cell: {
      border: "1px solid #777",
      display: "inline-block",
      width: "18px",
      height: "18px",
      margin: "1px",

      "&.active": {
        background: "#777",
      },
    },
    sliderWrapper: {
      width: "220px",
      padding: "0 4px",
      height: "84px",
    },
  };
});

function Tools(props) {
  const {
    activeTool,
    handleActiveToolChange,
    handlePlayClick,
    isPlaying,
    frameRate,
    handleFrameRateChange,
    handleLayoutChange,
    handleChannelChange,
    handleReset,
    showSharedButton,
    handleDownloadPicture,
    handleDownloadImagesPdf,
    handleDownloadConclusion,
    handleCopyConclusionText,
    handleDownloadDicom,
    handleConcilium,
    showConciliumButton,
    showDownloadButton,
    ConciliumMenuPopover,
    erdbHistory
  } = props;

  const [layoutAnchor, setLayoutAnchor] = useState(null);
  const [cineAnchor, setCineAnchor] = useState(null);
  const [annotationAnchor, setAnnotationAnchor] = useState(null);
  const [downloadAnchor, setDownloadAnchor] = useState(null);
  const [channelAnchor, setChannelAnchor] = useState(null);

  const conciliumMenuAnchor = useRef();

  const [activeCells, setActiveCells] = useState([0, 0]);
  const classes = useStyles();

  const cells = [
    [true, true, true],
    [true, true, true],
    [true, true, true],
  ];

  function activateTool(toolName) {
    handleActiveToolChange(toolName);
    setAnnotationAnchor(null);
  }
  function changeChannel(ch) {
    handleChannelChange(ch);
    setChannelAnchor(null);
  }
  const { t } = useTranslation("image-viewer", { useSuspense: false });
  return (
    <Grid container item className={classes.tools} alignItems="center">
      {toolsBtnGroup.map((toolBtn) => (
        <div
          key={toolBtn.name}
          className={`pb-4 pt-4 pl-4 pr-4 text-center tool ${
            activeTool === toolBtn.name ? "active-tool" : ""
          }`}
          onClick={() => handleActiveToolChange(toolBtn.name)}
        >
          {toolBtn.icon}
          <Typography className="ws-nowrap">
            {t(toolBtn.name.toUpperCase())}
          </Typography>
        </div>
      ))}
      <div
        className="pb-4 pt-4 pl-4 pr-4 text-center tool"
        onClick={handleReset}
      >
        <Restore />
        <Typography className="ws-nowrap">{t("DISCARD")}</Typography>
      </div>
      <div className="pb-4 pt-4 pl-4 pr-4 text-center tool">
        <div onClick={(el) => setAnnotationAnchor(el.currentTarget)}>
          <Create />
          <Typography className="d-flex ws-nowrap">
            {t("ANNOTATIONS")} <ArrowDropDown />
          </Typography>
        </div>
        <Menu
          anchorEl={annotationAnchor}
          keepMounted
          open={Boolean(annotationAnchor)}
          onClose={() => setAnnotationAnchor(null)}
        >
          <MenuItem onClick={() => activateTool("FreehandRoi")}>
            {t("INTEREST_REGION")}
          </MenuItem>
          <MenuItem onClick={() => activateTool("ArrowAnnotate")}>
            {t("ARROW")}
          </MenuItem>
          <MenuItem onClick={() => activateTool("Angle")}>
            {t("ANGLE")}
          </MenuItem>
          <MenuItem onClick={() => activateTool("Eraser")}>
            {t("ERASER")}
          </MenuItem>
        </Menu>
      </div>
      <div className="pb-4 pt-4 pl-4 pr-4 text-center tool">
        <div onClick={(el) => setDownloadAnchor(el.currentTarget)}>
          <CloudDownload />
          <Typography className="d-flex ws-nowrap">
            {t("DOWNLOAD")} <ArrowDropDown />
          </Typography>
        </div>
        <Menu
          anchorEl={downloadAnchor}
          keepMounted
          open={Boolean(downloadAnchor)}
          onClose={() => setDownloadAnchor(null)}
        >
          <MenuItem onClick={handleDownloadPicture}>{t("PICTURES")}</MenuItem>
          {showDownloadButton && (
            <MenuItem>
              <AsyncButton onClick={handleDownloadImagesPdf} fullWidth>
                <Typography className="text-transform-none">
                  {t("PDF_PICS")}
                </Typography>
              </AsyncButton>
            </MenuItem>
          )}
          {handleDownloadConclusion && (
            <MenuItem>
              <AsyncButton onClick={handleDownloadConclusion} fullWidth>
                <Typography className="text-transform-none">
                  {t("CONCLUSION")}
                </Typography>
              </AsyncButton>
            </MenuItem>
          )}
          {showDownloadButton && (
            <MenuItem>
              <AsyncButton onClick={handleDownloadDicom} fullWidth>
                <Typography className="text-transform-none">
                  {t("DOWNLOAD_DICOM")}
                </Typography>
              </AsyncButton>
            </MenuItem>
          )}
          {handleCopyConclusionText && (
            <MenuItem>
              <AsyncButton onClick={handleCopyConclusionText} fullWidth>
                <Typography className="text-transform-none">
                  {t("COPY_TEXT_CONCLUSION")}
                </Typography>
              </AsyncButton>
            </MenuItem>
          )}
        </Menu>
      </div>
      <div className="pb-4 pt-4 pl-4 pr-4 text-center tool">
        <div onClick={(el) => setChannelAnchor(el.currentTarget)}>
          <Opacity />
          <Typography className="d-flex ws-nowrap">
            {t("CHANNELS")} <ArrowDropDown />
          </Typography>
        </div>
        <Menu
          anchorEl={channelAnchor}
          keepMounted
          open={Boolean(channelAnchor)}
          onClose={() => setChannelAnchor(null)}
        >
          <MenuItem onClick={() => changeChannel(CHANNELS.DEFAULT)}>
            {t("DISCARD")}
          </MenuItem>
          <MenuItem onClick={() => changeChannel(CHANNELS.RED)}>
            {t("RED")}
          </MenuItem>
          <MenuItem onClick={() => changeChannel(CHANNELS.GREEN)}>
            {t("GREEN")}
          </MenuItem>
          <MenuItem onClick={() => changeChannel(CHANNELS.BLUE)}>
            {t("BLUE")}
          </MenuItem>
          <MenuItem onClick={() => changeChannel(CHANNELS.GREEN_BLUE)}>
            {t("GREEN")}, {t("BLUE")}
          </MenuItem>
          <MenuItem onClick={() => changeChannel(CHANNELS.RED_BLUE)}>
            {t("RED")}, {t("BLUE")}
          </MenuItem>
          <MenuItem onClick={() => changeChannel(CHANNELS.RED_GREEN)}>
            {t("RED")}, {t("GREEN")}
          </MenuItem>
        </Menu>
      </div>
      <div className="pb-4 pt-4 pl-4 pr-4 text-center tool">
        <div onClick={(el) => setCineAnchor(el.currentTarget)}>
          <YouTube />
          <Typography className="ws-nowrap">{t("CINE_LOOP")}</Typography>
        </div>
        <Popover
          anchorEl={cineAnchor}
          onClose={() => setCineAnchor(null)}
          open={Boolean(cineAnchor)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Grid container alignItems="center" className={classes.sliderWrapper}>
            <Grid item>
              <IconButton onClick={handlePlayClick}>
                {isPlaying ? <Stop /> : <PlayArrow />}
              </IconButton>
            </Grid>
            <Grid item xs className="pt-4 pr-4">
              <Slider
                min={1}
                max={60}
                value={frameRate}
                onChange={handleFrameRateChange}
              />
            </Grid>
            <Grid item>{frameRate}</Grid>
          </Grid>
        </Popover>
      </div>
      <div className="pb-4 pt-4 pl-4 pr-4 text-center tool">
        <div onClick={(el) => setLayoutAnchor(el.currentTarget)}>
          <Layers />
          <Typography className="ws-nowrap">{t("SCREEN_SPLIT")}</Typography>
        </div>
        <Popover
          anchorEl={layoutAnchor}
          onClose={() => setLayoutAnchor(null)}
          open={Boolean(layoutAnchor)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="pl-4 pr-4 pb-4 pt-4">
            {cells.map((row, ridx) => (
              <Grid container alignItems="center" key={ridx}>
                {row.map((_, cidx) => (
                  <span
                    key={cidx}
                    className={`${classes.cell} ${
                      activeCells[0] > ridx && activeCells[1] > cidx
                        ? "active"
                        : ""
                    }`}
                    onMouseEnter={() => setActiveCells([ridx + 1, cidx + 1])}
                    onClick={() => handleLayoutChange(activeCells)}
                  />
                ))}
              </Grid>
            ))}
          </div>
        </Popover>
      </div>
      <div className="pb-4 pt-4 pl-4 pr-4 text-center tool">
        {showSharedButton && <SharedButton />}
      </div>
      {showConciliumButton && (
        <div
          className="pb-4 pt-4 pl-4 pr-4 text-center tool"
          onClick={handleConcilium}
          ref={conciliumMenuAnchor}
        >
          <div>
            <PeopleAlt />
            <Typography className="ws-nowrap">{t("CONCILIUM")}</Typography>
          </div>
        </div>
      )}
      {ConciliumMenuPopover && (
        <ConciliumMenuPopover anchorEl={conciliumMenuAnchor.current} />
      )}
      <div className="pb-4 pt-4 pl-4 pr-4 text-center tool">
        <div onClick={erdbHistory}>
          <Storage />
          <Typography className="ws-nowrap">{t("ERDB")}</Typography>
        </div>
      </div>
    </Grid>
  );
}

function updateImage(enabledElement, pixelData) {
  const newImg = {
    ...enabledElement.image,
    getPixelData: () => pixelData,
  };
  cornerstone.displayImage(enabledElement.element, newImg);
  viewportUpdateHack(enabledElement);
}

function viewportUpdateHack(enabledElement) {
  enabledElement.viewport.voi.windowCenter += 10 ** -10;
  cornerstone.setViewport(enabledElement.element, enabledElement.viewport);
}

function applyChannel(channel, pixelData) {
  if (channel === CHANNELS.RED) {
    for (let i = 0; i < pixelData.length; i += 4) {
      // green = red
      pixelData[i + 1] = pixelData[i];
      // blue = red
      pixelData[i + 2] = pixelData[i];
    }
  }
  if (channel === CHANNELS.GREEN) {
    for (let i = 0; i < pixelData.length; i += 4) {
      // red = green
      pixelData[i] = pixelData[i + 1];
      // blue = green
      pixelData[i + 2] = pixelData[i + 1];
    }
  }
  if (channel === CHANNELS.BLUE) {
    for (let i = 0; i < pixelData.length; i += 4) {
      // red = blue
      pixelData[i] = pixelData[i + 2];
      // green = blue
      pixelData[i + 1] = pixelData[i + 2];
    }
  }
  if (channel === CHANNELS.GREEN_BLUE) {
    for (let i = 0; i < pixelData.length; i += 4) {
      let greenAndBlue = pixelData[i + 1] + pixelData[i + 2];
      if (greenAndBlue > 255) {
        greenAndBlue = 255;
      }
      // red = blue + green
      pixelData[i] = greenAndBlue;
      // green = blue + green
      pixelData[i + 1] = greenAndBlue;
      // blue = blue + green
      pixelData[i + 2] = greenAndBlue;
    }
  }
  if (channel === CHANNELS.RED_BLUE) {
    for (let i = 0; i < pixelData.length; i += 4) {
      let redAndBlue = pixelData[i] + pixelData[i + 2];
      if (redAndBlue > 255) {
        redAndBlue = 255;
      }
      // red = blue + red
      pixelData[i] = redAndBlue;
      // green = blue + red
      pixelData[i + 1] = redAndBlue;
      // blue = blue + red
      pixelData[i + 2] = redAndBlue;
    }
  }
  if (channel === CHANNELS.RED_GREEN) {
    for (let i = 0; i < pixelData.length; i += 4) {
      let redAndGreen = pixelData[i] + pixelData[i + 1];
      if (redAndGreen > 255) {
        redAndGreen = 255;
      }
      // red = red + green
      pixelData[i] = redAndGreen;
      // green = red + green
      pixelData[i + 1] = redAndGreen;
      // blue = red + green
      pixelData[i + 2] = redAndGreen;
    }
  }
  return pixelData;
}

function getEyesSideAndIdxFromId(eyes, id) {
  const rightIdx = eyes.rightEyes?.findIndex((eye) => eye.id == id);
  if (rightIdx > -1) {
    return ["rightEyes", rightIdx];
  }
  const leftIdx = eyes.leftEyes?.findIndex((eye) => eye.id == id);
  if (leftIdx > -1) {
    return ["leftEyes", leftIdx];
  }
  if (eyes.rightEyes) {
    return ["rightEyes", 0];
  }
  if (eyes.leftEyes) {
    return ["leftEyes", 0];
  }
  return [undefined, null];
}

export function CornerstoneImageViewer({
  eyes,
  activeImg,
  setActiveImg,
  CustomOverlay,
  isConclusionOpen,
  handleDownloadPicture,
  handleDownloadImagesPdf,
  handleDownloadConclusion,
  handleCopyConclusionText,
  handleDownloadDicom,
  handleConcilium,
  showSharedButton,
  showConciliumButton,
  showDownloadButton,
  ConciliumMenuPopover,
  erdbHistory
}) {
  const classes = useStyles();
  const [activeTool, setActiveTool] = useState("Pan");
  const [frameRate, setFrameRate] = useState(22);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeViewportIndex, setActiveViewportIndex] = useState("0_0");
  const [viewportEnabledElement, setViewportEnabledElement] = useState({
    [activeViewportIndex]: null,
  });
  const defaultViewportImg = useMemo(() => {
    const [side, activeImgIdx] = getEyesSideAndIdxFromId(eyes, activeImg);

    return {
      activeImgIdx,
      eyesImg: eyes[side],
      side,
    };
  }, [activeImg, eyes]);
  const [viewportImg, setViewportImg] = useState({
    "0_0": defaultViewportImg,
    "0_1": defaultViewportImg,
    "0_2": defaultViewportImg,
    "1_0": defaultViewportImg,
    "1_1": defaultViewportImg,
    "1_2": defaultViewportImg,
    "2_0": defaultViewportImg,
    "2_1": defaultViewportImg,
    "2_2": defaultViewportImg,
  });
  const [layout, setLayout] = useState([1, 1]);
  const rows = useMemo(() => Array(layout[0]).fill(true), [layout]);
  const cols = useMemo(() => Array(layout[1]).fill(true), [layout]);
  const enabledElement = viewportEnabledElement[activeViewportIndex];

  useEffect(() => {
    const [side, activeImgIdx] = getEyesSideAndIdxFromId(eyes, activeImg);
    setViewportImg((state) => ({
      ...state,
      [activeViewportIndex]: {
        activeImgIdx,
        eyesImg: eyes[side],
        side,
      },
    }));
  }, [activeImg, eyes]);

  return (
    <Grid container className="h-100" direction="column">
      <Tools
        activeTool={activeTool}
        handlePlayClick={() => setIsPlaying((prevState) => !prevState)}
        handleFrameRateChange={(_, newValue) => setFrameRate(newValue)}
        frameRate={frameRate}
        isPlaying={isPlaying}
        handleActiveToolChange={(tool) => setActiveTool(tool)}
        handleLayoutChange={(newValue) => setLayout(newValue)}
        handleChannelChange={(ch) => {
          if (enabledElement) {
            const { eyesImg, activeImgIdx } = viewportImg[activeViewportIndex];
            const activeImageOrig = cornerstone.imageCache.cachedImages.find(
              (cache) => cache.imageId.includes(eyesImg[activeImgIdx].fullUrl)
            );
            const pixels = applyChannel(
              ch,
              activeImageOrig.image.getPixelData()
            );
            updateImage(enabledElement, pixels);
          }
        }}
        showSharedButton={showSharedButton}
        handleReset={() => {
          cornerstone.reset(enabledElement.element);
          const { eyesImg, activeImgIdx } = viewportImg[activeViewportIndex];
          const activeImageOrig = cornerstone.imageCache.cachedImages.find(
            (cache) => cache.imageId.includes(eyesImg[activeImgIdx].fullUrl)
          );
          updateImage(enabledElement, activeImageOrig.image.getPixelData());
        }}
        handleDownloadPicture={handleDownloadPicture}
        handleDownloadImagesPdf={handleDownloadImagesPdf}
        handleDownloadConclusion={handleDownloadConclusion}
        handleCopyConclusionText={handleCopyConclusionText}
        handleDownloadDicom={handleDownloadDicom}
        handleConcilium={handleConcilium}
        showDownloadButton={showDownloadButton}
        showConciliumButton={showConciliumButton}
        ConciliumMenuPopover={ConciliumMenuPopover}
        erdbHistory={erdbHistory}
      />
      <Grid
        key={JSON.stringify(layout)}
        container
        item
        direction="column"
        className={classes.viewer}
      >
        {rows.map((_, ridx) => (
          <Grid key={ridx} item className="flex-1 minh-0 h-100" container>
            {cols.map((_, cidx) => (
              <Grid
                item
                key={cidx}
                xs={12 / layout[1]}
                className="maxh-100"
                onDragOver={(e) => {
                  e.preventDefault();
                  setActiveViewportIndex(`${ridx}_${cidx}`);
                }}
                onDrop={(e) => {
                  const id = e.dataTransfer.getData("id");
                  const researchId = e.dataTransfer.getData("researchId");
                  setActiveImg(id, researchId);
                }}
              >
                <CornerstoneViewport
                  key={isConclusionOpen.toString()}
                  tools={tools}
                  activeTool={activeTool}
                  isPlaying={isPlaying}
                  frameRate={frameRate}
                  className={`h-100 flex-1 ${
                    activeViewportIndex === `${ridx}_${cidx}`
                      ? "active-vp"
                      : "nonactive-vp"
                  }`}
                  imageIds={viewportImg[`${ridx}_${cidx}`].eyesImg.map(
                    (eye) => `${eye.fullUrl || eye.smallUrl}`
                  )}
                  imageIdIndex={viewportImg[`${ridx}_${cidx}`].activeImgIdx}
                  enableResizeDetector={false}
                  viewportOverlayComponent={(props) => (
                    <CustomOverlay
                      {...props}
                      side={viewportImg[`${ridx}_${cidx}`].side}
                      image={viewportEnabledElement[`${ridx}_${cidx}`].image}
                    />
                  )}
                  setViewportActive={() => {
                    setActiveViewportIndex(`${ridx}_${cidx}`);
                    const newViewport = viewportImg[`${ridx}_${cidx}`];
                    const image = newViewport.eyesImg[newViewport.activeImgIdx];
                    setActiveImg(image.id, image.researchId);
                  }}
                  onNewImage={({ currentImageIdIndex }) => {
                    if (
                      viewportImg[activeViewportIndex].eyesImg[
                        currentImageIdIndex
                      ]
                    ) {
                      setActiveImg(
                        viewportImg[activeViewportIndex].eyesImg[
                          currentImageIdIndex
                        ].id
                      );
                    }
                  }}
                  onElementEnabled={(elementEnabledEvt) => {
                    setViewportEnabledElement((state) => ({
                      ...state,
                      [`${ridx}_${cidx}`]: elementEnabledEvt.detail,
                    }));
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
