// @ts-check
import React, { useRef, useState } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  Typography,
  Button,
} from "@material-ui/core";
import { Field } from "formik";
import { SizeMe } from "react-sizeme";
import Camera from "./Camera";
import useSocket from "./useSocket";
import { getFileFromDataUrl } from "../../../utils";
import { FUNDUS_VERSION } from "../../../config/constants";

function Cameras({ t, formikProps }) {
  const [socketData, setSocketData] = useState({ data: null, cb: null });
  const valuesRef = useRef(formikProps.values);
  valuesRef.current = formikProps.values;
  function handleAddedFiles(data, cb) {
    if (valuesRef.current.iin && valuesRef.current.iin != data.iin) {
      return setSocketData({ data, cb });
    }
    updateEyes(data);
    cb({ success: true });
  }
  /**
   * @param {"DEFAULT" | "OPTIMA"} fundusVersion
   */
  function handleFundusVersionSet(fundusVersion) {
    if (fundusVersion === FUNDUS_VERSION.OPTIMA) {
      formikProps.setValues({
        ...valuesRef.current,
        fundusVersion: FUNDUS_VERSION.OPTIMA,
      });
    }
  }
  function updateEyes(data) {
    const rightEyes = data.rightEyes.map((dataURL) =>
      getFileFromDataUrl(dataURL, "rightEyes")
    );
    const leftEyes = data.leftEyes.map((dataURL) =>
      getFileFromDataUrl(dataURL, "leftEyes")
    );
    formikProps.setValues({
      ...valuesRef.current,
      rightEyes: [...valuesRef.current.rightEyes, ...rightEyes],
      leftEyes: [...valuesRef.current.leftEyes, ...leftEyes],
    });
  }
  const { status } = useSocket(true, handleAddedFiles, handleFundusVersionSet);

  return (
    <>
      <Grid container justify="space-between">
        <Field
          name="rightEyes"
          render={({ field, form: { setFieldValue } }) => (
            <SizeMe>
              {({ size }) => (
                <Camera
                  setFieldValue={setFieldValue}
                  name={field.name}
                  label={t("RIGHT") + " (OD)"}
                  value={field.value}
                  size={size}
                  socketStatus={status}
                />
              )}
            </SizeMe>
          )}
        />
        <Field
          name="leftEyes"
          render={({ field, form: { setFieldValue } }) => (
            <SizeMe>
              {({ size }) => (
                <Camera
                  setFieldValue={setFieldValue}
                  name={field.name}
                  value={field.value}
                  label={t("LEFT") + " (OS)"}
                  size={size}
                  socketStatus={status}
                />
              )}
            </SizeMe>
          )}
        />
      </Grid>
      <Dialog open={Boolean(socketData.data && socketData.cb)}>
        <DialogContent>
          <Typography variant="h6" color="secondary" paragraph align="center">
            {t("SOCKET_IIN_ERROR_MAIN")}
          </Typography>
          <Typography paragraph align="center">
            {t("SOCKET_IIN_ERROR_DESCRIPTION")}
          </Typography>
          <Grid container justify="space-between">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                updateEyes(socketData.data);
                socketData.cb({ success: true });
                setSocketData({ data: null, cb: null });
              }}
            >
              {t("LOAD_ANYWAY")}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                socketData.cb({ success: false });
                setSocketData({ data: null, cb: null });
              }}
            >
              {t("CANCEL_LOAD")}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Cameras;
