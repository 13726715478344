import {
  Box,
  Button,
  FormHelperText,
  Grid,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import {
  createToast,
  MessagesContext,
} from "../../../../globalContexts/MessagesContext";
import { queryClient } from "../../../../globalContexts/queryContext";
import { DictionariesContext } from "../../../../globalContexts/DictionariesContext";
import Select from "../../../../components/MuiSelect";
import { RESEARCH_SERVICE_NAME } from "../../../ResearchesPage/constants";
import { setRecommendation } from "../../../../api";
import { recommendationSchema } from "../Service/Forms/validationSchemas";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "80vw",
    padding: "24px",
    position: "relative",
  },
  bold: {
    fontWeight: "bold",
  },
  field: {
    marginLeft: "25px",
    width: "70%",
  },
  table: {
    "& td": {
      padding: "5px",
    },
  },
  closeIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
  },
  input: {
    display: "flex",
  },
}));

const CreateFormModal = ({ open, onClose, chosen, editingItem }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("patient-registry");

  const { id: researchId } = useParams();
  const dispatchMessage = useContext(MessagesContext)[1];
  const initialValues = useMemo(
    () => ({
      codenames: [],
      text: "",
    }),
    []
  );
  const { recommendations } = useContext(DictionariesContext)[0];
  const { handleSubmit, control, reset, errors, register } = useForm({
    defaultValues: initialValues,
    mode: "onChange",
    validationSchema: recommendationSchema,
  });
  const options = useMemo(() => {
    let property = "nameRu";
    if (i18n.language.includes("en")) property = "nameEn";
    else if (i18n.language.includes("kk")) property = "nameKz";
    return recommendations
      .filter(({ serviceNames }) =>
        serviceNames.includes(
          RESEARCH_SERVICE_NAME.OPHTHALMOLOGIST_CONSULTATION
        )
      )
      .map((item) => ({ value: item.codename, label: item[property] }));
  }, [i18n.language, recommendations]);

  useEffect(() => {
    if (chosen) {
      reset({
        ...chosen,
        codenames: chosen.codenames.map((codename) =>
          options.find(({ value }) => value === codename)
        ),
      });
    }
    if (editingItem) {
      reset({
        ...editingItem,
        codenames: editingItem.codenames.map((codename) =>
          options.find(({ value }) => value === codename)
        ),
      });
    }
    if (!chosen && !editingItem) {
      reset(initialValues);
    }
  }, [chosen, editingItem, initialValues, options, reset]);

  const handleSubmitCreateForm = async (values) => {
    try {
      await setRecommendation(researchId, {
        recommendationCodenames: values.codenames.map(({ value }) => value),
        recommendationText: values.text,
      });
      onClose();
      dispatchMessage(createToast(t("RECOMMENDATION_SUCCESSFULLY_CREATED")));
      await queryClient.invalidateQueries(["researchDetails", researchId]);
      return;
    } catch (error) {
      console.error(error);
    }
    onClose();
  };

  const handleSubmitEditForm = async (values) => {
    try {
      await setRecommendation(researchId, {
        recommendationCodenames: values.codenames.map(({ value }) => value),
        recommendationText: values.text,
      });
      onClose();
      dispatchMessage(createToast(t("RECOMMENDATION_SUCCESSFULLY_EDITED")));
      await queryClient.invalidateQueries(["researchDetails", researchId]);
      return;
    } catch (error) {
      console.error(error);
    }
    onClose();
  };
  return (
    <Modal
      open={open}
      onClose={
        !chosen
          ? () => window.confirm(t("CONFIRM_CLOSE")) && onClose()
          : onClose
      }
      className={classes.modal}
    >
      <Paper className={classes.modalContent}>
        <Close className={classes.closeIcon} onClick={onClose} />
        <Typography className={classes.bold}>{t("RECOMMENDATION")}</Typography>
        <form
          onSubmit={handleSubmit(
            editingItem ? handleSubmitEditForm : handleSubmitCreateForm
          )}
        >
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"space-between"}
          >
            <span>{t("RECOMMENDATION")}*:</span>
            <div className={classes.field}>
              <Controller
                name={"codenames"}
                control={control}
                isDisabled={!!chosen}
                placeholder={t("CHOOSE_RECOMMENDATION")}
                isMulti
                options={options}
                as={Select}
              />
              {errors.codenames && (
                <FormHelperText error>
                  {t(errors.codenames.message)}
                </FormHelperText>
              )}
            </div>
          </Grid>
          <Box height={"24px"} />
          <Grid container direction={"column"}>
            <span>{t("RECOMMENDATION_TEXT")}:</span>
            <TextField
              name="text"
              error={!!errors["text"]}
              inputProps={{ readOnly: chosen }}
              fullWidth={true}
              variant={"outlined"}
              multiline={true}
              rows={6}
              inputRef={register}
            />
          </Grid>
          {!chosen && (
            <>
              <Box height={"24px"} />
              <Grid container justify={"flex-end"}>
                <Button type={"submit"} variant={"contained"} color={"primary"}>
                  {t("SAVE")}
                </Button>
                <Box width={"6px"} />
                <Button
                  onClick={onClose}
                  variant={"contained"}
                  color={"secondary"}
                >
                  {t("CANCEL")}
                </Button>
              </Grid>
            </>
          )}
        </form>
      </Paper>
    </Modal>
  );
};

export default CreateFormModal;
