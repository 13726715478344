import { formatDate, formatDateAndTime, formatTime } from "../../../utils";
import {
  RESEARCH_SERVICE_NAME,
  RESEARCH_STATUS,
} from "../../ResearchesPage/constants";

export function renderResearchInfo(research, t) {
  return `
    <p><b>${t("research:RESEARCH")}:</b> ${research.id}</p>
    <p><b>${t("FULL_NAME")}:</b> ${research.fullName}</p>
    <p><b>${t("BIRTHDAY")}:</b> ${research.birthday}</p>
    <p><b>${t("APPOINTMENT_TIME")}:</b> ${formatDateAndTime(
    research.appointmentTimestamp || research.createdTimestamp
  )}</p>
    <br /> 
    `;
}

function renderAutorefractomy({ serviceName, service }, t) {
  return `<h3>${t("serviceNamesDict:" + serviceName)}</h3>
          <h4>OD</h4>
          <p>sph${service.odSph > 0 ? "(+)" : "(-)"}${service.odSph} |
              cyl${service.odCyl > 0 ? "(+)" : "(-)"}${service.odCyl} | 
              axº${service.odAx}
          </p>
          <p>R1: ${service.odR1Mm}mm | ${service.odR1D}D | ax${
    service.odR1Ax
  }º</p>
          <p>R2: ${service.odR2Mm}mm | ${service.odR2D}D | ax${
    service.odR2Ax
  }º</p>
          <p>Ave: ${service.odAveMm}mm | ${service.odAveD}D | ax${
    service.odAveAx
  }º</p>
          <p>Cyl: ${service.odCylMm}mm | ${service.odCylD}D | ax${
    service.odCylAx
  }º</p>
          <h4>OS</h4>
          <p>sph${service.osSph > 0 ? "(+)" : "(-)"}${service.osSph} |
              cyl${service.osCyl > 0 ? "(+)" : "(-)"}${service.osCyl} | axº${
    service.osAx
  }
          </p>
          <p>R1: ${service.osR1Mm}mm | ${service.osR1D}D | ax${
    service.osR1Ax
  }º</p>
          <p>R2: ${service.osR2Mm}mm | ${service.osR2D}D | ax${
    service.osR2Ax
  }º</p>
          <p>Ave: ${service.osAveMm}mm | ${service.osAveD}D | ax${
    service.osAveAx
  }º</p>
          <p>Cyl: ${service.osCylMm}mm | ${service.osCylD}D | ax${
    service.osCylAx
  }º</p>
`;
}

export function renderServices(services, t) {
  if (!services) {
    return "";
  }
  const easyServiceNames = [
    RESEARCH_SERVICE_NAME.EXOPHTHALMOMETRY,
    RESEARCH_SERVICE_NAME.NON_CONTACT_TONOMETRY,
    RESEARCH_SERVICE_NAME.MAKLAKOV_TEST,
    RESEARCH_SERVICE_NAME.SCHIRMER_TEST,
  ];
  return (
    services
      .map(({ service, serviceName }) => {
        if (
          serviceName === RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY &&
          service.status === RESEARCH_STATUS.FINISHED
        ) {
          const leftEyeConclusion = service?.leftEyeConclusion
            ? `<h6><strong>Описание левого глаза:</strong> ${service?.leftEyeConclusion}</h6>`
            : "";
          const rightEyeConclusion = service?.rightEyeConclusion
            ? `<h6><strong>Описание правого глаза:</strong> ${service?.rightEyeConclusion}</h6>`
            : "";
          return `<h3>${t("serviceNamesDict:" + serviceName)}</h3>
          ${leftEyeConclusion}
          ${rightEyeConclusion}`;
        }
        if (easyServiceNames.includes(serviceName)) {
          return `<h3>${t("serviceNamesDict:" + serviceName)}</h3><p>OD: ${
            service.odMm
          }</p><p>OS: ${service.osMm}</p>`;
        }
        if (serviceName === RESEARCH_SERVICE_NAME.AUTOREFRACTOMY) {
          return renderAutorefractomy({ serviceName, service }, t);
        }
        if (serviceName !== RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY) {
          return renderUniversalServices({ serviceName, service }, t);
        }
      })
      .join("<br />") + "<br />"
  );
}

export function renderUniversalServices({ serviceName, service }, t) {
  return `<h3>${t("serviceNamesDict:" + serviceName)}</h3> ${service.comment}`;
}

export function renderDiseaseHistory(diseaseHistories, _researchId, t) {
  if (!diseaseHistories) {
    return "";
  }
  const currentDiseaseHistory = diseaseHistories.find(
    ({ researchId }) => Number(researchId) === Number(_researchId)
  );
  if (!currentDiseaseHistory) {
    return "";
  }
  return `<p>${t("COMPLAINTS")}: ${currentDiseaseHistory.complaints}</p>
            <p>An morbi: ${currentDiseaseHistory.anMorbi}</p>
            <p>An vitae: ${currentDiseaseHistory.anVitae}</p>
            <h4>Status oculorum</h4>
            <p>OD: sph${currentDiseaseHistory.odSph > 0 ? "(+)" : "(-)"}${
    currentDiseaseHistory.odSph
  } |
            cyl${currentDiseaseHistory.odCyl > 0 ? "(+)" : "(-)"}${
    currentDiseaseHistory.odCyl
  } | 
            axº${currentDiseaseHistory.odAx}
             </p>
             <p>OS: sph${currentDiseaseHistory.osSph > 0 ? "(+)" : "(-)"}${
    currentDiseaseHistory.osSph
  } |
            cyl${currentDiseaseHistory.osCyl > 0 ? "(+)" : "(-)"}${
    currentDiseaseHistory.osCyl
  } | 
            axº${currentDiseaseHistory.osAx}
             </p><br /> 
            `;
}

export function renderOphthalmologyStatus(
  ophthalmologyStatuses,
  t,
  fields,
  lang = "ru"
) {
  if (!ophthalmologyStatuses || !fields) {
    return "";
  }
  let langProperty = "nameRu";
  if (lang.includes("en")) {
    langProperty = "nameEn";
  } else if (lang.includes("kk")) {
    langProperty = "nameKz";
  }
  const currentOphthalmologyStatus = ophthalmologyStatuses.find(
    ({ isCurrent }) => isCurrent
  );
  if (!currentOphthalmologyStatus) {
    return "";
  }
  return `
        <h3>${t("OPHTHALMIC_STATUS")}</h3>
        ${fields
          .map((field) => {
            if (
              !currentOphthalmologyStatus.values[field.codename] ||
              currentOphthalmologyStatus.values[field.codename].length < 1
            ) {
              return "";
            }
            const values = currentOphthalmologyStatus.values[field.codename];
            return `<p>${field[langProperty]}: ${field.childrenFields
              .map((childrenField) => {
                if (values.indexOf(childrenField.codename) < 0) {
                  return "";
                }
                return childrenField[langProperty];
              })
              .filter((str) => str.length > 0)
              .join(", ")}</p>`;
          })
          .join("")}
        <br />
    `;
}

export function renderDiagnosis(diagnoses, _researchId, t, mkb10, lang = "ru") {
  if (!diagnoses || !mkb10) {
    return "";
  }
  const currentDiagnosis = diagnoses.find(
    ({ researchId }) => Number(researchId) === Number(_researchId)
  );
  if (!currentDiagnosis) {
    return "";
  }
  let fallbackLang = "name_ru";
  let langProperty = "name_ru";
  if (lang.includes("en")) {
    langProperty = "name_en";
  } else if (lang.includes("kk")) {
    langProperty = "name_kz";
  }
  let render = "";
  const mainDiagnosis = mkb10.find(
    ({ codename }) => codename === currentDiagnosis.mainDiagnosis
  );
  if (mainDiagnosis) {
    render += `<h3>${t("DIAGNOSIS")}</h3>
                   <p>${t("MAIN")}: ${
      currentDiagnosis.mainDiagnosis
    } | ${mainDiagnosis[langProperty] || mainDiagnosis[fallbackLang]}</p>
    `;
  }
  const concomitantDiagnosis = mkb10.find(
    ({ codename }) => codename === currentDiagnosis.concomitantDiagnosis
  );
  if (concomitantDiagnosis) {
    render += `<p>${t("CONCOMITANT")}: ${
      currentDiagnosis.concomitantDiagnosis
    } | ${concomitantDiagnosis[langProperty] ||
      concomitantDiagnosis[fallbackLang]}</p>`;
  }
  return render + "<br />";
}

export function renderRecommendations(
  research,
  recommendationDict,
  t,
  lang = "ru"
) {
  const ocularFundusPhotography = research.services.filter(
    (item) =>
      item.serviceName === RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY
  );
  const ocularFundusPhotographyFinished = ocularFundusPhotography.filter(
    (item) => item.service.status === RESEARCH_STATUS.FINISHED
  );

  if (ocularFundusPhotographyFinished.length <= 0 || !recommendationDict) {
    return "";
  }

  let langProperty = "nameRu";
  if (lang.includes("en")) {
    langProperty = "nameEn";
  } else if (lang.includes("kk")) {
    langProperty = "nameKz";
  }
  const recommendationsNormalized = recommendationDict.reduce((accum, curr) => {
    accum[curr.codename] = curr;
    return accum;
  }, {});

  const recommendation = research.recommendationCodenames
    ? `<p>${t("RECOMMENDATION")}: ${research.recommendationCodenames
        .map((codename) => {
          return recommendationsNormalized[codename]?.[langProperty];
        })
        .join(", ")}
            </p>
            <p>${t("RECOMMENDATION_TEXT")}: ${research.recommendationText}</p>`
    : "";
  const recommendationOcularFundusPhotography =
    ocularFundusPhotographyFinished.length > 0
      ? `<p>${ocularFundusPhotographyFinished
          .map((item) => {
            return (
              t("CONSULTATION") +
              ": " +
              item.service.recommendation +
              ", " +
              item.service.recommendationText
            );
          })
          .join("<br />")}, </p> `
      : "";
  return `<h3>${t("RECOMMENDATION")}</h3>
            ${recommendation}
            ${recommendationOcularFundusPhotography}
            <br />
`;
}

export function renderTreatments(medicalTreatments, _researchId, drugs, t) {
  if (!medicalTreatments) {
    return "";
  }
  const currentTreatment = medicalTreatments.find(
    ({ researchId }) => Number(researchId) === Number(_researchId)
  );
  if (!currentTreatment) {
    return "";
  }
  return `<h3>${t("MEDICAL_TREATMENT")}</h3>
            ${currentTreatment.treatments
              .map((item) => {
                return `<p><strong>${item.internationalName}</strong></p>
                        <p>${t("DOSAGE")}: ${item.dosage} ${
                  item.unitOfMeasurement
                }</p>
                        <p>${t("INPUT_METHOD")}: ${item.inputMethod}</p> ${
                  item.periodicity
                } ${item.duration}
                        ${
                          item.comment.length > 0
                            ? `<p>${t("COMMENT")}: ${item.comment}</p>`
                            : ""
                        }`;
              })
              .join("")}
`;
}

export function renderTemplateBegin(editorTemplates) {
  const notDefaultTemplate = editorTemplates.find(
    ({ isDefault }) => !isDefault
  );
  const defaultTemplate = editorTemplates.find(({ isDefault }) => isDefault);
  if (notDefaultTemplate) {
    return notDefaultTemplate.template;
  }
  if (defaultTemplate) {
    return defaultTemplate.template;
  }
  return `<h2>Выписка по результатам приема</h2>`;
}

export function renderTemplateEnd(doctorFullName) {
  return `<br /><strong>Доктор, к.м.н.</strong>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<strong style="text-align: right">${doctorFullName} ________________</strong>`;
}
