import React, { useState, useEffect, useCallback, useRef } from "react";
import Select from "./MuiSelect";

export default function AsyncWrapper(props) {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchData, disableLoadOnMount, ...rest } = props;

  const dataLoaded = useRef(false);

  const loadData = useCallback(() => {
    if (dataLoaded.current) return;
    setIsLoading(true);
    fetchData()
      .then(setOptions)
      .catch(console.error)
      .finally(() => {
        dataLoaded.current = true;
        setIsLoading(false);
      });
  }, [fetchData]);

  useEffect(() => {
    !disableLoadOnMount && loadData();
  }, [disableLoadOnMount, loadData]);

  return (
    <Select
      isLoading={isLoading}
      options={options}
      onMenuOpen={loadData}
      {...rest}
    />
  );
}
