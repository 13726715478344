import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CONCLUSION_TYPE } from "../../ResearchesPage/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    position: "absolute",
    width: "90%",
    right: 0,
    zIndex: 1000,
    top: "100%",
    background: theme.palette.background.paper,
    border: "2px solid #e0e0e0",
    maxHeight: "500px",
    flexWrap: "nowrap",
    overflowY: "auto",
    overflowX: "hidden",
  },
  row: {
    width: "100%",
    padding: "10px 15px",
    cursor: "pointer",
    "&:hover": {
      background: "#e0e0e0",
    },
  },
  inactive: {
    "&:hover": {
      background: theme.palette.background.paper,
    },
  },
  active: {
    background: "#e0e0e0",
  },
}));

const ScientificPreconclusionPicker = ({
  annotatorConclusionInfo,
  setShowMemberList,
  setConclusionType,
  conclusionType,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("research");

  return (
    <Grid container direction={"column"} className={classes.root}>
      {annotatorConclusionInfo?.annotatorConclusions.map(({ e }, index) => (
        <Grid
          container
          direction={"row"}
          alignItems={"center"}
          justify={"space-between"}
          key={index}
          className={`${classes.row}  ${
            index === conclusionType.chooseAnnotator.idx ? classes.active : ""
          } `}
          onClick={() => {
            setConclusionType((state) => ({
              ...state,
              chooseAnnotator: {
                idx: index,
                title: `${t("ANNOTATOR")} ${index + 1}`,
              },
            }));
            setShowMemberList(false);
          }}
        >
          <Typography>{`${t("ANNOTATOR")} ${index + 1}`}</Typography>
          <Box width={"20px"} height={"20px"} borderRadius={"50%"} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ScientificPreconclusionPicker;
