export const DESTROY_MODAL = "DESTROY_MODAL";
export const CREATE_MODAL = "CREATE_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export function closeModal() {
  return {
    type: CLOSE_MODAL
  };
}

export function createModal({
  dialogProps,
  dialogTitle,
  dialogContent,
  dialogFooter
}) {
  return {
    type: CREATE_MODAL,
    dialogProps,
    dialogTitle,
    dialogContent,
    dialogFooter
  };
}
export function destroyModal() {
  return {
    type: DESTROY_MODAL
  };
}
