import instance from "../config/request";

export const getDoctors = () => {
    return instance.get('/keycloak/doctors').then((res) => res.data);
}

export const createConcilium = (data) => {
    return instance.post('/conciliums', data).then((res) => res.data);
}

export const getConciliumInfo = (id) => {
    return instance.get(`/v3/conciliums/${id}`).then(res => res.data);
}

export const updateConciliumPreconclusion = (data) => {
    return instance.put(`/conciliums/preconclusion`, data).then(res => res.data);
}

export const updateConciliumStatus = (data) => {
    return instance.put(`/conciliums/status`, data).then(res => res.data);
}