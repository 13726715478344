import React, { useContext } from "react";
import MuiRemoteTable from "./MuiRemoteTable";
import {
  TableContext,
  registerTable,
  initialTableState,
} from "../../globalContexts/TableContext";

function MuiTable({ query = {}, remote = true, ...rest }) {
  const initialState = {
    ...initialTableState,
    ...query,
  };
  const dispatch = useContext(TableContext)[1];
  dispatch(registerTable(rest.tableName, initialState));
  if (remote) {
    return <MuiRemoteTable {...rest} initialState={initialState} />;
  }
}

export default React.memo(MuiTable);
