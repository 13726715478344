import React, { memo } from "react";
import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { ArrowBack, Info, EventNote } from "@material-ui/icons";
import { useSwipeable } from "react-swipeable";
import history from "../../../config/history";
import ConclusionsForm from ".";
import PatientForm from "../PatientForm";
import { createTeleporter } from "react-teleporter";
import { RESEARCH_SERVICE_NAME } from "../constants";
import { ROLES } from "../../../config/constants";
import Roled from "../../../components/Roled";
import { useIsPatient } from "../../../globalContexts/UserContext";

const handlerStyle = (slide) => ({
  display: "flex",
  transform: `translateX(${-slide * 100}%)`,
  transition: "transform .3s ease",
});

const containerStyle = (tabs) => ({
  display: "flex",
  flex: `0 0 ${tabs * 100}%`,
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
  },
  handlerWrapper: {
    overflow: "hidden",
  },
}));
export const DownloadFeportForNurseAndPatientMobileTeleporter = createTeleporter();

function MobileConclusionForm({
  t,
  patientFormInitialValues,
  handleSubmitPatientForm,
  conclusionFormInitialValues,
  handleSubmitConclusions,
  handleTakeResearch,
  isCreated,
}) {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(1);
  const isFundusPhotography = conclusionFormInitialValues.serviceName === RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY;
  const isPatient = useIsPatient();

  function onSwipedLeft() {
    // всего 4 табов если isCreated или 5 табов (индекс начинается с 0)
    if (isCreated) {
      if (tabIndex === 3) return;
    }
    if (tabIndex === 4) return;
    handleChange(null, tabIndex + 1);
  }

  function onSwipedRight() {
    if (tabIndex === 0) return;
    handleChange(null, tabIndex - 1);
  }

  function handleChange(event, index) {
    if (index === 0) {
      return isPatient
        ? history.push("/my-researches")
        : history.push("/researches");
    }
    setTabIndex(index);
  }

  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft,
    onSwipedRight,
  });

  return (
    <div className={classes.root}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        centered
      >
        <Tab icon={<ArrowBack />} />
        <Tab icon={<Info />} />
        {isFundusPhotography && <Tab label="OD" />}
        {isFundusPhotography && <Tab label="OS" />}
        {!isCreated && isFundusPhotography && <Tab icon={<EventNote />} />}
      </Tabs>
      <div className={classes.handlerWrapper}>
        <div {...handlers} style={handlerStyle(tabIndex)}>
          <PatientForm
            mobileFormStyle={containerStyle(isCreated ? 4 : 2)}
            isDetails
            t={t}
            initialValues={patientFormInitialValues}
            handleSubmit={handleSubmitPatientForm}
            handleTakeResearch={handleTakeResearch}
          />
          {!isCreated && isFundusPhotography && (
            <ConclusionsForm
              mobileFormStyle={containerStyle(3)}
              t={t}
              initialValues={conclusionFormInitialValues}
              handleSubmit={handleSubmitConclusions}
            />
          )}
        </div>
      </div>
      <DownloadFeportForNurseAndPatientMobileTeleporter.Target />
    </div>
  );
}

export default memo(MobileConclusionForm);
