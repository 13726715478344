export const NOTIFICATION_TYPES = {
  RESEARCH_CREATED: "RESEARCH_CREATED",
  CONCILIUM_CREATED: "CONCILIUM_CREATED",
  CONCILIUM_ACCEPTED: "CONCILIUM_ACCEPTED",
  CONCILIUM_REJECTED: "CONCILIUM_REJECTED",
  CONCILIUM_PRECONCLUSION_GIVEN: "CONCILIUM_PRECONCLUSION_GIVEN",
  PRECONCLUSION_CREATED: "PRECONCLUSION_CREATED",
  PATIENT_CARD_ACCESS_ASKED: 'PATIENT_CARD_ACCESS_ASKED',
  PATIENT_CARD_ACCESS_REJECTED: 'PATIENT_CARD_ACCESS_REJECTED',
  PATIENT_CARD_ACCESS_GIVEN: 'PATIENT_CARD_ACCESS_GIVEN'
};
