import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { HeaderLeftTeleporter } from "../../../components/Header";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useRouterQuery } from "../../../hooks/useRouterQuery";
import { formatDateAndTime } from "../../../utils";
import { useQuery } from "react-query";
import { getMyResearches } from "../../../api";
import { MyResearchJournalTableActions } from "../MyResearchJournalTableActions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 10px",
  },
  periodFrom: {
    marginRight: "24px",
  },
  tableTitle: {
    backgroundColor: theme.palette.grey[100],
    top: "64px",
    fontWeight: "500",
    fontSize: "14px",
  },
  table: {
    marginTop: "20px",
  },
}));
export function MyResearchesPageDesktop() {
  const classes = useStyles();
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const { t } = useTranslation(["research", "serviceNamesDict"]);
  const [query, handleReplace] = useRouterQuery();
  const history = useHistory();
  const page = query.get("page") || 0;
  const limit = query.get("limit") || 20;
  const [order, serOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("appointmentTimestamp");

  const myResearchesQuery = {
    limit: limit,
    page: page,
    order: order,
    orderBy: orderBy,
  };

  const { data: myResearchesData, isLoading, error } = useQuery(
    ["MyResearchesData", { page, limit, order, orderBy }],
    () => getMyResearches(myResearchesQuery)
  );

  const handleChangePage = (event, newPage) => {
    query.set("page", newPage);
    history.push({ search: query.toString() });
  };

  const handleChangeRowsPerPage = (event) => {
    query.set("page", 0);
    query.set("limit", +event.target.value);
    history.push({ search: query.toString() });
  };
  function handleRequestSort(sortColumnName) {
    setOrderBy(sortColumnName);
    order === "desc" ? serOrder("asc") : serOrder("desc");
  }

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h5">{t("MY_RESEARCHES")}</Typography>
        <Box height={"12px"} />

        <Table stickyHeader size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.tableTitle}>
                <TableSortLabel
                  direction={order}
                  active={myResearchesData?.orderBy === "id"}
                  onClick={() => handleRequestSort("id")}
                >
                  №
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" className={classes.tableTitle}>
                {t("RECEPTION_DOCTOR_FULL_NAME")}
              </TableCell>

              <TableCell align="center" className={classes.tableTitle}>
                <TableSortLabel
                  direction={order}
                  active={myResearchesData?.orderBy === "appointmentTimestamp"}
                  onClick={() => handleRequestSort("appointmentTimestamp")}
                >
                  {t("RECEPTION_TIME")}
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" className={classes.tableTitle}>
                {t("SERVICE")}
              </TableCell>
              <TableCell align="center" className={classes.tableTitle}>
                {t("STATUS")}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableTitle}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <CircularProgress />
            ) : (
              myResearchesData?.result?.researches.map((rowData) => {
                return (
                  <TableRow key={rowData.id} className={classes.hideLastBorder}>
                    <TableCell align="center">{rowData.id}</TableCell>
                    <TableCell align="center">
                      {rowData.receptionDoctorFullName}
                    </TableCell>
                    <TableCell align="center">
                      {formatDateAndTime(rowData.appointmentTimestamp)}
                    </TableCell>
                    <TableCell align="center">
                      {t(`serviceNamesDict:${rowData.serviceName}`)}
                    </TableCell>
                    <TableCell align="center">
                      {t(`STATUS_${rowData.status}`)}
                    </TableCell>
                    <TableCell align="center">
                      {
                        <Grid>
                          <MyResearchJournalTableActions row={rowData} />
                        </Grid>
                      }
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={myResearchesData?.result?.count}
          rowsPerPage={+limit}
          labelRowsPerPage={`${t("ROWS_PER_PAGE")}:`}
          page={+page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
