import React from "react";
import PatientInfo from "./PatientInfo";
import history from "../../../config/history";
import { useTranslation } from "react-i18next";
import { ArrowBack, Info } from "@material-ui/icons";
import ConclusionsDetails from "./ConclusionsDetails";
import { Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import {
  ANNOTATION_STATUS,
  RESEARCH_STATUS,
} from "../../ResearchesPage/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
  },
  handlerWrapper: {
    overflow: "hidden",
  },
  tabStyle: {
    position: "sticky",
    top: "50px",
    background: "#fff",
    zIndex: 1,
    padding: 0,
  },
}));

function AnnotatotDetailsMobile(props) {
  const { handleSubmitConclusions, patientInfo, conclusionInfo } = props;
  const { t } = useTranslation("research");

  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(2);

  function handleChange(event, index) {
    if (index === 0) {
      return history.push("/annotator");
    }
    setTabIndex(index);
  }
  return (
    <>
      <div className={classes.root}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={classes.tabStyle}
          centered
        >
          <Tab icon={<ArrowBack />} />
          <Tab icon={<Info />} />
          <Tab label={t("DETAILS")} />
        </Tabs>
        <Grid className={classes.handlerWrapper}>
          {tabIndex === 1 && <PatientInfo patientInfo={patientInfo} t={t} />}

          {tabIndex === 2 && (
            <ConclusionsDetails
              t={t}
              editMode={patientInfo.status === ANNOTATION_STATUS.NOT_ANNOTATED}
              initialValues={conclusionInfo}
              handleSubmitConclusions={handleSubmitConclusions}
              patientInfo={patientInfo}
              conclusionInfo={conclusionInfo}
            />
          )}
        </Grid>
      </div>
    </>
  );
}

export default AnnotatotDetailsMobile;
