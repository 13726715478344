import React, { createRef, useEffect, memo } from "react";
import PinchZoomp from "pinch-zoom-js";

function PinchZoom(props) {
  const imgRef = createRef();
  useEffect(() => {
    new PinchZoomp(imgRef.current, {
      draggableUnzoomed: false,
      ...props.pzOptions
    });
  }, [imgRef, props.pzOptions]);
  return (
    <div className="h-100">
      <img ref={imgRef} alt="placeholder" {...props} />
    </div>
  );
}

export default memo(PinchZoom);
