import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useRouterQuery } from "../../hooks/useRouterQuery";
import { CalendarToday, MoreHoriz } from "@material-ui/icons";
import { DPicker } from "../../components/FormComponents";
import { formatDateAndTime } from "../../utils";
import { useQuery } from "react-query";
import { getAnnotations } from "../../api/annotationApi";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 10px",
  },
  periodFrom: {
    marginRight: "24px",
  },
  tableTitle: {
    backgroundColor: theme.palette.grey[100],
    top: "64px",
    fontWeight: "500",
    fontSize: "14px",
  },
  table: {
    marginTop: "20px",
  },
}));
function AnnotatorDesctopPage() {
  const classes = useStyles();
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const { t } = useTranslation(["annotator", "serviceNamesDict", "research"]);
  const [query, handleReplace] = useRouterQuery();
  const history = useHistory();
  const periodStart = query.get("periodStart") || null;
  const periodEnd = query.get("periodEnd") || null;
  const page = query.get("page") || 0;
  const limit = query.get("limit") || 20;
  const [togglebutton, setToggleButton] = useState("ALL");
  const [order, serOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("appointmentTimestamp");

  const annotationQuery = {
    limit: limit,
    periodStart: periodStart,
    periodEnd: periodEnd,
    page: page,
    status: togglebutton,
    order: order,
    orderBy: orderBy,
  };

  const { data: annotationData, isLoading, error } = useQuery(
    [
      "annotationData",
      { page, limit, periodEnd, periodStart, togglebutton, order, orderBy },
    ],
    () => getAnnotations(annotationQuery)
  );

  const handleToggleButton = (event, newAlignment) => {
    if (newAlignment !== null) {
      setToggleButton(newAlignment);
    }
  };
  const handleChangePage = (event, newPage) => {
    query.set("page", newPage);
    history.push({ search: query.toString() });
  };

  const handleChangeRowsPerPage = (event) => {
    query.set("page", 0);
    query.set("limit", +event.target.value);
    history.push({ search: query.toString() });
  };
  function handleRequestSort(sortColumnName) {
    setOrderBy(sortColumnName);
    order === "desc" ? serOrder("asc") : serOrder("desc");
  }

  return (
    <>
      <Paper className={classes.root}>
        <Typography variant="h5">{t("research:ATTENDANCE_JOURNAL")}</Typography>
        <Box height={"12px"} />
        <Grid container justifyContent="space-between">
          <ToggleButtonGroup
            value={togglebutton}
            onChange={handleToggleButton}
            exclusive
            size="small"
            className={classes.buttonGroup}
          >
            <ToggleButton value="ALL">{t("ALL")}</ToggleButton>
            <ToggleButton value="ANNOTATED">{t("ANNOTATED")}</ToggleButton>
            <ToggleButton value="NOT_ANNOTATED">
              {t("NOT_ANNOTATED")}
            </ToggleButton>
          </ToggleButtonGroup>
          <Grid>
            <DPicker
              className={classes.periodFrom}
              placeholder={t("PERIOD_FROM")}
              value={periodStart}
              okLabel={t("OK")}
              clearLabel={t("CLEAR")}
              cancelLabel={t("CANCEL_LABEL")}
              iconProps={<CalendarToday />}
              onChange={(date) =>
                handleReplace("periodStart", date?.toISOString())
              }
            />
            <DPicker
              placeholder={t("PERIOD_TO")}
              okLabel={t("OK")}
              clearLabel={t("CLEAR")}
              cancelLabel={t("CANCEL_LABEL")}
              value={periodEnd}
              onChange={(date) =>
                handleReplace("periodEnd", date?.toISOString())
              }
            />
          </Grid>
        </Grid>
        <Box height={"12px"} />

        <Table stickyHeader size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.tableTitle}>
                <TableSortLabel
                  direction={order}
                  active={annotationData?.orderBy === "id"}
                  onClick={() => handleRequestSort("id")}
                >
                  №
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" className={classes.tableTitle}>
                <TableSortLabel
                  direction={order}
                  active={annotationData?.orderBy === "appointmentTimestamp"}
                  onClick={() => handleRequestSort("appointmentTimestamp")}
                >
                  {t("DATE_AND_TIME")}
                </TableSortLabel>
              </TableCell>
              <TableCell align="center" className={classes.tableTitle}>
                {t("SERVICE")}
              </TableCell>
              <TableCell align="center" className={classes.tableTitle}>
                {t("STATUS")}
              </TableCell>
              <TableCell
                align="center"
                className={classes.tableTitle}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <CircularProgress />
            ) : (
              annotationData?.researches.map((rowData) => {
                return (
                  <TableRow key={rowData.id} className={classes.hideLastBorder}>
                    <TableCell align="center">{rowData.id}</TableCell>
                    <TableCell align="center">
                      {formatDateAndTime(rowData.appointmentTimestamp)}
                    </TableCell>
                    <TableCell align="center">
                      {t(`serviceNamesDict:${rowData.serviceName}`)}
                    </TableCell>
                    <TableCell align="center">{t(rowData.status)}</TableCell>
                    <TableCell align="center">
                      {
                        <Grid>
                          <Button
                            variant="contained"
                            className="mr-10"
                            color="primary"
                            size="small"
                            title={t("DETAILS")}
                            component={Link}
                            to={`/annotatorDetails/${rowData.id}`}
                          >
                            <MoreHoriz />
                          </Button>
                        </Grid>
                      }
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={annotationData?.count}
          rowsPerPage={+limit}
          labelRowsPerPage={`${t("ROWS_PER_PAGE")}:`}
          page={+page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}

export default AnnotatorDesctopPage;
