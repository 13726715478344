import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px",
    width: "350px",
    borderRadius: "5px",
    position: "relative",
    border: "1px solid #e0e0e0",
  },
  element: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    cursor: "pointer",
    padding: "10px",
    borderRadius: "5px",
  },
  chosen: {
    background: "#e0e0e0",
  },
}));

const JournalReestrPicker = ({ chosen }) => {
  const classes = useStyles();
  const { t } = useTranslation("research");
  return (
    <Grid container direction={"row"} className={classes.root}>
      <Box
        component={Link}
        to="/researches"
        className={`${classes.element} ${chosen === "researches" &&
          classes.chosen}`}
      >
        {t("ATTENDANCE_JOURNAL")}
      </Box>
      <Box
        component={Link}
        to="/patients"
        className={`${classes.element} ${chosen === "patients" &&
          classes.chosen}`}
      >
        {t("PATIENTS_REGISTRY")}
      </Box>
    </Grid>
  );
};

export default JournalReestrPicker;
