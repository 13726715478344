import React from "react";
import { Grid, makeStyles, Step, StepLabel, Stepper } from "@material-ui/core";
import EyeContainer from "../PatientForm/EyeContainer";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  form: {
    height: "100%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.grey[200],
  },
  stepper: {
    padding: theme.spacing(1),
    width: "100%",
    maxWidth: "600px",
  },
}));

function FormModal({ image, children, activeStep }) {
  const classes = useStyles();
  const { t } = useTranslation("research");
  return (
    <Grid container className={classes.root}>
      <Grid item sm={6} xs={12}>
        <EyeContainer value={image} />
      </Grid>
      <Grid
        container
        item
        sm={6}
        xs={12}
        justify="center"
        className={classes.form}
      >
        <Stepper className={classes.stepper} activeStep={activeStep}>
          <Step>
            <StepLabel>{t("CONCLUSION")}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t("DESCRIPTION")}</StepLabel>
          </Step>
        </Stepper>
        {children}
      </Grid>
    </Grid>
  );
}

export default FormModal;
