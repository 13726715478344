import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CircularProgress,
  useMediaQuery,
} from "@material-ui/core";
import { AttachFile, Clear } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { UserContext } from "../../globalContexts/UserContext";
import {
  addDocumentOphthalmologist,
  createOphthalmologist,
  updatePatientData,
} from "../../api";
import { useMutation, useQuery } from "react-query";
import {
  createToast,
  MessagesContext,
} from "../../globalContexts/MessagesContext";
import history from "../../config/history";
import {
  deleteOphthalmologistDocument,
  getUserDataByUserName,
} from "../../api/registrationApi";
import { useTranslation } from "react-i18next";
import { MobileMaskInput } from "../../components/FormComponents";
import { queryClient } from "../../globalContexts/queryContext";
import { Link } from "react-router-dom";
import { HeaderLeftTeleporter } from "../../components/Header";
import CostomInput, { CostomPhoneInput } from "../../components/CostomInput";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: " 20px 30px",
    maxWidth: "1200px",
    flexGrow: 1,
  },
  title: {
    fontSize: "20px",
    fontWeight: "500",
    paddingBottom: "20px",
  },
  form: {
    padding: "0 50px",
  },
  docContainer: {
    display: "flex",
    justifyContent: "center",
  },
  fileNames: {
    alignItems: "center",
  },
  underDocLine: {
    marginTop: "5px",
    with: "200px",
    borderBottom: "1px solid #ccc",
  },
  docText: {
    fontSize: "11px",
  },
  error: {
    margin: "0",
    color: "red",
    fontSize: "11px",
  },
  footer: {
    textAlign: "center",
  },
  notificationText: {
    color: "red",
    fontSize: "11px",
  },
  commentText: {
    color: "green",
    fontSize: "14px",
  },
  boxConteiner: {
    display: "flex",
    marginTop: "24px",
  },
  boxInTypography: {
    width: "200px",
    "@media (max-width: 600px)": { display: "none" },
  },
}));

export function FormRegistraton() {
  const sixHundred = useMediaQuery("(min-width:600px)");
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const { t } = useTranslation("registrationPage");
  const dispatchMessage = useContext(MessagesContext)[1];
  const [files, setFiles] = useState([]);
  const [typeUser, setTypeUser] = useState("");
  const [frontFile, setFrontFile] = useState([]);
  const [frontFileError, setFrontFileError] = useState();

  const { mutate: updatePatientDataMutate } = useMutation(
    async ({ patientData }) => {
      await updatePatientData({ patientData });
    },
    {
      onSuccess: () => {
        dispatchMessage(createToast(t("SUCCESS"), "success"));
        history.push("/researches");
        window.location.reload();
      },
    }
  );
  const { mutate: ophthalmologistMutate } = useMutation(
    async ({ ophthalmologistData }) => {
      await createOphthalmologist({ ophthalmologistData });
    },
    {
      onSuccess: () => {
        setFrontFile();
        dispatchMessage(
          createToast(t("CREATE_OPHTHALMOLOGIST") + user.email, "success")
        );
        queryClient.invalidateQueries([
          "getUserDataForRegistration",
          user.name,
        ]);
      },
    }
  );

  const { register, handleSubmit, errors, watch, setValue } = useForm();

  const docFiles = watch("docFiles");
  const {
    data: ophthalmologistData,
    isLoading: ophthalmologistLoading,
    isError: ophthalmologistError,
  } = useQuery(["getUserDataForRegistration", user.name], () =>
    getUserDataByUserName({ userName: user.name })
  );
  const { mutate: deleteDocument } = useMutation(
    async ({ id }) => {
      await deleteOphthalmologistDocument({ id });
    },
    {
      onSuccess: () => {
        setFrontFile();
        queryClient.invalidateQueries([
          "getUserDataForRegistration",
          user.name,
        ]);
      },
    }
  );

  const { mutate: addFileOphthalmologist } = useMutation(
    async ({ files, id }) => {
      await addDocumentOphthalmologist({ files, id });
    },
    {
      onSuccess: () => {
        setFrontFile();
        queryClient.invalidateQueries([
          "getUserDataForRegistration",
          user.name,
        ]);
      },
    }
  );

  useEffect(() => {
    const ophthalmologistFiles =
      ophthalmologistData?.documents?.map((file) => {
        return { id: file.id, name: file.file_name };
      }) || [];
    setFiles([...(docFiles || []), ...ophthalmologistFiles]);
  }, [ophthalmologistData?.documents, docFiles]);

  async function onSubmit(data) {
    if (typeUser === "PATIENT") {
      console.log("PATIENT");
      const patientData = {
        ...data,
        role: typeUser,
      };
      updatePatientDataMutate({ patientData: patientData });
    } else if (ophthalmologistData.userId) {
      if (frontFile[0]) {
        const files = {
          files: frontFile,
        };
        addFileOphthalmologist({
          files: files,
          id: ophthalmologistData.userId,
        });
      } else setFrontFileError("error");
    } else {
      if (frontFile[0]) {
        const ophthalmologistData = {
          ...data,
          id: user.id,
          files: frontFile,
        };
        ophthalmologistMutate({ ophthalmologistData: ophthalmologistData });
      } else setFrontFileError("error");
    }
  }

  function handleDeleteFile(file, idx) {
    if (file.id) deleteDocument({ id: file.id });
    setFiles(files.filter((_, fileIdx) => idx !== fileIdx));
  }
  return (
    <>
      <HeaderLeftTeleporter.Source>
        <Typography
          variant={isMd ? "body1" : "h6"}
          color="inherit"
          component={Link}
          to="/"
        >
          {isSm ? t("SYSTEM_UDDR_SHORTENED") : t("SYSTEM_UDDR")}
        </Typography>
      </HeaderLeftTeleporter.Source>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Paper className={classes.root}>
          <Typography className={classes.title}>
            {t("USER_INFORMATION")}
          </Typography>

          {ophthalmologistLoading ? (
            <CircularProgress />
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              id="userData"
              className={classes.form}
            >
              <CostomInput
                readOnly
                label={t("IIN")}
                value={ophthalmologistData.username}
                inputRef={register({ required: true })}
                name={"iinUser"}
                error={Boolean(errors.iinUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <CostomInput
                label={t("SURNAME")}
                value={ophthalmologistData.lastName}
                inputRef={register({ required: true })}
                name={"surnameUser"}
                error={Boolean(errors.surnameUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <CostomInput
                label={t("NAME")}
                value={ophthalmologistData.firstName}
                inputRef={register({ required: true })}
                name={"nameUser"}
                error={Boolean(errors.nameUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <CostomInput
                label={t("MIDDLE_NAME")}
                value={""}
                inputRef={register()}
                name={"middleNameUser"}
                error={Boolean(errors.middleNameUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <CostomPhoneInput
                label={t("PHONE")}
                value={ophthalmologistData?.attributes?.phone}
                inputRef={register({ required: true })}
                name={"phoneUser"}
                error={Boolean(errors.phoneUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <CostomInput
                label={t("EMAIL")}
                value={ophthalmologistData?.email}
                inputRef={register({
                  required: true,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
                name={"emailUser"}
                error={Boolean(errors.emailUser)}
                errorMessage={t("REQUIRED_FIELD")}
              />
              <RadioGroup
              style={{marginTop:"15px"}}
                value={ophthalmologistData.documents && "DOCTOR"}
                onChange={(e) => setTypeUser(e.target.value)}
              >
                <Grid container justifyContent="space-around">
                  <FormControlLabel
                    value="PATIENT"
                    disabled={!!ophthalmologistData?.documents}
                    control={<Radio color="primary" />}
                    label={t("PATIENT")}
                  />
                  <FormControlLabel
                    value="DOCTOR"
                    disabled={!!ophthalmologistData?.documents}
                    control={<Radio color="primary" />}
                    label={t("OPHTHALMOLOGIST")}
                  />
                </Grid>
              </RadioGroup>

              {typeUser === "" && (
                <p className={classes.error}>{t("REQUIRED_FIELD")}</p>
              )}
              <Grid className={classes.footer}>
                <Box height={"24px"} />
                {(ophthalmologistData.documents || typeUser === "DOCTOR") && (
                  <Grid container className={classes.docContainer}>
                    <Grid item>
                      {files.map((file, idx) => {
                        return (
                          <Grid
                            key={idx}
                            container
                            className={classes.fileNames}
                          >
                            <IconButton
                              size="small"
                              onClick={() => handleDeleteFile(idx)}
                            >
                              <Clear />
                            </IconButton>
                            <Grid item className={classes.documentName}>
                              {file.name}
                            </Grid>
                          </Grid>
                        );
                      })}
                      {frontFile &&
                        frontFile.map((file, idx) => {
                          return (
                            <div key={idx} style={{ display: "flex" }}>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  setFrontFile(
                                    frontFile.filter(
                                      (_, fileIdx) => idx !== fileIdx
                                    )
                                  )
                                }
                              >
                                <Clear />
                              </IconButton>
                              <div>{file?.name}</div>
                            </div>
                          );
                        })}

                      <div className={classes.underDocLine} />
                      <p className={classes.docText}>
                        {t("ATTACH_REQUIRED_DOCUMENTS")}*
                      </p>
                      {frontFileError && (
                        <p className={classes.error}>{t("REQUIRED_FIELD")}</p>
                      )}
                    </Grid>
                    <IconButton component="label" htmlFor="fileName">
                      <AttachFile />
                    </IconButton>
                    <input
                      type="file"
                      id="fileName"
                      onChange={(e) => {
                        setFrontFileError();
                        setFrontFile([
                          ...frontFile,
                          ...Object.values(e.target.files),
                        ]);
                      }}
                      name="docFiles"
                      style={{ display: "none" }}
                      multiple
                      readOnly={!!ophthalmologistData?.documents}
                    />
                  </Grid>
                )}
                <Box height={"48px"} />
                <Button
                  style={{ maxWidth: "300px" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                  form="userData"
                  fullWidth
                >
                  {t("CONTINUE_REGISTRATION")}
                </Button>
                <Box height={"48px"} />
                {!!ophthalmologistData?.documents && (
                  <p className={classes.notificationText}>
                    {t("CREATE_OPHTHALMOLOGIST") + ophthalmologistData.email}
                  </p>
                )}
              </Grid>
            </form>
          )}
        </Paper>
      </div>
    </>
  );
}
