import React, { useContext, useState } from "react";
import { GetApp, Send } from "@material-ui/icons";
import { makeStyles, Button, Menu, MenuItem } from "@material-ui/core";
import Roled from "../../../components/Roled";
import { ROLES } from "../../../config/constants";
import AsyncButton from "../../../components/AsyncButton";
import { RESEARCH_STATUS } from "../constants";
import {
  fetchReport,
  getImagesPdf,
} from "../ResearchesList/ResearchJournalTable";
import { MessagesContext } from "../../../globalContexts/MessagesContext";
import { NavBarTeleporter } from "../ResearchDetails/Breadcrumbs";

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  menu: {
    marginTop: "45px",
  },
  icon: {
    marginLeft: "5px",
  },
}));

function Buttons({ status, id, t, editMode }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatchMessage = useContext(MessagesContext)[1];

  // const { user } = useContext(UserContext);
  const isFinished = status === RESEARCH_STATUS.FINISHED;
  return (
    <>
      {editMode && (
        <Roled roles={[ROLES.DOCTOR]}>
          <div className={classes.buttonsWrapper}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              type="submit"
              form="conclusionsForm"
            >
              {t("SAVE_AND_SEND")} <Send className={classes.icon} />
            </Button>
          </div>
        </Roled>
      )}
      <NavBarTeleporter.Source>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          data-researchid={id}
          title={t("CONCLUSION")}
          onClick={handleClick}
        >
          <GetApp />
        </Button>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.menu}
        >
          <MenuItem
            component={AsyncButton}
            className="text-transform-none"
            onClick={() => {
              return getImagesPdf(id, dispatchMessage, t).finally(handleClose);
            }}
          >
            Снимки
          </MenuItem>
          {isFinished && !editMode && (
            <div className={classes.buttonsWrapper}>
              <MenuItem
                component={AsyncButton}
                className="text-transform-none"
                onClick={() => {
                  return fetchReport(id, dispatchMessage, t).finally(
                    handleClose
                  );
                }}
              >
                {t('CONCLUSION')}
              </MenuItem>
            </div>
          )}
        </Menu>
      </NavBarTeleporter.Source>
    </>
  );
}
export default Buttons;
