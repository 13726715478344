import React, { useContext, useState, useEffect } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Divider,
  RadioGroup,
  FormGroup,
  Radio,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  FormHelperText,
  TextField,
  Chip,
  Typography,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Field, Form, FastField, ErrorMessage } from "formik";
import { conclusionConfig } from "../formConfigs";
import { getNested } from "../../../utils";
import RenderCurrentLang from "../../../components/RenderCurrentLang";
import without from "lodash/without";
import concat from "lodash/concat";
import { renderTextField } from "../../../components/FormComponents";
import { DictionariesContext } from "../../../globalContexts/DictionariesContext";
import { useDialogFormStyles } from "./styles";
import { getImpossibleText } from "../../../api";

const useFieldStyles = makeStyles(() => ({
  fieldLabel: {
    justifyContent: "space-between",
    marginRight: "16px",
  },
  listItem: {
    paddingLeft: 5,
  },
  clearButton: {
    position: "absolute",
    right: "-26px",
    top: "6px",
  },
  textImpossible: {
    width: "100%",
    marginTop: "20px",
  },
  chip: {
    height: "100%",
  },
  chipLabel: {
    whiteSpace: "normal",
  },
}));

function RenderImpossibleAutocomplete({ field, form, ...rest }) {
  const classes = useFieldStyles();
  const [data, setData] = useState([]);
  useEffect(() => {
    getImpossibleText()
      .then((res) => setData(res.value))
      .catch(console.error);
  }, []);
  const filter = createFilterOptions();
  const impossibleValue = field.value ? field.value.split(", ") : [];

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      options={data}
      value={impossibleValue.map((value) => value)}
      onChange={(_e, value) => {
        const strValue = value.join(", ");
        form.setFieldValue(field.name, strValue, true);
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "") {
          filtered.push(`${params.inputValue}`);
        }

        return filtered;
      }}
      renderOption={(option) => option}
      renderInput={(params) => (
        <TextField {...params} {...rest} variant="outlined" />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            label={
              <Typography className={classes.chipLabel}>{option}</Typography>
            }
            {...getTagProps({ index })}
            title={value}
            className={classes.chip}
          />
        ))
      }
    />
  );
}

function RenderBooleanCollapse({ error, isOpen, children }) {
  return (
    <Collapse in={isOpen}>
      {error && <FormHelperText error>{error}</FormHelperText>}
      {children}
    </Collapse>
  );
}

function RenderComponent({
  t,
  field,
  conclusionName,
  form: { setFieldValue, values, errors },
  firstLevelField,
  config,
}) {
  function handleClick() {
    if (field.value) {
      setFieldValue(field.name, null);
    } else {
      setFieldValue(field.name, true);
    }
  }
  const fieldConfig = config[firstLevelField.codename];
  const classes = useFieldStyles();

  let isDisabled = false;
  if (fieldConfig.if) {
    isDisabled = fieldConfig.if.some((condition) => {
      // we need to test condition of other field's value(otherValue)
      // if test matches the case we need to disable fields, which item.codename matches cannotBe regexp;
      const valueRegex = new RegExp(condition.value);
      const cannotBeRegex = new RegExp(condition.cannotBe);
      // otherValue can be an array or a string
      const otherValue = getNested(
        [conclusionName, "conclusionResult", condition.fieldCodename],
        values
      );
      if (
        otherValue &&
        (otherValue.constructor === String ||
          otherValue.constructor === Boolean)
      ) {
        return (
          valueRegex.test(otherValue) &&
          cannotBeRegex.test(firstLevelField.codename)
        );
      }
      if (otherValue && otherValue.constructor === Array) {
        return otherValue.some(
          (innerVal) =>
            valueRegex.test(innerVal) &&
            cannotBeRegex.test(firstLevelField.codename)
        );
      }
      return false;
    });
  }

  if (fieldConfig.type === "boolean")
    return (
      <>
        <ListItem
          className={classes.listItem}
          onClick={handleClick}
          button
          disabled={isDisabled}
        >
          <ListItemText
            primary={<RenderCurrentLang textObj={firstLevelField.name} />}
            primaryTypographyProps={{ variant: "h6" }}
          />
          <ListItemSecondaryAction>
            <Checkbox
              {...field}
              onChange={handleClick}
              checked={Boolean(field.value)}
              disabled={isDisabled}
            />
          </ListItemSecondaryAction>
        </ListItem>
        {firstLevelField.codename === "OTHER" && (
          <RenderBooleanCollapse
            error={t(errors[conclusionName]?.conclusionResult?.textOther)}
            isOpen={Boolean(field.value)}
          >
            <Field
              name={`${conclusionName}.conclusionResult.textOther`}
              component={renderTextField}
              className="mt-20"
              multiline
              rows="5"
              fullWidth
              variant="outlined"
              label={t("OTHER_TEXT")}
            />
          </RenderBooleanCollapse>
        )}
        {firstLevelField.codename === "IMPOSSIBLE" && (
          <RenderBooleanCollapse
            error={t(errors[conclusionName]?.conclusionResult?.textImpossible)}
            isOpen={Boolean(field.value)}
          >
            <Field
              name={`${conclusionName}.conclusionResult.textImpossible`}
              component={RenderImpossibleAutocomplete}
              className={classes.textImpossible}
              multiline
              fullWidth
              variant="outlined"
              label={t("IMPOSSIBLE_TEXT")}
            />
          </RenderBooleanCollapse>
        )}
      </>
    );
  return (
    <>
      <ListItem
        className={classes.listItem}
        onClick={handleClick}
        button
        disabled={isDisabled}
      >
        <ListItemText
          primary={<RenderCurrentLang textObj={firstLevelField.name} />}
          primaryTypographyProps={{ variant: "h6" }}
        />
        <ListItemSecondaryAction>
          <Checkbox
            {...field}
            onChange={handleClick}
            checked={Boolean(field.value)}
            disabled={isDisabled}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <ErrorMessage name={field.name} error
                    render={(errorMessage => <FormHelperText error>{t(errorMessage)}</FormHelperText>)} />
      <Collapse in={Boolean(field.value)}>
        {fieldConfig.type === "object" ? (
          <RadioGroup>
            {firstLevelField.dictionary.map((item) => {
              return (
                <FormControlLabel
                  key={item.codename}
                  {...field}
                  value={item.codename}
                  disabled={isDisabled}
                  labelPlacement="start"
                  checked={field.value === item.codename}
                  className={classes.fieldLabel}
                  label={<RenderCurrentLang textObj={item.name} />}
                  control={<Radio />}
                />
              );
            })}
          </RadioGroup>
        ) : (
          <FormGroup>
            {firstLevelField.dictionary.map((item) => {
              const isChecked =
                !!field.value && field.value.includes(item.codename);
              return (
                <FormControlLabel
                  key={item.codename}
                  {...field}
                  value={item.codename}
                  onChange={({ target: { checked } }) => {
                    const prevValue =
                      getNested(field.name.split("."), values) || [];
                    const operation = checked ? concat : without;
                    setFieldValue(
                      field.name,
                      operation(prevValue, item.codename)
                    );
                  }}
                  labelPlacement="start"
                  checked={isChecked}
                  className={classes.fieldLabel}
                  label={<RenderCurrentLang textObj={item.name} />}
                  control={<Checkbox />}
                />
              );
            })}
          </FormGroup>
        )}
      </Collapse>
    </>
  );
}

export function ConclusionResultFormHeader(props) {
  const { children } = props;
  const classes = useDialogFormStyles();
  return <div className={classes.header}>{children}</div>;
}

export function ConclusionResultFormBody(props) {
  const { conclusionName, t } = props;
  const classes = useDialogFormStyles();
  const { structuredConclusionResults } = useContext(DictionariesContext)[0];
  return (
    <List dense className={classes.content}>
      {structuredConclusionResults.map((conclusionField) => (
        <Field
          key={conclusionField.codename}
          name={`${conclusionName}.conclusionResult.${conclusionField.codename}`}
          render={(props) => (
            <RenderComponent
              firstLevelField={conclusionField}
              config={conclusionConfig}
              conclusionName={conclusionName}
              t={t}
              {...props}
            />
          )}
        />
      ))}
    </List>
  );
}

export default function ConclusionResultForm({ children }) {
  const classes = useDialogFormStyles();
  return <Form className={classes.root}>{children}</Form>;
}
