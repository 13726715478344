import { useHistory } from "react-router-dom";
export function useRouterQuery() {
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);

  const handleChange = (name, value, pathname, method = "replace") => {
    if (query.get(name) === value) return;
    if (value) {
      query.set(name, value);
    } else {
      query.delete(name);
    }
    if (pathname) {
      history[method]({ search: query.toString(), pathname });
    } else {
      history[method]({ search: query.toString() });
    }
  };
  return [query, handleChange];
}
