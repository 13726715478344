import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light
  },
  cell: ({ isBordered, isSticky }) => ({
    padding: "14px",
    color: "#000",
    position: isSticky ? "sticky" : "unset",
    top: isSticky ? 64 : "unset",
    backgroundColor: isSticky ? theme.palette.grey[100] : "unset",
    boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 1px 0px",
    zIndex: 10,
    textAlign: isBordered ? "center" : "unset",
    borderLeft: isBordered ? `1px solid ${theme.palette.primary.main}` : "none"
  })
}));

function TableHeadComponent(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    isIndeterminated,
    onRequestSort,
    isSticky = true,
    isBordered,
    columns,
    sortableFields,
    isAllSelected,
    tableHeadProps,
    isSelectable,
    allowMiltiSelect,
    tableHeadRowProps
  } = props;

  const classes = useStyles({ isBordered, isSticky });
  const createSortHandler = field => () => {
    onRequestSort(field);
  };

  return (
    <TableHead className={classes.root} {...tableHeadProps}>
      <TableRow {...tableHeadRowProps}>
        {isSelectable && (
          <TableCell padding="checkbox">
            {allowMiltiSelect && (
              <Checkbox
                indeterminate={isIndeterminated}
                checked={isAllSelected}
                onChange={onSelectAllClick}
              />
            )}
          </TableCell>
        )}
        {columns.map(col => {
          const isSortable =
            sortableFields && sortableFields.includes(col.field);
          return (
            <TableCell
              key={col.field}
              className={classes.cell}
              variant="head"
              sortDirection={
                isSortable && orderBy && orderBy === col.field ? order : false
              }
              {...col.thProps}
            >
              {col.field && isSortable ? (
                <TableSortLabel
                  active={orderBy === col.field}
                  direction={order || undefined}
                  onClick={createSortHandler(col.field)}
                >
                  {col.title}
                </TableSortLabel>
              ) : (
                col.title
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default TableHeadComponent;
