import {Box, Button, Grid, makeStyles, TextField} from "@material-ui/core";
import {Controller, useForm} from "react-hook-form";
import React, {useContext, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {createToast, MessagesContext} from "../../../../../globalContexts/MessagesContext";
import {
    createExophthalmometry,
    createNonContactTonometry,
    editNonContactTonometry
} from "../../../../../api/patientRegistry";
import {useParams} from "react-router-dom";
import {queryClient} from "../../../../../globalContexts/queryContext";
import {simpleServiceSchema} from "./validationSchemas";

const useStyles = makeStyles(() => ({
    table: {
        '& td': {
            padding: '5px'
        }
    },
}))

const NonContactTonometryForm = React.forwardRef(({chosen, onClose, editingItem}, ref) => {
    const initialValues = useMemo(() => ({
        odMm: undefined,
        osMm: undefined
    }), []);
    const {handleSubmit, control, reset, errors } = useForm({
        defaultValues: initialValues,
        reValidateMode: 'onSubmit',
        validationSchema: simpleServiceSchema
    });
    const {id: researchId} = useParams();
    const {t} = useTranslation(['patient-registry', 'serviceNamesDict']);
    const classes = useStyles();
    const dispatchMessage = useContext(MessagesContext)[1];

    useEffect(() => {
        if(chosen) {
            reset(chosen.service);
        }
        if(editingItem) {
            reset(editingItem.service);
        }
        if(!chosen && !editingItem) {
            reset(initialValues);
        }
    }, [chosen, reset, editingItem]);

    const handleFormSubmit = async (value) => {
        try {
            await createNonContactTonometry({...value, researchId});
            onClose();
            dispatchMessage(createToast(t('SERVICE_SUCCESSFULLY_CREATED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return
        } catch(error) {
            console.error(error);
        }
        onClose();
    }

    const handleFormEdit = async(value) => {
        try {
            await editNonContactTonometry(editingItem.service.id, value);
            onClose();
            dispatchMessage(createToast(t('SERVICE_SUCCESSFULLY_EDITED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return
        } catch(error) {
            console.error(error);
        }
        onClose();
    }

    return <form ref={ref} onSubmit={handleSubmit(editingItem ? handleFormEdit : handleFormSubmit)}>
                <span>
                    {t('serviceNamesDict:NON_CONTACT_TONOMETRY')}:
                </span>
        <Grid container justify={'center'}>
            <table className={classes.table}>
                <tbody>
                <tr>
                    <td />
                    <td>mmHg</td>
                    <td />
                    <td>mmHg</td>
                </tr>
                <tr>
                    <td>
                        OD
                    </td>
                    <td>
                        <Controller name={'odMm'} control={control} as={
                            <TextField  error={!!errors['odMm']} inputProps={{className: "arrow-toggle", tabIndex:"1", step: 0.25, readOnly: chosen}} type={'number'}
                                       size={'small'} variant={'outlined'}  />
                        }/>
                    </td>
                    <td>
                        OS
                    </td>
                    <td>
                        <Controller name={'osMm'} control={control} as={
                            <TextField error={!!errors['osMm']} inputProps={{className: "arrow-toggle", tabIndex:"2", step: 0.25, readOnly: chosen}} type={'number'}
                                       size={'small'} variant={'outlined'}/>
                        } />
                    </td>
                </tr>
                </tbody>
            </table>
        </Grid>
    </form>
})


export default NonContactTonometryForm;