import {Grid, makeStyles, Modal, Paper, Typography} from "@material-ui/core";
import {Add, Close, Edit} from "@material-ui/icons";
import React, {useContext, useEffect, useState} from "react";
import CreateFormModal from "./CreateFormModal";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import {deleteDiseaseHistory, getDiseaseHistories} from "../../../../api/patientRegistry";
import RecommendationList from "./RecommendationList";
import ConfirmActionModal from "../../../../components/ConfirmActionModal";
import {queryClient} from "../../../../globalContexts/queryContext";
import {useTranslation} from "react-i18next";
import {createToast, MessagesContext} from "../../../../globalContexts/MessagesContext";
import {deleteRecommendation, setRecommendation} from "../../../../api";
import {ROLES} from "../../../../config/constants";
import Roled from "../../../../components/Roled";
import {RESEARCH_STATUS} from "../../../ResearchesPage/constants";

const useStyles = makeStyles((theme) => ({
    icon: {
        border: '1px solid black',
        color: theme.palette.primary.main
    },
}));

const componentStates = {
    IDLE: 'IDLE',
    CREATING: 'CREATING',
    EDITING: 'EDITING',
    REVIEWING: 'REVIEWING',
    DELETING: 'DELETING'
}

const Recommendation = () => {
    const classes = useStyles();
    const {t} = useTranslation('patient-registry')
    const [state, setState] = useState(componentStates.IDLE);

    const dispatchMessage = useContext(MessagesContext)[1];
    const { id: researchId } = useParams();
    const {data: research} = useQuery(['researchDetails', researchId], {enabled: false});
    // const researchInfo = queryClient.getQueryData(['researchDetails', researchId]);
    const recommendationExists = research?.recommendationCodenames?.length > 0;
    const recommendation = recommendationExists ? {
        codenames: research.recommendationCodenames || [],
        text: research.recommendationText,
        recommendationGivenTime: research.recommendationGivenTime
    } : null;
    const isFinished = research.status === RESEARCH_STATUS.FINISHED;

    const handleCloseModal = () => {
        setState(componentStates.IDLE);
    }

    const handleDeleteRecommendation = async () => {
        try {
            await deleteRecommendation(researchId);
            handleCloseModal()
            dispatchMessage(createToast(t('RECOMMENDATION_SUCCESSFULLY_DELETED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return;
        } catch(error) {
            console.error(error);
        }
        handleCloseModal()
    }

    return  <Grid container direction={'column'}>
        <Grid container>
            {!recommendationExists && !isFinished && <Roled roles={[ROLES.DOCTOR]}><Add className={classes.icon} onClick={() => setState(componentStates.CREATING)} /></Roled>}
        </Grid>
        <RecommendationList isFinished={isFinished}
                            recommendation={recommendation}
                            onChooseRecommendation={() => setState(componentStates.REVIEWING)}
                            onEditRecommendation={() => setState(componentStates.EDITING)}
                            onDeleteRecommendation={() => setState(componentStates.DELETING)}
        />
        <CreateFormModal open={state === componentStates.CREATING || state === componentStates.EDITING || state === componentStates.REVIEWING}
                         editingItem={state === componentStates.EDITING && recommendation}
                         chosen={state === componentStates.REVIEWING && recommendation}
                         onClose={handleCloseModal} />
        <ConfirmActionModal open={state === componentStates.DELETING}
                            label={t('CONFIRM_DELETION')}
                            onClose={handleCloseModal}
                            onConfirm={handleDeleteRecommendation}/>
    </Grid>
}

export default Recommendation;