import React from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  eyeCapturer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  dropzone: ({ disableClick }) => ({
    margin: "10px",
    border: disableClick ? "none" : "1px dashed #777",
    outline: "none",
    cursor: disableClick ? "unset" : "pointer",
    flex: 1,
  }),
}));

function EyeDropzone({ name, onDrop, children, disableClick }) {
  const classes = useStyles({ disableClick });
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*,.dcm",
    multiple: true,
  });

  return (
    <div className={classes.eyeCapturer}>
      <div
        data-testid={`dnd__${name}`}
        className={classes.dropzone}
        {...getRootProps(
          disableClick && { onClick: (e) => e.stopPropagation() }
        )}
      >
        <input {...getInputProps({ name })} id={`dropzone_${name}`} />
        {children}
      </div>
    </div>
  );
}

EyeDropzone.propTypes = {
  name: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  disableClick: PropTypes.bool,
};

export default EyeDropzone;
