import instance from "../config/request";

export function getAnnotations(query) {
  const limit = `limit=${query.limit}`;
  const status = query.status !== "ALL" ? `&status=${query.status}` : "";
  const orderBy = query.orderBy
    ? `&orderBy=${query.orderBy}&order=${query.order}`
    : "";
  const periodStart = query.periodStart
    ? `&periodStart=${query.periodStart}`
    : "";
  const periodEnd = query.periodEnd ? `&periodEnd=${query.periodEnd}` : "";
  const offset =
    Number(query.page) > 0
      ? `&offset=${Number(query.page) * Number(query.limit)}`
      : "";

  return instance
    .get(
      `/research/annotation?${limit}${orderBy}${periodStart}${periodEnd}${offset}${status}`
    )
    .then((res) => res.data);
}

export function getAnnotationDetails(id) {
  return instance.get(`/v3/research/${id}/annotation`).then((res) => res.data);
}

export function saveAnnotator(id, data) {
  return instance
    .post(`/research/${id}/annotation`, data)
    .then((res) => res.data);
}
