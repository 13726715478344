import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import Breadcrumb from "./Breadcrumbs";
import {getOptimaReport, sendDriverValues, updateResearch,} from "../../../api";
import {Hidden, Typography, useMediaQuery} from "@material-ui/core";
import {createToast, MessagesContext,} from "../../../globalContexts/MessagesContext";
import PatientForm from "../PatientForm";
import ConclusionsForm from "../ConclusionForm";
import MobileConclusionForm from "../ConclusionForm/mobile";
import {RESEARCH_STATUS} from "../constants";
import {getErrorMessage} from "../../../utils/errorHandlers";
import {FUNDUS_VERSION} from "../../../config/constants";
import {normalizePatientFieldsValues} from "../utils";
import {HeaderLeftTeleporter, HeaderRightTeleporter} from "../../../components/Header";
import {Link, useParams} from "react-router-dom";
import SupportTelephoneNumber from "../../../components/SupportTelephoneNumber";
import history from "../../../config/history";

export function ResearchDetailsDefault(props) {
  const {
    patientInfo,
    conclusionInfo,
    handleTakeResearch,
    handleSubmitConclusions,
    refetch,
  } = props;
  const dispatchMessage = useContext(MessagesContext)[1];
  const { t } = useTranslation("research");
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const { id: researchId } = useParams();
  const isCreated = patientInfo.status === RESEARCH_STATUS.CREATED;

  async function handleSubmitPatientForm(values, actions) {
    try {
      if ([...values.leftEyes, ...values.rightEyes].length <= 0) {
        actions.setSubmitting(false);
        return dispatchMessage(createToast(t("ATTACH_IMAGE_PLEASE"), "error"));
      }
      const { researchId } = await updateResearch(
        values.id,
        normalizePatientFieldsValues(values)
      );
      dispatchMessage(createToast(t("RESEARCH_UPDATE_SUCCESS")));
      if (values.fundusVersion === FUNDUS_VERSION.OPTIMA) {
        if (values.leftEyes.length > 0 || values.rightEyes.length > 0) {
          dispatchMessage(createToast(t("SENDING_DATA_OPTIMA"), "info"));
          try {
            const { report } = await getOptimaReport(researchId);
            await sendDriverValues(
              normalizePatientFieldsValues({
                ...values,
                id: researchId,
                report,
              })
            );
          } catch (error) {
            dispatchMessage(createToast(t("ERROR_SAVING_OPTIMA"), "error"));
            console.error(error);
          }
        }
      }
      refetch();
    } catch (err) {
      console.error(err);
      dispatchMessage(createToast(getErrorMessage(err), "error"));
      return history.push("/researches");
    } finally {
      actions.setSubmitting(false);
    }
  }
  return (
    <>
      <Hidden only="xs">
        <HeaderLeftTeleporter.Source>
          <Typography
            variant={isMd ? "body1" : "h6"}
            color="inherit"
            component={Link}
            to="/"
          >
            {isSm ? t("SYSTEM_UDDR_SHORTENED") : t("SYSTEM_UDDR")}
          </Typography>
        </HeaderLeftTeleporter.Source>
        <HeaderRightTeleporter.Source>
            <SupportTelephoneNumber/>
        </HeaderRightTeleporter.Source>
        <Breadcrumb t={t} />
        <br />
        <PatientForm
          isDetails={true}
          t={t}
          initialValues={patientInfo}
          handleSubmit={handleSubmitPatientForm}
          handleTakeResearch={handleTakeResearch}
        />
        {!isCreated && (
          <ConclusionsForm
            handleSubmit={handleSubmitConclusions}
            t={t}
            initialValues={{ ...conclusionInfo }}
          />
        )}
      </Hidden>
      <Hidden smUp>
        <MobileConclusionForm
          t={t}
          patientFormInitialValues={patientInfo}
          handleSubmitPatientForm={handleSubmitPatientForm}
          handleSubmitConclusions={handleSubmitConclusions}
          conclusionFormInitialValues={{ ...conclusionInfo }}
          handleTakeResearch={handleTakeResearch}
          isCreated={isCreated}
        />
      </Hidden>
    </>
  );
}

export default ResearchDetailsDefault;
