import {useContext, useEffect, useState} from "react";
import { LngContext } from "../globalContexts/LngContext";
import {useTranslation} from "react-i18next";

export default function RenderCurrentLang({ textObj }) {
  const {i18n} = useTranslation();
  const [lng, setLng] = useState('ru');

  useEffect(() => {
    if(i18n.language.includes('kk')) {
      setLng('kk')
    } else if(i18n.language.includes('en')) {
      setLng('en')
    } else {
      setLng('ru')
    }
  }, [i18n.language])
  if (textObj && textObj[lng]) return textObj[lng];
  return "";
}
