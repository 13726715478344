export const ADD_DICTIONARY = "ADD_DICTIONARY";
export const INITIATE_DICTIONARIES = "INITIATE_DICTIONARIES";

export function addDictionary(key, dictionary) {
  return {
    type: ADD_DICTIONARY,
    key,
    dictionary,
  };
}

export function initiateDictionaries(initialDictionaries) {
  return {
    type: INITIATE_DICTIONARIES,
    initialDictionaries,
  };
}
