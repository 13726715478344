import {Box, Button, Grid, makeStyles, Modal, Paper, TextField, Typography} from "@material-ui/core";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {createDiagnosis, editDiagnosis} from "../../../../api/patientRegistry";
import {Close, ListAlt} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import Select from "../../../../components/MuiSelect";
import {DIAGNOSIS_TYPE} from "../../../ResearchesPage/constants";
import DiagnosisTable from "./DiagnosisTable";
import {DictionariesContext} from "../../../../globalContexts/DictionariesContext";
import {createToast, MessagesContext} from "../../../../globalContexts/MessagesContext";
import {queryClient} from "../../../../globalContexts/queryContext";
import DiagnosisTableRow from "./DiagnosisTableRow";

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        width: '80vw',
        padding: '24px',
        position: 'relative'
    },
    bold: {
        fontWeight: 'bold'
    },
    field: {
        marginLeft: '25px',
        width: '70%'
    },
    table: {
        '& td': {
            padding: '5px'
        }
    },
    diagnosis: {
        flex: 2,
        maxWidth: '55%'
    },
    closeIcon: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        cursor: 'pointer',
    },
    diagnosisHistory: {
        marginTop: '8px',
        maxHeight: '250px',
        overflowY: 'auto'
    }
}));

const CreateFormModal = ({open, onClose, diagnoses, editingItem, chosen}) => {
    const classes = useStyles();
    const {id: researchId} = useParams();
    const {t} = useTranslation('patient-registry');
    const types = useMemo(() => Object.values(DIAGNOSIS_TYPE).map(value => ({value, label: t(value)})), [t]);
    const {mkb10: MKB_DIAGNOSIS} = useContext(DictionariesContext)[0];
    const mkbs = MKB_DIAGNOSIS?.map(({codename}) => ({value: codename, label: codename})) || [];
    const diagnosis = MKB_DIAGNOSIS?.map(({codename, name_ru}) => ({value: codename, label: name_ru})) || [];
    const [isMainHistoryOpen, setIsMainHistoryOpen] = useState(false);
    const [isConcomitantHistoryOpen, setIsConcomitantHistoryOpen] = useState(false);
    const dispatchMessage = useContext(MessagesContext)[1];


    const handleMainHistoryChoose = (chosen) => {
        setValue('mainDiagnosisMkb', mkbs.find(({value}) => value === chosen));
        setValue('mainDiagnosis', diagnosis.find(({value}) => value === chosen));
        setIsMainHistoryOpen(false);
    }

    const handleConcomitantHistoryChoose = (chosen) => {
        setValue('concomitantDiagnosisMkb', mkbs.find(({value}) => value === chosen));
        setValue('concomitantDiagnosis', diagnosis.find(({value}) => value === chosen));
        setIsConcomitantHistoryOpen(false);
    }

    const initialValues = useMemo(() =>  ({
        type: null,
        mainDiagnosisMkb: null,
        mainDiagnosis: null,
        concomitantDiagnosisMkb: null,
        concomitantDiagnosis: null,
    }), []);

    const {handleSubmit, control, watch, setValue, reset } = useForm({
        defaultValues: initialValues,
        reValidateMode: 'onSubmit',
    });
    const mainDiagnosisMkb = watch('mainDiagnosisMkb');
    const mainDiagnosis = watch('mainDiagnosis');
    const concomitantDiagnosisMkb = watch('concomitantDiagnosisMkb');
    const concomitantDiagnosis = watch('concomitantDiagnosis');

    useEffect(() => {
        if(types?.length) {
            setValue("type", types[0])
        }
    }, [types]);

    useEffect(() => {
        if(editingItem || chosen) {
            const currentValues = editingItem || chosen;
            reset({
                type: types.find(({value}) => value === currentValues.type),
                mainDiagnosisMkb: mkbs.find(({value}) => value === currentValues.mainDiagnosis),
                mainDiagnosis: diagnosis.find(({value}) => value === currentValues.mainDiagnosis),
                concomitantDiagnosisMkb: mkbs.find(({value}) => value === currentValues.concomitantDiagnosis),
                concomitantDiagnosis: diagnosis.find(({value}) => value === currentValues.concomitantDiagnosis),
            })
        } else {
            reset(initialValues)
        }
    }, [editingItem, chosen, reset]);

    //===================main diagnosis flow control region start=============//

    useEffect(() => {
        if(!mainDiagnosisMkb) {
            setValue('mainDiagnosis', null);
            return;
        }
        if(mainDiagnosisMkb.value === mainDiagnosis?.value) {
            return;
        }
        setValue('mainDiagnosis', diagnosis.find(({value}) => value === mainDiagnosisMkb.value));
    }, [mainDiagnosisMkb, setValue]);

    useEffect(() => {
        if(!mainDiagnosis) {
            setValue('mainDiagnosisMkb', null);
            return;
        }
        if(mainDiagnosis.value === mainDiagnosisMkb?.value) {
            return;
        }
        setValue('mainDiagnosisMkb', mkbs.find(({value}) => value === mainDiagnosis.value));
    }, [mainDiagnosis, setValue]);

    //===================main diagnosis flow control region end=============//

    //===================main diagnosis flow control region start=============//

    useEffect(() => {
        if(!concomitantDiagnosisMkb) {
            setValue('concomitantDiagnosis', null);
            return;
        }
        if(concomitantDiagnosisMkb.value === concomitantDiagnosis?.value) {
            return;
        }
        setValue('concomitantDiagnosis', diagnosis.find(({value}) => value === concomitantDiagnosisMkb.value));
    }, [concomitantDiagnosisMkb, setValue]);

    useEffect(() => {
        if(!concomitantDiagnosis) {
            setValue('concomitantDiagnosisMkb', null);
            return;
        }
        if(concomitantDiagnosis?.value === concomitantDiagnosisMkb?.value) {
            return;
        }
        setValue('concomitantDiagnosisMkb', mkbs.find(({value}) => value === concomitantDiagnosis.value));
    }, [concomitantDiagnosis, setValue]);

    //===================main diagnosis flow control region end=============//

    const handleSubmitForm = handleSubmit(async (values) => {
        try {
            await createDiagnosis({
                type: values.type.value,
                mainDiagnosis: values.mainDiagnosis.value,
                concomitantDiagnosis: values.concomitantDiagnosis?.value || null,
                researchId
            });
            onClose();
            dispatchMessage(createToast(t('DIAGNOSIS_SUCCESSFULLY_CREATED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return;
        } catch(error) {
            console.error(error);
        }
        onClose();
    });

    const handleEditForm = handleSubmit(async (values) => {
        try {
            await editDiagnosis(editingItem.id, {
                type: values.type?.value || undefined,
                mainDiagnosis: values.mainDiagnosis?.value || null,
                concomitantDiagnosis: values.concomitantDiagnosis?.value || null,
                researchId
            });
            onClose();
            dispatchMessage(createToast(t('DIAGNOSIS_SUCCESSFULLY_EDITED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return;
        } catch(error) {
            console.error(error);
        }
        onClose();
    });

    return <Modal open={open} onClose={!chosen ? () => window.confirm(t('CONFIRM_CLOSE')) && onClose() : onClose} className={classes.modal}>
            <Paper className={classes.modalContent}>
                <Close className={classes.closeIcon} onClick={onClose} />
                <Typography className={classes.bold}>
                    {t('DIAGNOSIS')}
                </Typography>
                <form onSubmit={editingItem ? handleEditForm : handleSubmitForm}>
                    <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                <span>
                    {t('TYPE')}:
                </span>
                        <div className={classes.field}>
                            <Controller name={'type'} control={control} as={
                                <Select isDisabled={!!chosen} options={types} />
                            } />
                        </div>

                    </Grid>
                    <Box height={'8px'} />
                    <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                <span>
                    {t('MAIN')} ({t('MKB')}):
                </span>
                        <Grid container className={classes.field}>
                            <Controller name={'mainDiagnosisMkb'} control={control} as={
                                <Select isDisabled={!!chosen} options={mkbs} />
                            } />
                            <Box width={'12px'} />
                            <div className={classes.diagnosis}>
                                <Controller name={'mainDiagnosis'} control={control} as={
                                    <Select isDisabled={!!chosen} options={diagnosis} />
                                } />
                            </div>
                            <Box width={'5px'} />
                            <ListAlt onClick={() => setIsMainHistoryOpen((prev) => !prev)} />
                        </Grid>
                    </Grid>
                    {
                        isMainHistoryOpen && <div className={classes.diagnosisHistory}>
                            <DiagnosisTable>
                                {
                                    diagnoses.map(item => (
                                        <DiagnosisTableRow showFullName={true} showCheckboxes={true} showServiceName={true}
                                                           diagnosis={item} key={item.researchId} onDiagnosisChoose={handleMainHistoryChoose} />
                                    ))
                                }
                            </DiagnosisTable>
                        </div>
                    }
                    <Box height={'8px'} />
                    <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                <span>
                    {t('CONCOMITANT')} ({t('MKB')}):
                </span>
                        <Grid container className={classes.field} alignItems={'center'}>
                            <Controller name={'concomitantDiagnosisMkb'} control={control} as={
                                <Select isDisabled={!!chosen}  options={mkbs} />
                            } />
                            <Box width={'12px'} />
                            <div className={classes.diagnosis}>
                                <Controller name={'concomitantDiagnosis'} control={control} as={
                                    <Select isDisabled={!!chosen}  options={diagnosis} />
                                } />
                            </div>
                            <Box width={'5px'} />
                            <ListAlt onClick={() => setIsConcomitantHistoryOpen((prev) => !prev)} />
                        </Grid>
                    </Grid>
                    {
                        isConcomitantHistoryOpen && <div className={classes.diagnosisHistory}>
                            <DiagnosisTable>
                                {
                                    diagnoses.map(item => (
                                        <DiagnosisTableRow showFullName={true} showCheckboxes={true} showServiceName={true}
                                                           diagnosis={item} key={item.researchId} onDiagnosisChoose={handleConcomitantHistoryChoose} />
                                    ))
                                }
                            </DiagnosisTable>
                        </div>
                    }
                    {
                        !chosen && <>
                            <Box height={'24px'} />
                            <Grid container justify={'flex-end'}>
                                <Button type={'submit'} variant={'contained'} color={'primary'}>
                                    {t('SAVE')}
                                </Button>
                                <Box width={'6px'}/>
                                <Button onClick={onClose} variant={'contained'} color={'secondary'}>
                                    {t('CANCEL')}
                                </Button>
                            </Grid>
                        </>
                    }

                </form>
            </Paper>
    </Modal>
}

export default CreateFormModal;