import React, { useState, useEffect, useRef, memo } from "react";
import { IconButton, makeStyles, useMediaQuery } from "@material-ui/core";
import { VerticalAlignBottom, Delete, AttachFile } from "@material-ui/icons";
import PropTypes from "prop-types";
import ReactViewer from "./ReactViewer";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import MobileEyesViewer from "../MobileEyesViewer";
import { deleteImage } from "../../../api";
import { SOCKET_STATUS } from "../constants";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    "& .react-viewer-inline": {
      minHeight: "unset",
    },
  },
  control: ({ showControl }) => {
    if (!showControl)
      return {
        display: "none",
      };
    return {
      height: "36px",
      marginBottom: "10px",
      paddingTop: "10px",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      "& .camera": {
        flex: 1,
      },
      "& .red": {
        color: "#ec407a",
      },
      "& .blue": {
        color: "#42a5f5",
      },
    };
  },
  viewer: {
    flex: 1,
  },
}));

function EyeContainer({
  value,
  showControl,
  name,
  setFieldValue,
  socketStatus,
  forcedActiveIndex = 0,
  editable = false,
  isImgDeletable = false,
}) {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation("research");
  const classes = useStyles({ showControl });
  const containerRef = useRef(null);

  const handleDeleteAsync = useCallback(async () => {
    try {
      // минимум 1 снимок должен оставаться по блоку
      if (value.length > 1 && window.confirm(t("CONFIRM_DELETE"))) {
        await deleteImage(value[activeIndex].id);
        const newValue = value.filter((_, idx) => idx !== activeIndex);
        setFieldValue(name, newValue);
        setActiveIndex(0);
      }
    } catch (error) {
      console.error(error);
    }
  }, [activeIndex, name, setFieldValue, t, value]);

  const handleDelete = useCallback(() => {
    if (window.confirm(t("CONFIRM_DELETE"))) {
      const newValue = value.filter((_, idx) => idx !== activeIndex);
      setFieldValue(name, newValue);
      setActiveIndex(0);
    }
  }, [activeIndex, name, setFieldValue, t, value]);

  const images = value.map((val) =>
    val.constructor === File
      ? { src: URL.createObjectURL(val) }
      : { src: `${val.fullUrl}` }
  );

  // bc react-viewer need to set container first then become visible
  useEffect(() => {
    // hack to re-render react-viewer with proper scale
    setTimeout(() => {
      setVisible(true);
    }, 50);
  }, []);

  useEffect(() => {
    setActiveIndex(forcedActiveIndex);
  }, [forcedActiveIndex]);

  return (
    <div className={classes.container}>
      <div className={classes.control}>
        {socketStatus === SOCKET_STATUS.CONNECTED && (
          <div className="camera">{t("CAMERA_WAITING")}</div>
        )}
        {editable && (
          <>
            <IconButton component="label" htmlFor={`dropzone_${name}`}>
              <AttachFile />
            </IconButton>
            <IconButton className="red" onClick={handleDelete}>
              <Delete />
            </IconButton>
          </>
        )}
        {/* минимум 1 снимок должен оставаться по блоку */}
        {/* editable - flag для показа контроля (см выше) */}
        {!editable && isImgDeletable && (
          <IconButton
            className="red"
            disabled={value.length <= 1}
            onClick={handleDeleteAsync}
          >
            <Delete />
          </IconButton>
        )}
        <a
          href={images[activeIndex] && images[activeIndex].src}
          download={name}
          type={value.type}
        >
          <IconButton className="blue">
            <VerticalAlignBottom />
          </IconButton>
        </a>
      </div>
      <div ref={containerRef} className={classes.viewer}>
        {isMobile ? (
          <MobileEyesViewer
            images={images}
            onIndexChange={setActiveIndex}
            activeIndex={activeIndex}
          />
        ) : (
          <ReactViewer
            key={String(visible)}
            activeIndex={activeIndex}
            onIndexChange={(_, idx) => setActiveIndex(idx)}
            isVisible={visible}
            containerRef={containerRef}
            images={images}
          />
        )}
      </div>
    </div>
  );
}

EyeContainer.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  showControl: PropTypes.bool,
  editable: PropTypes.bool,
  setFieldValue: PropTypes.func,
  name: PropTypes.string,
};

export default memo(EyeContainer);
