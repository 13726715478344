import instance from "../config/request";

export async function createDiseaseHistory(data) {
  return instance
    .post("/patientRegistry/diseaseHistory", data)
    .then((res) => res.data);
}

export async function editDiseaseHistory(id, data) {
  return instance
    .put(`/patientRegistry/diseaseHistory/${id}`, data)
    .then((res) => res.data);
}

export async function deleteDiseaseHistory(id) {
  return instance
    .delete(`/patientRegistry/diseaseHistory/${id}`)
    .then((res) => res.data);
}

export async function getDiseaseHistories(researchId) {
  return instance
    .get(`/patientRegistry/diseaseHistory?researchId=${researchId}`)
    .then((res) => res.data);
}

export async function createDiagnosis(data) {
  return instance
    .post("/patientRegistry/diagnosis", data)
    .then((res) => res.data);
}

export async function editDiagnosis(id, data) {
  return instance
    .put(`/patientRegistry/diagnosis/${id}`, data)
    .then((res) => res.data);
}

export async function deleteDiagnosis(id) {
  return instance
    .delete(`/patientRegistry/diagnosis/${id}`)
    .then((res) => res.data);
}

export async function getDiagnoses(researchId) {
  return instance
    .get(`/patientRegistry/diagnosis?researchId=${researchId}`)
    .then((res) => res.data);
}

export async function createExophthalmometry(data) {
  return instance
    .post("/patientRegistry/exophthalmometry", data)
    .then((res) => res.data);
}

export async function editExophthalmometry(id, data) {
  return instance
    .put(`/patientRegistry/exophthalmometry/${id}`, data)
    .then((res) => res.data);
}

export async function deleteExophthalmometry(id) {
  return instance
    .delete(`/patientRegistry/exophthalmometry/${id}`)
    .then((res) => res.data);
}

export async function createNonContactTonometry(data) {
  return instance
    .post("/patientRegistry/nonContactTonometry", data)
    .then((res) => res.data);
}

export async function editNonContactTonometry(id, data) {
  return instance
    .put(`/patientRegistry/nonContactTonometry/${id}`, data)
    .then((res) => res.data);
}

export async function deleteNonContactTonometry(id) {
  return instance
    .delete(`/patientRegistry/nonContactTonometry/${id}`)
    .then((res) => res.data);
}

export async function createSchirmerTest(data) {
  return instance
    .post("/patientRegistry/schirmerTest", data)
    .then((res) => res.data);
}

export async function editSchirmerTest(id, data) {
  return instance
    .put(`/patientRegistry/schirmerTest/${id}`, data)
    .then((res) => res.data);
}

export async function deleteSchirmerTest(id) {
  return instance
    .delete(`/patientRegistry/schirmerTest/${id}`)
    .then((res) => res.data);
}

export async function createMaklakovTest(data) {
  return instance
    .post("/patientRegistry/maklakovTest", data)
    .then((res) => res.data);
}

export async function editMaklakovTest(id, data) {
  return instance
    .put(`/patientRegistry/maklakovTest/${id}`, data)
    .then((res) => res.data);
}

export async function deleteMaklakovTest(id) {
  return instance
    .delete(`/patientRegistry/maklakovTest/${id}`)
    .then((res) => res.data);
}

export async function getServices(id) {
  return instance
    .get(`/patientRegistry/services/${id}`)
    .then((res) => res.data);
}

export async function createAutorefractomy(data) {
  return instance
    .post("/patientRegistry/autorefractomy", data)
    .then((res) => res.data);
}

export async function editAutorefractomy(id, data) {
  return instance
    .put(`/patientRegistry/autorefractomy/${id}`, data)
    .then((res) => res.data);
}

export async function deleteAutorefractomy(id) {
  return instance
    .delete(`/patientRegistry/autorefractomy/${id}`)
    .then((res) => res.data);
}

export async function createOphthalmologyStatus(data) {
  return instance
    .post(`/patientRegistry/ophthalmologyStatus`, data)
    .then((res) => res.data);
}

export async function editOphthalmologyStatus(id, data) {
  return instance
    .put(`/patientRegistry/ophthalmologyStatus/${id}`, data)
    .then((res) => res.data);
}

export async function deleteOphthalmologyStatus(id) {
  return instance
    .delete(`/patientRegistry/ophthalmologyStatus/${id}`)
    .then((res) => res.data);
}

export async function createMedicalTreatment(data) {
  return instance
    .post(`/patientRegistry/medicalTreatment`, data)
    .then((res) => res.data);
}

export async function editMedicalTreatment(id, data) {
  return instance
    .put(`/patientRegistry/medicalTreatment/${id}`, data)
    .then((res) => res.data);
}

export async function deleteMedicalTreatment(id) {
  return instance
    .delete(`/patientRegistry/medicalTreatment/${id}`)
    .then((res) => res.data);
}

export async function deleteMedicalTreatments(ids) {
  return instance
    .delete(`/patientRegistry/medicalTreatments`, { data: { ids } })
    .then((res) => res.data);
}

export async function getRecommendedDrugs(mkbCodename) {
  return instance
    .get(`/patientRegistry/recommendedDrugs`, { params: { mkbCodename } })
    .then((res) => res.data);
}

export async function getEditorTemplates() {
  return instance
    .get(`/patientRegistry/editorTemplate`)
    .then((res) => res.data);
}

export async function createUniversalService({ universalData }) {
  const fd = new FormData();
  Object.entries(universalData).forEach(([key, val]) => {
    if (val && val.constructor === Array) {
      if(val.length === 0) {
        fd.append(key + "[]", []);
      } else {
        val.forEach((v) => fd.append(key, v));
      }
    } else {
      fd.append(key, val);
    }
  });
  return instance
    .post("/patientRegistry/customService", fd)
    .then((res) => res.data);
}

export async function editUniversalService({ id, editUniversalData }) {
  const fd = new FormData();
  Object.entries(editUniversalData).forEach(([key, val]) => {
    if (Array.isArray(val)) {
      if(val.length === 0) {
        fd.append(key + "[]", []);
      } else {
        val.forEach((v) => fd.append(key, v));
      }
    } else {
      fd.append(key, val);
    }
  });
  return instance
    .put(`/patientRegistry/customService/${id}`, fd)
    .then((res) => res.data);
}

export async function deleteUniversalService(id) {
  return instance
    .delete(`/patientRegistry/customService/${id}`)
    .then((res) => res.data);
}

export async function getUniversalServiceFile(id) {
  return instance
    .get(`/patientRegistry/customService/file/${id}`)
    .then((res) => res.data);
}

