/**
 *
 * Header
 *
 */

import React, { memo, useContext } from "react";
import { IconButton, Grid, Toolbar, AppBar } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { createTeleporter } from "react-teleporter";
import { UserContext } from "../../globalContexts/UserContext";
import { DrawerContext } from "../../globalContexts/DrawerContext";
import { SlaNotification } from "../../modules/SlaNotification/SlaNotification";
import { ROLES } from "../../config/constants";
import { LanguageSelectorUnauthed } from "../LanguageSelectors";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const HeaderRightTeleporter = createTeleporter();
export const HeaderLeftTeleporter = createTeleporter();

const useStyles = makeStyles(() => ({
  header: {
    justifyContent: "center",
  },
  toolbar: {
    minHeight: "unset",
  },
  menuButton: {
    marginLeft: "-12px",
    marginRight: "20px",
  },
  item: {
    flex: 1,
    "& button": {
      margin: "0 5px",
    },
  },
}));

// eslint-disable-next-line no-shadow
function Header() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const toggleDrawer = useContext(DrawerContext)[1];
  return (
    <AppBar position="sticky" className={classes.header}>
      <Toolbar className={classes.toolbar}>
        <Grid container alignItems="center">
          <HeaderLeftTeleporter.Target />
          <Grid
            container
            className={classes.item}
            justify="flex-end"
            alignItems="center"
          >
            <HeaderRightTeleporter.Target />

            {(user?.role === ROLES.DOCTOR || user?.role === ROLES.PATIENT) && <SlaNotification />}
            {user ? (
              <IconButton
                onClick={toggleDrawer}
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                data-testid="Menu"
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <LanguageSelectorUnauthed />
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default memo(Header);
