import React, { useState } from "react";
import {
  IconButton,
  Button,
  Typography,
  Breadcrumbs,
  makeStyles,
} from "@material-ui/core";
import Home from "@material-ui/icons/Home";
import NavigateNext from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import history from "../../../config/history";
import { createTeleporter } from "react-teleporter";

export const NavBarTeleporter = createTeleporter();
export const SaveTeleporter = createTeleporter();

const useClasses = makeStyles(() => ({
  wrapperStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    background: "#fff",
    zIndex: 1300,
    top: "64px",
    boxShadow: "0px 1px 4px 0px rgba(0,0,0,.2)",
  },
  buttonsWrapper: {
    margin: "0 20px 0 50px",
  },
  buttons: {
    display: "flex",
  },
  "@media (max-width: 980px)": {
    wrapperStyle: {
      top: "48px",
    },
  },
}));

export default function Breadcrumb({ t }) {
  const classes = useClasses();
  return (
    <div className={classes.wrapperStyle}>
      <Breadcrumbs separator={<NavigateNext />}>
        <IconButton component={Link} to="/">
          <Home />
        </IconButton>
        <Typography variant="subtitle1" component={Link} to="/researches">
          {t("ATTENDANCE_JOURNAL")}
        </Typography>
        <Typography variant="h6">{t("RESEARCH_DETAILS")}</Typography>
      </Breadcrumbs>
      <div className={classes.buttons}>
        <NavBarTeleporter.Target />
        <SaveTeleporter.Target />
        <Button
          className={classes.buttonsWrapper}
          onClick={() => history.goBack()}
          color="primary"
          variant="outlined"
          size="small"
        >
          {t("BACK")}
        </Button>
      </div>
    </div>
  );
}
