import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MessagesContext } from "../../globalContexts/MessagesContext";
import { composeFullName, lastNameDotFirstName, parseIIN } from "../../utils";
import { PatientsActions } from "./PatientsActions";

export function PatientsDesktopTable({ tableData }) {
  const { t } = useTranslation("research");
  const dispatchMessage = useContext(MessagesContext)[1];

  return (
    <Table size="small">
      <TableHead style={{ background: "#F5F5F5" }}>
        <TableRow>
          <TableCell>№</TableCell>
          <TableCell>{t("FULL_NAME")}</TableCell>
          <TableCell>{t("IIN")}</TableCell>
          <TableCell>{t("BIRTHDAY")}</TableCell>
          <TableCell>{t("GENDER")}</TableCell>
          <TableCell>{t("PHONE")}</TableCell>
          <TableCell>{t("ACTIONS")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableData
          .map((patient, idx) => {
            return (
              <TableRow key={patient?.id}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>
                  {composeFullName(patient?.firstName, patient?.lastName)}
                </TableCell>
                <TableCell>{patient?.username}</TableCell>
                <TableCell>{parseIIN(patient?.username).birthday}</TableCell>
                <TableCell>{parseIIN(patient?.username).gender}</TableCell>
                <TableCell>{patient?.attributes?.phone}</TableCell>
                <TableCell>
                  <PatientsActions patient={patient} desktop={true} />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
}
