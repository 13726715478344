///@ts-check
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useQueries, useQuery } from "react-query";
import {
  getAutoConclusions,
  getErdbHistory,
  getEyeImage,
  getResearchDetails,
  getResearches,
} from "../../../api";
import { queryClient } from "../../../globalContexts/queryContext";
import {
  falsyAndEmptyObject,
  getBase64FromBlob,
  parseIIN,
} from "../../../utils";
import {
  getDefaultDescriptions,
  getDefaultRecommendations,
} from "../ConclusionForm/defaultDescriptionSetterUtils";
import { RESEARCH_SERVICE_NAME } from "../constants";

let isAutoConclusionCanceled = false;

export function cancelAutoConclusionUpdate() {
  isAutoConclusionCanceled = true;
}

function getIsAutoConclusionCanceled() {
  return isAutoConclusionCanceled;
}

/**
 * @param {number} id
 */
export function useResearchDetails(id, withPreview = true) {
  const [absentConclusions, setAbsentConclusions] = useState([false, false]);
  const setResearchDetailsData = useCallback(
    (data) => {
      return queryClient.setQueryData(["researchDetails", id], data);
    },
    [id]
  );

  const query = useQuery(
    ["researchDetails", id],
    () => fetchResearchDetails({ id, withPreview, setAbsentConclusions }),
    {
      keepPreviousData: true,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    let intervalId;
    isAutoConclusionCanceled = false;
    if (absentConclusions.every(Boolean)) {
      intervalId = setInterval(() => {
        getAutoConclusions(id).then(({ autoConclusion }) => {
          const newData = {};
          if (autoConclusion.leftEyeConclusionAuto.conclusionResult) {
            newData.leftEyeConclusion = autoConclusion.leftEyeConclusionAuto;
          }
          if (autoConclusion.rightEyeConclusionAuto.conclusionResult) {
            newData.rightEyeConclusion = autoConclusion.rightEyeConclusionAuto;
          }
          if (!falsyAndEmptyObject(newData)) {
            if (!getIsAutoConclusionCanceled()) {
              // TODO: add toast
              setResearchDetailsData({
                ...query.data,
                ...newData,
              });
            }
            setAbsentConclusions([
              !newData.leftEyeConclusion,
              !newData.rightEyeConclusion,
            ]);
          }
        });
      }, 10000);
    }
    return () => clearInterval(intervalId);
  }, [absentConclusions, id, query.data, setResearchDetailsData]);
  return [query, setResearchDetailsData];
}

export function useAllResearchDetails(ids, enabled) {
  return useQueries(
    ids.map((id) => ({
      queryKey: ["researchDetails", id],
      queryFn: () => fetchResearchDetails({ id }),
      staleTime: Infinity,
      enabled,
    }))
  );
}

export function usePatientAllImages(iin, status, enabled) {
  const { data: patientHistoryData } = usePatientHistory(iin, status);
  const allResearchDetails = useAllResearchDetails(
    patientHistoryData
      ?.filter(
        ({ serviceName }) =>
          serviceName === RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY
      )
      .map((research) => research.id) || [],
    enabled
  );
  return allResearchDetails
    .filter((res) => res.data)
    .reduce(
      (acc, { data }) => {
        acc.leftEyes = [...acc.leftEyes, ...data.leftEyes];
        acc.rightEyes = [...acc.rightEyes, ...data.rightEyes];
        return acc;
      },
      { leftEyes: [], rightEyes: [] }
    );
}

async function fetchFullEyes(data) {
  // fetch eye image and update research
  // mutate eye object
  await Promise.all(
    [...data.rightEyes, ...data.leftEyes]
      .filter((eye) => !eye.base64)
      .map(async (eye) => {
        const image = await getEyeImage(eye.id);
        eye.base64 = await getBase64FromBlob(image);
        queryClient.setQueryData(["researchDetails", data.id], data);
      })
  );
}

// setAbsentConclusions - function to notify that conclusionResultAuto exists,
// but its conslusionResult is absent
// it is better to notify it with just data, bc this function is used with multiple useQueries hook
// and in that scenario value of the setAbsentConclusions will be undefined, bc not needed
const fetchResearchDetails = async ({
  id,
  withPreview,
  setAbsentConclusions,
}) => {
  const data = await getResearchDetails(id, withPreview);
  const {
    conclusionResultFormStructure,
    descriptionFormStructure,
  } = queryClient.getQueryData("dictionaries");
  // add age and gender to initial values to avoid react warnings
  const research = {
    ...data.research,
    age: "",
    gender: "",
  };
  try {
    if (research.iin) {
      const { age, gender, birthday } = parseIIN(research.iin);
      research.age = age;
      research.gender = gender;
      research.birthday = birthday;
    }
  } catch (error) {
    console.error(error);
  }
  research.referrerId = {
    value: research.referrerId,
    label: research.referrerFullName,
  };
  research.receptionDoctorId = research.receptionDoctorId
    ? {
        value: research.receptionDoctorId,
        label: research.receptionDoctorFullName,
      }
    : null;
  // for setting default recommendations;
  const autoConclusionResults = [];

  // add leftEyeConclusion and rightEyeConclusion to result from server
  // if there is auto-conclusion or just conclusion change defaults for rightEye
  if (research.rightEyeConclusion) {
    research.rightEyeConclusion = {
      id: research.rightEyeConclusion.id,
      conclusionResult:
        research.rightEyeConclusion.conclusionResult ||
        conclusionResultFormStructure,
      description:
        research.rightEyeConclusion.description || descriptionFormStructure,
    };
  } else if (research.rightEyeConclusionAuto) {
    let conclusionResultAuto = research.rightEyeConclusionAuto.conclusionResult;
    if (!conclusionResultAuto) {
      conclusionResultAuto = conclusionResultFormStructure;
      if (research.rightEyes?.length > 0 || research.leftEyes?.length > 0)
        setAbsentConclusions?.((state) => [state[0], true]);
    }
    research.rightEyeConclusion = {
      id: null,
      conclusionResult: conclusionResultAuto,
      description: getDefaultDescriptions(conclusionResultAuto),
    };
    autoConclusionResults.push(conclusionResultAuto);
  } else {
    // default to
    research.rightEyeConclusion = {
      id: null,
      conclusionResult: conclusionResultFormStructure,
      description: descriptionFormStructure,
    };
  }
  // if there is auto-conclusion or just conclusion change defaults for leftEye
  if (research.leftEyeConclusion) {
    research.leftEyeConclusion = {
      id: research.leftEyeConclusion.id,
      conclusionResult:
        research.leftEyeConclusion.conclusionResult ||
        conclusionResultFormStructure,
      description:
        research.leftEyeConclusion.description || descriptionFormStructure,
    };
  } else if (research.leftEyeConclusionAuto) {
    let conclusionResultAuto = research.leftEyeConclusionAuto.conclusionResult;
    if (!conclusionResultAuto) {
      conclusionResultAuto = conclusionResultFormStructure;
      if (research.rightEyes?.length > 0 || research.leftEyes?.length > 0)
        setAbsentConclusions?.((state) => [true, state[1]]);
    }
    research.leftEyeConclusion = {
      id: null,
      conclusionResult: conclusionResultAuto,
      description: getDefaultDescriptions(conclusionResultAuto),
    };
    autoConclusionResults.push(conclusionResultAuto);
  } else {
    // default to
    research.leftEyeConclusion = {
      id: null,
      conclusionResult: conclusionResultFormStructure,
      description: descriptionFormStructure,
    };
  }
  if (!research.leftEyeConclusion && !research.rightEyeConclusion) {
    const {
      recommendationCodenames,
      recommendationText,
    } = getDefaultRecommendations(autoConclusionResults);
    // this sets initial values so form will not be dirty
    research.recommendationCodenames = recommendationCodenames;
    research.recommendationText = recommendationText;
  }
  // fetchFullEyes(research).catch((err) =>
  //   console.error("UPDATE EYES ERROR", err)
  // );
  return research;
};

/**
 * @param {string} iin
 */
export function usePatientHistory(iin, status) {
  return useQuery(
    ["patientHistory", iin, status],
    () => {
      const query = {
        per_page: 100,
        search: iin,
        propFilters: {},
      };
      return getResearches(query).then((res) => res.result.researches);
    },
    { refetchOnWindowFocus: false }
  );
}
