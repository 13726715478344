import { Box, makeStyles } from "@material-ui/core";
import Select, { components } from "react-select";
import PublicIcon from "@material-ui/icons/Public";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { LANGUAGES } from "./constants";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
}));

function ControlComponent({ children, ...rest }) {
  const classes = useStyles();

  return (
    <components.Control {...rest}>
      <Box className={classes.wrapper}>
        <PublicIcon /> {children}
      </Box>
    </components.Control>
  );
}
const customStyles = {
  container: () => ({
    flexGrow: 1,
  }),
  singleValue: () => ({
    color: "white",
  }),
  control: () => ({
    flexGrow: 1,
  }),
  menuList: () => ({
    color: "black",
  }),
};

function LanguageSelectorAuthed() {
  const { i18n } = useTranslation();
  const initialLang =
    LANGUAGES.find((lang) => i18n.language.includes(lang.value)) ||
    LANGUAGES[0];
  const [lng, setLng] = useState(initialLang);
  const handleOnChange = (lang) => {
    i18n.changeLanguage(lang.value).then(() => setLng(lang));
  };
  return (
    <Select
      options={LANGUAGES}
      value={lng}
      onChange={(lang) => handleOnChange(lang)}
      components={{
        Control: ControlComponent,
        IndicatorSeparator: () => null,
      }}
      styles={customStyles}
    />
  );
}

export default LanguageSelectorAuthed;
