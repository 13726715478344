import {Box, Button, ClickAwayListener, Grid, makeStyles, Modal, Paper, TextField, Typography} from "@material-ui/core";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {createDiseaseHistory, editDiseaseHistory} from "../../../../api/patientRegistry";
import {Close} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {createToast, MessagesContext} from "../../../../globalContexts/MessagesContext";
import {queryClient} from "../../../../globalContexts/queryContext";
import * as Yup from "yup";

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        width: '80vw',
        padding: '24px',
        position: 'relative'
    },
    bold: {
        fontWeight: 'bold'
    },
    field: {
        marginLeft: '25px',
        width: '70%'
    },
    table: {
        '& td': {
            padding: '5px'
        }
    },
    closeIcon: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        cursor: 'pointer',
    },
    input: {
        display: 'flex'
    },
}));

const diseaseHistorySchema = Yup.object().shape({
    odSph: Yup.number().required(),
    odCyl: Yup.number().required(),
    odAx: Yup.number().required(),
    odAcuity: Yup.number().required(),
    osSph: Yup.number().required(),
    osCyl: Yup.number().required(),
    osAx: Yup.number().required(),
    osAcuity: Yup.number().required()
})

const CreateFormModal = ({open, onClose, chosen, editingItem}) => {
    const classes = useStyles();
    const {id: researchId} = useParams();
    const dispatchMessage = useContext(MessagesContext)[1];
    const initialValues = useMemo(() => ({
        complaints: "",
        anMorbi: "",
        anVitae: "",
        odSph: undefined,
        odCyl: undefined,
        odAx: undefined,
        odAcuity: undefined,
        osSph: undefined,
        osCyl: undefined,
        osAx: undefined,
        osAcuity: undefined
    }), [])
    const {handleSubmit, control, reset, watch, errors } = useForm({
        defaultValues: initialValues,
        reValidateMode: 'onSubmit',
        validationSchema: diseaseHistorySchema,
    });
    const {t} = useTranslation('patient-registry');
    const values = watch();

    useEffect(() => {
        const handleKeyDown = function(e) {
            const elements = Array.from(document.getElementsByClassName("arrow-toggle")).sort((a, b) => a.tabIndex - b.tabIndex);
            switch (e.key) {
                case "ArrowLeft":
                    elements[elements.indexOf(e.target) - 1]?.focus()
                    break;
                case "ArrowRight":
                    elements[elements.indexOf(e.target) + 1]?.focus()
                    break;
                default:
                    break;
            }
        };
        document.addEventListener('keydown', handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    }, [])

    useEffect(() => {
        if(chosen) {
            reset(chosen);
        }
        if(editingItem) {
            reset(editingItem);
        }
        if(!chosen && !editingItem) {
            reset(initialValues);
        }
    }, [chosen, editingItem, reset]);

    const handleSubmitCreateForm = handleSubmit(async (values) => {
        try {
            await createDiseaseHistory({...values, researchId});
            onClose();
            dispatchMessage(createToast(t('DISEASE_HISTORY_SUCCESSFULLY_CREATED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return;
        } catch(error) {
            console.error(error);
        }
        onClose();
    });

    const handleSubmitEditForm = handleSubmit(async(values) => {
        try {
            await editDiseaseHistory(editingItem.id, values);
            onClose();
            dispatchMessage(createToast(t('DISEASE_HISTORY_SUCCESSFULLY_EDITED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return;
        } catch(error) {
            console.error(error);
        }
        onClose();
    })
    return <Modal open={open} onClose={!chosen ? () => window.confirm(t('CONFIRM_CLOSE')) && onClose() : onClose} className={classes.modal}>
            <Paper className={classes.modalContent}>
                <Close className={classes.closeIcon} onClick={onClose} />
                <Typography className={classes.bold}>
                    {t('DISEASE_HISTORY')}
                </Typography>
                <form onSubmit={Boolean(editingItem) ? handleSubmitEditForm : handleSubmitCreateForm}>
                    <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                        <span>
                            {t('COMPLAINTS')}:
                        </span>
                        <Controller name={'complaints'} control={control} as={
                            <TextField className={classes.field} size={'small'} variant={'outlined'} inputProps={{className: "arrow-toggle", readOnly: chosen}} />
                        } />
                    </Grid>
                    <Box height={'8px'} />
                    <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                        <span>
                            An.morbi:
                        </span>
                        <Controller name={'anMorbi'} control={control} as={
                            <TextField className={classes.field} size={'small'} variant={'outlined'} inputProps={{className: "arrow-toggle", readOnly: chosen}}  />
                        } />
                    </Grid>
                    <Box height={'8px'} />
                    <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                        <span>
                            An.vitae:
                        </span>
                        <Controller name={'anVitae'} control={control} as={
                            <TextField className={classes.field} size={'small'} variant={'outlined'} inputProps={{className: "arrow-toggle", readOnly: chosen}} />
                        } />
                    </Grid>
                    <Box height={'8px'} />
                    <span>
                        Status oculorum:
                    </span>
                    <Grid container justify={'center'}>
                        <table className={classes.table}>
                            <tbody>
                            <tr>
                                <td />
                                <td>sph</td>
                                <td>cyl</td>
                                <td>ax (°)</td>
                                <td>{t('ACUITY')}</td>
                            </tr>
                            <tr>
                                <td>
                                    OD
                                </td>
                                <td>
                                    <Controller name={'odSph'} control={control} as={
                                        <TextField className={classes.input} error={!!errors['odSph']} inputProps={{className: "arrow-toggle", step: 0.25, readOnly: chosen, min: -25, max: 25}} type={'number'}
                                                   size={'small'} variant={'outlined'} InputProps={{startAdornment: values['odSph'] > 0 ? '+' : '',}}  />
                                    }/>
                                </td>
                                <td>
                                    <Controller name={'odCyl'} control={control} as={
                                        <TextField className={classes.input} error={!!errors['odCyl']}  inputProps={{className: "arrow-toggle", step: 0.25, readOnly: chosen, min: -25, max: 25}} type={'number'}
                                                   size={'small'} variant={'outlined'} InputProps={{startAdornment: values['odCyl'] > 0 ? '+' : ''}} />
                                    } />
                                </td>
                                <td>
                                    <Controller name={'odAx'} control={control} as={
                                        <TextField className={classes.input} error={!!errors['odAx']}  inputProps={{className: "arrow-toggle", step: 1, readOnly: chosen, min: 0, max: 180}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'odAcuity'} control={control} as={
                                        <TextField error={!!errors['odAcuity']}  inputProps={{className: "arrow-toggle", step: 1, readOnly: chosen}} type={'number'} size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    OS
                                </td>
                                <td>
                                    <Controller name={'osSph'} control={control} as={
                                        <TextField error={!!errors['osSph']}  className={classes.input} inputProps={{className: "arrow-toggle", step: 0.25, readOnly: chosen, min: -25, max: 25}} type={'number'}
                                                   size={'small'} variant={'outlined'} InputProps={{startAdornment: values['osSph'] > 0 ? '+' : ''}}/>
                                    }/>
                                </td>
                                <td>
                                    <Controller name={'osCyl'} control={control} as={
                                        <TextField error={!!errors['osCyl']} className={classes.input} inputProps={{className: "arrow-toggle", step: 0.25, readOnly: chosen, min: -25, max: 25}} type={'number'}
                                                   size={'small'} variant={'outlined'} InputProps={{startAdornment: values['osCyl'] > 0 ? '+' : ''}}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'osAx'} control={control} as={
                                        <TextField error={!!errors['osAx']} className={classes.input} inputProps={{className: "arrow-toggle", step: 1, readOnly: chosen, min: 0, max: 180}} type={'number'}
                                                   size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                                <td>
                                    <Controller name={'osAcuity'} control={control} as={
                                        <TextField error={!!errors['osAcuity']} inputProps={{className: "arrow-toggle", step: 1, readOnly: chosen}} type={'number'} size={'small'} variant={'outlined'}/>
                                    } />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Grid>
                    {
                        !chosen && <>
                            <Box height={'24px'} />
                            <Grid container justify={'flex-end'}>
                                <Button type={'submit'} variant={'contained'} color={'primary'}>
                                    {t('SAVE')}
                                </Button>
                                <Box width={'6px'}/>
                                <Button onClick={onClose} variant={'contained'} color={'secondary'}>
                                    {t('CANCEL')}
                                </Button>
                            </Grid>
                        </>
                    }
                </form>
            </Paper>
    </Modal>
}

export default CreateFormModal;