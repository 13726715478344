import React, { useReducer } from "react";
import tableReducer from "./tableReducer";
export * from "./tableActions";

export const initialTableState = {
  // controlled
  selectedRows: [],
  isAllSelected: false,
  isIndeterminated: false,
  mobileData: [],

  // uncontrolled
  data: [],
  meta: {},
  total: 0,
  page: 0,
  per_page: 20,
  search: "",
  propFilters: {},
  isLoading: false,
  order: "", // 'desc' | 'asc' | ''
  orderBy: "",
};

export const TableContext = React.createContext({});

export default function TableContextProvider({ children }) {
  const [state, dispatch] = useReducer(tableReducer, {});
  return (
    <TableContext.Provider value={[state, dispatch]}>
      {children}
    </TableContext.Provider>
  );
}
