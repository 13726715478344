import { Avatar, IconButton } from "@material-ui/core";
import { Assignment } from "@material-ui/icons";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { askPatientPermission } from "../../api";
import {
  createToast,
  MessagesContext,
} from "../../globalContexts/MessagesContext";

export const PatientsActions = ({ patient, desktop }) => {
  const { t } = useTranslation(["research"]);
  const dispatchMessage = useContext(MessagesContext)[1];
  const handleClickButton = async () => {
    if (patient?.accessGivenToPatientInfo) {
      window
        .open(
          window.location.origin + "/patientInfo/" + patient?.username,
          "_blank"
        )
        .focus();
      return;
    }
    await askPatientPermission(patient.username);
    dispatchMessage(createToast(t("Разрешение успешно запрошено")));
  };
  return (
    <IconButton
      onClick={handleClickButton}
      title={
        patient?.accessGivenToPatientInfo
          ? t("OPEN_PATIENT_CARD")
          : t("ASK_PATIENT_CARD")
      }
    >
      {desktop ? (
        <Assignment color={"primary"} />
      ) : (
        <Avatar
          style={{
            backgroundColor: patient?.accessGivenToPatientInfo
              ? "#005BB5"
              : "gray",
          }}
        >
          <Assignment sx={{ color: "#FDFEFE" }} />
        </Avatar>
      )}
    </IconButton>
  );
};
