import { Box, Grid, Paper } from "@material-ui/core";
import { Person } from "@material-ui/icons";
import React, { useContext } from "react";
import { formatDate } from "../../../../utils";
import { DictionariesContext } from "../../../../globalContexts/DictionariesContext";
import { useTranslation } from "react-i18next";

const DiagnosisTableRow = ({
  diagnosis,
  onDiagnosisChoose,
  showCheckboxes = false,
  showFullName = false,
  showServiceName = false,
}) => {
  const { mkb10 } = useContext(DictionariesContext)[0];
  const { t } = useTranslation(["patient-registry", "serviceNamesDict"]);
  return (
    <Paper elevation={3}>
      <Paper className={"row"}>
        <tr className={"padding-top"}>
          <td>{formatDate(diagnosis.createdAt)}</td>
          {showServiceName && (
            <td>{t("serviceNamesDict:" + diagnosis.serviceName)}</td>
          )}
        </tr>
        <tr>
          <td>
            <Grid container alignItems={"center"}>
              {showCheckboxes && (
                <>
                  <input
                    onChange={() =>
                      onDiagnosisChoose &&
                      onDiagnosisChoose(diagnosis.mainDiagnosis)
                    }
                    type={"checkbox"}
                  />
                  <Box width={"4px"} />
                </>
              )}
              <span>{t("MAIN")}</span>
            </Grid>
          </td>
          <td>{diagnosis.mainDiagnosis}</td>
          <td>
            {
              mkb10?.find(
                ({ codename }) => codename === diagnosis.mainDiagnosis
              )?.name_ru
            }
          </td>
          {showFullName && (
            <td>
              <Grid container alignItems={"center"}>
                <Person />
                <span>{diagnosis.doctorFullName}</span>
              </Grid>
            </td>
          )}
        </tr>
        <tr className={"padding-bottom"}>
          <td>
            <Grid container alignItems={"center"}>
              {showCheckboxes && (
                <>
                  <input
                    onChange={() =>
                      onDiagnosisChoose &&
                      onDiagnosisChoose(diagnosis.concomitantDiagnosis)
                    }
                    type={"checkbox"}
                  />
                  <Box width={"4px"} />
                </>
              )}
              <span>{t("CONCOMITANT")}</span>
            </Grid>
          </td>
          <td>{diagnosis.concomitantDiagnosis}</td>
          <td>
            {
              mkb10?.find(
                ({ codename }) => codename === diagnosis.concomitantDiagnosis
              )?.name_ru
            }
          </td>
          {showFullName && (
            <td>
              <Grid container alignItems={"center"}>
                <Person />
                <span>{diagnosis.doctorFullName}</span>
              </Grid>
            </td>
          )}
        </tr>
      </Paper>
    </Paper>
  );
};

export default DiagnosisTableRow;
