import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { Close, Edit, Visibility } from "@material-ui/icons";
import { formatDate } from "../../../../utils";
import { useTranslation } from "react-i18next";
import { ROLES } from "../../../../config/constants";
import Roled from "../../../../components/Roled";
import { RESEARCH_SERVICE_NAME } from "../../../ResearchesPage/constants";

const useStyle = makeStyles(() => ({
  serviceItem: {
    padding: "0 10px",
    marginBottom: "5px",
  },
}));

const ServiceList = ({
  services,
  onChooseService,
  onEditService,
  onDeleteService,
  isFinished,
}) => {
  const { t } = useTranslation(["patient-registry", "serviceNamesDict"]);
  const classes = useStyle();
  function handleChooseService(item) {
    item.serviceName === RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY
      ? window
          .open(
            window.location.origin + "/researches/" + item.service.id,
            "_blank"
          )
          .focus()
      : onChooseService(item);
  }
  return (
    <Grid container direction={"column"}>
      {services.map((item) => (
        <Paper elevation={3} key={item.id} className={classes.serviceItem}>
          <Grid
            className={"item"}
            key={item?.service?.researchId}
            container
            direction={"row"}
            justify={"space-between"}
            alignItems={"center"}
          >
            <span>{formatDate(item.service.createdAt)}</span>
            <span
              className={"clickable"}
              onClick={() => handleChooseService(item)}
            >
              {t("serviceNamesDict:" + item.serviceName)}
            </span>
            {!isFinished &&
              (item.serviceName !==
              RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY ? (
                <div>
                  <Roled roles={[ROLES.DOCTOR]}>
                    <Edit
                      cursor={"pointer"}
                      color={"primary"}
                      onClick={() => onEditService(item)}
                    />
                  </Roled>
                  <Roled roles={[ROLES.DOCTOR]}>
                    <Close
                      cursor={"pointer"}
                      color={"primary"}
                      onClick={() => onDeleteService(item)}
                    />
                  </Roled>
                </div>
              ) : (
                <div/>
              ))}
          </Grid>
        </Paper>
      ))}
    </Grid>
  );
};

export default ServiceList;
