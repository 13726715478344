const ROLES = {
  ADMIN: "admin",
  NURSE: "nurse",
  DOCTOR: "doctor",
  PATIENT: "patient",
  ANNOTATOR: "annotator",
};

const FUNDUS_VERSION = {
  DEFAULT: "",
  OPTIMA: "OPTIMA",
};

const APP_LANGUAGES = ["ru", "kk", "en"];
const DEFAULT_LOCALE = "ru";
const MOBILE_WIDTH = "600px";

const LANGUAGE_LS_KEY = "LANGUAGE_LOCAL_STORAGE_KEY";

const PATH_TO_KEYCLOAK =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_SERVER === "true"
      ? "/keycloak-localDocker.json"
      : "/keycloak-dev.json"
    : "/keycloak-prod.json";

const SCIENTIFIC_CODENAME = {
  OTHER: "OTHER",
  DEGENERATION: "DEGENERATION",
  GLAUCOMA_SUSPICION: "GLAUCOMA_SUSPICION",
  HEALTHY: "HEALTHY",
  HYPERTONIC: "HYPERTONIC",
  IMPOSSIBLE: "IMPOSSIBLE",
  MACULAR_RETINAL_TEAR: "MACULAR_RETINAL_TEAR",
  RETINAL_ANGIOPATHY: "RETINAL_ANGIOPATHY",
  RETINA_FORMATION: "RETINA_FORMATION",
  RETINOPATHY: "RETINOPATHY",
};

module.exports = {
  ROLES,
  FUNDUS_VERSION,
  APP_LANGUAGES,
  DEFAULT_LOCALE,
  MOBILE_WIDTH,
  PATH_TO_KEYCLOAK,
  LANGUAGE_LS_KEY,
  SCIENTIFIC_CODENAME,
};
