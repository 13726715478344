import {
  Breadcrumbs,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Home, NavigateNext } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import {
  HeaderLeftTeleporter,
  HeaderRightTeleporter,
} from "../../../../components/Header";

const useStyles = makeStyles((theme) => ({
  cw: {
    color: "#fff",
  },
}));

export function BreadcrumbsDoctor({ t }) {
  const classes = useStyles();
  return (
    <Breadcrumbs separator={<NavigateNext />} className={classes.cw}>
      <IconButton className={classes.cw} component={Link} to="/">
        <Home />
      </IconButton>
      <Typography
        className={classes.cw}
        variant="body1"
        component={Link}
        to="/researches"
      >
        {t("ATTENDANCE_JOURNAL")}
      </Typography>
      <Typography variant="h6" color="inherit">
        {t("RESEARCH_DETAILS")}
      </Typography>
    </Breadcrumbs>
  );
}
