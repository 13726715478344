import {Grid, Typography} from "@material-ui/core";
import {differenceBetweenDates, formatDate, formatTime} from "../../../utils";
import React, {useEffect, useState} from "react";
import * as PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const ConciliumTimer = ({concilium}) => {
    const [timeLeftInfo, setTimeLeftInfo] = useState({days: 0, hours: 0, minutes: 0});
    const { t } = useTranslation('research');

    useEffect(() => {
        if(!concilium.deadline) {
            return;
        }
        setTimeLeftInfo(differenceBetweenDates(new Date(concilium.deadline), new Date()));
        const timer = setInterval(() => {
            setTimeLeftInfo(differenceBetweenDates(new Date(concilium.deadline), new Date()));
        }, 1000);
        return () => clearInterval(timer);
    }, [concilium])

    return <Grid container justify="space-between" alignItems="center" direction={'row'}>
        {
            timeLeftInfo.days > 0 || timeLeftInfo.hours > 0 || timeLeftInfo.minutes > 0 ? <>
                <Typography className="text-gray">
                    {t('CONCILIUM_EXPIRATION_DATE')}:
                </Typography>
                <Typography className="text-gray">
                    {timeLeftInfo.days} {t('DAYS_SHORTENED')}
                    {timeLeftInfo.hours} {t('HOURS_SHORTENED')}
                    {timeLeftInfo.minutes} {t('MINUTES_SHORTENED')}
                </Typography>
                </> : <>
                <Typography className="text-gray">
                    {t('CONCILIUM_DATE_EXPIRED')}:
                </Typography>
                <Typography className="text-gray">
                    {formatDate(concilium.deadline)} {formatTime(concilium.deadline)}
                </Typography>
            </>
        }
    </Grid>
}

ConciliumTimer.propTypes = {
    concilium: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.oneOf([null])
    ])
}

export default ConciliumTimer;