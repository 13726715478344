import { useContext, useEffect, useState } from "react";
import { ResearchContext } from "../modules/ResearchesPage/ResearchContext";
const usePreConclusionInfo = (concilium, shared, autoInfo, annotationInfo) => {
  const conciliumMembers = concilium?.conciliumMembers;
  const shareMembers = shared?.shared;
  const annotationMembers = annotationInfo?.annotation;
  const [{ preConclusionType }] = useContext(ResearchContext);

  const showConclusionPicker =
    conciliumMembers?.length > 0 ||
    shareMembers?.length > 0 ||
    annotationMembers?.length > 0;

  const preconclusionFormValues = conciliumMembers?.find(
    ({ username }) => username === preConclusionType.chooseConcilium.username
  )?.preconclusion;

  const preconclusionShared = shareMembers?.find(
    ({ id }) => id === preConclusionType.chooseShared.id
  )?.pre_conclusion;

  const preconclusionAnnotator = annotationMembers?.find(
    ({ username }) => username === preConclusionType.chooseAnnotator.username
  );

  const preconclusionSharedFormValues = {
    leftEyeConclusion: preconclusionShared?.leftEyePreConclusion
      ? {
          ...preconclusionShared.leftEyePreConclusion,
          conclusionResult:
            preconclusionShared.leftEyePreConclusion.preconclusionDiagnos,
        }
      : null,
    rightEyeConclusion: preconclusionShared?.rightEyePreConclusion
      ? {
          ...preconclusionShared.rightEyePreConclusion,
          conclusionResult:
            preconclusionShared.rightEyePreConclusion.preconclusionDiagnos,
        }
      : null,
    recommendationCodenames: preconclusionShared?.recommendationCodenames,
    recommendationText: preconclusionShared?.recommendationText || "",
  };

  const preconclusionAutoFormValues = {
    ...autoInfo,
    leftEyeConclusion: {
      ...autoInfo.leftEyeConclusion,
      description: {
        ...autoInfo.leftEyeConclusion.description,
        text: autoInfo.leftEyeConclusion.description?.text || "",
      },
    },
    rightEyeConclusion: {
      ...autoInfo.rightEyeConclusion,
      description: {
        ...autoInfo.rightEyeConclusion.description,
        text: autoInfo.rightEyeConclusion.description?.text || "",
      },
    },
    recommendationText: autoInfo?.recommendationText || "",
  };

  const preconclusionAnnototorFormValues = {
    leftEyeConclusion: preconclusionAnnotator?.leftEyeConclusion
      ? {
          ...preconclusionAnnotator.leftEyeConclusion,
          conclusionResult:
            preconclusionAnnotator.leftEyeConclusion.conclusionResult,
        }
      : null,
    rightEyeConclusion: preconclusionAnnotator?.rightEyeConclusion
      ? {
          ...preconclusionAnnotator.rightEyeConclusion,
          conclusionResult:
            preconclusionAnnotator.rightEyeConclusion.conclusionResult,
        }
      : null,
    recommendationCodenames: preconclusionAnnotator?.recommendationCodenames,
    recommendationText: preconclusionAnnotator?.recommendationText || "",
  };

  return {
    showConclusionPicker,
    preconclusionFormValues,
    preconclusionSharedFormValues,
    preconclusionAutoFormValues,
    preconclusionAnnototorFormValues,
  };
};

export default usePreConclusionInfo;
