import { Grid, makeStyles, Paper } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import ServiceFormModal from "./ServiceFormModal";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import ServiceList from "./ServiceList";
import { DictionariesContext } from "../../../../globalContexts/DictionariesContext";
import { getServiceNameDictionaries } from "../../../../api";
import { addDictionary } from "../../../../globalContexts/DictionariesContext/dictionariesActions";
import {
  deleteAutorefractomy,
  deleteExophthalmometry,
  deleteMaklakovTest,
  deleteNonContactTonometry,
  deleteSchirmerTest,
  deleteUniversalService,
} from "../../../../api/patientRegistry";
import ConfirmActionModal from "../../../../components/ConfirmActionModal";
import {
  RESEARCH_SERVICE_NAME,
  RESEARCH_STATUS,
} from "../../../ResearchesPage/constants";
import {
  createToast,
  MessagesContext,
} from "../../../../globalContexts/MessagesContext";
import { queryClient } from "../../../../globalContexts/queryContext";
import { useTranslation } from "react-i18next";
import { useResearchDetails } from "../../../ResearchesPage/ResearchDetails/queryHooks";
import { ROLES } from "../../../../config/constants";
import Roled from "../../../../components/Roled";

const useStyles = makeStyles((theme) => ({
  serviceList: {
    flex: 1,
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "300px",
  },
  icon: {
    border: "1px solid black",
    color: theme.palette.primary.main,
  },
}));

const deleteService = (item) => {
  if (item.serviceName === RESEARCH_SERVICE_NAME.EXOPHTHALMOMETRY) {
    return deleteExophthalmometry(item.service.id);
  }
  if (item.serviceName === RESEARCH_SERVICE_NAME.MAKLAKOV_TEST) {
    return deleteMaklakovTest(item.service.id);
  }
  if (item.serviceName === RESEARCH_SERVICE_NAME.SCHIRMER_TEST) {
    return deleteSchirmerTest(item.service.id);
  }
  if (item.serviceName === RESEARCH_SERVICE_NAME.NON_CONTACT_TONOMETRY) {
    return deleteNonContactTonometry(item.service.id);
  }
  if (item.serviceName === RESEARCH_SERVICE_NAME.AUTOREFRACTOMY) {
    return deleteAutorefractomy(item.service.id);
  }
  return deleteUniversalService(item.service.id);
};

const Service = () => {
  const classes = useStyles();
  const [isCreatingService, setIsCreatingService] = useState(false);
  const [serviceChosen, setServiceChosen] = useState(null);
  const [serviceEditing, setServiceEditing] = useState(null);
  const [serviceDeleting, setServiceDeleting] = useState(null);
  const [{ serviceNames }, dispatch] = useContext(DictionariesContext);
  const dispatchMessage = useContext(MessagesContext)[1];
  const { t } = useTranslation("patient-registry");
  const { id: researchId } = useParams();

  const [{ data: research }] = useResearchDetails(researchId);
  const isFinished = research.status === RESEARCH_STATUS.FINISHED;
  const { data: serviceNamesDict } = useQuery(
    "serviceNames",
    () => getServiceNameDictionaries(),
    {
      initialData: [],
      enabled: !Boolean(serviceNames),
    }
  );
  useEffect(() => {
    if (serviceNamesDict.length) {
      dispatch(addDictionary("serviceNames", serviceNamesDict));
    }
  }, [dispatch, serviceNamesDict]);

  const handleCloseModal = () => {
    setIsCreatingService(false);
    setServiceChosen(null);
    setServiceEditing(null);
  };

  const handleOnDelete = async (item) => {
    try {
      await deleteService(item);
      setServiceDeleting(null);
      dispatchMessage(createToast(t("SERVICE_SUCCESSFULLY_DELETED")));
      await queryClient.invalidateQueries(["researchDetails", researchId]);
      return;
    } catch (error) {
      console.error(error);
    }
    setServiceDeleting(null);
  };
  return (
    <>
      <Grid container direction={"column"}>
        <Roled roles={[ROLES.DOCTOR]}>
          {!isFinished && (
            <Grid container>
              <Add
                className={classes.icon}
                onClick={() => setIsCreatingService(true)}
              />
            </Grid>
          )}
        </Roled>
        <Paper className={classes.serviceList}>
          <ServiceList
            services={research.services}
            onChooseService={(item) => setServiceChosen(item)}
            onEditService={(item) => setServiceEditing(item)}
            onDeleteService={(item) => setServiceDeleting(item)}
            isFinished={isFinished}
          />
        </Paper>
        <ServiceFormModal
          open={
            isCreatingService ||
            Boolean(serviceChosen) ||
            Boolean(serviceEditing)
          }
          editingItem={serviceEditing}
          onClose={handleCloseModal}
          chosen={serviceChosen}
          research={research}
        />
        <ConfirmActionModal
          open={Boolean(serviceDeleting)}
          label={t("CONFIRM_DELETION")}
          onClose={() => setServiceDeleting(null)}
          onConfirm={() => handleOnDelete(serviceDeleting)}
        />
      </Grid>
    </>
  );
};

export default Service;
