///@ts-check
import React, { useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Hidden, LinearProgress } from "@material-ui/core";
import { useResearchDetails } from "../../ResearchesPage/ResearchDetails/queryHooks";
import Loader from "../../../components/Loader";
import ResearchDetailsDefault from "../../ResearchesPage/ResearchDetails/ResearchDetailsDefault";
import { useParams } from "react-router-dom";

export function MyResearchDetailsDecktop() {
  const { t } = useTranslation("research");
  const { id: researchId } = useParams();
  const [
    { data: research, isLoading, error, isFetching, refetch },
    setResearchDetailsData,
  ] = useResearchDetails(researchId);

  if (isLoading) return <Loader isFullscreen />;
  if (error) {
    return <p>{error?.message}</p>;
  }

  const patientInfo = {
    id: research.id,
    referrerId: research.referrerId,
    referrerFullName: research.referrerFullName,
    iin: research.iin,
    fullName: research.fullName,
    birthday: research.birthday,
    gender: research.gender,
    age: research.age,
    phone: research.phone,
    complaints: research.complaints,
    diabetes: research.diabetes,
    arterialHypertension: research.arterialHypertension,
    status: research.status,
    leftEyes: research.leftEyes,
    rightEyes: research.rightEyes,
    appointmentTimestamp: research.appointmentTimestamp,
    receptionDoctorId: research.receptionDoctorId,
    receptionDoctorFullName: research.receptionDoctorFullName,
    fundusVersion: "",
    refractionRight: research.refractionRight,
    refractionLeft: research.refractionLeft,
    serviceType: research.serviceType,
  };
  const autoInfo = {
    rightEyeConclusion: research.rightEyeConclusionAuto,
    leftEyeConclusion: research.leftEyeConclusionAuto,
  };
  const conclusionInfo = {
    id: research.id,
    rightEyeConclusion: research.rightEyeConclusion,
    leftEyeConclusion: research.leftEyeConclusion,
    recommendationCodenames: research.recommendationCodenames,
    recommendationText: research.recommendationText,
    leftEyes: research.leftEyes,
    rightEyes: research.rightEyes,
    status: research.status,
    responsibleDoctorUsername: research.responsibleDoctorId,
    serviceName: research.serviceName,
  };

  const props = {
    patientInfo,
    conclusionInfo,
    autoInfo,
    refetch,
  };

  return (
    <>
      {isFetching && <LinearProgress color="secondary" className="w-100" />}
      <Helmet>
        <title>{t("RESEARCH_DETAILS")}</title>
        <meta name="description" content="Description of ResearchDetails" />
      </Helmet>
      <ResearchDetailsDefault {...props} />
    </>
  );
}

export default MyResearchDetailsDecktop;
