import React from "react";
import useStyle from "./slaCardStyles";
import {useMutation} from "react-query";
import {updateConciliumStatus} from "../../api/conciliumApi";
import {queryClient} from "../../globalContexts/queryContext";
import {useHistory} from "react-router-dom";
import {Box, Button, Grid, Paper, Typography} from "@material-ui/core";
import {format} from "date-fns";

const SlaCardConcilium = React.forwardRef(({ notification, handleClose, t }, ref) => {
    const classes = useStyle();
    const history = useHistory();
    if(!notification.payload) {
        return null;
    }
    return (
        <Paper className={classes.card} ref={ref} id={notification.object_id}>
            <Typography variant={'caption'} className={classes.cardTitle}
                        title={
                            t(notification.notification_type, {
                                researchId: notification.payload.researchId,
                                fullName: notification.payload.respondedByFullName
                            })
                        }>
                {t(notification.notification_type, {
                    researchId: notification.payload.researchId,
                    fullName: notification.payload.respondedByFullName
                })}
            </Typography>
            <Grid className={classes.cardInfo}>
                <Button
                    size="small"
                    variant="outlined"
                    className={classes.cardButton}
                    onClick={() => history.push(`/researches/${notification.payload.researchId}`)}
                >
                    {t("DETAILS")}
                </Button>
                <Typography
                    variant="caption"
                    className={notification.deadline < 600000 ? classes.deadline : ""}
                >
                    {format(new Date(notification.deadline), "HH:mm:ss")}
                </Typography>
            </Grid>
        </Paper>
    );
})

export default SlaCardConcilium;