import React from "react";
import { Input, InputAdornment, CircularProgress } from "@material-ui/core";

export default function LoadingInput({ loading, ...rest }) {
  return (
    <Input
      {...rest}
      endAdornment={
        loading && (
          <InputAdornment>
            <CircularProgress size={26} />
          </InputAdornment>
        )
      }
    />
  );
}
