import React from "react";
import {
  Box,
  Checkbox,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    padding: "10px",
    width: "100%",
  },
}));

const PatientInfo = ({ t, patientInfo }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Grid container direction={"column"}>
        <div className="pt-4 pl-8 pr-8">
          <Box marginBottom={"10px"} />
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("AGE")}:</Typography>
            <Typography className="text-gray">{patientInfo.age}</Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("DIABETES")}:</Typography>
            <Checkbox size="small" disabled checked={patientInfo.diabetes} />
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">
              {t("ARTERIAL_HYPERTENSION")}:
            </Typography>
            <Checkbox
              size="small"
              disabled
              checked={patientInfo.arterialHypertension}
            />
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("COMPLAINTS")}:</Typography>
            <Typography className="text-gray">
              {patientInfo.complaints}
            </Typography>
          </Grid>
        </div>
      </Grid>
    </Paper>
  );
};

export default PatientInfo;
