import React, { useReducer } from "react";
import modalReducer from "./modalReducer";

export const initialState = null;

export const ModalContext = React.createContext();

export default function ModalContextProvider({ children }) {
  const [state, dispatch] = useReducer(modalReducer, initialState);
  return (
    <ModalContext.Provider value={[state, dispatch]}>
      {children}
    </ModalContext.Provider>
  );
}

export * from "./modalActions";
