import React from "react";
import { useHistory } from "react-router-dom";
import useStyle from "./slaCardStyles";
import { Button, Grid, Paper, Typography } from "@material-ui/core";

const SlaCardShare = React.forwardRef(
  ({ notification, handleClose, t }, ref) => {
    const classes = useStyle();
    const history = useHistory();

    function handleClick(objectId) {
      if (history.location.pathname !== `/researches/${objectId}`) {
        history.push(`/researches/${objectId}`);
      }
      handleClose();
    }

    return (
      <Paper className={classes.card} ref={ref} id={notification.object_id}>
        <Typography variant="caption" className={classes.cardTitle}>
          {t(notification.notification_type) + notification.object_id}
        </Typography>
        <Grid className={classes.cardInfo}>
          <Button
            size="small"
            variant="outlined"
            className={classes.cardButton}
            compo
            onClick={() => handleClick(notification.object_id)}
          >
            {t("DETAILS")}
          </Button>
        </Grid>
      </Paper>
    );
  }
);

export default SlaCardShare;
