import { useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../globalContexts/UserContext";

function Roled({ roles, children, reverse, forcedShow }) {
  const { user } = useContext(UserContext);
  if (forcedShow) return children;
  if (!user) return null;
  if (reverse) {
    // for reversed conditions
    if (roles.includes(user.role)) return null;
  } else {
    // for normal conditions
    if (!roles.includes(user.role)) return null;
  }
  return children;
}

Roled.propTypes = {
  children: PropTypes.object.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  reverse: PropTypes.bool,
  forcedShow: PropTypes.bool,
};

export default Roled;
