import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Dialog,
  useMediaQuery,
  Button,
  Divider,
  FormHelperText,
} from "@material-ui/core";
import ConclusionResultForm, {
  ConclusionResultFormBody,
  ConclusionResultFormHeader,
} from "./ConclusionResultForm";
import DescriptionForm from "./DescriptionForm";
import FormModal from "./FormModal";
import FormModalMobile from "./FormModalMobile";
import ConclusionFormToShow from "./ConclusionFormToShow";
import { getDefaultDescriptions } from "./defaultDescriptionSetterUtils";
import { falsyAndEmptyObject, falsyObject } from "../../../utils";
import { KeyboardArrowLeft } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { DescriptionFormBody, DescriptionFormHeader } from "./DescriptionForm";

const conclusionResultSchema = Yup.object({
  RETINOPATHY: Yup.string()
    .oneOf(
      ["NON_PROLIFERATIVE", "PREPROLIFERATIVE", "PROLIFERATIVE", null],
      "PLEASE_CHOOSE_VALUE"
    )
    .nullable(),
  HYPERTONIC: Yup.string()
    .oneOf(
      ["ANGIOPATHY", "ANGIOSCLEROSIS", "RETINOPATHY", null],
      "PLEASE_CHOOSE_VALUE"
    )
    .nullable(),
  DEGENERATION: Yup.string()
    .oneOf(["DRY", "WET", "WET_IN_DOUBT", null], "PLEASE_CHOOSE_VALUE")
    .nullable(),
  IMPOSSIBLE: Yup.boolean().nullable(),
  textImpossible: Yup.string().when("IMPOSSIBLE", {
    is: true,
    then: Yup.string().required("PLEASE_FILL_REASON"),
  }),
  OTHER: Yup.boolean().nullable(),
  textOther: Yup.string().when("OTHER", {
    is: true,
    then: Yup.string().required("PLEASE_FILL_OTHER"),
  }),
}).nullable();

export const temporaryFormSchema = Yup.object().shape({
  rightEyeConclusion: Yup.object({
    conclusionResult: conclusionResultSchema,
  }).nullable(),
  leftEyeConclusion: Yup.object({
    conclusionResult: conclusionResultSchema,
  }).nullable(),
});

function ConclusionForm({
  isLeft,
  eyeImage,
  eyeLabel,
  descriptionValues,
  conclusionResultValues,
  setFieldValue,
  editMode,
}) {
  const [openedDialog, setOpenedDialog] = useState(null);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { t } = useTranslation("research");

  function openDescriptionDialog() {
    setOpenedDialog("description");
  }
  function openConclusionDialog() {
    setOpenedDialog("conclusionResult");
  }

  function closeIfNotDirty(isDirty, handleReset) {
    if (isDirty) {
      return function() {
        if (window.confirm(t("WILL_NOT_BE_SAVED"))) {
          handleClose();
          handleReset();
        }
      };
    } else {
      return handleClose;
    }
  }
  function handleDescriptionBack() {
    openConclusionDialog();
  }
  function handleClose() {
    setOpenedDialog(null);
  }
  function handleTemporaryFormSubmit(values, { setErrors }) {
    if (openedDialog === "conclusionResult") {
      Object.entries(values).forEach(([key, value]) => {
        if (falsyObject(value.conclusionResult)) {
          return setErrors({ conclusionResultsError: t("REQUIRED") });
        }
        setFieldValue(key, {
          conclusionResult: value.conclusionResult,
          description: getDefaultDescriptions(value.conclusionResult),
        });
        openDescriptionDialog();
      });
      return;
    }
    Object.entries(values).forEach(([key, value]) => {
      const { HEALTHY, IMPOSSIBLE } = value.conclusionResult;
      if (!HEALTHY && !IMPOSSIBLE) {
        if (falsyAndEmptyObject(value.description)) {
          return setErrors({ descriptionError: t("REQUIRED") });
        }
      }
      setFieldValue(key, value);
      handleClose();
    });
  }
  const FormComponent = isMobile ? FormModalMobile : FormModal;
  const conclusionName = isLeft ? "leftEyeConclusion" : "rightEyeConclusion";
  return (
    <>
      <ConclusionFormToShow
        label={eyeLabel}
        openConclusionDialog={openConclusionDialog}
        description={descriptionValues}
        conclusionResult={conclusionResultValues}
        editMode={editMode}
      />
      <Formik
        onSubmit={handleTemporaryFormSubmit}
        validationSchema={temporaryFormSchema}
        enableReinitialize
        initialValues={{
          [conclusionName]: {
            description: descriptionValues,
            conclusionResult: conclusionResultValues,
          },
        }}
      >
        {(subForm) => (
          <Dialog
            fullScreen
            open={Boolean(openedDialog)}
            onClose={closeIfNotDirty(subForm.dirty, subForm.handleReset)}
            onEntered={() => {
              // to remove body scroll
              document.body.style.overflow = "hidden";
            }}
          >
            <FormComponent
              image={eyeImage}
              activeStep={openedDialog === "conclusionResult" ? 0 : 1}
            >
              {openedDialog === "conclusionResult" ? (
                <ConclusionResultForm>
                  <ConclusionResultFormHeader>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={closeIfNotDirty(
                        subForm.dirty,
                        subForm.handleReset
                      )}
                    >
                      <KeyboardArrowLeft />
                      {t("BACK")}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={subForm.submitForm}
                    >
                      {t("APPROVE")}
                    </Button>
                  </ConclusionResultFormHeader>
                  <Divider />
                  <FormHelperText error>
                    {subForm.errors.conclusionResultsError}
                  </FormHelperText>
                  <ConclusionResultFormBody
                    conclusionName={conclusionName}
                    t={t}
                  />
                </ConclusionResultForm>
              ) : (
                <DescriptionForm>
                  <DescriptionFormHeader>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleDescriptionBack}
                    >
                      <KeyboardArrowLeft />
                      {t("BACK")}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={subForm.submitForm}
                    >
                      {t("SAVE")}
                    </Button>
                  </DescriptionFormHeader>
                  <Divider />
                  <FormHelperText error>
                    {subForm.errors.descriptionError}
                  </FormHelperText>
                  <DescriptionFormBody conclusionName={conclusionName} t={t} />
                </DescriptionForm>
              )}
            </FormComponent>
          </Dialog>
        )}
      </Formik>
    </>
  );
}

export default ConclusionForm;
