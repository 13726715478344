import { makeStyles } from "@material-ui/core";

export const useDialogFormStyles = makeStyles(() => ({
  root: {
    maxWidth: "600px",
    width: "100%",
    overflowY: "auto",
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    padding: "5px",
    justifyContent: "space-between",
  },
  content: {
    padding: "10px",
    overflowY: "auto",
    flex: 1,
  },
}));
