import React, { useState } from "react";

export const DrawerContext = React.createContext(false);

export default function DrawerContextProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  function toggleDrawer() {
    setIsOpen(!isOpen);
  }
  return (
    <DrawerContext.Provider value={[isOpen, toggleDrawer]}>
      {children}
    </DrawerContext.Provider>
  );
}
