import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Router } from "react-router-dom";
import App from "./App";
import history from "./config/history";
import "./i18n";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import MuiPickersProvider from "./MuiPickerProvider";
import GlobalContext from "./globalContexts";
import kc from "./config/keycloak";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { getMostValuableRole } from "./globalContexts/KeycloakContext";
import { queryClient } from "./globalContexts/queryContext";

// lazy load cornerstone
import("./initCornerstone").then((initCornerstone) => {
  initCornerstone.default();
});

const MOUNT_NODE = document.getElementById("root");

const render = (globalProps) => {
  ReactDOM.render(
    <GlobalContext {...globalProps}>
      <MuiPickersProvider>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <App />
            <ReactQueryDevtools />
          </Router>
        </QueryClientProvider>
      </MuiPickersProvider>
    </GlobalContext>,
    MOUNT_NODE
  );
};

// check if user has logged in
kc.init({
  onLoad: "check-sso",
  promiseType: "native",
  checkLoginIframe: false,
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
}).then((authenticated) => {
  if (authenticated) {
    kc.loadUserInfo().then((userInfo) => {
      const [lastName, secondName] = userInfo.family_name?.split(" ") || " ";
      const user = {
        lastName: lastName,
        firstName:
          secondName == undefined
            ? userInfo.given_name
            : userInfo.given_name + " " + secondName,
        name: userInfo.preferred_username,
        email: userInfo.email || " ",
        phone: userInfo?.attributes?.phone || "",
        id: userInfo.sub,
        role: getMostValuableRole(kc.tokenParsed.realm_access.roles),
        token: kc.token,
      };
      render({ user, kc });
    });
  } else {
    render({});
  }
});

serviceWorkerRegistration.unregister();
