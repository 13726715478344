import { Grid, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  table: {
    "& td": {
      padding: "5px",
    },
  },
  error: {
    color: theme.palette.secondary.main,
    fontSize: "11px",
    marginLeft: "48%",
  },
}));

export function OtherResearchServiceForm({
  details,
  register,
  serviceNameError,
  serviceName,
  data,
}) {
  const classes = useStyles();
  const { t } = useTranslation(["patient-registry", "serviceNamesDict"]);
  return (
    <>
      <span>{t(`serviceNamesDict:${serviceName}`)}:</span>
      <Grid container justify={"center"}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td />
              <td>mmHg</td>
              <td />
              <td>mmHg</td>
            </tr>
            <tr>
              <td>OD</td>
              <td>
                <TextField
                  name="odMm"
                  value={data ? data?.odMm : null}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "1",
                    step: 0.25,
                    readOnly: details,
                  }}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                  inputRef={register()}
                />
              </td>
              <td>OS</td>
              <td>
                <TextField
                  name="osMm"
                  value={data ? data?.osMm : null}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "2",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
      {serviceNameError && (
        <div className={classes.error}>{serviceNameError}</div>
      )}
    </>
  );
}
