import { QueryClient } from "react-query";
import { getInitialDictionaries } from "../api";

export const queryClient = new QueryClient();
queryClient.prefetchQuery(
  "dictionaries",
  async () => {
    const {
      structuredConclusionResults,
      structuredDescriptions,
      recommendations,
    } = await getInitialDictionaries();

    return {
      conclusionResultFormStructure: structuredConclusionResults.reduce(
        (acc, item) => {
          acc[item.codename] = null;
          return acc;
        },
        {}
      ),
      descriptionFormStructure: structuredDescriptions.reduce((acc, item) => {
        acc[item.codename] = null;
        return acc;
      }, {}),
      recommendations,
    };
  },
  { cacheTime: Infinity }
);
