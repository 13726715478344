import React, { useReducer } from "react";
import messagesReducer from "./messagesReducer";

export const initialState = null;

export const MessagesContext = React.createContext(initialState);

export default function MessagesContextProvider({ children }) {
  const [state, dispatch] = useReducer(messagesReducer, initialState);
  return (
    <MessagesContext.Provider value={[state, dispatch]}>
      {children}
    </MessagesContext.Provider>
  );
}

export * from "./messagesActions";
