// .+ - любое непустое значение
export const descriptionConfig = {
  DISK: {
    type: "object" // object | array | boolean
  },
  BORDER: {
    type: "object"
  },
  FOSSA: {
    type: "object"
  },
  ARTERIES: {
    type: "array"
  },
  VEINS: {
    type: "array"
  },
  MACULA: {
    type: "object"
  },
  RETINA: {
    type: "array"
  },
  VESSEL: {
    type: "object"
  }
};

export const conclusionConfig = {
  HEALTHY: {
    type: "boolean",
    if: [
      {
        fieldCodename: "IMPOSSIBLE",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "RETINOPATHY",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "GLAUCOMA_SUSPICION",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "RETINA_FORMATION",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "DEGENERATION",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "HYPERTONIC",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "MACULAR_RETINAL_TEAR",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "OTHER",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "RETINAL_ANGIOPATHY",
        value: ".+",
        cannotBe: ".+"
      }
    ]
  },
  RETINOPATHY: {
    type: "object",
    if: [
      {
        fieldCodename: "HEALTHY",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "IMPOSSIBLE",
        value: ".+",
        cannotBe: ".+"
      }
    ]
  },
  GLAUCOMA_SUSPICION: {
    type: "boolean",
    if: [
      {
        fieldCodename: "HEALTHY",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "IMPOSSIBLE",
        value: ".+",
        cannotBe: ".+"
      }
    ]
  },
  RETINA_FORMATION: {
    type: "boolean",
    if: [
      {
        fieldCodename: "HEALTHY",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "IMPOSSIBLE",
        value: ".+",
        cannotBe: ".+"
      }
    ]
  },
  DEGENERATION: {
    type: "object",
    if: [
      {
        fieldCodename: "HEALTHY",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "IMPOSSIBLE",
        value: ".+",
        cannotBe: ".+"
      }
    ]
  },
  HYPERTONIC: {
    type: "object",
    if: [
      {
        fieldCodename: "HEALTHY",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "IMPOSSIBLE",
        value: ".+",
        cannotBe: ".+"
      }
    ]
  },
  MACULAR_RETINAL_TEAR: {
    type: "boolean",
    if: [
      {
        fieldCodename: "HEALTHY",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "IMPOSSIBLE",
        value: ".+",
        cannotBe: ".+"
      }
    ]
  },
  IMPOSSIBLE: {
    type: "boolean",
    if: [
      {
        fieldCodename: "HEALTHY",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "RETINOPATHY",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "GLAUCOMA_SUSPICION",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "RETINA_FORMATION",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "DEGENERATION",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "HYPERTONIC",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "MACULAR_RETINAL_TEAR",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "OTHER",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "RETINAL_ANGIOPATHY",
        value: ".+",
        cannotBe: ".+"
      }
    ]
  },
  OTHER: {
    type: "boolean",
    if: [
      {
        fieldCodename: "HEALTHY",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "IMPOSSIBLE",
        value: ".+",
        cannotBe: ".+"
      }
    ]
  },
  RETINAL_ANGIOPATHY: {
    type: "boolean",
    if: [
      {
        fieldCodename: "HEALTHY",
        value: ".+",
        cannotBe: ".+"
      },
      {
        fieldCodename: "IMPOSSIBLE",
        value: ".+",
        cannotBe: ".+"
      }
    ]
  }
};
