import React, { useState } from 'react';
import { IconButton, Menu, Typography, useMediaQuery } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import { useTranslation } from 'react-i18next';

function SupportTelephoneNumber() {
    const isSm = useMediaQuery('(max-width:1200px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const {t} = useTranslation('common');

    if (!isSm) {
        return (
            <a style={{ textDecoration: "none", display: "flex", color: "#F7F9F9", marginRight: "15px", padding: 0, alignItems:"center"}} href="tel:+77710837362">
                <Typography>{t("TECHNICAL_SUPPORT")}: +7 (771) 083 73 62</Typography>
            </a>
        )
    } else {
        return (
            <>
                <IconButton color={"inherit"} onClick={(event) => setAnchorEl(event.currentTarget)}>
                    <PhoneIcon />
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={()=>setAnchorEl(null)}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                >
                    <a style={{ textDecoration: "none", display: "flex", color: "#000000", margin: "7px" }} href="tel:+77710837362">
                        <Typography variant="inherit">
                            <div>{t("TECHNICAL_SUPPORT")}:</div><div> +7 (771) 083 73 62 </div>
                        </Typography>
                    </a>
                </Menu>
            </>

        );
    }
}

export default SupportTelephoneNumber;
