import React, { useContext } from "react";
import {
  ModalContext,
  closeModal,
  destroyModal
} from "../../globalContexts/ModalContext";
import { Dialog, Slide } from "@material-ui/core";

function MuiDialog() {
  const [state, dispatch] = useContext(ModalContext);
  if (!state) return null;

  function handleClose() {
    dispatch(closeModal());
  }

  function handleExit() {
    dispatch(destroyModal());
  }

  return (
    <Dialog
      onClose={handleClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: "up" }}
      onExited={handleExit}
      {...state.dialogProps}
    >
      {state.dialogTitle}
      {state.dialogContent}
      {state.dialogFooter}
    </Dialog>
  );
}

export default MuiDialog;
