import { Box, Grid, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  field: {
    marginLeft: "25px",
    width: "70%",
  },
  table: {
    "& td": {
      padding: "5px",
    },
    "& .space": {
      width: "50px",
    },
    "& .header": {
      textAlign: "center",
    },
  },
  input: {
    display: "flex",
    minWidth: "100px",
  },
}));

export function AutorefractomyServiceForm({
  register,
  data,
  watch,
  errors,
  details,
}) {
  const classes = useStyles();
  const { t } = useTranslation(["patient-registry", "serviceNamesDict"]);
  const values = watch();
  return (
    <form>
      <span>{t("serviceNamesDict:AUTOREFRACTOMY")}:</span>
      <Box height={"15px"} />
      <Grid container justify={"center"}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td />
              <td className={"header"} colSpan={3}>
                OD
              </td>
              <td className={"space"} />
              <td className={"header"} colSpan={3}>
                OS
              </td>
            </tr>
            <tr>
              <td />
              <td>sph</td>
              <td>cyl</td>
              <td>ax (°)</td>
              <td />
              <td>sph</td>
              <td>cyl</td>
              <td>ax (°)</td>
            </tr>
            <tr>
              <td />
              <td>
                <TextField
                  error={!!errors["odSph"]}
                  name={"odSph"}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "1",
                    step: 0.25,
                    min: -25,
                    max: 25,
                    readOnly: details,
                  }}
                  value={data?.odSph || undefined}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                  InputProps={{
                    startAdornment: values["odSph"] > 0 ? "+" : "",
                  }}
                />
              </td>
              <td>
                <TextField
                  name={"odCyl"}
                  value={data?.odCyl || undefined}
                  error={!!errors["odCyl"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "2",
                    step: 0.25,
                    min: -25,
                    max: 25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                  InputProps={{
                    startAdornment: values["odCyl"] > 0 ? "+" : "",
                  }}
                />
              </td>
              <td>
                <TextField
                  name={"odAx"}
                  value={data?.odAx || undefined}
                  error={!!errors["odAx"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "3",
                    step: 1,
                    min: 0,
                    max: 180,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td />
              <td>
                <TextField
                  value={data?.osSph || undefined}
                  error={!!errors["osSph"]}
                  name={"osSph"}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "16",
                    step: 0.25,
                    min: -25,
                    max: 25,
                    readOnly: details,
                  }}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                  inputRef={register()}
                  InputProps={{
                    startAdornment: values["osSph"] > 0 ? "+" : "",
                  }}
                />
              </td>
              <td>
                <TextField
                  value={data?.osCyl || undefined}
                  name={"osCyl"}
                  error={!!errors["osCyl"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "17",
                    step: 0.25,
                    min: -25,
                    max: 25,
                    readOnly: details,
                  }}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                  inputRef={register()}
                  InputProps={{
                    startAdornment: values["osCyl"] > 0 ? "+" : "",
                  }}
                />
              </td>
              <td>
                <TextField
                  value={data?.osAx || undefined}
                  name={"osAx"}
                  error={!!errors["osAx"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "18",
                    step: 1,
                    min: 0,
                    max: 180,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
            </tr>
            <tr>
              <td />
              <td>mm</td>
              <td>D</td>
              <td>ax (°)</td>
              <td />
              <td>mm</td>
              <td>D</td>
              <td>ax (°)</td>
            </tr>
            <tr>
              <td>R1</td>
              <td>
                <TextField
                  name={"odR1Mm"}
                  value={data?.odR1Mm || undefined}
                  error={!!errors["odR1Mm"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "4",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"odR1D"}
                  value={data?.odR1D || undefined}
                  error={!!errors["odR1D"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "5",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"odR1Ax"}
                  value={data?.odR1Ax || undefined}
                  error={!!errors["odR1Ax"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "6",
                    step: 1,
                    min: 0,
                    max: 180,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td />
              <td>
                <TextField
                  name={"osR1Mm"}
                  value={data?.osR1Mm || undefined}
                  error={!!errors["osR1Mm"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "19",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"osR1D"}
                  value={data?.osR1D || undefined}
                  error={!!errors["osR1D"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "20",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"osR1Ax"}
                  value={data?.osR1Ax || undefined}
                  error={!!errors["osR1Ax"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "21",
                    step: 1,
                    min: 0,
                    max: 180,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
            </tr>
            <tr>
              <td>R2</td>
              <td>
                <TextField
                  name={"odR2Mm"}
                  value={data?.odR2Mm || undefined}
                  error={!!errors["odR2Mm"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "7",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"odR2D"}
                  value={data?.odR2D || undefined}
                  error={!!errors["odR2D"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "8",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"odR2Ax"}
                  value={data?.odR2Ax || undefined}
                  error={!!errors["odR2Ax"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "9",
                    step: 1,
                    min: 0,
                    max: 180,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td />
              <td>
                <TextField
                  name={"osR2Mm"}
                  value={data?.osR2Mm || undefined}
                  error={!!errors["osR2Mm"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "22",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"osR2D"}
                  value={data?.osR2D || undefined}
                  error={!!errors["osR2D"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "23",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"osR2Ax"}
                  value={data?.osR2Ax || undefined}
                  error={!!errors["osR2Ax"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "24",
                    step: 1,
                    min: 0,
                    max: 180,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
            </tr>
            <tr>
              <td>Ave</td>
              <td>
                <TextField
                  name={"odAveMm"}
                  value={data?.odAveMm || undefined}
                  error={!!errors["odAveMm"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "10",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"odAveD"}
                  value={data?.odAveD || undefined}
                  error={!!errors["odAveD"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "11",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"odAveAx"}
                  value={data?.odAveAx || undefined}
                  error={!!errors["odAveAx"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "12",
                    step: 1,
                    min: 0,
                    max: 180,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td />
              <td>
                <TextField
                  name={"osAveMm"}
                  value={data?.osAveMm || undefined}
                  error={!!errors["osAveMm"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "25",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"osAveD"}
                  value={data?.osAveD || undefined}
                  error={!!errors["osAveD"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "26",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"osAveAx"}
                  value={data?.osAveAx || undefined}
                  error={!!errors["osAveAx"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "27",
                    step: 1,
                    min: 0,
                    max: 180,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
            </tr>
            <tr>
              <td>Cyl</td>
              <td>
                <TextField
                  name={"odCylMm"}
                  value={data?.odCylMm || undefined}
                  error={!!errors["odCylMm"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "13",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"odCylD"}
                  value={data?.odCylD || undefined}
                  error={!!errors["odCylD"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "14",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"odCylAx"}
                  value={data?.odCylAx || undefined}
                  error={!!errors["odCylAx"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "15",
                    step: 0.25,
                    min: 0,
                    max: 180,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td />
              <td>
                <TextField
                  name={"osCylMm"}
                  value={data?.osCylMm || undefined}
                  error={!!errors["osCylMm"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "28",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"osCylD"}
                  value={data?.osCylD || undefined}
                  error={!!errors["osCylD"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "29",
                    step: 0.25,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
              <td>
                <TextField
                  name={"osCylAx"}
                  value={data?.osCylAx || undefined}
                  error={!!errors["osCylAx"]}
                  className={classes.input}
                  inputProps={{
                    className: "arrow-toggle",
                    tabIndex: "30",
                    step: 1,
                    min: 0,
                    max: 180,
                    readOnly: details,
                  }}
                  inputRef={register()}
                  type={"number"}
                  size={"small"}
                  variant={"outlined"}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </form>
  );
}
