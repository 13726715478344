import React, { useContext } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  date: {
    marginLeft: "4px",
    marginBottom: "5px",
  },
  table: {
    borderCollapse: "collapse",
    background: "#ececec",
    "& td": {
      paddingLeft: "8px",
      paddingRight: "15px",
    },
    "& tr.padding-bottom td": {
      paddingBottom: "6px",
    },
    "& tr.padding-top td": {
      paddingTop: "6px",
    },
  },
}));

const DiagnosisTable = ({ children, onClick }) => {
  const classes = useStyles();
  return (
    <div onClick={onClick} className={classes.root}>
      <table className={classes.table}>{children}</table>
    </div>
  );
};

export default DiagnosisTable;
