import React, { useContext } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Divider,
  RadioGroup,
  FormGroup,
  Radio,
  Checkbox,
  Typography,
  Grid,
  FormControlLabel,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import { KeyboardArrowLeft, Close } from "@material-ui/icons";
import { Field, Form, FastField } from "formik";
import { useTranslation } from "react-i18next";
import RenderCurrentLang from "../../../components/RenderCurrentLang";
import without from "lodash/without";
import concat from "lodash/concat";
import { getNested } from "../../../utils";
import { renderTextField } from "../../../components/FormComponents";
import { descriptionConfig } from "../formConfigs";
import { DictionariesContext } from "../../../globalContexts/DictionariesContext";
import { useDialogFormStyles } from "./styles";

const useFieldStyles = makeStyles(() => ({
  fieldLabel: {
    justifyContent: "space-between",
  },
}));

function RenderComponent({
  field,
  form: { setFieldValue, values },
  descriptionField,
  conclusionName,
}) {
  const fieldConfig = descriptionConfig[descriptionField.codename];
  const classes = useFieldStyles();
  return (
    <div>
      <Grid container justify="space-between">
        <Typography variant="h6">
          <RenderCurrentLang textObj={descriptionField.name} />
        </Typography>
        {field.value && (
          <IconButton
            size="small"
            onClick={() => setFieldValue(field.name, null)}
          >
            <Close />
          </IconButton>
        )}
      </Grid>
      {fieldConfig.type === "object" ? (
        <RadioGroup>
          {descriptionField.dictionary.map((item) => {
            let isDisabled = false;
            if (fieldConfig.if) {
              isDisabled = fieldConfig.if.some((condition) => {
                // we need to test condition of other field's value(otherValue)
                // if test matches the case we need to disable fields, which item.codename matches cannotBe regexp;
                const valueRegex = new RegExp(condition.value);
                const cannotBeRegex = new RegExp(condition.cannotBe);
                // otherValue can be an array or a string
                const otherValue = getNested(
                  [conclusionName, "description", condition.fieldCodename],
                  values
                );
                if (otherValue && otherValue.constructor === String) {
                  return (
                    valueRegex.test(otherValue) &&
                    cannotBeRegex.test(item.codename)
                  );
                }
                if (otherValue && otherValue.constructor === Array) {
                  return otherValue.some(
                    (innerVal) =>
                      valueRegex.test(innerVal) &&
                      cannotBeRegex.test(item.codename)
                  );
                }

                return false;
              });
            }
            return (
              <FormControlLabel
                key={item.codename}
                {...field}
                value={item.codename}
                disabled={isDisabled}
                labelPlacement="start"
                checked={field.value === item.codename}
                className={classes.fieldLabel}
                label={<RenderCurrentLang textObj={item.name} />}
                control={<Radio />}
              />
            );
          })}
        </RadioGroup>
      ) : (
        <FormGroup>
          {descriptionField.dictionary.map((item) => {
            const isChecked =
              !!field.value && field.value.includes(item.codename);
            return (
              <FormControlLabel
                key={item.codename}
                {...field}
                value={item.codename}
                onChange={({ target: { checked } }) => {
                  const prevValue =
                    getNested(field.name.split("."), values) || [];
                  const operation = checked ? concat : without;
                  setFieldValue(
                    field.name,
                    operation(prevValue, item.codename)
                  );
                }}
                labelPlacement="start"
                checked={isChecked}
                className={classes.fieldLabel}
                label={<RenderCurrentLang textObj={item.name} />}
                control={<Checkbox />}
              />
            );
          })}
        </FormGroup>
      )}
    </div>
  );
}

export function DescriptionFormHeader(props) {
  const { children } = props;
  const classes = useDialogFormStyles();
  return <div className={classes.header}>{children}</div>;
}

export function DescriptionFormBody(props) {
  const { conclusionName, t } = props;
  const classes = useDialogFormStyles();
  const { structuredDescriptions } = useContext(DictionariesContext)[0];
  return (
    <div className={classes.content}>
      {structuredDescriptions.map((descriptionField) => (
        <Field
          key={descriptionField.codename}
          name={`${conclusionName}.description.${descriptionField.codename}`}
          render={(props) => (
            <RenderComponent
              descriptionField={descriptionField}
              conclusionName={conclusionName}
              {...props}
            />
          )}
        />
      ))}
      <FastField
        name={`${conclusionName}.description.text`}
        component={renderTextField}
        className="mt-20"
        multiline
        rows="5"
        fullWidth
        variant="outlined"
        label={t("DESCRIPTION_TEXT")}
      />
    </div>
  );
}

export default function DescriptionForm({ children }) {
  const classes = useDialogFormStyles();
  return (
    <Paper className={classes.root} component={Form}>
      {children}
    </Paper>
  );
}
