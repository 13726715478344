import {Box, Grid, makeStyles, Typography} from "@material-ui/core";
import ConciliumTimer from "../Concilium/ConciliumTimer";
import React, {useContext} from "react";
import {CONCILIUM_STATUS, CONCLUSION_TYPE} from "../constants";
import {useTranslation} from "react-i18next";
import {ResearchContext, setConciliumPreconclusion, toggleConciliumMenu} from "../ResearchContext";

const useStyles = makeStyles((theme) => ({
    members: {
        background: theme.palette.background.paper,
    },
    row: {
        width: "100%",
        padding: "10px 15px",
        cursor: "pointer",
        "&:hover": {
            background: "#e0e0e0",
        },
    },
    inactive: {
        "&:hover": {
            background: theme.palette.background.paper,
        },
    },
    [CONCILIUM_STATUS.PRECONCLUSION_GIVEN]: {
        background: "green",
    },
    [CONCILIUM_STATUS.REJECTED]: {
        background: "darkred",
    },
    [CONCILIUM_STATUS.ACCEPTED]: {
        background: "orange",
    },
    [CONCILIUM_STATUS.PENDING]: {
        background: "black",
    },
    active: {
        background: "#e0e0e0",
    },
}))

const ConciliumMenu = ({conciliumInfo, isConclusionOpened, toggleConclusionOpened}) => {
    const classes = useStyles();
    const {t} = useTranslation('research');
    const [{preConclusionType}, dispatch] = useContext(ResearchContext);
    if(!conciliumInfo) {
        return;
    }
    return <Grid container direction={'column'}>
        <Grid container direction={'column'}>
            <ConciliumTimer concilium={conciliumInfo} />
            <Box marginBottom={"10px"} />
            <Grid container justify="space-between" alignItems="center">
                <Typography className="text-gray">{t("NOTE_FOR_CASE")}:</Typography>
                <Box width={"100%"} padding={"24px"} border={"1px solid black"}>
                    <Typography className="text-gray">
                        {conciliumInfo.comment}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
        <Box height={'10px'} />
        <Grid container direction={'column'} className={classes.members}>
            {conciliumInfo.conciliumMembers.map(({ username, fullName, status }) => (
                <Grid
                    key={username}
                    container
                    direction={"row"}
                    alignItems={"center"}
                    justify={"space-between"}
                    className={`${classes.row}  ${status !== CONCILIUM_STATUS.PRECONCLUSION_GIVEN && classes.inactive}
                     ${preConclusionType.type === CONCLUSION_TYPE.CONCILIUM_PRECONCLUSION &&
                       isConclusionOpened &&
                       preConclusionType.chooseConcilium.username === username && classes.active}`}
                    title={t(status)}
                    onClick={() => {
                        if(status === CONCILIUM_STATUS.PRECONCLUSION_GIVEN) {
                            dispatch(setConciliumPreconclusion(username, fullName));
                            if(!isConclusionOpened) {
                                toggleConclusionOpened()
                            }
                            dispatch(toggleConciliumMenu());
                        }
                    }}
                >
                    <Typography>{fullName}</Typography>
                    <Box
                        width={"20px"}
                        height={"20px"}
                        borderRadius={"50%"}
                        className={classes[status]}
                    />
                </Grid>
            ))}
        </Grid>

    </Grid>
}

export default ConciliumMenu;