import instance, { dicomInstance, driverInstance } from "../config/request";

export function getResearches(query) {
  const limit = `limit=${query.per_page}`;
  const status = query.propFilters.status
    ? `&status=${query.propFilters.status}`
    : "";
  const orderBy = query.orderBy
    ? `&orderBy=${query.orderBy}&order=${query.order}`
    : "";
  const receptionDoctorId = query.propFilters.receptionDoctor
    ? `&receptionDoctorId=${query.propFilters.receptionDoctor.value}`
    : "";
  const iinOrFullName = query.search ? `&iinOrFullName=${query.search}` : "";
  const serviceName = query.propFilters.serviceName
    ? `&serviceName=${query.propFilters.serviceName}`
    : "";
  const periodStart = query.propFilters.periodStart
    ? `&periodStart=${query.propFilters.periodStart}`
    : "";
  const periodEnd = query.propFilters.periodEnd
    ? `&periodEnd=${query.propFilters.periodEnd}`
    : "";
  const offset =
    query.offset || Number(query.page) > 0
      ? `&offset=${query.offset || Number(query.page) * Number(query.per_page)}`
      : "";
  return instance
    .get(
      `/researches?${limit}${status}${orderBy}${receptionDoctorId}${iinOrFullName}${serviceName}${periodStart}${periodEnd}${offset}`
    )
    .then((res) => res.data);
}

export function getResearchesCalendar() {
  return instance.get("/researches?limit=100").then((res) => res.data);
}

export function getVisitHistory(iin) {
  const iinOrFullName = `&iinOrFullName=${iin}`;
  return instance
    .get(`/researches?limit=3&order=desc&orderBy=receptionDate${iinOrFullName}`)
    .then((res) => res.data);
}

export function getResearchDetails(id, withPreview) {
  return instance
    .get(`/v3/researches/${id}`, { params: { withPreview } })
    .then((res) => res.data);
}

export function createResearch(data) {
  const fd = new FormData();
  Object.entries(data).forEach(([key, val]) => {
    if (val && val.constructor === Array) {
      val.forEach((v) => fd.append(key, v));
    } else {
      fd.append(key, val);
    }
  });
  return instance.post("/v3/researches", fd).then((res) => res.data);
}
export function sendDriverValues(data) {
  const fd = new FormData();
  Object.entries(data).forEach(([key, val]) => {
    if (val && val.constructor === Array) {
      val.forEach((v) => fd.append(key, v));
    } else {
      fd.append(key, val);
    }
  });
  return driverInstance.post("/optima/images", fd);
}

export function updateResearch(id, data) {
  const fd = new FormData();
  Object.entries(data).forEach(([key, val]) => {
    if (val && val.constructor === Array) {
      val.forEach((v) => fd.append(key, v));
    } else {
      fd.append(key, val);
    }
  });
  return instance.put(`/v3/researches/${id}`, fd).then((res) => res.data);
}
export function updateResearchIIN(data) {
  return instance
    .put(`researches/iinUpdate/${data.id}`, data.researchData)
    .then((res) => res.data);
}

export function createConclusions(researchId, data) {
  return instance.post(`/conclusions/${researchId}`, data);
}

export function updateConclusions(researchId, data) {
  return instance.put(`/conclusions/${researchId}`, data);
}

export function getRefferers() {
  return instance.get("/referrers").then((res) => res.data);
}

export function getResearchesRefferers() {
  return instance.get("/referrers/researches").then((res) => res.data);
}

export function getConsultations() {
  return instance.get("/referrers").then((res) => res.data);
}

export function getReport(id) {
  return instance.get(`/v3/researches/${id}/report`, {
    responseType: "arraybuffer",
  });
}

export function getOptimaReport(id) {
  return instance
    .get(`/v3/researches/${id}/report/optima`)
    .then((res) => res.data);
}

export async function getEyeImage(id) {
  return instance
    .get(`/eyes/${id}`, { responseType: "blob" })
    .then((res) => res.data);
}

export async function deleteImage(id) {
  return instance.delete(`/eyes/${id}`);
}

export async function takeResearchRequest(id) {
  return instance
    .patch(`/researches/${id}/responsibleDoctor`)
    .then((res) => res.data);
}

export function getImpossibleText() {
  return instance.get("/conclusions/impossibleText").then((res) => res.data);
}

export function getAutoConclusions(resaerchId) {
  return instance
    .get(`/researches/${resaerchId}/autoConclusion`)
    .then((res) => res.data);
}

export async function getErdbHistory(iin) {
  return instance.get(`/erdb/${iin}`).then((res) => res.data);
}

export async function setRecommendation(id, data) {
  return instance
    .patch(`/researches/${id}/recommendations`, data)
    .then((res) => res.data);
}

export async function deleteRecommendation(id) {
  return instance
    .delete(`/researches/${id}/recommendations`)
    .then((res) => res.data);
}

export async function getConclusionText(id) {
  return instance
    .get(`/v3/researches/${id}/conclusionText`)
    .then((res) => res.data);
}

export async function getPreviews(id) {
  return instance.get(`/researches/${id}/previews`).then((res) => res.data);
}

export async function getImages(id) {
  return instance.get(`/researches/${id}/images`).then((res) => res.data);
}

export async function fetchDicom(id) {
  return dicomInstance
    .get(`/researches/${id}`, { responseType: "blob" })
    .then((res) => res.data);
}

export async function CreateNonContactTonometry({ nonContactTonometryData }) {
  return instance
    .post("/research/nonContactTonometry", nonContactTonometryData)
    .then((res) => res.data);
}

export async function CreateSchirmerTest({ schirmerTestData }) {
  return instance
    .post("/research/schirmerTest", schirmerTestData)
    .then((res) => res.data);
}

export async function CreateExophthalmometry({ exophthalmometryData }) {
  return instance
    .post("/research/exophthalmometry", exophthalmometryData)
    .then((res) => res.data);
}

export async function CreateMaklakovTest({ maklakovTestData }) {
  return instance
    .post("/research/maklakovTest", maklakovTestData)
    .then((res) => res.data);
}

export async function createAutorefractomy({ autorefractomyData }) {
  return instance
    .post("/research/autorefractomy", autorefractomyData)
    .then((res) => res.data);
}

export async function getNonContactTonometry(researchid) {
  return instance
    .get(`/research/${researchid}/nonContactTonometry`)
    .then((res) => res.data);
}

export async function getSchirmerTest(researchid) {
  return instance
    .get(`/research/${researchid}/schirmerTest`)
    .then((res) => res.data);
}

export async function getExophthalmometry(researchid) {
  return instance
    .get(`/research/${researchid}/exophthalmometry`)
    .then((res) => res.data);
}

export async function getMaklakovTest(researchid) {
  return instance
    .get(`/research/${researchid}/maklakovTest`)
    .then((res) => res.data);
}

export async function getAutorefractomy(researchid) {
  return instance
    .get(`/research/${researchid}/autorefractomy`)
    .then((res) => res.data);
}
export async function getMyResearches(query) {
  const limit = `limit=${query.limit}`;
  const orderBy = query.orderBy
    ? `&orderBy=${query.orderBy}&order=${query.order}`
    : "";
  const offset =
    Number(query.page) > 0
      ? `&offset=${Number(query.page) * Number(query.limit)}`
      : "";
  return instance
    .get(`/my-researches?${limit}${orderBy}${offset}`)
    .then((res) => res.data);
}

export async function getMyResearchesVisitHistory() {
  return instance
    .get(`/my-researches?limit=3&order=desc&orderBy=receptionDate`)
    .then((res) => res.data);
}
