export function checkIinBinSum(iin) {
    const endDigit = parseInt(iin[11], 10);
    let sum = 0;
    for (let i = 0; i < 11; i++) {
        sum += (i + 1) * parseInt(iin[i], 10);
    }
    let k = sum % 11;
    if (k === 10) {
        sum = 0;
        let t;
        for (let i = 0; i < 11; i++) {
            if (i + 1 < 10) {
                t = i + 1 + 2;
            } else {
                t = i + 1 - 9;
            }
            sum += t * parseInt(iin[i], 10);
        }
        k = sum % 11;
        if (k === 10) return false;
    }
    return k === endDigit;
}