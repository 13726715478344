import React, {useContext, useEffect, useMemo, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {createToast, MessagesContext} from "../../../../../globalContexts/MessagesContext";
import {
    createMaklakovTest,
    createMedicalTreatment,
    editMaklakovTest,
    editMedicalTreatment, getRecommendedDrugs
} from "../../../../../api/patientRegistry";
import {queryClient} from "../../../../../globalContexts/queryContext";
import {Box, CircularProgress, Grid, TextField, Typography} from "@material-ui/core";
import Select from "../../../../../components/MuiSelect";
import {DPicker} from "../../../../../components/FormComponents";
import {makeStyles} from "@material-ui/core/styles";
import {useQuery} from "react-query";
import {getDrugsDictionary} from "../../../../../api";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
    label: {
        marginBottom: '6px'
    },
    drugTable: {
        maxHeight: '200px',
        overflowY: 'auto',
        '& table': {
            width: '100%',
            tableLayout: 'fixed',
            borderCollapse: 'collapse',
        },
        '& tr': {

            '& :first-child': {
                paddingLeft: '6px',
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px'
            },
            '& :last-child': {
                paddingRight: '6px',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px'
            },

        },
        '& td': {
            borderBottom: '1px solid ' + theme.palette.grey.A200,
            paddingBottom: '6px',
            paddingTop: '6px'
        },
        padding: '12px',
        border: '1px solid #333333',
    },
    clickable: {
        cursor: 'pointer',
        '&:hover': {
            background: '#eeecec'
        },
    },
    error: {
        borderColor: 'red'
    },
    recommendedDrugs: {
        maxHeight: '150px',
        overflowY: 'auto',
        border: '1px solid #333333',
        padding: '8px'
    },
    recommendedDrug: {
        padding: '6px',
        borderRadius: '6px',
        background: theme.palette.divider,
        cursor: 'pointer',
        margin: '4px'
    },
}));

const medicalTreatmentSchema = Yup.object().shape({
    drug: Yup.object().required(),
    dosage: Yup.number().required().min(1),
    unitOfMeasurement: Yup.string().required(),
    inputMethod: Yup.string().required(),
    prescriptionDate: Yup.string().required(),
    periodicity: Yup.string().required(),
    duration: Yup.string().required(),
})

const MedicalTreatmentForm = React.forwardRef(({currentTreatments, onClose, setButtonsDisabled}, ref) => {
    const initialValues = {
        drug: null,
        dosage: undefined,
        unitOfMeasurement: "",
        inputMethod: "",
        prescriptionDate: new Date(),
        periodicity: "",
        duration: "",
        comment: ""
    }
    const {handleSubmit, control, reset, setValue, formState, errors } = useForm({
        defaultValues: initialValues,
        reValidateMode: 'onSubmit',
        validationSchema: medicalTreatmentSchema
    });
    const classes = useStyles();
    const {id: researchId} = useParams();
    const {data: drugs, isLoading} = useQuery(['dictionary', 'drugs'], () => getDrugsDictionary());
    const researchData = queryClient.getQueryData(["researchDetails", researchId]);
    const currentDiagnosis = researchData.diagnoses?.find(({isCurrent}) => isCurrent);
    const {data: recommendedDrugs} = useQuery(['recommendedDrugs', researchId], () => getRecommendedDrugs(currentDiagnosis.mainDiagnosis));
    const drugsOptions = drugs?.map(({internationalName, id}) => ({value: id, label: internationalName}));
    const {t} = useTranslation(['patient-registry', 'serviceNamesDict']);
    const dispatchMessage = useContext(MessagesContext)[1];
    const isTreatmentChosen = Number.isInteger(currentTreatments?.chosenDrugIndex);
    const isTreatmentEditing = Number.isInteger(currentTreatments?.editingDrugIndex);

    useEffect(() => {
        if(isTreatmentChosen && drugs) {
            const chosenDrug = currentTreatments.treatments[currentTreatments.chosenDrugIndex];
            const drug = drugsOptions.find(({value}) => value === chosenDrug.drugId);
            reset({
                ...chosenDrug,
                drug
            });
            return;
        }
        if(isTreatmentEditing && drugs) {
            const chosenDrug = currentTreatments.treatments[currentTreatments.editingDrugIndex];
            const drug = drugsOptions.find(({value}) => value === chosenDrug.drugId);
            reset({
                ...chosenDrug,
                drug
            });
            return;
        }
        if(!isTreatmentChosen && !isTreatmentEditing) {
            reset(initialValues);
        }
    }, [currentTreatments, reset, drugs]);


    const handleFormCreate = async (value) => {
        try {
            await createMedicalTreatment({
                ...value,
                drugId: value.drug.value,
                researchId
            });
            dispatchMessage(createToast(t('TREATMENT_SUCCESSFULLY_CREATED'), "success"))
            reset(initialValues);
            await queryClient.invalidateQueries(['researchDetails', researchId]);
        } catch(err) {
            console.error(err);
        }
    }

    const handleFormEdit = async (value) => {
        try {
            const drugId = currentTreatments.treatments[currentTreatments.editingDrugIndex].id;
            await editMedicalTreatment(drugId, {
                ...value,
                drugId: value.drug.value,
            })
            onClose();
            dispatchMessage(createToast(t('TREATMENT_SUCCESSFULLY_EDITED'), "success"))
            await queryClient.invalidateQueries(['researchDetails', researchId]);
        } catch(err) {
            console.error(err);
        }
    }

    const handleTreatmentChoose = (treatment) => {
        if(isTreatmentChosen) {
            return;
        }
        const {drugId, dosage, unitOfMeasurement, inputMethod, periodicity, duration} = treatment;
        const drugFound = drugsOptions.find(({value}) => value === drugId);
        if(drugFound) {
            setValue('drug', drugFound);
        }
        setValue('dosage', dosage || 0);
        setValue('unitOfMeasurement', unitOfMeasurement || "");
        setValue('inputMethod', inputMethod || "");
        setValue('periodicity', periodicity || "");
        setValue('duration', duration || "");
    }

    const handleDrugChange = (args) => {
        const [drug] = args;
        if(!drug) {
            setValue('drug', null);
            setValue('dosage', 0);
            setValue('unitOfMeasurement', "");
            setValue('inputMethod', "");
            return;
        }
        const drugFound = drugs.find(({id}) => id === drug.value);
        if(!drugFound) {
            return;
        }
        setValue('dosage', drugFound.dosage || 0);
        setValue('unitOfMeasurement', drugFound.unitOfMeasurement || "");
        setValue('inputMethod', drugFound.inputMethod || "");
        return drug;
    }

    const handleChooseRecommendedDrug = (recommendedDrug) => {
        setValue('drug', {label: recommendedDrug.drug.internationalName, value: recommendedDrug.drug.id})
        setValue('dosage', recommendedDrug.drug.dosage || 0);
        setValue('unitOfMeasurement', recommendedDrug.drug.unitOfMeasurement || "");
        setValue('inputMethod', recommendedDrug.drug.inputMethod || "");
        setValue('periodicity', recommendedDrug.periodicity);
        setValue('duration', recommendedDrug.duration);
        setValue('comment', recommendedDrug.comment);
    }

    if(isLoading) {
        return <Grid container alignItems={'center'} justifyContent={'center'}><CircularProgress /></Grid>
    }
    if(!drugs) {
        return null;
    }

    return <form ref={ref} onSubmit={handleSubmit(isTreatmentEditing ? handleFormEdit : handleFormCreate)}>
        <Grid container direction={'column'}>
            <Grid container direction={'column'}>
                <Typography className={classes.label}>
                    {t('DRUG')}*:
                </Typography>
                <Controller name={'drug'} control={control} as={<Select isDisabled={isTreatmentChosen} className={classes.error} options={drugsOptions} />}
                            onChange={handleDrugChange} />
            </Grid>
            <Box height={'24px'} />
            <Grid container direction={'row'} wrap={'nowrap'} justify={'space-between'}>
                <Grid container direction={'column'}>
                    <Typography className={classes.label}>
                        {t('DOSAGE')}*:
                    </Typography>
                    <Controller control={control} name={'dosage'} as={<TextField inputProps={{readOnly: isTreatmentChosen}} error={!!errors['dosage']} type={'number'} variant={'outlined'} size={'small'} />} />
                </Grid>
                <Box width={'48px'} />
                <Grid container direction={'column'}>
                    <Typography className={classes.label}>
                        {t('UNIT_OF_MEASUREMENT')}*:
                    </Typography>
                    <Controller control={control} name={'unitOfMeasurement'} as={<TextField placeholder={t('MILLIGRAM')} inputProps={{readOnly: isTreatmentChosen}} error={!!errors['unitOfMeasurement']} variant={'outlined'} size={'small'} />} />
                </Grid>
                <Box width={'48px'} />
                <Grid container direction={'column'}>
                    <Typography className={classes.label}>
                        {t('INPUT_METHOD')}*:
                    </Typography>
                    <Controller control={control} name={'inputMethod'} as={<TextField placeholder={t('INTRAMUSCULAR')} inputProps={{readOnly: isTreatmentChosen}} error={!!errors['inputMethod']} variant={'outlined'} size={'small'} />} />
                </Grid>
            </Grid>
            <Box height={'24px'} />
            <Grid container direction={'row'} wrap={'nowrap'} justify={'space-between'}>
                <Grid container direction={'column'} justify={'space-between'}>
                    <Typography className={classes.label}>
                        {t('PRESCRIPTION_DATE')}*:
                    </Typography>
                    <Controller control={control} name={'prescriptionDate'} as={<DPicker disabled={Boolean(currentTreatments?.chosenDrugIndex)} />} />
                </Grid>
                <Box width={'48px'} />
                <Grid container direction={'column'}>
                    <Typography className={classes.label}>
                        {t('PERIODICITY')}*:
                    </Typography>
                    <Controller control={control} name={'periodicity'} as={<TextField placeholder={t('ONCE')} inputProps={{readOnly: isTreatmentChosen}} error={!!errors['periodicity']} variant={'outlined'} size={'small'} />} />
                </Grid>
                <Box width={'48px'} />
                <Grid container direction={'column'}>
                    <Typography className={classes.label}>
                        {t('DURATION')}*:
                    </Typography>
                    <Controller control={control} name={'duration'} as={<TextField placeholder={t('PER_MONTH')} inputProps={{readOnly: isTreatmentChosen}} error={!!errors['duration']} variant={'outlined'} size={'small'} />} />
                </Grid>
            </Grid>
            <Box height={'24px'} />
            <Grid container direction={'column'}>
                <Typography className={classes.label}>
                    {t('COMMENT')}:
                </Typography>
                <Controller control={control} name={'comment'} as={
                    <TextField inputProps={{readOnly: isTreatmentChosen}} variant={'outlined'} multiline maxRows={5} size={'small'} />
                } />
            </Grid>
            <Box height={'24px'} />
            <Grid container direction={'column'}>
                <Typography className={classes.label}>
                    {t('DRUGS_GIVEN')}:
                </Typography>
                <div className={classes.drugTable}>
                    <table>
                        <tbody>
                        {
                            currentTreatments?.treatments?.map((drug) => <tr className={!isTreatmentChosen ? classes.clickable : ''} onClick={() => handleTreatmentChoose(drug)} key={drug.id}>
                                <td>{drug.internationalName}</td>
                                <td>{drug.dosage} {drug.unitOfMeasurement}</td>
                                <td>{drug.inputMethod}</td>
                                <td>{drug.periodicity}-{drug.duration}</td>
                            </tr>)
                        }
                        </tbody>
                    </table>
                </div>
            </Grid>

            {
                recommendedDrugs?.length > 0 && !isTreatmentChosen && (<>
                    <Box height={'24px'} />
                    <Grid container direction={'column'}>
                        <Typography className={classes.label}>
                            {t('RECOMMENDED_DRUGS')}:
                        </Typography>
                        <Grid container direction={'row'} wrap={'wrap'} className={classes.recommendedDrugs}>
                            {
                                recommendedDrugs.map((recommendedDrug) => (
                                    <Grid item className={classes.recommendedDrug} onClick={() => handleChooseRecommendedDrug(recommendedDrug)}>
                                        {recommendedDrug.drug.internationalName}
                                    </Grid>))
                            }
                        </Grid>
                    </Grid>
                </>)
            }

        </Grid>
    </form>
})

export default MedicalTreatmentForm;