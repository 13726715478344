import { Button, Hidden, LinearProgress } from "@material-ui/core";
import React, { useContext, useRef } from "react";
import { useQuery } from "react-query";
import { falsyAndEmptyObject, falsyObject } from "../../utils";
import {
  createToast,
  MessagesContext,
} from "../../globalContexts/MessagesContext";
import { getErrorMessage } from "../../utils/errorHandlers";
import AnnotatorDetailsDesktop from "./desktop";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getAnnotationDetails, saveAnnotator } from "../../api/annotationApi";
import {
  HeaderLeftTeleporter,
  HeaderRightTeleporter,
} from "../../components/Header";
import history from "../../config/history";
import { Breadcrumb } from "./Breadcrumb";
import AnnotatotDetailsMobile from "./mobile";
import SupportTelephoneNumber from "../../components/SupportTelephoneNumber";

const AnnotatorDetails = () => {
  const { id: researchId } = useParams();
  const {
    data: annotationData,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(["AnnotationDetails", researchId], () =>
    getAnnotationDetails(researchId)
  );
  const dispatchMessage = useContext(MessagesContext)[1];
  const isCreating = useRef(false);
  const { t } = useTranslation(["research", "image-viewer"]);

  const research = annotationData?.research || {};

  let conclusionInfo = {
    id: research.id,
    rightEyeConclusion: research.rightEyeConclusion,
    leftEyeConclusion: research.leftEyeConclusion,
    recommendationCodenames: research.recommendationCodenames,
    recommendationText: research.recommendationText,
    leftEyes: research.leftEyes,
    rightEyes: research.rightEyes,
    status: research.status,
    responsibleDoctorUsername: research.responsibleDoctorId,
  };

  if (annotationData?.preconclusion) {
    conclusionInfo = {
      ...conclusionInfo,
      rightEyeConclusion: annotationData.preconclusion.rightEyeConclusion,
      leftEyeConclusion: annotationData.preconclusion.leftEyeConclusion,
      recommendationCodenames:
        annotationData.preconclusion.recommendationCodenames,
      recommendationText: annotationData.preconclusion.recommendationText,
    };
  }

  const patientInfo = {
    age: research.age,
    diabetes: research.diabetes,
    arterialHypertension: research.arterialHypertension,
    refractionLeft: research.refractionLeft,
    refractionRight: research.refractionRight,
    leftEyes: research.leftEyes,
    rightEyes: research.rightEyes,
    status: annotationData?.preconclusion ? "ANNOTATED" : "NOT_ANNOTATED",
    complaints: research.complaints,
  };
  async function handleSubmitConclusions(values, actions) {
    const {
      rightEyes,
      rightEyeConclusion,
      leftEyes,
      leftEyeConclusion,
      recommendationCodenames,
      recommendationText,
      status,
    } = values;
    // #region common validations
    actions.setStatus(null);
    if (rightEyes.length > 0) {
      const {
        HEALTHY,
        IMPOSSIBLE,
        OTHER,
      } = rightEyeConclusion.conclusionResult;
      if (!IMPOSSIBLE) rightEyeConclusion.conclusionResult.textImpossible = "";
      if (!OTHER) rightEyeConclusion.conclusionResult.textOther = "";
      if (falsyObject(rightEyeConclusion.conclusionResult)) {
        actions.setStatus({ error: true });
        dispatchMessage(
          createToast(t("FILL_CONCLUSION_FOR_RIGHT_EYE"), "error")
        );
        return actions.setSubmitting(false);
      }
      // Если Статус заключения не Здоров и не Невозможно дать заключение, тогда поля Рекомендации и Описания обязательны для заполнения.
      if (!HEALTHY && !IMPOSSIBLE) {
        if (falsyAndEmptyObject(rightEyeConclusion.description)) {
          actions.setStatus({ error: true });
          dispatchMessage(
            createToast(t("FILL_DESCRIPTION_FOR_RIGHT_EYE"), "error")
          );
          return actions.setSubmitting(false);
        }
        if (!recommendationCodenames && !recommendationText) {
          actions.setStatus({ error: true });
          dispatchMessage(createToast(t("FILL_RECOMMENDATIONS"), "error"));
          return actions.setSubmitting(false);
        }
      }
    }
    if (leftEyes.length > 0) {
      const { HEALTHY, IMPOSSIBLE, OTHER } = leftEyeConclusion.conclusionResult;
      if (!IMPOSSIBLE) leftEyeConclusion.conclusionResult.textImpossible = "";
      if (!OTHER) leftEyeConclusion.conclusionResult.textOther = "";
      if (falsyObject(leftEyeConclusion.conclusionResult)) {
        actions.setStatus({ error: true });
        dispatchMessage(
          createToast(t("FILL_CONCLUSION_FOR_LEFT_EYE"), "error")
        );
        return actions.setSubmitting(false);
      }
      // Если Статус заключения не Здоров и не Невозможно дать заключение, тогда поля Рекомендации и Описания обязательны для заполнения.
      if (!HEALTHY && !IMPOSSIBLE) {
        if (falsyAndEmptyObject(leftEyeConclusion.description)) {
          actions.setStatus({ error: true });
          dispatchMessage(
            createToast(t("FILL_DESCRIPTION_FOR_LEFT_EYE"), "error")
          );
          return actions.setSubmitting(false);
        }
        if (!recommendationCodenames && !recommendationText) {
          actions.setStatus({ error: true });
          dispatchMessage(createToast(t("FILL_RECOMMENDATIONS"), "error"));
          return actions.setSubmitting(false);
        }
      }
    }
    // #endregion common validations
    return handleCreateConclusions(values, actions);
  }
  async function handleCreateConclusions(values, actions) {
    const {
      rightEyes,
      leftEyes,
      rightEyeConclusion,
      leftEyeConclusion,
      recommendationCodenames,
      recommendationText,
    } = values;
    try {
      if (!isCreating.current) {
        isCreating.current = true;
        await saveAnnotator(researchId, {
          rightEyePreConclusion:
            rightEyeConclusion && rightEyes.length > 0
              ? {
                  description: rightEyeConclusion.description,
                  preconclusionDiagnos: rightEyeConclusion.conclusionResult,
                }
              : null,
          leftEyePreConclusion:
            leftEyeConclusion && leftEyes.length > 0
              ? {
                  description: leftEyeConclusion.description,
                  preconclusionDiagnos: leftEyeConclusion.conclusionResult,
                }
              : null,
          recommendationText,
          recommendationCodenames: recommendationCodenames
            ? recommendationCodenames.map((option) => option.value)
            : null,
        });
        isCreating.current = false;
        await refetch();
        return dispatchMessage(createToast(t("CONCLUSION_SAVED"), "success"));
      }
    } catch (err) {
      isCreating.current = false;
      console.error(err);
      dispatchMessage(createToast(getErrorMessage(err), "error"));
    } finally {
      actions.setSubmitting(false);
    }
  }

  const props = {
    patientInfo,
    conclusionInfo,
    handleSubmitConclusions,
  };

  return (
    <>
      <Hidden only="xs">
        <HeaderLeftTeleporter.Source>
          <Breadcrumb />
        </HeaderLeftTeleporter.Source>
        <HeaderRightTeleporter.Source>
          <div style={{display:"flex"}}>
              <SupportTelephoneNumber/>
              <Button
                className="text-white border-white"
                variant="outlined"
                onClick={history.goBack}
              >
                {t("BACK")}
              </Button>
            </div>
        </HeaderRightTeleporter.Source>
        {(isLoading || isFetching) && (
          <LinearProgress color="secondary" className="w-100" />
        )}
        <AnnotatorDetailsDesktop {...props} />
      </Hidden>
      <Hidden smUp implementation="js">
        <AnnotatotDetailsMobile {...props} />
      </Hidden>
    </>
  );
};

export default AnnotatorDetails;
