import {
  Button,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Modal,
} from "@material-ui/core";
import { GetApp, MoreHoriz } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AsyncButton from "../../components/AsyncButton";
import { MessagesContext } from "../../globalContexts/MessagesContext";
import { downloadConclusionConclusion } from "../PatientRegistry/PatientRegistry";
import {
  RESEARCH_STATUS,
  RESEARCH_SERVICE_NAME,
} from "../ResearchesPage/constants";
import {
  fetchReport,
  getImagesPdf,
} from "../ResearchesPage/ResearchesList/ResearchJournalTable";
import { ResearchServiceDetails } from "../ResearchesPage/ResearchServices/ResearchServiceDetails";

const useStyles = makeStyles(() => ({
  menu: {
    margin: "35px 0 0 50px",
  },
  button: {
    display: "flex",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export function MyResearchJournalTableActions({ row }) {
  const classes = useStyles();
  const { t } = useTranslation("research");
  const dispatchMessage = useContext(MessagesContext)[1];

  const [anchorEl, setAnchorEl] = useState(null);
  const [otherResearchServiceOpen, setOtherResearchServiceOpen] = useState(
    false
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getDetailsRoute = () => {
    if (row.serviceName === RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY) {
      return `/my-researches/${row.id}`;
    }
    if (
      row.serviceName === RESEARCH_SERVICE_NAME.OPHTHALMOLOGIST_CONSULTATION
    ) {
      return `/patientRegistry/${row.id}`;
    }
  };
  const isOcularFundusPhotography =
    row.serviceName === RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY &&
    row.status !== RESEARCH_STATUS.CREATED;
  const isOphtalmologistConsultation =
    row.serviceName === RESEARCH_SERVICE_NAME.OPHTHALMOLOGIST_CONSULTATION &&
    row.status === RESEARCH_STATUS.FINISHED;

  const otherResearchService =
    row.serviceName === RESEARCH_SERVICE_NAME.MAKLAKOV_TEST ||
    row.serviceName === RESEARCH_SERVICE_NAME.NON_CONTACT_TONOMETRY ||
    row.serviceName === RESEARCH_SERVICE_NAME.SCHIRMER_TEST ||
    row.serviceName === RESEARCH_SERVICE_NAME.EXOPHTHALMOMETRY;

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid className={classes.button}>
      {!otherResearchService ? (
        <Button
          variant="contained"
          className="mr-10"
          color="primary"
          size="small"
          title={t("DETAILS")}
          component={Link}
          to={getDetailsRoute}
        >
          <MoreHoriz />
        </Button>
      ) : (
        <>
          <Button
            variant="contained"
            className="mr-10"
            color="primary"
            size="small"
            title={t("DETAILS")}
            onClick={() => {
              setOtherResearchServiceOpen(true);
            }}
          >
            <MoreHoriz />
          </Button>
          <Modal
            open={otherResearchServiceOpen}
            onClose={() => setOtherResearchServiceOpen(false)}
            className={classes.modal}
          >
            <ResearchServiceDetails
              onClose={() => setOtherResearchServiceOpen(false)}
              research={row}
            />
          </Modal>
        </>
      )}
      {(isOcularFundusPhotography || isOphtalmologistConsultation) && (
        <Button
          className="mr-10"
          variant="contained"
          color="secondary"
          size="small"
          data-researchid={row.id}
          title={t("CONCLUSION")}
          onClick={handleClick}
        >
          <GetApp />
        </Button>
      )}
      {isOcularFundusPhotography && (
        <Menu
          open={anchorEl?.dataset.researchid == row.id}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.menu}
        >
          <MenuItem>
            <AsyncButton
              fullWidth
              onClick={() => {
                return getImagesPdf(row.id, dispatchMessage, t).finally(
                  handleClose
                );
              }}
            >
              {t("PICTURES")}
            </AsyncButton>
          </MenuItem>
          {row.status === RESEARCH_STATUS.FINISHED && (
            <MenuItem>
              <AsyncButton
                fullWidth
                onClick={() => {
                  return fetchReport(row.id, dispatchMessage, t).finally(
                    handleClose
                  );
                }}
              >
                {t("CONCLUSION")}
              </AsyncButton>
            </MenuItem>
          )}
        </Menu>
      )}
      {isOphtalmologistConsultation && (
        <Menu
          open={anchorEl?.dataset.researchid == row.id}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.menu}
        >
          <MenuItem>
            <AsyncButton
              fullWidth
              onClick={() => {
                return downloadConclusionConclusion(
                  row.id,
                  dispatchMessage,
                  t
                ).finally(handleClose);
              }}
            >
              {t("CONCLUSION")}
            </AsyncButton>
          </MenuItem>
        </Menu>
      )}
    </Grid>
  );
}
