import React, { useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import history from "../../../config/history";
import { ResultDashboardRadialBar } from "./Radialbar/ResultDashboardRadialBar";
import { ResultLineAndBarChart } from "./LineAndBarDashboardChart/ResultLineAndBarChart";
import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";
import { HeaderLeftTeleporter, HeaderRightTeleporter } from "../../../components/Header";
import { Link } from "react-router-dom";
import kc from "../../../config/keycloak";
import SupportTelephoneNumber from "../../../components/SupportTelephoneNumber";

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    margin: "15px",
  },
}));

export function ScientificDashboardPage() {
  const { t } = useTranslation("scientificAnalytics");
  const classes = useStyles();
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const [togglebutton, setToggleButton] = useState("DASHBOARD");
  const API_URL = "/sci_analytics_api";
  const handleToggleButton = (event, newAlignment) => {
    if (newAlignment === "REGISTRY") {
      setToggleButton(newAlignment);
      history.push("/scientificRegistry");
    }
  };
  const cubejsApi = cubejs(async () => await kc.token, {
    apiUrl: `${API_URL}/cubejs-api/v1`,
  });
  // const cubejsApi = cubejs({ apiUrl: "http://localhost:4000/cubejs-api/v1" });

  return (
    <>
      <HeaderLeftTeleporter.Source>
        <Typography
          variant={isMd ? "body1" : "h6"}
          color="inherit"
          component={Link}
          to="/"
        >
          {isSm ? t("SYSTEM_UDDR_SHORTENED") : t("SYSTEM_UDDR")}
        </Typography>
      </HeaderLeftTeleporter.Source>
      <HeaderRightTeleporter.Source>
            <SupportTelephoneNumber/>
        </HeaderRightTeleporter.Source>
      <CubeProvider cubejsApi={cubejsApi}>
        <ToggleButtonGroup
          value={togglebutton}
          onChange={handleToggleButton}
          exclusive
          className={classes.toggleButton}
          size="small"
        >
          <ToggleButton value="REGISTRY">{t("REGISTRY")}</ToggleButton>
          <ToggleButton value="DASHBOARD">{t("DASHBOARD")}</ToggleButton>
        </ToggleButtonGroup>

        <Paper>
          <Grid>
            <ResultDashboardRadialBar />
            <ResultLineAndBarChart />
          </Grid>
        </Paper>
      </CubeProvider>
    </>
  );
}
