import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Home, NavigateNext } from "@material-ui/icons";
import {
  Breadcrumbs,
  Button,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cw: {
    color: "#fff",
  },
}));

export function Breadcrumb() {
  const { t } = useTranslation("research");
  const classes = useStyles();
  return (
    <Breadcrumbs separator={<NavigateNext />} className={classes.cw}>
      <IconButton className={classes.cw} component={Link} to="/">
        <Home />
      </IconButton>
      <Typography
        className={classes.cw}
        variant="body1"
        component={Link}
        to="/annotator"
      >
        {t("ATTENDANCE_JOURNAL")}
      </Typography>
      <Typography variant="h6" color="inherit">
        {t("RESEARCH_DETAILS")}
      </Typography>
    </Breadcrumbs>
  );
}
