import React, { useEffect, useContext, useCallback, useState } from "react";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import {
  TableContext,
  setTableData,
} from "../../../globalContexts/TableContext";
import { RESEARCH_SERVICE_NAME, RESEARCH_STATUS } from "../constants";
import { MOBILE_WIDTH } from "../../../config/constants";
import {
  useMediaQuery,
  makeStyles,
  Input,
  Grid,
  InputAdornment,
  Button,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  Edit,
  EventNote,
  Cancel,
  Search,
  Directions,
  ErrorOutline,
  Work,
  CalendarToday,
} from "@material-ui/icons";
import { ResearchContext, setResearchesFilter } from "../ResearchContext";
import { useIsDoctor } from "../../../globalContexts/UserContext";
import MuiSelect from "../../../components/MuiSelect";
import {
  getResearchesRefferers,
  getServiceNameDictionaries,
} from "../../../api";
import { useRouterQuery } from "../../../hooks/useRouterQuery";
import { useTranslation } from "react-i18next";
import { DPicker } from "../../../components/FormComponents";

const useStyles = makeStyles((theme) => ({
  [`@media (max-width: ${MOBILE_WIDTH})`]: {
    group: {
      width: "100%",
      background: theme.palette.primary.main,
      borderRadius: 0,
    },
    button: {
      color: "#fff",
      flex: 1,
    },
    dateFrom: {
      margin: "0 10px",
    },
  },
}));

export function getStatusIcon(status, iconProps = {}) {
  switch (status) {
    case RESEARCH_STATUS.FINISHED:
      return <EventNote {...iconProps} />;
    case RESEARCH_STATUS.IN_PROGRESS:
      return <Edit />;
    case RESEARCH_STATUS.CANCELLED:
      return <Cancel {...iconProps} />;
    case RESEARCH_STATUS.CREATED:
      return <Directions {...iconProps} />;
    case RESEARCH_STATUS.TAKEN_TO_WORK:
      return <Work {...iconProps} />;
    default:
      return null;
  }
}

async function fetchReferrers() {
  const { referrers } = await getResearchesRefferers();
  return referrers.map((item) => ({
    value: item.id,
    label: item.fullName,
  }));
}

const filteredStatuses = Object.keys(RESEARCH_STATUS);
function ResearchJournalFilters({ t, searchPlaceholder }) {
  const classes = useStyles();
  const { t: tServiceName } = useTranslation("serviceNamesDict");

  // ========================FILTERS
  const [{ researchFilter }, dispatchResearch] = useContext(ResearchContext);
  const status = researchFilter.status;
  const receptionDoctor = researchFilter.receptionDoctor || null;
  const [query, handleReplace] = useRouterQuery();
  const setStatus = (newStatus) => {
    dispatchResearch(
      setResearchesFilter({ ...researchFilter, status: newStatus })
    );
  };
  const setReceptionDoctor = (receptionDoctor) =>
    dispatchResearch(
      setResearchesFilter({ ...researchFilter, receptionDoctor })
    );

  useEffect(() => {
    if (isMobile) handleReplace("serviceName", "OCULAR_FUNDUS_PHOTOGRAPHY");
  });
  const [serviceName, setServiceName] = useState(
    () => query.get("serviceName") || null
  );
  const periodStart = query.get("periodStart") || null;
  const periodEnd = query.get("periodEnd") || null;

  // ========================FILTERS

  const isMobile = useMediaQuery(`(max-width: ${MOBILE_WIDTH})`);
  const [{ researches }, dispatch] = useContext(TableContext);
  useEffect(() => {
    dispatch(
      setTableData("researches", {
        propFilters: {
          status,
          receptionDoctor,
          serviceName: serviceName?.value,
          periodStart,
          periodEnd,
        },
      })
    );
  }, [dispatch, status, receptionDoctor, serviceName, periodStart, periodEnd]);
  const serviceNamesAvailable = [
    RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY,
    RESEARCH_SERVICE_NAME.OPHTHALMOLOGIST_CONSULTATION,
  ];
  
  useEffect(() => {
    if(isMobile){
      setServiceName({value: 'OCULAR_FUNDUS_PHOTOGRAPHY', label: 'Фотографирование глазного дна'});
      handleReplace("serviceName", "OCULAR_FUNDUS_PHOTOGRAPHY");
    }
  },[isMobile]);

  const fetchServiceNames = useCallback(async () => {
    const serviceNames = await getServiceNameDictionaries();
    return serviceNames
      .filter(({ codename }) => serviceNamesAvailable.includes(codename))
      .map(({ codename }) => ({
        value: codename,
        label: tServiceName(codename),
      }));
  }, [tServiceName]);

  const isDoctor = useIsDoctor();
  if (!researches) return null;
  return (
    <Grid container direction="column">
      <Grid container alignItems="flex-end" justify="space-between">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ToggleButtonGroup
            value={status}
            size="small"
            className={classes.group}
            exclusive
            onChange={(_e, status) => setStatus(status || "")}
          >
            {!isMobile && (
              <ToggleButton
                value=""
                className={classes.button}
                selected={status === ""}
              >
                {t("ALL")}
              </ToggleButton>
            )}
            {filteredStatuses.map((status) => (
              <ToggleButton
                key={status}
                value={status}
                className={classes.button}
              >
                {(isMobile && getStatusIcon(status)) || t(`STATUS_${status}`)}
              </ToggleButton>
            ))}
            {isDoctor && (
              <ToggleButton
                value="MINE"
                className={classes.button}
                selected={status === "MINE"}
              >
                {t("STATUS_MINE")}
              </ToggleButton>
            )}
            <ToggleButton
              value="HAS_PATHALOGY"
              className={classes.button}
              selected={status === "HAS_PATHALOGY"}
            >
              {isMobile ? <ErrorOutline /> : t("HAS_PATHALOGY")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MuiSelect
            isAsync
            value={receptionDoctor}
            onChange={setReceptionDoctor}
            placeholder={t("RECEPTION_DOCTOR_FULL_NAME")}
            fetchData={fetchReferrers}
          />
        </Grid>
        <Grid container item xs={12} sm={5} md={2} lg={3}>
          <Grid item xs={9} sm={12}>
            <Input
              value={researches.search}
              onChange={(e) =>
                dispatch(setTableData("researches", { search: e.target.value }))
              }
              fullWidth
              type="search"
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              placeholder={searchPlaceholder}
            />
          </Grid>
          {isMobile && (
            <Grid item xs={3}>
              <Button
                color="secondary"
                fullWidth
                size="small"
                variant="contained"
                component={Link}
                to={"/researches/new-research"}
              >
                {t("CREATE")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="space-between">
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <MuiSelect
            isAsync
            value={serviceName}
            onChange={(serviceName) => {
              if(!isMobile){
                setServiceName(serviceName);
                handleReplace("serviceName", serviceName?.value);  
              }
            }}
            placeholder={t("CHOOSE_SERVICE_NAME")}
            fetchData={fetchServiceNames}
          />
        </Grid>
        <Grid container item xs={12} sm={12} md={6} lg={6}>
          <Grid
            container
            alignItems="center"
            item
            xs={5}
            sm={6}
            md={6}
            lg={6}
            className={classes.dateFrom}
          >
            <Box width="5px" />
            <DPicker
              placeholder={t("PERIOD_FROM")}
              value={periodStart}
              okLabel={t("OK")}
              clearLabel={t("CLEAR")}
              cancelLabel={t("CANCEL_LABEL")}
              iconProps={<CalendarToday />}
              onChange={(date) =>
                handleReplace("periodStart", date?.toISOString())
              }
            />
          </Grid>
          <Grid container alignItems="center" item xs={5} sm={6} md={6} lg={6}>
            <Box width="5px" />
            <DPicker
              placeholder={t("PERIOD_TO")}
              okLabel={t("OK")}
              clearLabel={t("CLEAR")}
              cancelLabel={t("CANCEL_LABEL")}
              value={periodEnd}
              onChange={(date) =>
                handleReplace("periodEnd", date?.toISOString())
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(ResearchJournalFilters);
