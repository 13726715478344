import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import { useResearchDetails } from "../../../ResearchesPage/ResearchDetails/queryHooks";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDateAndTime } from "../../../../utils";
import { RESEARCH_SERVICE_NAME } from "../../../ResearchesPage/constants";

const useStyle = makeStyles((theme) => ({
  historyIcon: {
    color: theme.palette.primary.main,
  },
  container: {
    flex: 1,
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "300px",
  },
  historyContainer: {
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "10px",
  },
  historyContainerDisable: {
    background: "#c1c1c1",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },

  historyData: {
    textAlign: "center",
    marginLeft: "20%",
  },
}));

export function VisitHistory() {
  const { t } = useTranslation(["research", "serviceNamesDict"]);
  const classes = useStyle();
  const { id } = useParams();
  const [{ data: research, isLoading }] = useResearchDetails(id);

  function handleClick(history) {
    if (
      RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY === history.serviceName
    ) {
      window
        .open(window.location.origin + "/researches/" + history.id, "_blank")
        .focus();
      return;
    }
    if (
      RESEARCH_SERVICE_NAME.OPHTHALMOLOGIST_CONSULTATION === history.serviceName
    ) {
      window
        .open(
          window.location.origin + "/patientRegistry/" + history.id,
          "_blank"
        )
        .focus();
      return;
    }
    if (history.originalResearchId) {
      window
        .open(
          window.location.origin +
            "/patientRegistry/" +
            history.originalResearchId,
          "_blank"
        )
        .focus();
    }
  }
  return (
    <Grid className={classes.container}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        research.historyOfAppointments.map((history) => {
          return (
            <Paper
              className={
                history.id === Number(id)
                  ? classes.historyContainerDisable
                  : classes.historyContainer
              }
              key={history.id}
              elevation={3}
              onClick={() => history.id !== Number(id) && handleClick(history)}
            >
              <Grid>
                <DateRange className={classes.historyIcon} />
              </Grid>
              <Grid className={classes.historyData}>
                <Typography>{formatDateAndTime(history.timestamp)}</Typography>
                <Typography>
                  {t(`serviceNamesDict:${history.serviceName}`)}
                </Typography>
                <Typography>
                  {t("STATUS")}: {t(`STATUS_${history.status}`)}
                </Typography>
              </Grid>
            </Paper>
          );
        })
      )}
    </Grid>
  );
}
