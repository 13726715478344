import React from "react";
import { Prompt, useLocation } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";

export default function BeforeLoose({ condition, message }) {
  const location = useLocation();
  useBeforeunload(() => {
    if (condition) return message;
  });
  return (
    <Prompt
      when={condition}
      message={(newLocation) =>
        newLocation.pathname === location.pathname ? true : message
      }
    />
  );
}
