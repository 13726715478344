import {
  Button,
  Hidden,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import { useQuery } from "react-query";
import ScientificDetailsDesktop from "./desktop";
import { getReserchForDetailsForScientific } from "../../../api/dashboard";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  HeaderLeftTeleporter,
  HeaderRightTeleporter,
} from "../../../components/Header";
import { useTranslation } from "react-i18next";
import SupportTelephoneNumber from "../../../components/SupportTelephoneNumber";

const AutoconclusionScientificDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const { t } = useTranslation();
  const { data: scientificData, isLoading: dataLoading, error } = useQuery(
    ["researchDataForScientific", id],
    () => getReserchForDetailsForScientific(id)
  );

  const research = scientificData?.research || {};
  const conclusionInfo = {
    id: research.id,
    rightEyeConclusion: research.rightEyeConclusionAuto,
    leftEyeConclusion: research.leftEyeConclusionAuto,
    leftEyes: research.leftEyes,
    rightEyes: research.rightEyes,
    status: research.status,
    responsibleDoctorUsername: research.responsibleDoctorId,
  };

  const patientInfo = {
    birthday: research.birthday,
    diabetes: research.diabetes,
    arterialHypertension: research.arterialHypertension,
    refractionLeft: research.refractionLeft,
    refractionRight: research.refractionRight,
    leftEyes: research.leftEyes,
    rightEyes: research.rightEyes,
    status: research.status,
    complaints: research.complaints,
  };

  const props = { patientInfo, conclusionInfo };

  return (
    <>
      {dataLoading && <LinearProgress color="secondary" className="w-100" />}
      <Hidden only="xs">
        <HeaderLeftTeleporter.Source>
          <Typography
            variant={isMd ? "body1" : "h6"}
            color="inherit"
            component={Link}
            to="/"
          >
            {isSm ? t("SYSTEM_UDDR_SHORTENED") : t("SYSTEM_UDDR")}
          </Typography>
        </HeaderLeftTeleporter.Source>
        <HeaderRightTeleporter.Source>
        <div style={{display:"flex"}}>
            <SupportTelephoneNumber/>
            <Button
              className="text-white border-white"
              variant="outlined"
              onClick={history.goBack}
              >
                {t("BACK")}
              </Button>
          </div>
        </HeaderRightTeleporter.Source>
        <ScientificDetailsDesktop {...props} />
      </Hidden>
    </>
  );
};

export default AutoconclusionScientificDetails;
