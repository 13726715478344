import instance, { scientificApiInctance } from "../config/request";
import axios from "axios";

export function getDoctorsData() {
  return instance.get("/keycloak/doctors").then((res) => res.data);
}

export function getOrganizationsData() {
  return instance.get("/keycloak/groupsInfo").then((res) => res.data);
}

export function getReserchForDetailsForScientific(id) {
  return scientificApiInctance
    .get(`/info/research/${id}`)
    .then((res) => res.data);
}
