import React, { useState, useMemo, useEffect, useContext } from "react";
import CornerstoneViewport from "react-cornerstone-viewport";
import cornerstone from "cornerstone-core";
import {
  Typography,
  Grid,
  makeStyles,
  Popover,
  IconButton,
  Slider,
  Menu,
  MenuItem,
  Box,
} from "@material-ui/core";
import {
  Brightness6,
  PanTool,
  Pageview,
  Create,
  YouTube,
  Layers,
  RotateLeft,
  PlayArrow,
  Stop,
  ZoomIn,
  Opacity,
  Restore,
  CloudDownload,
  ArrowDropDown,
  PeopleAlt,
  Update,
  Share,
  Brightness1,
  Storage,
} from "@material-ui/icons";
import AsyncButton from "../AsyncButton";
import { SharedButton } from "../../modules/SharePage/SharedButton";
import { useTranslation } from "react-i18next";
import { useRouterQuery } from "../../hooks/useRouterQuery";
import { useParams } from "react-router-dom";

const tools = [
  // Mouse
  {
    name: "Wwwc",
    mode: "active",
    modeOptions: { mouseButtonMask: 1 },
  },
  {
    name: "Zoom",
    mode: "active",
    modeOptions: { mouseButtonMask: 2 },
  },
  {
    name: "Pan",
    mode: "active",
    modeOptions: { mouseButtonMask: 4 },
  },
  "Length",
  "Angle",
  "Bidirectional",
  "FreehandRoi",
  "Eraser",
  "Magnify",
  "Rotate",
  {
    name: "TextMarker",
    mode: "active",
    props: {
      configuration: {
        markers: ["F5", "F4", "F3", "F2", "F1"],
        current: "F5",
        ascending: true,
        loop: true,
      },
    },
  },
  { name: "ArrowAnnotate", mode: "active" },
  // Scroll
  { name: "StackScrollMouseWheel", mode: "active" },
  // Touch
  { name: "PanMultiTouch", mode: "active" },
  { name: "ZoomTouchPinch", mode: "active" },
  { name: "StackScrollMultiTouch", mode: "active" },
];

const CHANNELS = {
  DEFAULT: "",
  RED: "RED",
  GREEN: "GREEN",
  BLUE: "BLUE",
  RED_GREEN: "RED_GREEN",
  RED_BLUE: "RED_BLUE",
  GREEN_BLUE: "GREEN_BLUE",
};

const toolsBtnGroup = [
  {
    name: "Wwwc",
    label: "Уровни",
    icon: <Brightness6 />,
  },
  {
    name: "Pan",
    label: "Панорамирование",
    icon: <PanTool />,
  },
  { name: "Zoom", label: "Масштаб", icon: <ZoomIn /> },
  { name: "Magnify", label: "Лупа", icon: <Pageview /> },
  { name: "Rotate", label: "Изменить", icon: <RotateLeft /> },
];

const useStyles = makeStyles(() => {
  const TOOLS_HEIGHT = "54px";
  return {
    tools: {
      height: TOOLS_HEIGHT,
      padding: "8px",
      flexWrap: "nowrap",
      overflowX: "auto",
      background: "#2a2929",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      overflowY: "hidden",
    },
    activeTool: {
      border: "1px solid grey",
    },
    viewer: {
      height: `calc(70vh - ${TOOLS_HEIGHT})`,
      maxHeight: `calc(70vh - ${TOOLS_HEIGHT})`,
      padding: "4px",
      background: "black",
      "& .active-vp": {
        border: "2px solid #d32f2f",
      },
      "& .nonactive-vp": {
        border: "1px solid #901a1a",
      },
    },
    cell: {
      border: "1px solid #777",
      display: "inline-block",
      width: "18px",
      height: "18px",
      margin: "1px",

      "&.active": {
        background: "#777",
      },
    },
    icon: {
      fontSize: "24px",
      margin: "0px 12px",
      color: "white",
    },
    imageGridContainer: {
      overflowX: "auto",
      background: "black",
      padding: "0px 4px",
    },
    image: {
      margin: "0px 6px",
      "&[data-active=true]": {
        border: "1px solid red",
      },
    },
  };
});

function updateImage(enabledElement, pixelData) {
  const newImg = {
    ...enabledElement.image,
    getPixelData: () => pixelData,
  };
  cornerstone.displayImage(enabledElement.element, newImg);
  viewportUpdateHack(enabledElement);
}

function viewportUpdateHack(enabledElement) {
  enabledElement.viewport.voi.windowCenter += 10 ** -10;
  cornerstone.setViewport(enabledElement.element, enabledElement.viewport);
}

function applyChannel(channel, pixelData) {
  if (channel === CHANNELS.RED) {
    for (let i = 0; i < pixelData.length; i += 4) {
      // green = red
      pixelData[i + 1] = pixelData[i];
      // blue = red
      pixelData[i + 2] = pixelData[i];
    }
  }
  if (channel === CHANNELS.GREEN) {
    for (let i = 0; i < pixelData.length; i += 4) {
      // red = green
      pixelData[i] = pixelData[i + 1];
      // blue = green
      pixelData[i + 2] = pixelData[i + 1];
    }
  }
  if (channel === CHANNELS.BLUE) {
    for (let i = 0; i < pixelData.length; i += 4) {
      // red = blue
      pixelData[i] = pixelData[i + 2];
      // green = blue
      pixelData[i + 1] = pixelData[i + 2];
    }
  }
  if (channel === CHANNELS.GREEN_BLUE) {
    for (let i = 0; i < pixelData.length; i += 4) {
      let greenAndBlue = pixelData[i + 1] + pixelData[i + 2];
      if (greenAndBlue > 255) {
        greenAndBlue = 255;
      }
      // red = blue + green
      pixelData[i] = greenAndBlue;
      // green = blue + green
      pixelData[i + 1] = greenAndBlue;
      // blue = blue + green
      pixelData[i + 2] = greenAndBlue;
    }
  }
  if (channel === CHANNELS.RED_BLUE) {
    for (let i = 0; i < pixelData.length; i += 4) {
      let redAndBlue = pixelData[i] + pixelData[i + 2];
      if (redAndBlue > 255) {
        redAndBlue = 255;
      }
      // red = blue + red
      pixelData[i] = redAndBlue;
      // green = blue + red
      pixelData[i + 1] = redAndBlue;
      // blue = blue + red
      pixelData[i + 2] = redAndBlue;
    }
  }
  if (channel === CHANNELS.RED_GREEN) {
    for (let i = 0; i < pixelData.length; i += 4) {
      let redAndGreen = pixelData[i] + pixelData[i + 1];
      if (redAndGreen > 255) {
        redAndGreen = 255;
      }
      // red = red + green
      pixelData[i] = redAndGreen;
      // green = red + green
      pixelData[i + 1] = redAndGreen;
      // blue = red + green
      pixelData[i + 2] = redAndGreen;
    }
  }
  return pixelData;
}

function getEyesSideAndIdxFromId(eyes, id) {
  const rightIdx = eyes.rightEyes?.findIndex((eye) => eye.id == id);
  if (rightIdx > -1) {
    return ["rightEyes", rightIdx];
  }
  const leftIdx = eyes.leftEyes?.findIndex((eye) => eye.id == id);
  if (leftIdx > -1) {
    return ["leftEyes", leftIdx];
  }
  if (eyes.rightEyes) {
    return ["rightEyes", 0];
  }
  if (eyes.leftEyes) {
    return ["leftEyes", 0];
  }
  return [undefined, null];
}

export function CornerstoneImageViewerMobile({
  eyes,
  activeImg,
  setActiveImg,
  CustomOverlay,
  isConclusionOpen,
  handleDownloadPicture,
  handleDownloadImagesPdf,
  handleDownloadConclusion,
  handleConcilium,
  handleShared,
  showSharedButton,
  showDownloadButton,
  showConciliumButton,
  erdbHistory
}) {
  const classes = useStyles();
  const [activeTool, setActiveTool] = useState("Pan");
  const [frameRate, setFrameRate] = useState(22);
  const { id: researchId } = useParams();
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeViewportIndex, setActiveViewportIndex] = useState("0_0");
  const [viewportEnabledElement, setViewportEnabledElement] = useState({
    [activeViewportIndex]: null,
  });
  const [query, handleReplace] = useRouterQuery();
  const [side, activeImgIdx] = getEyesSideAndIdxFromId(eyes, activeImg);
  const defaultViewportImg = {
    activeImgIdx,
    eyesImg: eyes[side],
    side,
  };
  const [viewportImg, setViewportImg] = useState({
    "0_0": defaultViewportImg,
    "0_1": defaultViewportImg,
    "0_2": defaultViewportImg,
    "1_0": defaultViewportImg,
    "1_1": defaultViewportImg,
    "1_2": defaultViewportImg,
    "2_0": defaultViewportImg,
    "2_1": defaultViewportImg,
    "2_2": defaultViewportImg,
  });
  const [layout, setLayout] = useState([1, 1]);
  const rows = useMemo(() => Array(layout[0]).fill(true), [layout]);
  const cols = useMemo(() => Array(layout[1]).fill(true), [layout]);
  const enabledElement = viewportEnabledElement[activeViewportIndex];

  useEffect(() => {
    const [side, activeImgIdx] = getEyesSideAndIdxFromId(eyes, activeImg);
    setViewportImg((state) => ({
      ...state,
      [activeViewportIndex]: {
        activeImgIdx,
        eyesImg: eyes[side],
        side,
      },
    }));
  }, [activeImg, eyes, getEyesSideAndIdxFromId]);

  return (
    <Grid container className="h-100" direction="column">
      <Grid
        key={JSON.stringify(layout)}
        container
        item
        direction="column"
        className={classes.viewer}
      >
        {rows.map((_, ridx) => (
          <Grid key={ridx} item className="flex-1 minh-0 h-100" container>
            {cols.map((_, cidx) => (
              <Grid
                item
                key={cidx}
                xs={12 / layout[1]}
                className="maxh-100"
                onDragOver={(e) => {
                  e.preventDefault();
                  setActiveViewportIndex(`${ridx}_${cidx}`);
                }}
                onDrop={(e) => {
                  setActiveImg(e.dataTransfer.getData("text"));
                }}
              >
                <CornerstoneViewport
                  key={isConclusionOpen.toString()}
                  tools={tools}
                  activeTool={activeTool}
                  isPlaying={isPlaying}
                  frameRate={frameRate}
                  className={`h-100 flex-1 ${
                    activeViewportIndex === `${ridx}_${cidx}`
                      ? "active-vp"
                      : "nonactive-vp"
                  }`}
                  imageIds={viewportImg[`${ridx}_${cidx}`].eyesImg.map(
                    (eye) => `${eye.fullUrl}`
                  )}
                  imageIdIndex={viewportImg[`${ridx}_${cidx}`].activeImgIdx}
                  enableResizeDetector={false}
                  viewportOverlayComponent={(props) => (
                    <CustomOverlay
                      {...props}
                      side={viewportImg[`${ridx}_${cidx}`].side}
                      image={viewportEnabledElement[`${ridx}_${cidx}`].image}
                    />
                  )}
                  setViewportActive={() => {
                    setActiveViewportIndex(`${ridx}_${cidx}`);
                    const newViewportImg = viewportImg[`${ridx}_${cidx}`];
                    setActiveImg(
                      newViewportImg.eyesImg[newViewportImg.activeImgIdx].id
                    );
                  }}
                  onNewImage={({ currentImageIdIndex }) => {
                    if (
                      viewportImg[activeViewportIndex].eyesImg[
                        currentImageIdIndex
                      ]
                    ) {
                      setActiveImg(
                        viewportImg[activeViewportIndex].eyesImg[
                          currentImageIdIndex
                        ].id
                      );
                    }
                  }}
                  onElementEnabled={(elementEnabledEvt) => {
                    setViewportEnabledElement((state) => ({
                      ...state,
                      [`${ridx}_${cidx}`]: elementEnabledEvt.detail,
                    }));
                  }}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
      <Grid container wrap="nowrap" className={classes.imageGridContainer}>
        {eyes["rightEyes"]?.length > 0 &&
          eyes["rightEyes"].map(({ id, fullUrl, contentType, base64 }) => (
            <Grid
              key={id}
              item
              xs={2}
              data-active={activeImg === id}
              onClick={() => handleReplace("activeImg", id, researchId)}
              className={classes.image}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData("text/plain", id);
              }}
            >
              <img width="100%" src={`${fullUrl}`} alt="rightEyes" />
            </Grid>
          ))}
        {eyes["leftEyes"]?.length > 0 &&
          eyes["leftEyes"].map(({ id, smallUrl }) => (
            <Grid
              key={id}
              item
              xs={2}
              onClick={() => {
                handleReplace("activeImg", id, researchId);
              }}
              data-active={activeImg === id}
              className={classes.image}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData("text/plain", id);
              }}
            >
              <img width="100%" src={`${smallUrl}`} alt="leftEyes" />
            </Grid>
          ))}
      </Grid>
      <Tools
        activeTool={activeTool}
        handlePlayClick={() => setIsPlaying((prevState) => !prevState)}
        handleFrameRateChange={(_, newValue) => setFrameRate(newValue)}
        frameRate={frameRate}
        isPlaying={isPlaying}
        handleActiveToolChange={(tool) => setActiveTool(tool)}
        handleLayoutChange={(newValue) => setLayout(newValue)}
        handleChannelChange={(ch) => {
          if (enabledElement) {
            const { eyesImg, activeImgIdx } = viewportImg[activeViewportIndex];
            const activeImageOrig = cornerstone.imageCache.cachedImages.find(
              (cache) => cache.imageId.includes(eyesImg[activeImgIdx].fullUrl)
            );
            const pixels = applyChannel(
              ch,
              activeImageOrig.image.getPixelData()
            );
            updateImage(enabledElement, pixels);
          }
        }}
        showDownloadButton={showDownloadButton}
        showSharedButton={showSharedButton}
        handleReset={() => {
          cornerstone.reset(enabledElement.element);
          const { eyesImg, activeImgIdx } = viewportImg[activeViewportIndex];
          const activeImageOrig = cornerstone.imageCache.cachedImages.find(
            (cache) => cache.imageId.includes(eyesImg[activeImgIdx].fullUrl)
          );
          updateImage(enabledElement, activeImageOrig.image.getPixelData());
        }}
        handleDownloadPicture={handleDownloadPicture}
        handleDownloadImagesPdf={handleDownloadImagesPdf}
        handleDownloadConclusion={handleDownloadConclusion}
        handleConcilium={handleConcilium}
        handleShared={handleShared}
        showConciliumButton={showConciliumButton}
        erdbHistory={erdbHistory}
      />
    </Grid>
  );
}

function Tools(props) {
  const {
    activeTool,
    handleActiveToolChange,
    handleChannelChange,
    handleReset,
    handleDownloadPicture,
    handleDownloadImagesPdf,
    handleDownloadConclusion,
    handleConcilium,
    handleShared,
    showConciliumButton,
    showDownloadButton,
    showSharedButton,
    erdbHistory
  } = props;

  const [downloadAnchor, setDownloadAnchor] = useState(null);
  const [channelAnchor, setChannelAnchor] = useState(null);
  const classes = useStyles();
  function changeChannel(ch) {
    handleChannelChange(ch);
    setChannelAnchor(null);
  }
  const { t } = useTranslation("image-viewer", { useSuspense: false });
  return (
    <Grid container className={classes.tools}>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingY={"8px"}
        onClick={() => handleActiveToolChange("Pan")}
        className={activeTool === "Pan" ? classes.activeTool : ""}
      >
        <PanTool className={classes.icon} />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingY={"8px"}
        onClick={() => handleActiveToolChange("Wwwc")}
        className={activeTool === "Wwwc" ? classes.activeTool : ""}
      >
        <Brightness6 className={classes.icon} />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingY={"8px"}
        onClick={() => handleActiveToolChange("Rotate")}
        className={activeTool === "Rotate" ? classes.activeTool : ""}
      >
        <RotateLeft className={classes.icon} />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingY={"8px"}
        onClick={handleReset}
      >
        <Restore className={classes.icon} />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingY={"8px"}
        onClick={(el) => setDownloadAnchor(el.currentTarget)}
      >
        <CloudDownload className={classes.icon} />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingY={"8px"}
        onClick={(el) => setChannelAnchor(el.currentTarget)}
      >
        <Opacity className={classes.icon} />
      </Box>
      {showSharedButton && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingY={"8px"}
        >
          <Share className={classes.icon} onClick={handleShared} />
        </Box>
      )}
      {showConciliumButton && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingY={"8px"}
          onClick={handleConcilium}
        >
          <PeopleAlt className={classes.icon} />
        </Box>
      )}
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingY={"8px"}
        onClick={erdbHistory}
      >
        <Storage className={classes.icon}/>
      </Box>

      <Menu
        anchorEl={channelAnchor}
        keepMounted
        open={Boolean(channelAnchor)}
        onClose={() => setChannelAnchor(null)}
      >
        <MenuItem onClick={() => changeChannel(CHANNELS.DEFAULT)}>
          {t("DISCARD")}
        </MenuItem>
        <MenuItem onClick={() => changeChannel(CHANNELS.RED)}>
          {t("RED")}
        </MenuItem>
        <MenuItem onClick={() => changeChannel(CHANNELS.GREEN)}>
          {t("GREEN")}
        </MenuItem>
        <MenuItem onClick={() => changeChannel(CHANNELS.BLUE)}>
          {t("BLUE")}
        </MenuItem>
        <MenuItem onClick={() => changeChannel(CHANNELS.GREEN_BLUE)}>
          {t("GREEN")}, {t("BLUE")}
        </MenuItem>
        <MenuItem onClick={() => changeChannel(CHANNELS.RED_BLUE)}>
          {t("RED")}, {t("BLUE")}
        </MenuItem>
        <MenuItem onClick={() => changeChannel(CHANNELS.RED_GREEN)}>
          {t("RED")}, {t("GREEN")}
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={downloadAnchor}
        keepMounted
        open={Boolean(downloadAnchor)}
        onClose={() => setDownloadAnchor(null)}
      >
        <MenuItem onClick={handleDownloadPicture}>{t("PICTURES")}</MenuItem>
        {showDownloadButton && (
          <MenuItem>
            <AsyncButton onClick={handleDownloadImagesPdf} fullWidth>
              <Typography className="text-transform-none">
                {t("PDF_PICS")}
              </Typography>
            </AsyncButton>
          </MenuItem>
        )}
        {handleDownloadConclusion && (
          <MenuItem>
            <AsyncButton onClick={handleDownloadConclusion} fullWidth>
              <Typography className="text-transform-none">
                {t("CONCLUSION")}
              </Typography>
            </AsyncButton>
          </MenuItem>
        )}
      </Menu>
    </Grid>
  );
}
