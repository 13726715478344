import React, { useContext } from "react";
import {
  renderTextField,
  renderSelect,
} from "../../../components/FormComponents";
import { Field } from "formik";
import { Typography } from "@material-ui/core";
import { DictionariesContext } from "../../../globalContexts/DictionariesContext";
import { mapDictionionaryForSelect } from "../../../utils";
import {RESEARCH_SERVICE_NAME} from "../../ResearchesPage/constants";
import {useTranslation} from "react-i18next";

export default function SharedRecommendation({ t, hasEyesImage }) {
  const { recommendations: recommendationsAll } = useContext(DictionariesContext)[0];
  const { i18n } = useTranslation();
  const recommendations = recommendationsAll.filter(
      ({serviceNames}) => serviceNames.includes(RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY)
  )
  if (!hasEyesImage) return null;

  return (
    <>
      <Typography>{t("RECOMENDATION")}</Typography>
      <Field
        name="recommendationCodenames"
        component={renderSelect}
        placeholder={t("CHOOSE_CONSULTATION")}
        options={mapDictionionaryForSelect(recommendations, i18n.language)}
        menuPlacement="auto"
        isMulti
      />
      <Field
        name="recommendationText"
        className="mt-10 pl-10 pr-10"
        label={t("RECOMENDATION_TEXT")}
        component={renderTextField}
        multiline
        rows="5"
        fullWidth
        variant="outlined"
      />
    </>
  );
}
