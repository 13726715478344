import React from "react";
import {
  Grid,
  Paper,
  Button,
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { formatDateAndTime } from "../../../utils";
import { getMyResearchesVisitHistory, getVisitHistory } from "../../../api";
import { useIsPatient } from "../../../globalContexts/UserContext";
import { MoreHoriz } from "@material-ui/icons";
import { useQuery } from "react-query";

const useStyles = makeStyles(() => ({
  table: {
    maxWidth: "100%",
  },
  title: {
    padding: "4px",
    background: "#F5F5F5",
  },
  tableHead: {
    background: "#F5F5F5",
  },
  equalRow: {
    background: "#E0E0E0",
  },
}));
function VisitHistoryMyResearch({ t, iin, id }) {
  const classes = useStyles();
  const isPatient = useIsPatient();

  const { data, isLoading, error } = useQuery(
    ["VisitHistoryMyResearch"],
    () => {
      return getMyResearchesVisitHistory();
    }
  );

  const history = useHistory();
  const handleOnClick = () => {
    history.push("/my-researches");
  };

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Paper>
      <Grid container className={classes.title} justify="space-between">
        <Typography variant="h6">{t("VISIT_HISTORY")}</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={iin?.length !== 12}
          onClick={handleOnClick}
        >
          {t("ALL_LIST")}
        </Button>
      </Grid>
      <Table className={classes.table} size="small">
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>{t("VISIT_TIME")}</TableCell>
            <TableCell>{t("STATUS")}</TableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading == true ? (
            <CircularProgress />
          ) : (
            data.result?.researches?.map((research) => {
              const isDetailsItself = research.id == id;
              return (
                <TableRow
                  key={research.id}
                  className={isDetailsItself && classes.equalRow}
                >
                  <TableCell>
                    {formatDateAndTime(research.appointmentTimestamp)}
                  </TableCell>
                  <TableCell>{t(`STATUS_${research.status}`)}</TableCell>
                  <TableCell align="right">
                    {!isDetailsItself && (
                      <Button
                        variant="contained"
                        className="mr-10"
                        color="primary"
                        size="small"
                        title={isPatient ? t("LOOK") : t("DETAILS")}
                        component={Link}
                        to={`/my-researches/${research.id}`}
                      >
                        <MoreHoriz />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      {iin?.length !== 12 && (
        <Typography>{t("ATTENDANCE_LIST_DISPLAYED")}</Typography>
      )}
    </Paper>
  );
}

export default VisitHistoryMyResearch;
