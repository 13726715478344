import React, { useState } from "react";
import kc from "../config/keycloak";
import { ROLES } from "../config/constants";

// if user has several roles give it the most valuable one;
const roleLevels = {
  [ROLES.PATIENT]: 1,
  [ROLES.NURSE]: 2,
  [ROLES.DOCTOR]: 3,
  [ROLES.ADMIN]: 4,
  [ROLES.ANNOTATOR]: 5,
};

export function getMostValuableRole(roles) {
  let roleLevel = 0;
  let role;

  roles.forEach((testedRole) => {
    const testedRoleLevel = roleLevels[testedRole] || 0;
    if (testedRoleLevel > roleLevel) {
      roleLevel = testedRoleLevel;
      role = testedRole;
    }
  });
  return role || roles[0];
}

export const KeycloakContext = React.createContext(null);
export function initKeycloak(onLoad, setKeycloak, handleLogin) {
  kc.init({
    onLoad,
    promiseType: "native",
    checkLoginIframe: false,
    silentCheckSsoRedirectUri:
      window.location.origin + "/silent-check-sso.html",
  }).then((authenticated) => {
    if (authenticated) {
      setKeycloak(kc);
      kc.loadUserInfo().then((userInfo) => {
        const [lastName, secondName] = userInfo.family_name?.split(" ") || " ";
        const user = {
          id: userInfo.sub,
          lastName: lastName,
          firstName:
            secondName == undefined
              ? userInfo.given_name
              : userInfo.given_name + " " + secondName,
          name: userInfo.preferred_username,
          email: userInfo.email || "",
          phone: userInfo?.attributes?.phone || "",
          role: getMostValuableRole(kc.tokenParsed.realm_access.roles),
          token: kc.token,
        };
        handleLogin(user);
      });
    }
  });
}

export default function KeycloakContextProvider({ children, kc }) {
  const [state, setState] = useState(kc || null);
  return (
    <KeycloakContext.Provider value={[state, setState]}>
      {children}
    </KeycloakContext.Provider>
  );
}
