import { initialState } from ".";
import { ADD_DICTIONARY, INITIATE_DICTIONARIES } from "./dictionariesActions";

export default function dictionariesReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_DICTIONARY:
      return { ...state, [action.key]: action.dictionary };
    case INITIATE_DICTIONARIES:
      return { ...state, ...action.initialDictionaries };
    default:
      return state;
  }
}
