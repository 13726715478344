import instance from "../config/request";

export async function getUserDataByUserName({ userName }) {
  return instance
    .get(`/registration/getUser/${userName}`)
    .then((res) => res.data);
}

export async function updateUserData({ id, data }) {
  return instance.put(`/personialUserData/${id}`, data).then((res) => res.data);
}

export async function updateUserPassword({ userName, data }) {
  return instance.put(`/personialUserData/changePassword/${userName}`, data);
}

export async function deleteOphthalmologistDocument({ id }) {
  return instance
    .delete(`/ophthalmologistRegistry/${id}`)
    .then((res) => res.data);
}
