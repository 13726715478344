import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import {
  ClassRounded,
  Close,
  Delete,
  Edit,
  Visibility,
} from "@material-ui/icons";
import { formatDate } from "../../../../utils";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ROLES } from "../../../../config/constants";
import Roled from "../../../../components/Roled";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "auto",
    maxHeight: "300px",
  },
  treatment: {
    "& .bold": {
      fontWeight: "bold",
    },
    padding: "6px",
    "& span": {
      fontSize: "14px",
    },
    marginBottom: "10px",
  },
  drugInfo: {
    padding: "6px",
    cursor: "pointer",
    borderRadius: "6px",
    "&:hover": {
      background: theme.palette.grey.A200,
    },
  },
  drug: {
    padding: "6px",
    borderBottom: "1px solid " + theme.palette.divider,
  },
  icons: {
    marginLeft: "6px",
  },
  header: {
    paddingLeft: "12px",
    paddingRight: "6px",
  },
}));


const MedicalTreatmentList = ({
  allTreatments,
  setCurrentTreatments,
  setDeletingTreatmentId,
  setIsDeletingAll,
  isFinished
}) => {
  const { t } = useTranslation("patient-registry");
  const { id: researchId } = useParams();
  const classes = useStyles();

  const { isFetching } = useQuery(["researchDetails", researchId], {
    enabled: false,
  });
  if (isFetching) {
    return (
      <Grid container alignItems={"center"} justify={"center"}>
        <Box height={"10px"} />
        <CircularProgress />;
      </Grid>
    );
  }
  if (!allTreatments) {
    return null;
  }
  const handleChooseTreatment = (treatments, i) =>
    setCurrentTreatments({ ...treatments, chosenDrugIndex: i });
  const handleEditTreatment = (treatments, i) =>
    setCurrentTreatments({ ...treatments, editingDrugIndex: i });
  return (
    <Grid container direction={"column"}>
      {allTreatments.map((researchTreatments) => (
        <Grid
          direction={"column"}
          key={researchTreatments.researchId}
          container
          className={classes.treatment}
        >
          <Grid
            direction={"row"}
            container
            justifyContent={"space-between"}
            className={classes.header}
          >
            <Typography>{formatDate(researchTreatments.timestamp)}</Typography>
            {Number(researchTreatments.researchId) === Number(researchId) && !isFinished && (
              <Close
                cursor={"pointer"}
                onClick={() => setIsDeletingAll(true)}
              />
            )}
          </Grid>
          {researchTreatments.treatments.map((drug, i) => (
            <Grid
              key={drug.id}
              className={classes.drug}
              container
              direction={"row"}
              wrap={"nowrap"}
              alignItems={"center"}
            >
              <Grid
                className={classes.drugInfo}
                item
                direction={"column"}
                lg={true}
                onClick={() => handleChooseTreatment(researchTreatments, i)}
              >
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"flex-start"}
                >
                  <span className={"bold"}>{drug.internationalName}</span>
                </Grid>
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid
                    item
                  >
                    <span>{t("DOSAGE")}: {drug.dosage}</span>
                    <div>{drug.duration}-{drug.periodicity}</div>

                  </Grid>
                  <span>{t("INPUT_METHOD")}: {drug.inputMethod}</span>
                </Grid>
              </Grid>
              {Number(researchTreatments.researchId) === Number(researchId) && !isFinished && (
                <Grid className={classes.icons}>
                  <Roled roles={[ROLES.DOCTOR]}>
                    <Edit
                      onClick={() => handleEditTreatment(researchTreatments, i)}
                      cursor={"pointer"}
                    />
                  </Roled>
                  <Roled roles={[ROLES.DOCTOR]}>
                    <Delete
                      onClick={() => setDeletingTreatmentId(drug.id)}
                      cursor={"pointer"}
                    />
                  </Roled>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default MedicalTreatmentList;
