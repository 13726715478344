import React from "react";
import UserContextProvider from "./UserContext";
import LngContextProvider from "./LngContext";
import TableContextProvider from "./TableContext";
import MessagesContextProvider from "./MessagesContext";
import DrawerContextProvider from "./DrawerContext";
import ModalContextProvider from "./ModalContext";
import KeycloakContextProvider from "./KeycloakContext";
import DictionariesContextProvider from "./DictionariesContext";

export default function GlobalContext({ children, user, kc }) {
  return (
    <KeycloakContextProvider kc={kc}>
      <DictionariesContextProvider>
        <UserContextProvider userProp={user}>
          <LngContextProvider>
            <MessagesContextProvider>
              <ModalContextProvider>
                <DrawerContextProvider>
                  <TableContextProvider>{children}</TableContextProvider>
                </DrawerContextProvider>
              </ModalContextProvider>
            </MessagesContextProvider>
          </LngContextProvider>
        </UserContextProvider>
      </DictionariesContextProvider>
    </KeycloakContextProvider>
  );
}
