import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { AttachFile, Clear } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import {
  createUniversalService,
  editUniversalService, getUniversalServiceFile,
} from "../../../../../api/patientRegistry";
import {
  createToast,
  MessagesContext,
} from "../../../../../globalContexts/MessagesContext";
import { queryClient } from "../../../../../globalContexts/queryContext";
import TextEditor from "../../../TextEditor";
import {downloadFileBase64, downloadFileObject} from "../../../../../utils";

const useStyles = makeStyles((theme) => ({
  textEditor: {
    height: "500px",
    flex: 2,
    overflow: "auto",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "6px",
    "& .ck-content": {
      background: "white",
      flex: 1,
      border: "1px solid " + theme.palette.grey.A200,
    },
  },
  fileContainer: {
    alignItems: "center",
  },
  files: {
    padding: "10px",
    alignItems: "center",
    position: "relative",
    minHeight: "50px",
    border: "1px solid #d5d5d5",
    borderRadius: "6px",
    "&:hover": {
      border: "1px solid #000",
    },
  },
  filesName: {
    background: "#d5d5d5",
    padding: "5px 12px",
    margin: "2px",
    marginRight: "12px",
    borderRadius: "6px",
    cursor: 'pointer'
  },
  fileButton: {
    position: "absolute",
    left: "95%",
  },
}));

const UniversalServiceForm = React.forwardRef(
  ({ chosen, onClose, editingItem, serviceChosen }, ref) => {
    const classes = useStyles();
    const { t } = useTranslation(["patient-registry", "serviceNamesDict"]);
    const [editor, setEditor] = useState(null);
    const [files, setFiles] = useState([]);
    const dispatchMessage = useContext(MessagesContext)[1];
    const { id } = useParams();
    const isViewing = Boolean(chosen)

    const { mutate: createMutate } = useMutation(
      async ({ universalData }) =>
        await createUniversalService({ universalData }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["researchDetails", id]);
          onClose();
          dispatchMessage(createToast(t("SERVICE_SUCCESSFULLY_EDITED")));
        },
      }
    );

    const { mutate: editMutate } = useMutation(
      async ({ id, editUniversalData }) =>
        await editUniversalService({ id, editUniversalData }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["researchDetails", id]);
          onClose();
          dispatchMessage(createToast(t("SERVICE_SUCCESSFULLY_EDITED")));
        },
      }
    );

    useEffect(() => {
      if(chosen?.service?.files) {
        setFiles(chosen?.service?.files);
      } else if(editingItem?.service?.files) {
        setFiles(editingItem?.service?.files);
      }
    }, [chosen, editingItem, editor]);

    useEffect(() => {
      if(!editor) {
        return;
      }
      if(chosen?.service?.comment) {
        editor.setData(chosen.service.comment)
      } else if(editingItem?.service?.comment) {
        editor.setData(editingItem.service.comment)
      }
    }, [chosen, editingItem, editor]);

    function handleFormSubmit(event) {
      event.preventDefault();
      if (editingItem) {
        const deletedFilesId = editingItem?.service?.files?.filter(function(odj) {
          return files.map((file) => file.id).indexOf(odj.id) === -1;
        });
        const editUniversalData = {
          comment: editor.getData(),
          attachments: files.filter(({id}) => !id),
          deleteAttachmentIds: deletedFilesId.map((file) => file.id) || [],
        };

        editMutate({
          id: editingItem.service.id,
          editUniversalData: editUniversalData,
        });
      } else {
        const universalData = {
          attachments: files,
          comment: editor.getData(),
          serviceName: serviceChosen.value,
          researchId: id,
        };
        createMutate({ universalData: universalData });
      }
    }

    function handleDeleteFile(file, idx) {
      setFiles(files.filter((_, fileIdx) => idx !== fileIdx));
    }

    async function handleDownloadFile(file) {
      const fetchedFile = await getUniversalServiceFile(file.id);
      downloadFileBase64(`data:${fetchedFile.contentType};base64,${fetchedFile.base64}`, fetchedFile.name);
    }

    return (
      <form ref={ref} onSubmit={handleFormSubmit}>
        <Grid container className={classes.fileContainer}>
          <Grid item md={2}>
            <Typography>{t("serviceNamesDict:ATTACH_FILE")}*:</Typography>
          </Grid>
          <Grid item md={10} className={classes.files}>
            <Grid container>
              {files.map((file, idx) => {
                return (
                  <Grid key={idx} className={classes.filesName} onClick={() => handleDownloadFile(file)}>
                    {file.name}
                    {
                      !isViewing && (
                        <IconButton
                          size="small"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDeleteFile(file, idx)
                          }}
                        >
                          <Clear />
                        </IconButton>
                      )
                    }
                  </Grid>
                );
              })}
              {
                !isViewing && (
                  <IconButton
                    size="small"
                    component="label"
                    htmlFor="fileName"
                    className={classes.fileButton}
                  >
                    <AttachFile />
                  </IconButton>
                )
              }

            </Grid>
          </Grid>
        </Grid>
        <Box height={"12px"} />
        <input
          type="file"
          id="fileName"
          style={{ display: "none" }}
          onChange={(event) => setFiles((prev) => [...prev, ...event.target.files])}
          multiple
        />

        <Grid container className={classes.textEditor} direction={"column"}>
          <TextEditor
            setEditor={setEditor}
            editor={editor}
            disabled={!!chosen}
          />
        </Grid>
      </form>
    );
  }
);

export default UniversalServiceForm;
