import {
    Breadcrumbs,
    Button,
    IconButton,
    makeStyles,
    Typography,
  } from "@material-ui/core";
  import { Home, NavigateNext } from "@material-ui/icons";
  import React from "react";
  import { Link } from "react-router-dom";
  
  const useStyles = makeStyles((theme) => ({
    cw: {
      color: "#fff",
    },
  }));
  
  export function BreadcrumbsModeration({ t }) {
    const classes = useStyles();
    return (
      <Breadcrumbs separator={<NavigateNext />} className={classes.cw}>
        <IconButton className={classes.cw} component={Link} to="/">
          <Home />
        </IconButton>
        <Typography
          className={classes.cw}
          variant="body1"
          component={Link}
          to="/moderation"
        >
          {t("USERS")}
        </Typography>
        <Typography variant="h6" color="inherit">
          {t("USER_INFORMATION")}
        </Typography>
      </Breadcrumbs>
    );
  }
  