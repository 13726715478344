import { useCubeQuery } from "@cubejs-client/react";
import { CircularProgress } from "@material-ui/core";
import React, { PureComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

export function DashboardBarCharts({ query }) {
  const { t } = useTranslation("scientificAnalytics");
  const filters = [
    {
      member: "Research.researchStatus",
      operator: "equals",
      values: ["FINISHED"],
    },
  ];

  if (query.organizationFilterData) {
    filters.push({
      member: "Research.groupId",
      operator: "equals",
      values: [query.organizationFilterData],
    });
  }
  if (query.doctorFilterData) {
    filters.push({
      member: "Research.responsibleDoctorId",
      operator: "equals",
      values: [query.doctorFilterData],
    });
  }

  if (query.gender) {
    filters.push({
      member: "Research.gender",
      operator: "equals",
      values: [query.gender],
    });
  }

  if (query.doctorFilterData) {
    filters.push({
      member: "Research.responsibleDoctorId",
      operator: "equals",
      values: [query.doctorFilterData],
    });
  }
  const doctorFilter = [];
  if (query.fromDate) {
    doctorFilter.push({
      member: "Conclusion.conclusionTimestamp",
      operator: "afterDate",
      values: [query.fromDate],
    });
  }
  if (query.beforeDate) {
    doctorFilter.push({
      member: "Conclusion.conclusionTimestamp",
      operator: "beforeDate",
      values: [query.beforeDate],
    });
  }
  if (query.diagnos) {
    doctorFilter.push({
      member: "ConclusionResultToRetinopathy.retinopathyCodename",
      operator: "equals",
      values: [query.diagnos],
    });
  }
  const autoConclusionFilter = [];
  if (query.fromDate) {
    autoConclusionFilter.push({
      member: "PreConclusion.createdAt",
      operator: "afterDate",
      values: [query.fromDate],
    });
  }
  if (query.beforeDate) {
    autoConclusionFilter.push({
      member: "PreConclusion.createdAt",
      operator: "beforeDate",
      values: [query.beforeDate],
    });
  }
  if (query.diagnos) {
    autoConclusionFilter.push({
      member: "PreConclusionDiagnosisToRetinopathy.retinopathyCodename",
      operator: "equals",
      values: [query.diagnos],
    });
  }
  const { resultSet, isLoading, error } = useCubeQuery({
    measures: [
      "Research.count",
      "Conclusion.count",
      "ConclusionResult.count",
      "ConclusionResultToRetinopathy.count",
    ],
    order: [["Conclusion.conclusionTimestamp", "asc"]],
    dimensions: ["ConclusionResultToRetinopathy.retinopathyCodename"],
    filters: [...filters, ...doctorFilter],
  });

  const {
    resultSet: autoResultSet,
    isLoading: autoIsLoading,
    error: autoError,
  } = useCubeQuery({
    measures: [
      "Research.count",
      "AutoConclusion.count",
      "PreConclusion.count",
      "PreConclusionBlock.count",
      "PreConclusionDiagnosis.count",
      "PreConclusionDiagnosisToRetinopathy.count",
    ],
    order: {
      "Research.count": "desc",
    },
    dimensions: ["PreConclusionDiagnosisToRetinopathy.retinopathyCodename"],
    filters: [...filters, ...autoConclusionFilter],
  });

  if (isLoading || autoIsLoading) {
    return <div>{<CircularProgress />}</div>;
  }

  if (error || autoError) {
    return <div>{error.toString() || autoError.toString()}</div>;
  }

  const data = [
    {
      name: "Непролиферативная стадия",
      autoConclusion:
        autoResultSet
          ?.tablePivot()
          .find(
            (value) =>
              value[
                "PreConclusionDiagnosisToRetinopathy.retinopathyCodename"
              ] === "NON_PROLIFERATIVE"
          )?.["AutoConclusion.count"] || 0,
      doctor:
        resultSet
          ?.tablePivot()
          .find(
            (value) =>
              value["ConclusionResultToRetinopathy.retinopathyCodename"] ===
              "NON_PROLIFERATIVE"
          )?.["Conclusion.count"] || 0,
    },
    {
      name: "Препролиферативная стадия",
      autoConclusion:
        autoResultSet
          ?.tablePivot()
          .find(
            (value) =>
              value[
                "PreConclusionDiagnosisToRetinopathy.retinopathyCodename"
              ] === "PREPROLIFERATIVE"
          )?.["AutoConclusion.count"] || 0,
      doctor:
        resultSet
          ?.tablePivot()
          .find(
            (value) =>
              value["ConclusionResultToRetinopathy.retinopathyCodename"] ===
              "PREPROLIFERATIVE"
          )?.["Conclusion.count"] || 0,
    },
    {
      name: "Пролиферативная стадия",
      autoConclusion:
        autoResultSet
          ?.tablePivot()
          .find(
            (value) =>
              value[
                "PreConclusionDiagnosisToRetinopathy.retinopathyCodename"
              ] === "PROLIFERATIVE"
          )?.["AutoConclusion.count"] || 0,
      doctor:
        resultSet
          ?.tablePivot()
          .find(
            (value) =>
              value["ConclusionResultToRetinopathy.retinopathyCodename"] ===
              "PROLIFERATIVE"
          )?.["Conclusion.count"] || 0,
    },
  ];
  return (
    <BarChart
      width={1000}
      height={500}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar name={t("AUTOCONCLUSION")} dataKey="autoConclusion" fill="#8884d8" />
      <Bar name={t("DOCTOR")} dataKey="doctor" fill="#82ca9d" />
    </BarChart>
  );
}
