import {Box, Button, Grid, makeStyles, Modal, Paper, TextField, Typography} from "@material-ui/core";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {
    createDiseaseHistory,
    createOphthalmologyStatus,
    editDiseaseHistory,
    editOphthalmologyStatus
} from "../../../../api/patientRegistry";
import {Close} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {createToast, MessagesContext} from "../../../../globalContexts/MessagesContext";
import {queryClient} from "../../../../globalContexts/queryContext";
import Select from "../../../../components/MuiSelect";
import {useQuery} from "react-query";
import SaveButton from "./SaveButton";

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        width: '80vw',
        padding: '24px',
        position: 'relative',
        maxHeight: '90vh',
        overflow: 'auto'
    },
    bold: {
        fontWeight: 'bold'
    },
    field: {
        marginLeft: '25px',
        width: '70%'
    },
    table: {
        '& td': {
            padding: '5px'
        }
    },
    closeIcon: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        cursor: 'pointer',
    },
    input: {
        display: 'flex'
    },
}));

const langMapping = {
    en: 'nameEn',
    ru: 'nameRu',
    kk: 'nameKz'
}

const CreateFormModal = ({open, onClose, chosen, editingItem}) => {
    const classes = useStyles();
    const {id: researchId} = useParams();
    const dispatchMessage = useContext(MessagesContext)[1];

    const {data: fields} = useQuery('ophthalmologyStatusFields', {
        enabled: false
    });

    const {i18n} = useTranslation();
    const langPrefix = i18n.language.split("-")[0];

    const initialValues = fields?.reduce((accum, {codename}) => {
        accum[codename] = null;
        return accum;
    }, {}) || {};

    const fieldToValues = fields?.reduce((accum, {childrenFields, codename}) => {
        if(!childrenFields) {
            return accum;
        }
        accum[codename] = childrenFields.map((field) =>({value: field.codename, label: field[langMapping[langPrefix]]}));
        return accum;
    }, {}) || {};

    const dictionaryOfOptions = fields?.reduce((accum, {childrenFields}) => {
        if(!childrenFields) {
            return accum;
        }
        childrenFields.forEach(({codename, nameRu, nameKz, nameEn}) => {
            accum[codename] = {nameRu, nameKz, nameEn}
        });
        return accum;
    }, {}) || {};

    const transformValues = (values) => {
        const entries = Object.entries(values);
        const newEntries = entries.map(([field, values]) => [field, values.map((codename) => ({
            value: codename,
            label: dictionaryOfOptions[codename][langMapping[langPrefix]]
        }))]);
        return Object.fromEntries(newEntries);
    }

    const {handleSubmit, control, reset, watch } = useForm({
        defaultValues: initialValues,
    });

    useEffect(() => {
        if(chosen) {
            reset(transformValues(chosen.values));
        }
        if(editingItem) {
            reset(transformValues(editingItem.values));
        }
        if(!chosen && !editingItem) {
            reset(initialValues);
        }
    }, [chosen, editingItem, reset])

    const {t} = useTranslation('patient-registry');

    const handleSubmitCreateForm = handleSubmit(async (values) => {
        try {
            const entries = Object.entries(values)
                .filter(([_, value]) => value)
                .map(([key, values]) => [key, values.map(({value}) => value)])
            const valuesTransformed = Object.fromEntries(entries);
            await createOphthalmologyStatus({values: valuesTransformed, researchId});
            onClose();
            dispatchMessage(createToast(t('OPHTHALMOLOGY_STATUS_SUCCESSFULLY_CREATED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return;
        } catch(error) {
            console.error(error);
        }
        onClose();
    });

    const handleSubmitEditForm = handleSubmit(async (values) => {
        try {
            const entries = Object.entries(values)
                .filter(([_, value]) => value)
                .map(([key, values]) => [key, values.map(({value}) => value)])
            const valuesTransformed = Object.fromEntries(entries);
            await editOphthalmologyStatus(editingItem.id, {values: valuesTransformed, researchId});
            onClose();
            dispatchMessage(createToast(t('OPHTHALMOLOGY_STATUS_SUCCESSFULLY_EDITED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return;
        } catch(error) {
            console.error(error);
        }
        onClose();
    });
    return <Modal open={open} onClose={!chosen ? () => window.confirm(t('CONFIRM_CLOSE')) && onClose() : onClose}  className={classes.modal}>
        <Paper className={classes.modalContent}>
                <Close className={classes.closeIcon} onClick={onClose} />
                <Typography className={classes.bold}>
                    {t('OPHTHALMOLOGY_STATUS')}
                </Typography>
                <form onSubmit={editingItem ? handleSubmitEditForm : handleSubmitCreateForm}>
                    {
                        fields?.map(({codename, ...rest}) => (
                            <React.Fragment key={codename}>
                                <Grid container direction={'row'} alignItems={'center'} justify={'space-between'}>
                            <span>
                            {rest[langMapping[langPrefix]]}:
                            </span>
                                    <div className={classes.field}>
                                        <Controller name={codename} control={control} as={
                                            <Select isMulti placeholder={t('CHOOSE_STATUS')} options={fieldToValues[codename]} isDisabled={!!chosen} />
                                        } />
                                    </div>
                                </Grid>
                                <Box height={'24px'}/>
                            </React.Fragment>
                        ))
                    }
                    {
                        !chosen && <>
                            <Box height={'24px'} />
                            <Grid container justify={'flex-end'}>
                                <SaveButton watch={watch} />
                                <Box width={'6px'}/>
                                <Button onClick={onClose} variant={'contained'} color={'secondary'}>
                                    {t('CANCEL')}
                                </Button>
                            </Grid>
                        </>
                    }
                </form>
            </Paper>

    </Modal>
}

export default CreateFormModal;