import { Grid, makeStyles, Modal, Paper, Typography } from "@material-ui/core";
import { Add, Close, Edit } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import CreateFormModal from "./CreateFormModal";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { deleteDiseaseHistory } from "../../../../api/patientRegistry";
import DiseaseHistoryList from "./DiseaseHistoryList";
import ConfirmActionModal from "../../../../components/ConfirmActionModal";
import { queryClient } from "../../../../globalContexts/queryContext";
import { useTranslation } from "react-i18next";
import {
  createToast,
  MessagesContext,
} from "../../../../globalContexts/MessagesContext";
import { useResearchDetails } from "../../../ResearchesPage/ResearchDetails/queryHooks";
import Roled from "../../../../components/Roled";
import {ROLES} from "../../../../config/constants";
import {RESEARCH_STATUS} from "../../../ResearchesPage/constants";

const useStyles = makeStyles((theme) => ({
  icon: {
    border: "1px solid black",
    color: theme.palette.primary.main,
  },
}));

const HistoryOfDisease = () => {
  const classes = useStyles();
  const { t } = useTranslation("patient-registry");
  const [isCreatingDiseaseHistory, setIsCreatingDiseaseHistory] = useState(
    false
  );
  const [diseaseHistoryChosen, setDiseaseHistoryChosen] = useState(null);
  const [diseaseHistoryEditing, setDiseaseHistoryEditing] = useState(null);
  const [diseaseHistoryDeletingId, setDiseaseHistoryDeletingId] = useState(
    null
  );
  const dispatchMessage = useContext(MessagesContext)[1];
  const { id: researchId } = useParams();
  const [{ data: research }] = useResearchDetails(researchId);
  const isFinished = research.status === RESEARCH_STATUS.FINISHED;
  const diseaseHistoryExists = research.diseaseHistories?.reduce(
    (accum, item) => item.isCurrent || accum,
    false
  );
  const handleCloseModal = () => {
    setIsCreatingDiseaseHistory(false);
    setDiseaseHistoryChosen(null);
    setDiseaseHistoryEditing(null);
  };

  const handleDeleteDiseaseHistory = async () => {
    try {
      await deleteDiseaseHistory(diseaseHistoryDeletingId);
      setDiseaseHistoryDeletingId(null);
      dispatchMessage(createToast(t("DISEASE_HISTORY_SUCCESSFULLY_DELETED")));
      await queryClient.invalidateQueries(["researchDetails", researchId]);
      return;
    } catch (error) {
      console.error(error);
    }
    setDiseaseHistoryDeletingId(null);
  };

  return (
    <Grid container direction={"column"}>
      <Grid container>
        {!diseaseHistoryExists && !isFinished && (
            <Roled roles={[ROLES.DOCTOR]}>
                <Add
                    className={classes.icon}
                    onClick={() => setIsCreatingDiseaseHistory(true)}
                />
            </Roled>
        )}
      </Grid>
      <DiseaseHistoryList
        diseaseHistories={research.diseaseHistories}
        onChooseHistoryDisease={(item) => setDiseaseHistoryChosen(item)}
        onEditDisease={(item) => setDiseaseHistoryEditing(item)}
        onDeleteDisease={(item) => setDiseaseHistoryDeletingId(item.id)}
        isFinished={isFinished}
      />
      <CreateFormModal
        open={
          isCreatingDiseaseHistory ||
          Boolean(diseaseHistoryChosen) ||
          Boolean(diseaseHistoryEditing)
        }
        onClose={handleCloseModal}
        chosen={diseaseHistoryChosen}
        editingItem={diseaseHistoryEditing}
      />
      <ConfirmActionModal
        open={Boolean(diseaseHistoryDeletingId)}
        label={t("CONFIRM_DELETION")}
        onClose={() => setDiseaseHistoryDeletingId(null)}
        onConfirm={handleDeleteDiseaseHistory}
      />
    </Grid>
  );
};

export default HistoryOfDisease;
