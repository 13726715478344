import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import {
  Assignment,
  Brightness1,
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
  KeyboardArrowLeft,
  Person,
  Send,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useDisclosure } from "../../../../hooks/useDisclosure";
import {
  CONCLUSION_TYPE,
  RESEARCH_SERVICE_NAME,
  RESEARCH_STATUS,
} from "../../constants";
import { getStatusIcon } from "../../ResearchesList/ResearchJournalFilters";
import { Formik } from "formik";
import ConclusionResultForm, {
  ConclusionResultFormBody,
} from "../../ConclusionForm/ConclusionResultForm";
import Loader from "../../../../components/Loader";
import {
  cancelAutoConclusionUpdate,
  usePatientAllImages,
  usePatientHistory,
} from "../queryHooks";
import { falsyObject, formatDateAndTime } from "../../../../utils";
import { temporaryFormSchema } from "../../ConclusionForm/ConclusionForm";
import { useRouterQuery } from "../../../../hooks/useRouterQuery";
import DescriptionForm, {
  DescriptionFormBody,
} from "../../ConclusionForm/DescriptionForm";
import { CornerstoneImageViewer } from "../../../../components/CornerstoneImageViewer/v1";
import Recommendation from "../../ConclusionForm/Recommendation";
import {
  createToast,
  MessagesContext,
} from "../../../../globalContexts/MessagesContext";
import { useIsMine } from "../../../../globalContexts/UserContext";
import {
  fetchConclusionText,
  fetchReport,
  getImagesPdf,
} from "../../ResearchesList/ResearchJournalTable";
import BeforeLoose from "../../../../components/BeforeLoose";
import JSZip from "jszip";
import FileSaver from "file-saver";
import {
  ResearchContext,
  toggleConciliumDrawer,
  toggleConciliumMenu,
} from "../../ResearchContext";
import ConclusionTypePicker from "../ConclusionTypePicker";
import usePreConclusionInfo from "../../../../hooks/usePreConclusionInfo";
import ConciliumTimer from "../../Concilium/ConciliumTimer";
import { getDefaultDescriptions } from "../../ConclusionForm/defaultDescriptionSetterUtils";
import SharedRecommendation from "../../../SharePage/components/SharedRecommendation";
import { queryClient } from "../../../../globalContexts/queryContext";
import ConciliumMenuPopover from "../../ConciliumMenu/ConciliumMenuPopover";
import { BreadcrumbsDoctor } from "./BreadcrumbsDoctor";
import {
  HeaderLeftTeleporter,
  HeaderRightTeleporter,
} from "../../../../components/Header";
import {
  AppointmentCardContentMulti,
  AppointmentCardHeaderMulti,
} from "./MultiViewMode/AppointmentCardContentMulti";
import { fetchDicom } from "../../../../api";
import axios from "axios";
import SupportTelephoneNumber from "../../../../components/SupportTelephoneNumber";
import ConfirmationCode from "../../ConclusionForm/ConfirmationCode";

const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100vh - 76px)",
    margin: "6px",
    width: "auto",
  },
  progressBar: {
    width: "100%",
  },
  imageGridContainer: {
    overflowX: "auto",
  },
  imageGridItem: {
    flex: "0 0 33.3%",

    "& > img": {
      display: "block",
    },

    "&[data-active=true]": {
      border: "1px solid red",
    },
  },
  cw: {
    color: "#fff",
  },
}));

function PatientInfoCard(props) {
  const {
    patientInfo,
    approvedConclusions,
    conciliumInfo,
    isMultiMode,
    setIsMultiMode,
  } = props;
  const { t } = useTranslation("research");
  const { isOpen, toggle } = useDisclosure();
  const { data, isLoading } = usePatientHistory(
    patientInfo.iin,
    patientInfo.status
  );

  return (
    <Paper className="h-100 relative overflow-auto pl-8 pr-8">
      <div className="pt-4 pl-8 pr-8">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <Person color="primary" fontSize="large" />
          <Typography
            variant="h6"
            style={{ lineHeight: "24px" }}
            align="center"
          >
            {patientInfo.fullName}
          </Typography>
          <IconButton onClick={toggle} size="small">
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">{t("BIRTHDAY")}:</Typography>
          <Typography className="text-gray">{patientInfo.birthday}</Typography>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">{t("DIABETES")}:</Typography>
          <Checkbox size="small" disabled checked={patientInfo.diabetes} />
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography className="text-gray">
            {t("ARTERIAL_HYPERTENSION")}:
          </Typography>
          <Checkbox
            size="small"
            disabled
            checked={patientInfo.arterialHypertension}
          />
        </Grid>
        <Collapse in={isOpen}>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("IIN")}:</Typography>
            <Typography className="text-gray">{patientInfo.iin}</Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("AGE")}:</Typography>
            <Typography className="text-gray">{patientInfo.age}</Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("GENDER")}:</Typography>
            <Typography className="text-gray">{patientInfo.gender}</Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("PHONE")}:</Typography>
            <Typography className="text-gray">{patientInfo.phone}</Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("REFERRER")}:</Typography>
            <Typography className="text-gray">
              {patientInfo.referrerFullName}
            </Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">
              {t("RECEPTION_DOCTOR")}:
            </Typography>
            <Typography className="text-gray">
              {patientInfo.receptionDoctorFullName}
            </Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("SERVICE_TYPE")}:</Typography>
            <Typography className="text-gray">
              {t(patientInfo.serviceType)}
            </Typography>
          </Grid>
          <Grid container justify="space-between" alignItems="center">
            <Typography className="text-gray">{t("COMPLAINTS")}:</Typography>
            <Typography className="text-gray">
              {patientInfo.complaints}
            </Typography>
          </Grid>
          {conciliumInfo?.deadline && (
            <>
              <Typography>{t("CONCILIUM")}:</Typography>
              <ConciliumTimer concilium={conciliumInfo} />
              <Box marginBottom={"10px"} />
              <Grid container justify="space-between" alignItems="center">
                <Typography className="text-gray">
                  {t("NOTE_FOR_CASE")}:
                </Typography>
                <Box width={"100%"} padding={"24px"} border={"1px solid black"}>
                  <Typography className="text-gray">
                    {conciliumInfo.comment}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </Collapse>
        <Grid container justify="space-between" alignItems="center">
          <Typography>Раскрыть все изображения:</Typography>
          <Checkbox
            onChange={(event) => setIsMultiMode(event.target.checked)}
          />
        </Grid>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        data
          .filter(
            ({ serviceName }) =>
              serviceName === RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY
          )
          .map((research) => {
            const isOpen = research.id === patientInfo.id;
            return (
              <Paper elevation={8} key={research.id} className="mt-4 mb-4">
                {isMultiMode ? (
                  <>
                    <AppointmentCardHeaderMulti t={t} research={research} />
                    <AppointmentCardContentMulti t={t} research={research} />
                  </>
                ) : (
                  <>
                    <AppointmentCardHeader
                      t={t}
                      research={research}
                      isOpen={isOpen}
                    />
                    <Collapse in={isOpen}>
                      {isOpen && (
                        <AppointmentCardContent
                          t={t}
                          research={research}
                          rightEyes={patientInfo.rightEyes}
                          leftEyes={patientInfo.leftEyes}
                          approvedConclusions={approvedConclusions}
                        />
                      )}
                    </Collapse>
                  </>
                )}
              </Paper>
            );
          })
      )}
    </Paper>
  );
}

export function AppointmentCardContent(props) {
  const { research, rightEyes, leftEyes, t, approvedConclusions } = props;
  const classes = useStyles();
  const [query, handleReplace] = useRouterQuery();
  const activeImg = query.get("activeImg");
  function getStatusClassName(side) {
    if (RESEARCH_STATUS.FINISHED === research.status) {
      return "text-green";
    }
    if (RESEARCH_STATUS.CANCELLED === research.status) {
      return "text-red";
    }
    if (approvedConclusions[side]) return "text-blue";
    return "text-orange";
  }

  // on appointmentId change set activeImg according to existence of rightEyes or leftEyes
  useEffect(() => {
    if (!activeImg) {
      if (rightEyes?.length) {
        handleReplace("activeImg", rightEyes[0].id);
        return;
      }
      if (leftEyes?.length) {
        handleReplace("activeImg", leftEyes[0].id);
      }
    }
  }, [leftEyes, rightEyes]);

  return (
    <>
      {rightEyes?.length > 0 && (
        <div>
          <Grid
            container
            justify="space-between"
            className="pl-4 pr-4"
            title={t(`STATUS_${research.status}`)}
          >
            <Typography>OD 1/{rightEyes.length}</Typography>
            <Brightness1 className={getStatusClassName("rightEyeConclusion")} />
          </Grid>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            className={classes.imageGridContainer}
          >
            {rightEyes.map(({ id, smallUrl }) => (
              <Grid
                key={id}
                item
                xs={4}
                data-active={activeImg === id}
                onClick={() => handleReplace("activeImg", id, research.id)}
                className={classes.imageGridItem}
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData("researchId", research.id);
                  e.dataTransfer.setData("id", id);
                  e.dataTransfer.setData("side", "rightEyes");
                }}
              >
                <img width="100%" src={`${smallUrl}`} alt="rightEyes" />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {leftEyes?.length > 0 && (
        <div>
          <Grid
            container
            justify="space-between"
            className="pl-4 pr-4"
            title={t(`STATUS_${research.status}`)}
          >
            <Typography>OS 1/{leftEyes.length}</Typography>
            <Brightness1 className={getStatusClassName("leftEyeConclusion")} />
          </Grid>
          <Grid
            container
            wrap="nowrap"
            alignItems="center"
            className={classes.imageGridContainer}
          >
            {leftEyes.map(({ id, smallUrl }) => (
              <Grid
                key={id}
                item
                xs={4}
                onClick={() => {
                  handleReplace("activeImg", id, research.id);
                }}
                data-active={activeImg === id}
                className={classes.imageGridItem}
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData("researchId", research.id);
                  e.dataTransfer.setData("id", id);
                  e.dataTransfer.setData("side", "leftEyes");
                }}
              >
                <img width="100%" src={`${smallUrl}`} alt="leftEyes" />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </>
  );
}

function AppointmentCardHeader(props) {
  const { research, isOpen, t } = props;
  const history = useHistory();

  return (
    <Grid
      className="pointer pt-4 pl-8 pr-8"
      container
      alignItems="center"
      justify="space-between"
      wrap="nowrap"
      onClick={() => {
        // если это тоже самое заключение не надо менять history
        // иначе исчезнет activeImg
        if (
          !history.location.pathname.startsWith(`/researches/${research.id}`)
        ) {
          history.replace(`/researches/${research.id}`);
        }
      }}
    >
      {getStatusIcon(RESEARCH_STATUS.FINISHED, { color: "primary" })}
      <div>
        <Typography align="center">
          {formatDateAndTime(research.createdTimestamp)}
        </Typography>
        <Typography align="center">
          {t("STATUS")}: {t(`STATUS_${research.status}`)}
        </Typography>
      </div>
      {isOpen ? <ExpandLess /> : <ExpandMore />}
    </Grid>
  );
}

export function CornerstoneOverlay(props) {
  const {
    imageId,
    scale,
    windowWidth,
    windowCenter,
    side,
    date,
    researchId,
    t,
    imageIndex,
    stackSize,
    image: { rows, columns },
  } = props;

  if (!imageId) {
    return null;
  }

  const zoomPercentage = parseFloat(scale * 100).toFixed(0);
  const wwwc = `W: ${windowWidth.toFixed(0)} L: ${windowCenter.toFixed(0)}`;

  return (
    <div className="ViewportOverlay">
      <div className="top-left overlay-element">
        {side === "rightEyes" && <div>OD</div>}
        {side === "leftEyes" && <div>OS</div>}
        <div>{formatDateAndTime(date)}</div>
        {researchId && <div>id: {researchId}</div>}
        <div>
          {columns} x {rows}
        </div>
      </div>
      <div className="bottom-left overlay-element">
        <div>
          {imageIndex} / {stackSize}
        </div>
        <div>
          {t("ZOOM")}: {zoomPercentage}%
        </div>
        <div>{wwwc}</div>
      </div>
    </div>
  );
}

export function ResearchDetailsForDoctor(props) {
  const {
    patientInfo,
    conclusionInfo,
    conciliumInfo,
    sharedInfo,
    autoInfo,
    annotationInfo,
    handleTakeResearch,
    handleSubmitConclusions,
    erdbHistory,
  } = props;
  const [approvedConclusions, setApprovedConclusions] = useState({
    rightEyeConclusion: false,
    leftEyeConclusion: false,
  });

  const [lastActiveImg, setLastActiveImg] = useState({
    right: "",
    left: "",
  });

  const [isMultiMode, setIsMultiMode] = useState(false);
  const { isOpen, toggle } = useDisclosure();
  const dispatchMessage = useContext(MessagesContext)[1];
  const [{ preConclusionType }, dispatchResearch] = useContext(ResearchContext);
  const [query, handleReplace] = useRouterQuery();
  const { t } = useTranslation(["research", "image-viewer"]);
  const activeImg = query.get("activeImg");
  const history = useHistory();
  const classes = useStyles();
  const isFinished = patientInfo.status === RESEARCH_STATUS.FINISHED;
  const { id: researchId } = useParams();
  const allImages = usePatientAllImages(
    patientInfo.iin,
    patientInfo.status,
    isMultiMode
  );

  const eyes = useMemo(() => {
    return isMultiMode
      ? allImages
      : {
          rightEyes: patientInfo.rightEyes,
          leftEyes: patientInfo.leftEyes,
        };
  }, [allImages, isMultiMode, patientInfo.leftEyes, patientInfo.rightEyes]);

  const {
    showConclusionPicker,
    preconclusionFormValues,
    preconclusionSharedFormValues,
    preconclusionAutoFormValues,
    preconclusionAnnototorFormValues,
  } = usePreConclusionInfo(conciliumInfo, sharedInfo, autoInfo, annotationInfo);
  const isConciliumCreated = !!conciliumInfo.deadline;
  const conciliumMembers = conciliumInfo.conciliumMembers;
  const sharedMembers = sharedInfo.shared;
  const annotationMembers = annotationInfo.annotation;

  const isInProgress = patientInfo.status === RESEARCH_STATUS.IN_PROGRESS;
  let activeSide = "";
  const hasEyes =
    patientInfo.rightEyes?.length > 0 || patientInfo.leftEyes?.length > 0;
  if (patientInfo.rightEyes?.find((eye) => eye.id == activeImg)) {
    activeSide = "rightEye";
  }
  if (patientInfo.leftEyes?.find((eye) => eye.id == activeImg)) {
    activeSide = "leftEye";
  }

  useEffect(() => {
    setLastActiveImg({ right: "", left: "" });
  }, [researchId]);

  useEffect(() => {
    if (activeSide === "rightEye") {
      setLastActiveImg({ ...lastActiveImg, right: activeImg });
    }
    if (activeSide === "leftEye") {
      setLastActiveImg({ ...lastActiveImg, left: activeImg });
    }
  }, [activeSide, activeImg]);
  function handleToggle() {
    if (activeSide) {
      toggle();
    }
  }
  useEffect(() => {
    if (!activeImg) {
      if (patientInfo.rightEyes?.length) {
        handleReplace("activeImg", patientInfo.rightEyes[0].id);
      } else if (patientInfo.leftEyes?.length) {
        handleReplace("activeImg", patientInfo.leftEyes[0].id);
      }
    }
  }, [patientInfo.rightEyes?.length, patientInfo.leftEyes?.length, activeImg]);
  // TODO: REFACTOR OUT THIS TO queryHooks file !IMPORTANT KEEP LOGIC IN ONE PLACE!!!
  // AND GET RID OF first condition as it is just a quick fix
  function getInitialFormValues() {
    if (
      (conclusionInfo?.status === RESEARCH_STATUS.FINISHED ||
        conclusionInfo?.status === RESEARCH_STATUS.CANCELLED) &&
      preConclusionType.type !== CONCLUSION_TYPE.CONCILIUM_PRECONCLUSION &&
      preConclusionType.type !== CONCLUSION_TYPE.SHARED_PRECONCLUSION &&
      preConclusionType.type !== CONCLUSION_TYPE.AUTO_PRECONCLUSION &&
      preConclusionType.type !== CONCLUSION_TYPE.ANNOTATOR_CONCLUSION
    ) {
      return conclusionInfo;
    }
    if (
      preConclusionType.type === CONCLUSION_TYPE.AUTO_PRECONCLUSION &&
      preconclusionAutoFormValues
    ) {
      return {
        ...preconclusionAutoFormValues,
        leftEyes: conclusionInfo.leftEyes,
        rightEyes: conclusionInfo.rightEyes,
        status: conclusionInfo.status,
        id: conclusionInfo.id,
        code: conclusionInfo.code,
      };
    }
    if (
      preConclusionType.type === CONCLUSION_TYPE.CONCILIUM_PRECONCLUSION &&
      preconclusionFormValues
    ) {
      return {
        ...preconclusionFormValues,
        leftEyes: conclusionInfo.leftEyes,
        rightEyes: conclusionInfo.rightEyes,
        status: conclusionInfo.status,
        id: conclusionInfo.id,
        code: conclusionInfo.code,
      };
    }
    if (
      preConclusionType.type === CONCLUSION_TYPE.SHARED_PRECONCLUSION &&
      preconclusionSharedFormValues
    ) {
      return {
        ...preconclusionSharedFormValues,
        leftEyes: conclusionInfo.leftEyes,
        rightEyes: conclusionInfo.rightEyes,
        status: conclusionInfo.status,
        id: conclusionInfo.id,
        code: conclusionInfo.code,
      };
    }
    if (preConclusionType.type === CONCLUSION_TYPE.ANNOTATOR_CONCLUSION) {
      return {
        ...preconclusionAnnototorFormValues,
        leftEyes: conclusionInfo.leftEyes,
        rightEyes: conclusionInfo.rightEyes,
        status: conclusionInfo.status,
        id: conclusionInfo.id,
        code: conclusionInfo.code,
      };
    }
    return conclusionInfo;
  }
  const isMine = useIsMine(conclusionInfo.responsibleDoctorUsername);
  const isTakenToWork = conclusionInfo.status === RESEARCH_STATUS.TAKEN_TO_WORK;
  const copyConclusionText = isMine && isFinished;
  const editMode = isTakenToWork && isMine;

  useEffect(() => {
    setApprovedConclusions({
      rightEyeConclusion: false,
      leftEyeConclusion: false,
    });
  }, [history.location.pathname]);

  return (
    <Grid container item xs className={classes.root} spacing={1}>
      <HeaderLeftTeleporter.Source>
        <BreadcrumbsDoctor t={t} />
      </HeaderLeftTeleporter.Source>
      <HeaderRightTeleporter.Source>
        <div style={{ display: "flex" }}>
          <SupportTelephoneNumber />

          {isInProgress && (
            <Button
              className="text-white border-white"
              variant="outlined"
              onClick={handleTakeResearch}
            >
              {t("TAKE_RESEARCH")}
            </Button>
          )}
          <Button
            className="text-white border-white"
            variant="outlined"
            onClick={history.goBack}
          >
            {t("BACK")}
          </Button>
        </div>
      </HeaderRightTeleporter.Source>
      <Grid className="h-100" item xs={3} lg={2}>
        <PatientInfoCard
          isMultiMode={isMultiMode}
          setIsMultiMode={setIsMultiMode}
          patientInfo={patientInfo}
          conciliumInfo={conciliumInfo}
          approvedConclusions={approvedConclusions}
        />
      </Grid>
      <Grid className="h-100" item xs={isOpen ? 6 : 9} lg={isOpen ? 7 : 10}>
        <Paper className="h-100 pl-8 pr-8 relative">
          {/* // this solves some strange bug related to image load error */}
          {hasEyes && activeImg && (
            <CornerstoneImageViewer
              eyes={eyes}
              handleDownloadPicture={async () => {
                const zip = new JSZip();
                const rightEyesPromises = patientInfo.rightEyes?.map(
                  async (img) => {
                    const name = `rightEye_${img.id}.${
                      img.contentType.split("/")[1]
                    }`;
                    const file = await axios.get(img.fullUrl, {
                      responseType: "blob",
                    });
                    zip.file(name, file.data, { binary: true });
                  }
                );
                const leftEyesPromises = patientInfo.leftEyes?.map(
                  async (img) => {
                    const name = `leftEye_${img.id}.${
                      img.contentType.split("/")[1]
                    }`;
                    const file = await axios.get(img.fullUrl, {
                      responseType: "blob",
                    });
                    zip.file(name, file.data, { binary: true });
                  }
                );
                await Promise.all([...rightEyesPromises, ...leftEyesPromises]);
                zip.generateAsync({ type: "blob" }).then((zipFile) => {
                  FileSaver.saveAs(zipFile, "Снимки.zip");
                });
              }}
              handleDownloadImagesPdf={() =>
                getImagesPdf(patientInfo.id, dispatchMessage, t)
              }
              handleDownloadConclusion={
                isFinished
                  ? () => fetchReport(patientInfo.id, dispatchMessage, t)
                  : undefined
              }
              handleCopyConclusionText={
                copyConclusionText
                  ? () =>
                      fetchConclusionText(patientInfo.id, dispatchMessage, t)
                  : undefined
              }
              handleDownloadDicom={() =>
                fetchDicom(patientInfo.id).then((blob) => {
                  FileSaver.saveAs(blob, `dicom_${patientInfo.id}.tar.gz`);
                })
              }
              showDownloadButton={true}
              activeImg={activeImg}
              showSharedButton={editMode}
              setActiveImg={(id, _researchId = researchId) =>
                handleReplace("activeImg", id, _researchId)
              }
              isConclusionOpen={isOpen}
              handleConcilium={() => {
                isConciliumCreated
                  ? dispatchResearch(toggleConciliumMenu())
                  : dispatchResearch(toggleConciliumDrawer());
              }}
              showConciliumButton={
                isMine &&
                (isConciliumCreated ||
                  conclusionInfo.status === RESEARCH_STATUS.TAKEN_TO_WORK)
              }
              CustomOverlay={(props) => (
                <CornerstoneOverlay
                  {...props}
                  researchId={researchId}
                  t={t}
                  date={patientInfo.appointmentTimestamp}
                />
              )}
              ConciliumMenuPopover={({ anchorEl }) => (
                <ConciliumMenuPopover
                  conciliumInfo={conciliumInfo}
                  isConclusionOpened={isOpen}
                  toggleConclusionOpened={toggle}
                  anchorEl={anchorEl}
                />
              )}
              erdbHistory={erdbHistory}
            />
          )}
          <Button
            onClick={handleToggle}
            title={t("FORM_CONCLUSION")}
            className="absolute t-90 r-24 text-orange"
          >
            {!isOpen && <ChevronLeft />}
            <Assignment />
            {isOpen && <ChevronRight />}
          </Button>
        </Paper>
      </Grid>
      {isOpen && (
        <Grid className="h-100" item xs={3}>
          {showConclusionPicker && (
            <ConclusionTypePicker
              status={conclusionInfo.status}
              conciliumMembers={conciliumMembers}
              sharedMembers={sharedMembers}
              annotationMembers={annotationMembers}
            />
          )}
          <Box marginBottom={"10px"} />
          <Formik
            onSubmit={handleSubmitConclusions}
            validationSchema={temporaryFormSchema}
            enableReinitialize
            initialValues={getInitialFormValues()}
          >
            {(subForm) => (
              <ConclusionSubForm
                subForm={subForm}
                editMode={editMode}
                conclusionName={`${activeSide}Conclusion`}
                approvedConclusions={approvedConclusions}
                setApprovedConclusions={setApprovedConclusions}
                dispatchMessage={dispatchMessage}
                shared={false}
                handleCopyConclusionText={
                  copyConclusionText
                    ? () =>
                        fetchConclusionText(patientInfo.id, dispatchMessage, t)
                    : undefined
                }
                replaceActiveImg={(conclusionName) => {
                  if (conclusionName === "rightEyeConclusion") {
                    handleReplace(
                      "activeImg",
                      lastActiveImg.right || patientInfo.rightEyes[0].id
                    );
                  }
                  if (conclusionName === "leftEyeConclusion") {
                    handleReplace(
                      "activeImg",
                      lastActiveImg.left || patientInfo.leftEyes[0].id
                    );
                  }
                }}
              />
            )}
          </Formik>
        </Grid>
      )}
    </Grid>
  );
}

export function ConclusionSubForm(props) {
  const {
    subForm,
    editMode,
    handleCopyConclusionText,
    conclusionName,
    approvedConclusions,
    setApprovedConclusions,
    dispatchMessage,
    shared,
    replaceActiveImg,
  } = props;

  const [openedTab, setOpenedTab] = useState("rightEyeConclusion");
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation(["research", "image-viewer"]);
  const query = useRouterQuery()[0];
  useEffect(() => {
    setOpenedTab(conclusionName);
  }, [conclusionName]);

  useEffect(() => {
    if (subForm.dirty) {
      cancelAutoConclusionUpdate();
    }
  }, [subForm.dirty]);

  function handleTabChange(value) {
    if (value !== "recommendations") {
      replaceActiveImg(value);
    }
    setOpenedTab(value);
  }

  const hasRightEyes = subForm.initialValues.rightEyes?.length > 0;
  const hasLeftEyes = subForm.initialValues.leftEyes?.length > 0;

  function handleDescriptionApprove() {
    if (openedTab === "leftEyeConclusion") {
      if (hasRightEyes && !approvedConclusions.rightEyeConclusion) {
        handleTabChange("rightEyeConclusion");
      } else {
        handleTabChange("recommendations");
      }
      setApprovedConclusions((state) => ({
        ...state,
        [openedTab]: true,
      }));
    }
    if (openedTab === "rightEyeConclusion") {
      if (hasLeftEyes && !approvedConclusions.leftEyeConclusion) {
        handleTabChange("leftEyeConclusion");
      } else {
        handleTabChange("recommendations");
      }
      setApprovedConclusions((state) => ({
        ...state,
        [openedTab]: true,
      }));
    }
    setActiveStep(0);
  }

  function validateODOSStatus(subForm, shared, query) {
    if (
      !approvedConclusions.leftEyeConclusion &&
      !approvedConclusions.rightEyeConclusion &&
      hasRightEyes &&
      hasLeftEyes
    ) {
      return dispatchMessage(
        createToast(t("CONCLUSION_OD_AND_OS_NOT_CONFIRMED"), "error")
      );
    }
    if (!approvedConclusions.rightEyeConclusion && hasRightEyes) {
      return dispatchMessage(
        createToast(t("CONCLUSION_OD_NOT_CONFIRMED"), "error")
      );
    }
    if (!approvedConclusions.leftEyeConclusion && hasLeftEyes) {
      return dispatchMessage(
        createToast(t("CONCLUSION_OS_NOT_CONFIRMED"), "error")
      );
    }
    if (shared) {
      queryClient.invalidateQueries(["shareData", query.get("token")]);
    }

    subForm.submitForm();
  }

  return (
    <Paper className="h-100 pl-8 pr-8 relative overflow-auto">
      <form
        onSubmit={(event) => {
          event.preventDefault();
          validateODOSStatus(subForm, shared, query);
        }}
      >
        <BeforeLoose
          condition={subForm.dirty && editMode}
          message={t("WILL_NOT_BE_SAVED")}
        />
        <Tabs
          value={openedTab}
          onChange={(_e, value) => handleTabChange(value)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {hasRightEyes && (
            <Tab
              label="OD"
              value="rightEyeConclusion"
              style={{ minWidth: "80px" }}
            />
          )}
          {hasLeftEyes && (
            <Tab
              label="OS"
              value="leftEyeConclusion"
              style={{ minWidth: "80px" }}
            />
          )}
          <Tab
            label={t("RECOMENDATION")}
            value="recommendations"
            style={{ minWidth: "80px" }}
          />
        </Tabs>
        {openedTab === "recommendations" && (
          <>
            <fieldset className={`border-0 ${editMode ? "" : "pev-none"}`}>
              {shared ? (
                <SharedRecommendation
                  t={t}
                  hasEyesImage={hasRightEyes || hasLeftEyes}
                />
              ) : (
                <Recommendation
                  t={t}
                  hasEyesImage={hasRightEyes || hasLeftEyes}
                  status={subForm.initialValues.status}
                  editMode={editMode}
                />
              )}
              {subForm.status && subForm.status.isCodeRequired && (
                <ConfirmationCode
                  code={subForm.values.code}
                  codeLabel={subForm.status.codeLabel}
                />
              )}
              {editMode && (
                <>
                  <Grid container justify="flex-end" className="pt-4 pr-4">
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {t("SAVE_AND_SEND")} <Send className="ml-4" />
                    </Button>
                  </Grid>
                </>
              )}
            </fieldset>
            {handleCopyConclusionText && (
              <Grid container justify="flex-end" className="pt-4 pr-4">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleCopyConclusionText}
                >
                  {t("image-viewer:COPY_TEXT_CONCLUSION")}
                </Button>
              </Grid>
            )}
          </>
        )}
        {openedTab !== "recommendations" && (
          <>
            <Stepper
              nonLinear={!editMode}
              activeStep={activeStep}
              className="pl-8 pr-8 pt-8 pb-8"
            >
              <Step>
                {editMode ? (
                  <StepLabel>{t("CONCLUSION")}</StepLabel>
                ) : (
                  <StepButton onClick={() => setActiveStep(0)}>
                    {t("CONCLUSION")}
                  </StepButton>
                )}
              </Step>
              <Step>
                {editMode ? (
                  <StepLabel>{t("DESCRIPTION")}</StepLabel>
                ) : (
                  <StepButton onClick={() => setActiveStep(1)}>
                    {t("DESCRIPTION")}
                  </StepButton>
                )}
              </Step>
            </Stepper>
            <fieldset className={`border-0 ${editMode ? "" : "pev-none"}`}>
              {activeStep === 0 && (
                <ConclusionResultForm>
                  {editMode && (
                    <Grid container justify="flex-end" className="pb-4">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          if (
                            falsyObject(
                              subForm.values[openedTab].conclusionResult
                            )
                          ) {
                            return subForm.setErrors({
                              conclusionResultsError: t("REQUIRED"),
                            });
                          }
                          subForm.validateForm().then((validate) => {
                            if (falsyObject(validate[openedTab])) {
                              subForm.setFieldValue(openedTab, {
                                conclusionResult:
                                  subForm.values[openedTab].conclusionResult,
                                description:
                                  subForm.touched[openedTab] ||
                                  !subForm.values[openedTab].description
                                    ? getDefaultDescriptions(
                                        subForm.values[openedTab]
                                          .conclusionResult
                                      )
                                    : subForm.values[openedTab].description,
                              });

                              setActiveStep(1);
                            }
                          });
                        }}
                      >
                        {t("APPROVE")}
                      </Button>
                    </Grid>
                  )}
                  <Divider />
                  <FormHelperText error>
                    {subForm.errors.conclusionResultsError}
                  </FormHelperText>
                  <ConclusionResultFormBody
                    conclusionName={conclusionName}
                    t={t}
                  />
                </ConclusionResultForm>
              )}
              {activeStep === 1 && (
                <DescriptionForm>
                  {editMode && (
                    <Grid container justify="space-between" className="pb-4">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => setActiveStep(0)}
                      >
                        <KeyboardArrowLeft />
                        {t("BACK")}
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleDescriptionApprove}
                      >
                        {t("APPROVE")}
                      </Button>
                    </Grid>
                  )}
                  <Divider />
                  <FormHelperText error>
                    {subForm.errors.descriptionError}
                  </FormHelperText>
                  <DescriptionFormBody conclusionName={conclusionName} t={t} />
                </DescriptionForm>
              )}
            </fieldset>
          </>
        )}
      </form>
    </Paper>
  );
}
