import instance from "../config/request";

export async function getPatients() {
  return instance.get("/patients").then((res) => res.data);
}

export async function getPatientsFromMis(iin, cancelToken, timeout = 60000) {
  return instance
    .get(`/patients/mis/${iin}`, { cancelToken, timeout: timeout })
    .then((res) => res.data);
}

export async function createConsultationConclusion(id, data) {
  const conclusion = {
    researchId: id,
    file: data,
  };
  return instance
    .post(`/conclusionConsultationOphthalogist`, conclusion)
    .then((res) => res.data);
}

export async function getConsultationConclusion(researchId) {
  return instance
    .get(`/conclusionConsultationOphthalogist/${researchId}`)
    .then((res) => res.data);
}

export async function updatePatientData({ patientData }) {
  return instance
    .put(`/keycloak/updatePatientData`, patientData)
    .then((res) => res.data);
}

export async function createOphthalmologist({ ophthalmologistData }) {
  const fd = new FormData();
  Object.entries(ophthalmologistData).forEach(([key, val]) => {
    if (val && val.constructor === Array) {
      val.forEach((v) => fd.append(key, v));
    } else {
      fd.append(key, val);
    }
  });
  return instance.post(`/ophthalmologistRegistry`, fd).then((res) => res.data);
}

export async function addDocumentOphthalmologist({ files, id }) {
  const fd = new FormData();
  Object.entries(files).forEach(([key, val]) => {
    if (val && val.constructor === Array) {
      val.forEach((v) => fd.append(key, v));
    } else {
      fd.append(key, val);
    }
  });
  return instance
    .post(`/ophthalmologistRegistry/${id}`, fd)
    .then((res) => res.data);
}

export async function getPatientInfo(iin) {
  return instance.get(`/patients/${iin}`).then((res) => res.data);
}

export async function askPatientPermission(iin) {
  return instance.post(`/patients/${iin}/askPermission`).then((res) => res.data);
}

export async function getHistoryOfAppointmentForPatient(iin, filters) {
  const keyPair = Object.entries(filters)
    .filter(([_, value]) => value)
    .map(([key, value]) => `${key}=${value}`);
  let str = "";
  if (keyPair.length) {
    str = "?" + keyPair.join("&");
  }

  return instance
    .get(`/patients/${iin}/historyOfAppointments` + str)
    .then((res) => res.data);
}

export async function askPatientInfoPermissionRequest(iin) {
  return instance
    .post(`/patients/${iin}/askPermission`)
    .then((res) => res.data);
}

export async function answerPatientInfoPermissionAsk(accessObjId, data) {
  return instance
    .post(`/patients/permission/${accessObjId}`, data)
    .then((res) => res.data);
}

export async function getPatientIinOrFullName({
  iinOrFullName,
  page,
  rowsPerPage,
}) {
  return instance
    .get(`/patients`, {
      params: {
        page: page || 0,
        rowsPerPage: rowsPerPage || 20,
        iinOrFullName,
      },
    })
    .then((res) => res.data);
}
