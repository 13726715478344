import React, {useContext} from "react";
import useStyle from "./slaCardStyles";
import {useHistory} from "react-router-dom";
import {useMutation} from "react-query";
import {updateConciliumStatus} from "../../api/conciliumApi";
import {queryClient} from "../../globalContexts/queryContext";
import {Button, Grid, Paper, Typography} from "@material-ui/core";
import {answerPatientInfoPermissionAsk} from "../../api";
import {UserContext} from "../../globalContexts/UserContext";
import {ROLES} from "../../config/constants";

const SlaCardPatientCardAnswer = React.forwardRef(({ notification, handleClose, t }, ref) => {
  const classes = useStyle();
  const { user } = useContext(UserContext);
  const roleSuffix = user.role === ROLES.DOCTOR ? "/DOCTOR" : "/PATIENT";
  const history = useHistory();

  if(!notification.payload) {
    return null;
  }
  return (
    <Paper className={classes.card} ref={ref} id={notification.object_id}>
      <Typography variant="caption" className={classes.cardTitle} title={
        t(notification.notification_type + roleSuffix, {
          doctorFullName: notification.payload.doctorFullName,
          patientFullName: notification.payload.patientFullName
        })
      }>
        {t(notification.notification_type + roleSuffix, {
          doctorFullName: notification.payload.doctorFullName,
          patientFullName: notification.payload.patientFullName
        })}
      </Typography>
      {
        notification.payload.patientIin && (
          <Grid className={classes.cardInfo}>
            <Button
              size="small"
              variant="outlined"
              className={classes.cardButton}
              onClick={() => history.push(`/patientInfo/${notification.payload.patientIin}`)}
            >
              {t("DETAILS")}
            </Button>
          </Grid>
        )
      }
    </Paper>
  );
})

export default SlaCardPatientCardAnswer;