export const CREATE_TOAST = "CREATE_TOAST";
export const DESTROY_TOAST = "DESTROY_TOAST";

export function createToast(message, status) {
  return {
    type: CREATE_TOAST,
    message,
    status
  };
}

export function destroyToast() {
  return {
    type: DESTROY_TOAST
  };
}
