import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { ImportExport } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import {
  createResearch,
  getOptimaReport,
  getRefferers,
  sendDriverValues,
} from "../../../../../api";
import { usePatientsFromMisRequest } from "../../../../../hooks/requestHooks";
import { mobileMaskUtils, parseIIN } from "../../../../../utils";
import { MobileMaskInput } from "../../../../../components/FormComponents";
import {
  createToast,
  MessagesContext,
} from "../../../../../globalContexts/MessagesContext";
import { FUNDUS_VERSION } from "../../../../../config/constants";
import { DictionariesContext } from "../../../../../globalContexts/DictionariesContext";
import { normalizePatientFieldsValues } from "../../../../ResearchesPage/utils";
import newResearchValidationSchema from "../../../../ResearchesPage/NewResearch/newResearchValidationSchema";
import { queryClient } from "../../../../../globalContexts/queryContext";
import MuiSelect from "../../../../../components/MuiSelect";

const useStyles = makeStyles(() => ({
  paper: {
    padding: "50px 24px",
    position: "relative",
    maxHeight: "90vh",
  },
  closeIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  formWrapper: {
    overflow: "auto",
    maxHeight: "80vh",
  },
  field: {
    marginLeft: "25px",
    marginTop: "10px",
    width: "70%",
  },
  hidden: {
    display: "none",
  },
}));

const serviceTypeOptions = (t) => [
  {
    value: "POLYCLINIC",
    label: t("POLYCLINIC"),
  },
  {
    value: "HOSPITAL",
    label: t("HOSPITAL"),
  },
  {
    value: "PAID",
    label: t("PAID"),
  },
  {
    value: "SCREENING",
    label: t("SCREENING"),
  },
];

async function fetchReferrers() {
  const { referrers } = await getRefferers();
  return referrers.map((item) => ({
    value: item.id,
    label: item.fullName || "",
  }));
}

export function OcularFunducPhotography({ onClose, research }) {
  const { t } = useTranslation(["research", "serviceNamesDict"]);
  const initialValues = {
    iin: research.iin,
    fullName: research.fullName,
    phone: "",
    referrer: {
      value: "4296",
      label: "Врач не предусмотрен",
    },
    receptionDoctor: null,
    age: "",
    gender: research.gender,
    complaints: "",
    diabetes: false,
    arterialHypertension: false,
    appointmentTimestamp: "",
    serviceType: {
      value: "POLYCLINIC",
      label: t("POLYCLINIC"),
    },
  };

  const classes = useStyles();
  const {
    authUserConfig: { smsEnabled },
  } = useContext(DictionariesContext)[0];

  const validationSchema = useMemo(
    () => newResearchValidationSchema(smsEnabled),
    [smsEnabled]
  );

  const { handleSubmit, control, errors, setValue, watch, setError } = useForm({
    defaultValues: initialValues,
    validationSchema: validationSchema,
    reValidateMode: "onBlur",
  });
  const iin = watch("iin");
  const [fullNameEditable, setFullNameEditable] = useState(false);
  const [showRows, setShowRows] = useState(false);
  const [
    { data: patientsData, error: patientsError },
    getPatients,
  ] = usePatientsFromMisRequest();
  const dispatchMessage = useContext(MessagesContext)[1];

  useEffect(() => {
    if (patientsError) {
      setFullNameEditable(true);
    } else {
      setFullNameEditable(false);
    }
  }, [patientsError]);

  const onSubmit = async (values) => {
    const requestBody = {
      age: values.age,
      appointmentTimestamp: "",
      arterialHypertension:
        values?.arterialHypertension || initialValues.arterialHypertension,
      complaints: values.complaints || initialValues.complaints,
      diabetes: values.diabetes || initialValues.diabetes,
      fullName: values.fullName,
      fundusVersion: "",
      gender: values.gender,
      iin: values.iin,
      leftEyes: [],
      phone: values.phone,
      receptionDoctorId: values.receptionDoctor,
      referrerId: values.referrer,
      refractionLeft: values.refractionLeft,
      refractionRight: values.refractionRight,
      rightEyes: [],
      serviceType: values.serviceType.value || initialValues.serviceType.value,
      status: "",
      serviceName: "OCULAR_FUNDUS_PHOTOGRAPHY",
      originalResearchId: Number(research.id),
    };

    const {
      result: { researchId },
    } = await createResearch(normalizePatientFieldsValues(requestBody));
    dispatchMessage(createToast(t("RESEARCH_CREATE_SUCCESS")));
    if (values.fundusVersion === FUNDUS_VERSION.OPTIMA) {
      if (values.leftEyes.length > 0 || values.rightEyes.length > 0) {
        dispatchMessage(createToast(t("SENDING_DATA_OPTIMA"), "info"));
        try {
          const { report } = await getOptimaReport(researchId);
          await sendDriverValues(
            normalizePatientFieldsValues({
              ...values,
              id: researchId,
              report,
            })
          );
        } catch (error) {
          dispatchMessage(createToast(t("ERROR_SAVING_OPTIMA"), "error"));
          console.error(error);
        }
      }
    }
    onClose();
    window
      .open(window.location.origin + "/researches/" + researchId, "_blank")
      .focus();
    await queryClient.invalidateQueries(["researchDetails", research.id]);
  };

  useEffect(() => {
    if (iin.length === 12) {
      try {
        const { age, gender } = parseIIN(iin);
        setValue("age", age);
        setValue("gender", gender);
        getPatients(iin);
      } catch (error) {
        console.log("error occured");
        setError("iin", "INVALID_IIN");
      }
    } else {
      setValue("fullName", "");
      setValue("phone", initialValues.phone);
      setValue("complaints", initialValues.complaints);
      setValue("receptionDoctorId", initialValues.receptionDoctor || null);
      setValue("age", "");
      setValue("gender", "");
    }
  }, [iin, getPatients, setValue, showRows]);

  useEffect(() => {
    if (!patientsData) {
      return;
    }
    if (!patientsData.personFullName) {
      setFullNameEditable(true);
    } else {
      setFullNameEditable(false);
    }
    if (patientsData.isLocal) {
      setValue("fullName", patientsData.personFullName);
      setValue("phone", mobileMaskUtils.formatValue(patientsData.phone));
      setValue("diabetes", patientsData.diabetes);
      setValue("arterialHypertension", patientsData.arterialHypertension);
    } else {
      setValue("fullName", patientsData.personFullName);
      setValue("complaints", patientsData.treatmentReasonName);
      setValue("diabetes", patientsData.diabetes);
      setValue("arterialHypertension", patientsData.arterialHypertension);
      setValue("receptionDoctorId", {
        value: patientsData.doctorId,
        label: patientsData.doctorFullName,
      });
    }
  }, [patientsData, setValue]);

  return (
    <Grid className={classes.paper}>
      <div className={classes.formWrapper}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"space-between"}
          >
            <span>{t("PATIENT_IIN")}</span>
            <Grid container direction={"column"} className={classes.field}>
              <Controller
                name={"iin"}
                control={control}
                as={
                  <TextField
                    size={"small"}
                    inputProps={{ maxLength: 12 }}
                    variant={"outlined"}
                    inputProps={{ readOnly: true }}
                  />
                }
              />
              <ErrorMessage
                errors={errors}
                name={"iin"}
                children={({ message }) =>
                  message && (
                    <FormHelperText error>{t(`${message}`)}</FormHelperText>
                  )
                }
              />
            </Grid>
          </Grid>
          <Box height={"12px"} />
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"space-between"}
          >
            <span>{t("PATIENT_FULL_NAME")}</span>
            <Grid container direction={"column"} className={classes.field}>
              <Controller
                name={"fullName"}
                control={control}
                as={
                  <TextField
                    size={"small"}
                    variant={"outlined"}
                    inputProps={{ readOnly: !fullNameEditable }}
                  />
                }
              />
              <ErrorMessage
                errors={errors}
                name={"fullName"}
                children={({ message }) => (
                  <FormHelperText error>{t(`${message}`)}</FormHelperText>
                )}
              />
            </Grid>
          </Grid>
          <Box height={"12px"} />
          <Grid
            container
            direction={"row"}
            alignItems={"center"}
            justify={"space-between"}
            className={!showRows && !smsEnabled ? classes.hidden : ""}
          >
            <span>{t("PHONE")}</span>
            <Grid container direction={"column"} className={classes.field}>
              <Controller
                name="phone"
                control={control}
                as={
                  <Input
                    size={"small"}
                    inputComponent={MobileMaskInput}
                    variant={"outlined"}
                  />
                }
              />

              <ErrorMessage
                errors={errors}
                name={"phone"}
                children={({ message }) => (
                  <FormHelperText error>{t(`${message}`)}</FormHelperText>
                )}
              />
            </Grid>
          </Grid>
          <Box height={"12px"} />
          <Grid className={!showRows ? classes.hidden : ""}>
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("AGE")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <Controller
                  name={"age"}
                  control={control}
                  as={
                    <TextField
                      type={"number"}
                      size={"small"}
                      variant={"outlined"}
                      inputProps={{ readOnly: true }}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("GENDER")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <Controller
                  name={"gender"}
                  control={control}
                  as={
                    <TextField
                      size={"small"}
                      variant={"outlined"}
                      inputProps={{ readOnly: true }}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("SERVICE_TYPE")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <Controller
                  name={"serviceType"}
                  control={control}
                  as={
                    <MuiSelect
                      variant={"outlined"}
                      size={"small"}
                      options={serviceTypeOptions(t)}
                    />
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name={"serviceType"}
                  children={({ message }) => (
                    <FormHelperText error>{t(`${message}`)}</FormHelperText>
                  )}
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("REFERRER")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <Controller
                  name={"referrer"}
                  control={control}
                  as={
                    <MuiSelect
                      variant={"outlined"}
                      size={"small"}
                      isAsync
                      fetchData={fetchReferrers}
                    />
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name={"referrer"}
                  children={({ message }) => (
                    <FormHelperText error>{t(`${message}`)}</FormHelperText>
                  )}
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("RECEPTION_DOCTOR")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <Controller
                  name={"receptionDoctor"}
                  control={control}
                  as={
                    <MuiSelect
                      variant={"outlined"}
                      size={"small"}
                      isAsync
                      fetchData={fetchReferrers}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <span>{t("COMPLAINTS")}</span>
              <Grid container direction={"column"} className={classes.field}>
                <Controller
                  name={"complaints"}
                  control={control}
                  as={<TextField size={"small"} variant={"outlined"} />}
                />
                <ErrorMessage
                  errors={errors}
                  name={"complaints"}
                  children={({ message }) => (
                    <FormHelperText error>{t(`${message}`)}</FormHelperText>
                  )}
                />
              </Grid>
            </Grid>
            <Box height={"12px"} />
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              justify={"space-between"}
            >
              <Grid>
                <span>{t("DIABETES")}</span>
                <Controller
                  name={"diabetes"}
                  control={control}
                  as={<Checkbox />}
                />
              </Grid>
              <Grid>
                <span>{t("ARTERIAL_HYPERTENSION")}</span>
                <Controller
                  name={"arterialHypertension"}
                  control={control}
                  as={<Checkbox />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box height={"12px"} />
          <Grid container direction={"row"} justify={"flex-end"}>
            <IconButton
              color="primary"
              onClick={() => {
                setShowRows(!showRows);
              }}
            >
              <ImportExport />
            </IconButton>
            <Button type={"submit"} variant={"contained"} color={"primary"}>
              {t("CONTINUE")}
            </Button>
          </Grid>
        </form>
      </div>
    </Grid>
  );
}
