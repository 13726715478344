import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import driverZip from "../../../assets/driver.zip";
import {
  makeStyles,
  Paper,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import EyeContainer from "./EyeContainer";
import EyeDropzone from "./EyeDropzone";
import { SOCKET_STATUS } from "../constants";
import { loader } from "../../../components/AnimatedLoading";
import { useTranslation } from "react-i18next";
import { dicomToFile } from "../../../utils";

const useStyles = makeStyles(() => {
  return {
    root: ({ width = 0 }) => ({
      width: width * 0.8,
      height: width * 0.8,
      overflow: "hidden",
      position: "relative",
      display: "flex",
      alignItems: "center",
    }),
    "@media (max-width: 600px)": {
      root: ({ width = 0 }) => ({
        width: width * 0.95,
        height: width * 0.95,
      }),
    },
  };
});

function NoImage({ socketStatus }) {
  const { t } = useTranslation("research");
  if (socketStatus === SOCKET_STATUS.INITIAL_CONNECTING) {
    return loader;
  }
  if (socketStatus === SOCKET_STATUS.ERROR) {
    return (
      <>
        <Typography color="secondary" align="center" variant="h6" paragraph>
          {t("SOCKET_CONNECTION_ERROR_MAIN")}
        </Typography>
        <Typography color="primary" align="center">
          {t("SOCKET_CONNECTION_ERROR_INSTRUCTION")}{" "}
          <a href={driverZip} download="driver.zip" className="text-underline">
            {t("TO_REF")}
          </a>
        </Typography>
        <Typography
          color="primary"
          align="center"
          className="pt-8 pb-8 text-uppercase"
        >
          {t("OR")}
        </Typography>
        <Grid container alignItems="center" justify="center">
          <Typography>{t("CLICK_ON_ICON")}</Typography>
          <AttachFile color="action" />
          <Typography>{t("AND_UPLOAD_MANUALLY")}</Typography>
        </Grid>
      </>
    );
  }
  return (
    <>
      {loader}
      <Typography align="center" variant="h6" paragraph>
        {t("SOCKET_CONNECTION_SUCCESS_MAIN")}
      </Typography>
      <Typography color="primary" align="center">
        {t("SOCKET_CONNECTION_SUCCESS_INSTRUCTION")}
      </Typography>
      <Typography color="primary" align="center">
        {t("OR")}
      </Typography>
      <Grid container alignItems="center" justify="center">
        <Typography>{t("CLICK_ON_ICON")}</Typography>
        <AttachFile color="action" />
        <Typography>{t("AND_UPLOAD_MANUALLY")}</Typography>
      </Grid>
    </>
  );
}

function Camera({ value, setFieldValue, name, size, label, socketStatus }) {
  const [forcedActiveIndex, setForcedActiveIndex] = useState(0);
  const onDrop = async (files) => {
    const images = files.filter((file) => file.type !== "application/dicom");
    const dicomFiles = await Promise.all(
      files.filter((file) => file.type === "application/dicom").map(dicomToFile)
    );

    const newValue = [...value, ...images, ...dicomFiles];
    setFieldValue(name, newValue);
    setForcedActiveIndex(newValue.length - 1);
  };
  const classes = useStyles({ width: size.width });
  const hasImage = value.length > 0;
  return (
    <Grid item xs={12} lg={6} container alignItems="center" direction="column">
      <Paper className={classes.root}>
        {/* disableClick used to be hasImage but I don't think we need click at all,
        might refactor it into EyeDropzone */}
        <EyeDropzone onDrop={onDrop} disableClick={true} name={name}>
          {hasImage ? (
            <EyeContainer
              value={value}
              showControl
              socketStatus={socketStatus}
              editable
              setFieldValue={setFieldValue}
              forcedActiveIndex={forcedActiveIndex}
              name={name}
            />
          ) : (
            <Grid container direction="column" className="h-100">
              <Grid container item justify="flex-end">
                <IconButton component="label" htmlFor={`dropzone_${name}`}>
                  <AttachFile />
                </IconButton>
              </Grid>
              <Grid
                container
                item
                justify="center"
                direction="column"
                alignItems="center"
                className="fg-1 border-dashed pl-10 pr-10"
              >
                <NoImage socketStatus={socketStatus} />
              </Grid>
            </Grid>
          )}
        </EyeDropzone>
      </Paper>
      <Typography variant="h6">{label}</Typography>
    </Grid>
  );
}

Camera.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
  socketStatus: PropTypes.oneOf(Object.values(SOCKET_STATUS)),
};

export default memo(Camera);
