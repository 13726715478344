import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Modal,
  Paper,
} from "@material-ui/core";
import {
  GetApp,
  MoreHoriz,
  NoteAdd,
  Edit,
  Assignment,
  Rowing,
} from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import AsyncButton from "../../../components/AsyncButton";
import { MessagesContext } from "../../../globalContexts/MessagesContext";
import { queryClient } from "../../../globalContexts/queryContext";
import {
  FORCE_UPDATE,
  TableContext,
} from "../../../globalContexts/TableContext";
import { useIsDoctor } from "../../../globalContexts/UserContext";
import { downloadConclusionConclusion } from "../../PatientRegistry/PatientRegistry";
import { RESEARCH_SERVICE_NAME } from "../constants";
import { ResearchServiceDetails } from "../ResearchServices/ResearchServiceDetails";

import {
  askPatientCardPermission,
  fetchReport,
  getImagesPdf,
  takeResearch,
} from "./ResearchJournalTable";
import { ResearchJournalTableEdit } from "./ResearchJournalTableEdit";

const useStyles = makeStyles(() => ({
  menu: {
    margin: "35px 0 0 50px",
  },
  button: {
    display: "flex",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function TakeResearchButton({ t, id, dispatchMessage }) {
  const dispatchTable = useContext(TableContext)[1];
  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={() =>
        takeResearch(id, dispatchMessage, t).then(() =>
          dispatchTable({ type: FORCE_UPDATE })
        )
      }
      title={t("TAKE_RESEARCH")}
    >
      <NoteAdd />
    </Button>
  );
}

export function ResearchJournalTableActions({
  row,
  isPatient,
  researchStatus,
  user,
}) {
  const classes = useStyles();
  const { t } = useTranslation("research");
  const dispatchMessage = useContext(MessagesContext)[1];
  const idDoctor = useIsDoctor();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [otherResearchServiceOpen, setOtherResearchServiceOpen] = useState(
    false
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const isOcularFundusPhotography =
    row.serviceName === RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY &&
    row.status !== researchStatus.CREATED;
  const isOphtalmologistConsultation =
    row.serviceName === RESEARCH_SERVICE_NAME.OPHTHALMOLOGIST_CONSULTATION &&
    row.status === researchStatus.FINISHED;

  const getDetailsRoute = () => {
    if (row.serviceName === RESEARCH_SERVICE_NAME.OCULAR_FUNDUS_PHOTOGRAPHY) {
      isOcularFundusPhotography &&
        queryClient.invalidateQueries(["researchDetails", row.id]);
      return `/researches/${row.id}`;
    }
    if (
      row.serviceName === RESEARCH_SERVICE_NAME.OPHTHALMOLOGIST_CONSULTATION
    ) {
      return `/patientRegistry/${row.id}`;
    }
  };

  const otherResearchService =
    row.serviceName === RESEARCH_SERVICE_NAME.MAKLAKOV_TEST ||
    row.serviceName === RESEARCH_SERVICE_NAME.NON_CONTACT_TONOMETRY ||
    row.serviceName === RESEARCH_SERVICE_NAME.SCHIRMER_TEST ||
    row.serviceName === RESEARCH_SERVICE_NAME.EXOPHTHALMOMETRY ||
    row.serviceName === RESEARCH_SERVICE_NAME.AUTOREFRACTOMY;

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid className={classes.button}>
      {!otherResearchService ? (
        <Button
          variant="contained"
          className="mr-10"
          color="primary"
          size="small"
          title={isPatient ? "Смотреть" : t("DETAILS")}
          component={Link}
          to={getDetailsRoute}
        >
          <MoreHoriz />
        </Button>
      ) : (
        <>
          <Button
            variant="contained"
            className="mr-10"
            color="primary"
            size="small"
            title={isPatient ? "Смотреть" : t("DETAILS")}
            onClick={() => {
              setOtherResearchServiceOpen(true);
            }}
          >
            <MoreHoriz />
          </Button>
          <Modal
            open={otherResearchServiceOpen}
            onClose={() => setOtherResearchServiceOpen(false)}
            className={classes.modal}
          >
            <ResearchServiceDetails
              onClose={() => setOtherResearchServiceOpen(false)}
              research={row}
            />
          </Modal>
        </>
      )}
      {(isOcularFundusPhotography || isOphtalmologistConsultation) && (
        <Button
          className="mr-10"
          variant="contained"
          color="secondary"
          size="small"
          data-researchid={row.id}
          title={isPatient ? "Скачать" : t("CONCLUSION")}
          onClick={handleClick}
        >
          <GetApp />
        </Button>
      )}
      {isOcularFundusPhotography && (
        <Menu
          open={anchorEl?.dataset.researchid == row.id}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.menu}
        >
          <MenuItem>
            <AsyncButton
              fullWidth
              onClick={() => {
                return getImagesPdf(row.id, dispatchMessage, t).finally(
                  handleClose
                );
              }}
            >
              {t("PICTURES")}
            </AsyncButton>
          </MenuItem>
          {row.status === researchStatus.FINISHED && (
            <MenuItem>
              <AsyncButton
                fullWidth
                onClick={() => {
                  return fetchReport(row.id, dispatchMessage, t).finally(
                    handleClose
                  );
                }}
              >
                {t("CONCLUSION")}
              </AsyncButton>
            </MenuItem>
          )}
        </Menu>
      )}
      {isOphtalmologistConsultation && (
        <Menu
          open={anchorEl?.dataset.researchid == row.id}
          anchorEl={anchorEl}
          onClose={handleClose}
          className={classes.menu}
        >
          <MenuItem>
            <AsyncButton
              fullWidth
              onClick={() => {
                return downloadConclusionConclusion(
                  row.id,
                  dispatchMessage,
                  t
                ).finally(handleClose);
              }}
            >
              {t("CONCLUSION")}
            </AsyncButton>
          </MenuItem>
        </Menu>
      )}
      {idDoctor && row.status === researchStatus.IN_PROGRESS && (
        <TakeResearchButton
          id={row.id}
          dispatchMessage={dispatchMessage}
          t={t}
        />
      )}
      {user.name === row.username && row.source !== "IM" && (
        <IconButton
          size="small"
          color="primary"
          onClick={() => setOpenEdit(true)}
        >
          <Edit />
        </IconButton>
      )}
      {openEdit && <ResearchJournalTableEdit row={row} open={true} />}
    </Grid>
  );
}
