import _interception from "lodash/intersection";

export const descriptionMappers = {
  HEALTHY: {
    DISK: "PALE_PINK",
    BORDER: "CLEAR",
    FOSSA: "PHYSIOLOGICAL",
    ARTERIES: ["FINE"],
    VEINS: ["FINE"],
    MACULA: "WITHOUT_FEATURE",
    RETINA: ["WITHOUT_FEATURE"],
  },
  HYPERTONIC: {
    ANGIOPATHY: {},
    ANGIOSCLEROSIS: {},
    RETINOPATHY: {},
  },
  OTHER: {},
  RETINA_FORMATION: {},
  RETINOPATHY: {
    NON_PROLIFERATIVE: {
      DISK: "PALE_PINK",
      BORDER: "CLEAR",
      FOSSA: "PHYSIOLOGICAL",
      ARTERIES: ["NARROWED"],
      VEINS: ["POLYCLOVES"],
      MACULA: "REFLEX_NOT_AVAILABLE",
      RETINA: ["SOLID_EXUDATES", "POINT_HEMORRHAGES", "MICROANEURYSMS"],
    },
    PREPROLIFERATIVE: {
      DISK: "PALE_PINK",
      BORDER: "CLEAR",
      FOSSA: "PHYSIOLOGICAL",
      ARTERIES: ["NARROWED"],
      VEINS: ["POLYCLOVES", "CRIMPED"],
      MACULA: "REFLEX_NOT_AVAILABLE",
      RETINA: [
        "SOFT_EXUDATES",
        "SOLID_EXUDATES",
        "POINT_HEMORRHAGES",
        "DASHED_HEMORRHAGES",
        "MICROANEURYSMS",
      ],
      text: "Вены чёткообразно изменены, ИРМА",
    },
    PROLIFERATIVE: {
      DISK: "PALE_PINK",
      BORDER: "CLEAR",
      FOSSA: "PHYSIOLOGICAL",
      ARTERIES: ["NARROWED"],
      VEINS: ["POLYCLOVES", "CRIMPED"],
      MACULA: "REFLEX_NOT_AVAILABLE",
      RETINA: [
        "SOFT_EXUDATES",
        "SOLID_EXUDATES",
        "POINT_HEMORRHAGES",
        "DASHED_HEMORRHAGES",
        "MICROANEURYSMS",
      ],
      text: "Пролиферация ... Фиброз....",
    },
  },
  GLAUCOMA_SUSPICION: {
    DISK: "PALE_PINK",
    BORDER: "CLEAR",
    FOSSA: "EXTENDED",
    ARTERIES: ["NARROWED"],
    VEINS: ["FINE"],
    MACULA: "WITHOUT_FEATURE",
    RETINA: ["WITHOUT_FEATURE"],
  },
  DEGENERATION: {
    DRY: {
      DISK: "PALE_PINK",
      BORDER: "CLEAR",
      FOSSA: "PHYSIOLOGICAL",
      ARTERIES: ["FINE"],
      VEINS: ["FINE"],
      MACULA: "MOTTLING",
    },
    WET: {},
    WET_IN_DOUBT: {},
  },
  RETINAL_ANGIOPATHY: {
    DISK: "PALE_PINK",
    BORDER: "CLEAR",
    FOSSA: "PHYSIOLOGICAL",
    ARTERIES: ["NARROWED"],
    VEINS: ["POLYCLOVES"],
    MACULA: "WITHOUT_FEATURE",
    RETINA: ["WITHOUT_FEATURE"],
  },
  MACULAR_RETINAL_TEAR: {
    DISK: "PALE_PINK",
    BORDER: "CLEAR",
    FOSSA: "PHYSIOLOGICAL",
    ARTERIES: ["NARROWED"],
    VEINS: ["FINE"],
    text: "В макулярной зоне округлый очаг красного цвета с чёткими границами",
  },
};

export const recommendationMappers = {
  HEALTHY: {},
  HYPERTONIC: {
    ANGIOPATHY: {},
    ANGIOSCLEROSIS: {},
    RETINOPATHY: {},
  },
  OTHER: {},
  RETINA_FORMATION: {},
  RETINOPATHY: {
    NON_PROLIFERATIVE: {
      recommendationCodenames: [
        "ENDOCRINOLOGIST_CONSULTATION",
        "LASER_SURGEON_CONSULTATION",
        "OST_MACULA_DIAGNOSIS",
      ],
    },
    PREPROLIFERATIVE: {
      recommendationCodenames: [
        "ENDOCRINOLOGIST_CONSULTATION",
        "LASER_SURGEON_CONSULTATION",
        "OST_MACULA_DIAGNOSIS",
      ],
    },
    PROLIFERATIVE: {
      recommendationCodenames: [
        "ENDOCRINOLOGIST_CONSULTATION",
        "LASER_SURGEON_CONSULTATION",
        "OST_MACULA_DIAGNOSIS",
        "VITREORETINAL_SURGEON_CONSULTATION",
      ],
    },
  },
  GLAUCOMA_SUSPICION: {
    recommendationCodenames: [
      "OPHTHALMOGOLIST_CONSULTATION",
      "GLAUCOMA_SCREENING",
    ],
    recommendationText: "OU УЗИ глаз (А-скан)",
  },
  DEGENERATION: {
    DRY: {
      recommendationCodenames: ["OST_MACULA_DIAGNOSIS"],
    },
    WET: {},
    WET_IN_DOUBT: {},
  },
  RETINAL_ANGIOPATHY: {
    recommendationCodenames: [
      "INSPECTION_ONCE_SIX_MONTHS",
      "NEUROLOGIST_CONSULTATION",
    ],
  },
  MACULAR_RETINAL_TEAR: {
    recommendationCodenames: [
      "OST_MACULA_DIAGNOSIS",
      "VITREORETINAL_SURGEON_CONSULTATION",
    ],
  },
};

export const defaultDescription = {
  ARTERIES: null,
  BORDER: null,
  DISK: null,
  FOSSA: null,
  MACULA: null,
  RETINA: null,
  VEINS: null,
  VESSEL: null,
};

export const defaultRecomendations = {
  recommendationCodenames: null,
  recommendationText: "",
};

export function descriptionsInterception(objects) {
  const result = { ...defaultDescription };
  for (const key in objects[0]) {
    if (objects[0].hasOwnProperty(key)) {
      const value = objects[0][key];
      if (typeof value === "string") {
        if (objects.every((obj) => obj[key] === value)) {
          result[key] = value;
          continue;
        }
      }
      if (value.constructor === Array) {
        const interception = _interception(...objects.map((obj) => obj[key]));
        if (interception.length > 0) {
          result[key] = interception;
        } else {
          result[key] = null;
        }
        continue;
      }
    }
  }

  return result;
}

export function recommendationsJoin(objects) {
  const result = { ...defaultRecomendations };
  const allCodenames = [];
  objects.forEach((object) => {
    if (object.recommendationCodenames) {
      allCodenames.push(...object.recommendationCodenames);
    }
    if (object.recommendationText) {
      result.recommendationText = object.recommendationText;
    }
  });
  if (allCodenames.length > 0) {
    result.recommendationCodenames = [...new Set(allCodenames)];
  }

  return result;
}

export function getDefaultDescriptions(conclusionResult) {
  const defaults = [];
  for (const key in conclusionResult) {
    const value = conclusionResult[key];
    if (value) {
      switch (key) {
        case "HEALTHY":
        case "OTHER":
        case "GLAUCOMA_SUSPICION":
        case "MACULAR_RETINAL_TEAR":
        case "RETINAL_ANGIOPATHY":
        case "RETINA_FORMATION":
          descriptionMappers[key] && defaults.push(descriptionMappers[key]);
          break;
        case "DEGENERATION":
        case "RETINOPATHY":
        case "HYPERTONIC":
          descriptionMappers[key][value] &&
            defaults.push(descriptionMappers[key][value]);
        default:
          break;
      }
    }
  }
  return descriptionsInterception(defaults);
}

export function getDefaultRecommendations(conclusionResults) {
  const defaults = [];
  conclusionResults.forEach((conclusionResult) => {
    for (const key in conclusionResult) {
      const value = conclusionResult[key];
      if (value) {
        switch (key) {
          case "HEALTHY":
          case "OTHER":
          case "GLAUCOMA_SUSPICION":
          case "MACULAR_RETINAL_TEAR":
          case "RETINAL_ANGIOPATHY":
          case "RETINA_FORMATION":
            recommendationMappers[key] &&
              defaults.push(recommendationMappers[key]);
            break;
          case "DEGENERATION":
          case "RETINOPATHY":
          case "HYPERTONIC":
            recommendationMappers[key][value] &&
              defaults.push(recommendationMappers[key][value]);
          default:
            break;
        }
      }
    }
  });
  return recommendationsJoin(defaults);
}
