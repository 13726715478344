import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Typography,
  TextField,
  Box,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Close, Edit } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { parseIIN } from "../../../utils";
import { usePatientsFromMisRequest } from "../../../hooks/requestHooks";
import { useMutation } from "react-query";
import { updateResearchIIN } from "../../../api";
import {
  createToast,
  MessagesContext,
} from "../../../globalContexts/MessagesContext";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    padding: "32px 24px",
    position: "relative",
    width: "30vw",
    maxHeight: "90vh",
  },
  closeIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
  },
  textLine: {
    width: "15vw",
  },
}));

export function ResearchJournalTableEdit({ row, open }) {
  const classes = useStyles();
  const { t } = useTranslation("research");
  const dispatchMessage = useContext(MessagesContext)[1];
  const [openEditModal, setOpenEditModal] = useState(open);
  const [
    { data: patientsData, error: patientsError },
    getPatients,
    cancelPatientRequest,
  ] = usePatientsFromMisRequest();
  const { handleSubmit, control, errors, setValue, watch, setError } = useForm({
    defaultValues: {
      iin: row.iin || "",
      fullName: row.fullName || "",
    },
  });

  const { mutate } = useMutation(
    async ({ id, researchData }) => {
      await updateResearchIIN({ id, researchData });
    },
    {
      onSuccess: () => {
        dispatchMessage(createToast("Успешно", "success"));
        setOpenEditModal(false);
      },
    }
  );
  const iin = watch("iin");
  useEffect(() => {
    if (iin.length === 12) {
      try {
        getPatients(iin);
      } catch (error) {
        setError("iin", "INVALID_IIN");
      }
    } else {
      setValue("fullName", "");
    }
  }, [iin, getPatients, setValue, setError]);

  useEffect(() => {
    if (!patientsData) {
      return;
    } else {
      setValue("fullName", patientsData.personFullName);
    }
  }, [patientsData, setValue]);

  async function onSubmit(data) {
    const id = row.id;
    const researchData = { iin: data.iin, personFullName: data.fullName };
    mutate({ id, researchData });
  }
  return (
    <Grid>
      <Modal
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        className={classes.modal}
      >
        <Paper className={classes.paper}>
          <Close
            className={classes.closeIcon}
            onClick={() => setOpenEditModal(false)}
          />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container alignItems={"center"} justify={"space-around"}>
              <Typography>{t("PATIENT_IIN")}:</Typography>
              <Controller
                control={control}
                name="iin"
                as={<TextField className={classes.textLine} />}
              />
            </Grid>
            <Box height={"24px"} />
            <Grid container alignItems={"center"} justify={"space-around"}>
              <Typography>{t("PATIENT_FULL_NAME")}:</Typography>
              <Controller
                control={control}
                name="fullName"
                as={
                  <TextField
                    InputProps={{ readOnly: true }}
                    className={classes.textLine}
                  />
                }
              />
            </Grid>
            <Box height={"32px"} />
            <Grid container justify={"space-around"}>
              <Button variant="contained" color="primary" type="submit">
                {t("SAVE")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenEditModal(false)}
              >
                {t("CANCEL")}
              </Button>
            </Grid>
          </form>
        </Paper>
      </Modal>
    </Grid>
  );
}
