import {
  Box,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import { Add, Close, Edit, ListAlt } from "@material-ui/icons";
import React, { useContext, useEffect, useState } from "react";
import CreateFormModal from "./CreateFormModal";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import DiagnosisListModal from "./DiagnosisModalList";
import DiagnosisTable from "./DiagnosisTable";
import { getMkb10Dictionary } from "../../../../api";
import { DictionariesContext } from "../../../../globalContexts/DictionariesContext";
import { addDictionary } from "../../../../globalContexts/DictionariesContext/dictionariesActions";
import DiagnosisTableRow from "./DiagnosisTableRow";
import { useResearchDetails } from "../../../ResearchesPage/ResearchDetails/queryHooks";
import {ROLES} from "../../../../config/constants";
import Roled from "../../../../components/Roled";
import {RESEARCH_STATUS} from "../../../ResearchesPage/constants";

const useStyles = makeStyles((theme) => ({
  icon: {
    border: "1px solid black",
    color: theme.palette.primary.main,
  },
}));

const Diagnosis = () => {
  const classes = useStyles();
  const [diagnosisChosen, setDiagnosisChosen] = useState(null);
  const [isCreatingDiagnosis, setIsCreatingDiagnosis] = useState(false);
  const [diagnosisHistoryOpen, setDiagnosisHistoryOpen] = useState(false);
  const [diagnosisEditing, setDiagnosisEditing] = useState(null);
  const [{ mkb10 }, dispatch] = useContext(DictionariesContext);
  const { id: researchId } = useParams();
  const [{ data: research }] = useResearchDetails(researchId);
  const { data: mkb10Dict } = useQuery("mkb10", () => getMkb10Dictionary(), {
    initialData: [],
    enabled: !Boolean(mkb10),
  });
  const handleCloseModal = () => {
    setIsCreatingDiagnosis(false);
    setDiagnosisEditing(null);
    setDiagnosisChosen(null);
  };
  const currentDiagnosis = research.diagnoses.find((item) => item.isCurrent);
  useEffect(() => {
    if (mkb10Dict.length) {
      dispatch(addDictionary("mkb10", mkb10Dict));
    }
  }, [mkb10Dict]);
  const isFinished = research.status === RESEARCH_STATUS.FINISHED

  return (
    <Grid container direction={"column"}>
      <Grid container>
        {!currentDiagnosis && !isFinished && (
            <Roled roles={[ROLES.DOCTOR]}>
              <Add
                  cursor={"pointer"}
                  className={classes.icon}
                  onClick={() => setIsCreatingDiagnosis(true)}
              />
            </Roled>
        )}
        {currentDiagnosis && !isFinished &&  (
            <Roled roles={[ROLES.DOCTOR]}>
              <Edit
                  onClick={() => setDiagnosisEditing(currentDiagnosis)}
                  cursor={"pointer"}
                  className={classes.icon}
              />
            </Roled>
        )}
        <ListAlt
          cursor={"pointer"}
          className={classes.icon}
          onClick={() => setDiagnosisHistoryOpen(true)}
        />
      </Grid>
      <Box height={"8px"} />
      <DiagnosisListModal
        diagnoses={research.diagnoses}
        open={diagnosisHistoryOpen}
        onClose={() => setDiagnosisHistoryOpen(false)}

      />
      <CreateFormModal
        open={isCreatingDiagnosis || Boolean(diagnosisEditing) || Boolean(diagnosisChosen)}
        onClose={handleCloseModal}
        chosen={diagnosisChosen}
        editingItem={diagnosisEditing}
        diagnoses={research.diagnoses}
      />
      {currentDiagnosis && (
        <DiagnosisTable onClick={() => setDiagnosisChosen(currentDiagnosis)}>
          <DiagnosisTableRow diagnosis={currentDiagnosis} />
        </DiagnosisTable>
      )}
    </Grid>
  );
};

export default Diagnosis;
