import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-decoupled-document";
import {} from "react-i18next";
import * as editorLocales from "./skeditorLocales";
// "custom-editor": "git+https://git@gitlab.com/Nurbytes/ckeditor5-custom-build#main",
// import Editor from "custom-editor";

window.CKEDITOR_TRANSLATIONS = {
  lng: {
    dictionary:
      editorLocales[localStorage.getItem("LANGUAGE_LOCAL_STORAGE_KEY")] ||
      editorLocales.kk,
  },
};

const TextEditor = ({ setEditor, editor, disabled }) => {
  return (
    <CKEditor
      onReady={(_editor) => {
        _editor.ui
          .getEditableElement()
          .parentElement.insertBefore(
            _editor.ui.view.toolbar.element,
            _editor.ui.getEditableElement()
          );
        setEditor(_editor);
      }}
      onError={(error) => {
        console.log({ error });
        if (error.willEditorRestart) {
          editor.ui.view.toolbar.element.remove();
        }
      }}
      editor={Editor}
      disabled={disabled}
    />
  );
};

export default TextEditor;
