import { useCubeQuery } from "@cubejs-client/react";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import groupBy from "lodash/groupBy";
import { CircularProgress } from "@material-ui/core";
import { formatDate } from "../../../../../utils";

export function DashboardLineCharts({ query }) {
  const { t } = useTranslation("scientificAnalytics");
  const filters = [
    {
      member: "Research.researchStatus",
      operator: "equals",
      values: ["FINISHED"],
    },
  ];

  if (query.organizationFilterData) {
    filters.push({
      member: "Research.groupId",
      operator: "equals",
      values: [query.organizationFilterData],
    });
  }
  if (query.doctorFilterData) {
    filters.push({
      member: "Research.responsibleDoctorId",
      operator: "equals",
      values: [query.doctorFilterData],
    });
  }
  if (query.gender) {
    filters.push({
      member: "Research.gender",
      operator: "equals",
      values: [query.gender],
    });
  }
  if (query.doctorFilterData) {
    filters.push({
      member: "Research.responsibleDoctorId",
      operator: "equals",
      values: [query.doctorFilterData],
    });
  }
  const doctorFilter = [];
  if (query.fromDate) {
    doctorFilter.push({
      member: "Conclusion.conclusionTimestamp",
      operator: "afterDate",
      values: [query.fromDate],
    });
  }
  if (query.beforeDate) {
    doctorFilter.push({
      member: "Conclusion.conclusionTimestamp",
      operator: "beforeDate",
      values: [query.beforeDate],
    });
  }
  if (query.diagnos) {
    doctorFilter.push({
      member: "ConclusionResultToRetinopathy.retinopathyCodename",
      operator: "equals",
      values: [query.diagnos],
    });
  }
  const autoConclusionFilter = [];
  if (query.fromDate) {
    autoConclusionFilter.push({
      member: "PreConclusion.createdAt",
      operator: "afterDate",
      values: [query.fromDate],
    });
  }
  if (query.beforeDate) {
    autoConclusionFilter.push({
      member: "PreConclusion.createdAt",
      operator: "beforeDate",
      values: [query.beforeDate],
    });
  }
  if (query.diagnos) {
    autoConclusionFilter.push({
      member: "PreConclusionDiagnosisToRetinopathy.retinopathyCodename",
      operator: "equals",
      values: [query.diagnos],
    });
  }

  const { resultSet, isLoading, error, progress } = useCubeQuery({
    measures: [
      "Research.count",
      "Conclusion.count",
      "ConclusionResult.count",
      "ConclusionResultToRetinopathy.count",
    ],
    order: [["Conclusion.conclusionTimestamp", "asc"]],
    dimensions: [
      "ConclusionResultToRetinopathy.retinopathyCodename",
      "Conclusion.conclusionTimestamp",
      "Research.gender",
    ],
    filters: [...filters, ...doctorFilter],
  });

  const {
    resultSet: autoResultSet,
    isLoading: autoIsLoading,
    error: autoError,
  } = useCubeQuery({
    measures: [
      "Research.count",
      "AutoConclusion.count",
      "PreConclusion.count",
      "PreConclusionBlock.count",
      "PreConclusionDiagnosis.count",
      "PreConclusionDiagnosisToRetinopathy.count",
    ],
    order: {
      "Research.count": "desc",
    },
    dimensions: [
      "PreConclusionDiagnosisToRetinopathy.retinopathyCodename",
      "PreConclusion.createdAt",
      "Research.gender",
    ],
    filters: [...filters, ...autoConclusionFilter],
  });

  if (autoIsLoading || isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (autoError || error) {
    return <div>{autoError.toString() || error.toString()}</div>;
  }

  let resultSetCopy = [];

  if (query.type === "DOCTOR") {
    resultSetCopy = resultSet?.tablePivot().map((data) => {
      return {
        value: Number(data["ConclusionResult.count"]),
        date: formatDate(data["Conclusion.conclusionTimestamp"]),
        name: data["Research.gender"],
      };
    });
  }

  if (query.type === "AUTOCONCLUSION") {
    resultSetCopy = autoResultSet?.tablePivot().map((data) => {
      return {
        value: Number(data["PreConclusion.count"]),
        date: formatDate(data["PreConclusion.createdAt"]),
        name: data["Research.gender"],
      };
    });
  }

  const resultSetGroupBy = groupBy(resultSetCopy, "date");

  const lineData = Object.entries(resultSetGroupBy).map(([key, value]) =>
    value.reduce(
      (acc, curr) => {
        if (curr.name === "Мужчина") {
          acc.male += curr.value;
        }
        if (curr.name === "Женщина") {
          acc.female += curr.value;
        }
        return acc;
      },
      { date: key, male: 0, female: 0 }
    )
  );
  return (
    <AreaChart
      width={800}
      height={500}
      data={lineData}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend height={36} />
      <Area
        name={t("MALE")}
        type="monotone"
        dataKey="male"
        stroke="#8884d8"
        fill="#8884d8"
      />
      <Area
        name={t("FEMALE")}
        type="monotone"
        dataKey="female"
        stroke="#82ca9d"
        fill="#82ca9d"
      />
    </AreaChart>
  );
}
