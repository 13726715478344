import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input, InputAdornment } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px"
  },
  filters: {
    flex: 1,
    display: "flex"
  },
  search: {
    flex: "0 1 300px",
    minWidth: "100px",
    display: "flex",
    alignItems: "center"
  },
  input: {
    marginLeft: 8,
    width: "100%"
  },
  iconButton: {
    padding: 10
  },
  "@media (max-width: 600px)": {
    root: {
      flexDirection: "column",
      position: "sticky",
      top: 50,
      background: theme.palette.common.white,
      zIndex: 1,
      padding: 0
    },
    search: {
      flex: 1,
      width: "100%"
    },
    input: {
      marginLeft: 0
    }
  }
}));

export default function TableFilters({ filters, search, handleSearch }) {
  const classes = useStyles();
  if (!filters) return null;
  const { allowSearch, searchPlaceholder, filtersComponent } = filters;
  return (
    <div className={classes.root}>
      {filtersComponent}
      {allowSearch && (
        <div className={classes.search}>
          <Input
            value={search}
            onChange={handleSearch}
            type="search"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            className={classes.input}
            placeholder={searchPlaceholder || "search"}
          />
        </div>
      )}
    </div>
  );
}
