import React, { useContext } from "react";
import {
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import InputMask from "react-input-mask";
const useStyles = makeStyles(() => ({
  typography: {
    display: "flex",
    alignItems: "center",
    width: "200px",
    "@media (max-width: 600px)": { display: "none" },
  },
  root__box: {
    display: "flex",
    marginTop: "24px",
  },
}));

export default function CostomInput({
  label,
  value,
  inputRef,
  error,
  errorMessage,
  readOnly,
  name,
  props,
}) {
  const sixHundred = useMediaQuery("(min-width:600px)");
  const classes = useStyles();

  return (
    <>
      <div className={classes.root__box}>
        <Typography className={classes.typography}>{label}*:</Typography>{" "}
        <TextField
          name={name}
          InputProps={{ readOnly }}
          label={sixHundred ? "" : label}
          inputRef={inputRef}
          multiline
          variant="outlined"
          size="small"
          fullWidth
          error={error}
          helperText={error ? errorMessage : ""}
          defaultValue={value}
          {...props}
        />
      </div>
    </>
  );
}
export function CostomPhoneInput({
  label,
  value,
  inputRef,
  error,
  errorMessage,
  name
}) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root__box}>
        <Typography className={classes.typography}>{label}*:</Typography>{" "}
        <TextField
          name={name}
          InputProps={{
            inputComponent: MaskInput,
            inputProps: { ref: inputRef },
          }}
          label={""}
          multiline
          variant="outlined"
          size="small"
          fullWidth
          error={error}
          helperText={error ? errorMessage : ""}
          defaultValue={value}
        />
      </div>
    </>
  );
}

function MaskInput({ inputRef, ...other }) {
  return (
    <InputMask
      {...other}
      ref={inputRef}
      alwaysShowMask
      mask="+7 (799) 999 99 99"
    />
  );
}
