import React from "react";
import { TextField, Checkbox } from "@material-ui/core";
import {
  KeyboardArrowRight,
  AccessTime,
  DateRange,
  KeyboardArrowLeft,
} from "@material-ui/icons";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import Select from "../MuiSelect";
import InputMask from "react-input-mask";

export function renderTextField({ field, form, ...rest }) {
  return <TextField {...field} {...rest} InputLabelProps={{ shrink: true }} />;
}

export function renderCheckbox({ field, form, ...rest }) {
  return (
    <Checkbox
      {...field}
      {...rest}
      checked={Boolean(field.value)}
      value={String(field.value)}
    />
  );
}

const InputLabelProps = {
  style: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "95%",
  },
};

export function DTPicker(props) {
  return (
    <DateTimePicker
      autoOk
      ampm={false}
      leftArrowIcon={<KeyboardArrowLeft />}
      rightArrowIcon={<KeyboardArrowRight />}
      timeIcon={<AccessTime />}
      dateRangeIcon={<DateRange />}
      clearable
      InputLabelProps={InputLabelProps}
      {...props}
    />
  );
}

export function DPicker(props) {
  return (
    <DatePicker
      autoOk
      leftArrowIcon={<KeyboardArrowLeft />}
      rightArrowIcon={<KeyboardArrowRight />}
      clearable
      InputLabelProps={InputLabelProps}
      format="dd/MM/yyyy"
      {...props}
    />
  );
}
export function renderDPicker({ field, form, ...rest }) {
  const currentError = form.errors[field.name];
  return (
    <DPicker
      emptyLabel=""
      invalidLabel=""
      helperText={currentError}
      error={Boolean(currentError)}
      {...field}
      onError={(_, error) => form.setFieldError(field.name, error)}
      onChange={(date) => form.setFieldValue(field.name, date, true)}
      {...rest}
    />
  );
}

export function renderDTPicker({ field, form, ...rest }) {
  const currentError = form.errors[field.name];
  return (
    <DTPicker
      emptyLabel=""
      invalidLabel=""
      format="dd/MM/yyyy hh:mm"
      helperText={currentError}
      error={Boolean(currentError)}
      {...field}
      onError={(_, error) => form.setFieldError(field.name, error)}
      onChange={(date) => form.setFieldValue(field.name, date, true)}
      {...rest}
    />
  );
}

export function renderSelect({ field, form, ...rest }) {
  return (
    <Select
      {...field}
      onChange={(value) => {
        form.setFieldValue(field.name, value, true);
      }}
      {...rest}
    />
  );
}

export function MobileMaskInput(props) {
  const { inputRef, ...other } = props;

  return (
    <InputMask
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      alwaysShowMask
      mask="+7 (799) 999 99 99"
    />
  );
}

export function renderMobileField({ field, form, ...rest }) {
  return (
    <TextField
      {...field}
      {...rest}
      InputProps={{ inputComponent: MobileMaskInput }}
    />
  );
}
