import React from "react";
import Viewer from "react-viewer";

export default function ReactViewer({
  images,
  isVisible,
  containerRef,
  activeIndex,
  onIndexChange,
}) {
  return (
    <Viewer
      key={images.length}
      activeIndex={activeIndex}
      visible={isVisible}
      onChange={onIndexChange}
      noClose
      zoomSpeed={0.2}
      minScale={1.2}
      defaultScale={1.2}
      noImgDetails
      container={containerRef.current}
      images={images}
    />
  );
}
