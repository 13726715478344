import React from "react";
import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { withSize } from "react-sizeme";
import ConclusionForm from "./ConclusionForm";
import { RESEARCH_STATUS } from "../constants";
import Roled from "../../../components/Roled";
import { ROLES } from "../../../config/constants";
import EyeContainer from "../PatientForm/EyeContainer";
import { useIsMine } from "../../../globalContexts/UserContext";
import NoImage from "../NoImage";
import LoadingImage from "../LoadingImage";

const useClasses = makeStyles(() => ({
  eye: ({ width }) => ({
    width: width * 0.8,
    height: width * 0.8,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    alignItems: "center",
  }),
  "@media (max-width: 600px)": {
    eye: ({ width }) => ({
      width: width * 0.95,
      height: width * 0.95,
    }),
  },
}));

function EyeLayout({
  eyeValue,
  eyeName,
  label,
  isLeft,
  status,
  size,
  setFieldValue,
  descriptionValues,
  conclusionResultValues,
  editMode,
  responsibleDoctorUsername,
  isLoading
}) {
  const isFinished = status === RESEARCH_STATUS.FINISHED;
  const isCanceled = status === RESEARCH_STATUS.CANCELLED;
  const isTakenToWork = status === RESEARCH_STATUS.TAKEN_TO_WORK;

  // функционал удаления снимков доступно только врачу который Взял в работу текущее обследование
  const isMine = useIsMine(responsibleDoctorUsername);
  const isImgDeletable = isMine && isTakenToWork;

  const classes = useClasses({ width: size.width });
  if(isLoading) {
    return <Grid item xs={12} lg={6} container alignItems="center" direction="column">
      <Paper className={classes.eye}>
        <div>
          <LoadingImage />
        </div>
      </Paper>
    </Grid>
  }
  return (
    <Grid item xs={12} lg={6} container alignItems="center" direction="column">
      <Paper className={classes.eye}>
        {eyeValue.length > 0 ? (
          <EyeContainer
            value={eyeValue}
            name={eyeName}
            showControl
            setFieldValue={setFieldValue}
            isImgDeletable={isImgDeletable}
          />
        ) : (
          <div>
            <NoImage />
          </div>
        )}
      </Paper>
      <Typography variant="h6">{label}</Typography>
      {eyeValue.length > 0 && (
        <Roled roles={[ROLES.DOCTOR]} forcedShow={isCanceled || isFinished}>
          <ConclusionForm
            eyeLabel={label}
            isLeft={isLeft}
            eyeImage={eyeValue}
            setFieldValue={setFieldValue}
            descriptionValues={descriptionValues}
            conclusionResultValues={conclusionResultValues}
            editMode={editMode}
          />
        </Roled>
      )}
    </Grid>
  );
}

EyeLayout.propTypes = {
  eyeValue: PropTypes.arrayOf(PropTypes.object),
  eyeName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  isLeft: PropTypes.bool.isRequired,
};

export default withSize()(EyeLayout);
