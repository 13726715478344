import React, { useState, useEffect, useContext } from "react";
import instance, {
  dicomInstance,
  scientificApiInctance,
  setAuthorizationHeader,
} from "../config/request";
import history from "../config/history";
import { ROLES } from "../config/constants";
import { KeycloakContext, initKeycloak } from "./KeycloakContext";
import { getAuthUserConfig } from "../api";
import { DictionariesContext } from "./DictionariesContext";
import { addDictionary } from "./DictionariesContext/dictionariesActions";
export const UserContext = React.createContext({ user: null });

export default function UserContextProvider({ children, userProp }) {
  const [user, setUser] = useState(userProp || null);
  const [kc, setKeycloak] = useContext(KeycloakContext);
  const dispatchDictionary = useContext(DictionariesContext)[1];

  function handleLogin(user) {
    setUser(user);
    setAuthorizationHeader(user.token);
    getAuthUserConfig()
      .then((data) => dispatchDictionary(addDictionary("authUserConfig", data)))
      .catch(console.error);
  }
  function handleLogout(_e, path) {
    history.push(path || "/");
    kc.logout();
    setUser(null);
    setAuthorizationHeader(null);
  }

  function addRefreshInterceptor(inst) {
    let isFetchingToken = false;
    let pendingRequestCallbacks = [];

    inst.interceptors.response.use(
      (res) => res,
      (err) => {
        const { config: originalRequest, response } = err;
        if (response?.status === 401) {
          if (!isFetchingToken) {
            isFetchingToken = true;
            kc.updateToken(-1)
              .then((refreshed) => {
                isFetchingToken = false;
                if (refreshed) {
                  setAuthorizationHeader(kc.token);
                  pendingRequestCallbacks.forEach((cb) => cb(kc.token));
                  pendingRequestCallbacks = [];
                } else {
                  initKeycloak("login-required", setKeycloak, handleLogin);
                }
              })
              .catch((err) => {
                isFetchingToken = false;
                console.error("updateToken Error", err);
                initKeycloak("login-required", setKeycloak, handleLogin);
              });
          }
          // we need to make it promise so request returns promise that is not yet resolved
          // and the caller can wait for it
          const retryRequest = new Promise((resolve) => {
            // this is callback that will be called when new token is obtained
            pendingRequestCallbacks.push((token) => {
              originalRequest.headers["authorization"] = `Bearer ${token}`;
              resolve(instance(originalRequest));
            });
          });
          return retryRequest;
        } else {
          return Promise.reject(err);
        }
      }
    );
  }

  useEffect(() => {
    if (userProp) {
      handleLogin(userProp);
    }
    addRefreshInterceptor(instance);
    addRefreshInterceptor(scientificApiInctance);
    addRefreshInterceptor(dicomInstance);
  }, [setKeycloak, userProp]);
  return (
    <UserContext.Provider value={{ user, handleLogin, handleLogout }}>
      {children}
    </UserContext.Provider>
  );
}

export function useIsPatient() {
  const { user } = useContext(UserContext);

  return user && user.role === ROLES.PATIENT;
}

export function useIsDoctor() {
  const { user } = useContext(UserContext);

  return user && user.role === ROLES.DOCTOR;
}

// author - entity owner (research, conclusion)
export function useIsMine(author) {
  const { user } = useContext(UserContext);
  return user && user.name === author;
}
