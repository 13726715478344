import React, { useEffect, useReducer } from "react";
import dictionariesReducer from "./dictionariesReducer";
import { getInitialDictionaries } from "../../api/dictionariesApi";
import { initiateDictionaries } from "./dictionariesActions";

export const initialState = {
  recommendations: [],
  structuredConclusionResults: [],
  structuredDescriptions: [],
  authUserConfig: {
    smsEnabled: false,
    notificationDisabled: false,
  },
};

export const DictionariesContext = React.createContext(initialState);

export default function DictionariesContextProvider({ children }) {
  const [state, dispatch] = useReducer(dictionariesReducer, initialState);
  useEffect(() => {
    getInitialDictionaries()
      .then((data) => dispatch(initiateDictionaries(data)))
      .catch((err) => {
        console.error(err);
        alert("Ошибка загрузки справочников! Пожалуйста, обновите страницу");
      });
  }, []);
  return (
    <DictionariesContext.Provider value={[state, dispatch]}>
      {children}
    </DictionariesContext.Provider>
  );
}
