import axios from "axios";

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_SERVER === "true"
      ? "http://localhost:8080"
      : `${window.location.origin}/api`
    : `${window.location.origin}/api`;

export const SCIENTIFIC_BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_SERVER === "true"
      ? "http://localhost:8080"
      : `${window.location.origin}/sci_analytics_api`
    : `${window.location.origin}/sci_analytics_api`;

const DICOM_BASE_URL =
  process.env.REACT_APP_LOCAL_SERVER === "true"
    ? "http://localhost:5000"
    : `${window.location.origin}/dicom`;

export const DRIVER_URL = "127.0.0.1:23955";

const instance = axios.create({ baseURL: BASE_URL });
export const scientificApiInctance = axios.create({
  baseURL: SCIENTIFIC_BASE_URL,
});
export const driverInstance = axios.create({ baseURL: `http://${DRIVER_URL}` });
export const dicomInstance = axios.create({ baseURL: DICOM_BASE_URL });

export function setAuthorizationHeader(token = null) {
  if (token) {
    scientificApiInctance.defaults.headers.common.authorization = `Bearer ${token}`;
    instance.defaults.headers.common.authorization = `Bearer ${token}`;
    dicomInstance.defaults.headers.common.authorization = `Bearer ${token}`;
  } else {
    delete scientificApiInctance.defaults.headers.common.authorization;
    delete instance.defaults.headers.common.authorization;
    delete dicomInstance.defaults.headers.common.authorization;
  }
}

export default instance;
