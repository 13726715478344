import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { UserContext } from "../../globalContexts/UserContext";
import { useMutation, useQuery } from "react-query";
import {
  createToast,
  MessagesContext,
} from "../../globalContexts/MessagesContext";
import { useParams } from "react-router-dom";
import {
  confirmOphthalmologist,
  getDocumentById,
  getUserDataByUserName,
  rejectOphthalmologist,
} from "../../api/moderationApi";
import { downloadFileObject, getFileFromDataUrl } from "../../utils";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import {
  HeaderLeftTeleporter,
  HeaderRightTeleporter,
} from "../../components/Header";
import { BreadcrumbsModeration } from "./BreadcrumbsModeration";
import SupportTelephoneNumber from "../../components/SupportTelephoneNumber";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: " 20px 30px",
    margin: "2% 15%",
  },
  title: {
    fontSize: "20px",
    fontWeight: "500",
    paddingBottom: "20px",
  },
  form: {
    padding: "0 50px",
  },
  docLine: {
    width: "35%",
  },
  error: {
    margin: "0",
    color: "red",
    fontSize: "11px",
  },
  footer: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
  },
  aferts: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0 20px 30%",
  },
  documentsList: {
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "10px 0 0 10px",
    "&:hover": {
      border: "1px solid #000",
    },
  },
  documentName: {
    paddingRight: "5px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export function UserModerationForm() {
  const classes = useStyles();
  const { t } = useTranslation("registrationPage");
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const history = useHistory();
  const dispatchMessage = useContext(MessagesContext)[1];
  const { userName } = useParams();
  const {
    data: usersData,
    isLoading: loagingUsersData,
    error: errorUsersData,
  } = useQuery(
    ["userDataByUserName", userName],
    () => getUserDataByUserName({ userName }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { register, handleSubmit, errors } = useForm();

  async function handleClickDocumentName(id) {
    try {
      const file = await getDocumentById({ documentId: id });
      const fileDownload = getFileFromDataUrl(
        `data:${file.file.fileType};base64,${file.file.base64}`,
        `${file.file.fileName}`
      );
      downloadFileObject(fileDownload);
    } catch (error) {
      console.error(error);
    }
  }

  const { mutate: confirmDoctor } = useMutation(
    async ({ id }) => {
      await confirmOphthalmologist(id);
    },
    {
      onSuccess: () => {
        dispatchMessage(createToast("Подтвержден"));
        history.push("/moderation");
      },
    }
  );
  const { mutate: rejectDoctor } = useMutation(
    async ({ id, data }) => {
      await rejectOphthalmologist(id, data);
    },
    {
      onSuccess: () => {
        dispatchMessage(createToast("Отклонен"));
        history.push("/moderation");
      },
    }
  );

  async function onSubmit(data) {
    rejectDoctor({ id: usersData.id, data: data });
  }

  return (
    <>
      <HeaderLeftTeleporter.Source>
        <BreadcrumbsModeration t={t} />
      </HeaderLeftTeleporter.Source>
      <HeaderRightTeleporter.Source>
      <div style={{display:"flex"}}>
            <SupportTelephoneNumber/>
            <Button
              className="text-white border-white"
              variant="outlined"
              onClick={history.goBack}
              >
                {t("BACK")}
              </Button>
          </div>
      </HeaderRightTeleporter.Source>
      <Paper className={classes.root}>
        {loagingUsersData ? (
          <CircularProgress />
        ) : (
          <>
            <Typography className={classes.title}>
              {t("USER_INFORMATION")}
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              id="userData"
              className={classes.form}
            >
              <Grid container>
                <Grid item md={2}>
                  <Typography>{t("IIN")}*:</Typography>
                </Grid>
                <Grid item md={9}>
                  <TextField
                    value={usersData?.username}
                    inputProps={{ readOnly: true }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Box height={"24px"} />
              <Grid container>
                <Grid item md={2}>
                  <Typography>{t("SURNAME")}*:</Typography>
                </Grid>
                <Grid item md={9}>
                  <TextField
                    value={usersData?.firstName}
                    inputProps={{ readOnly: true }}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Box height={"24px"} />
              <Grid container>
                <Grid item md={2}>
                  <Typography>{t("NAME")}*:</Typography>
                </Grid>
                <Grid item md={9}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    value={usersData?.lastName}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Box height={"24px"} />
              <Grid container>
                <Grid item md={2}>
                  <Typography>{t("MIDDLE_NAME")}:</Typography>
                </Grid>
                <Grid item md={9}>
                  <TextField variant="outlined" size="small" fullWidth />
                </Grid>
              </Grid>
              <Box height={"24px"} />
              <Grid container>
                <Grid item md={2}>
                  <Typography>{t("PHONE")}*:</Typography>
                </Grid>
                <Grid item md={9}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    value={usersData?.attributes?.phone}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Box height={"24px"} />
              <Grid container>
                <Grid item md={2}>
                  <Typography>{t("EMAIL")}*:</Typography>
                </Grid>
                <Grid item md={9}>
                  <TextField
                    inputProps={{ readOnly: true }}
                    value={usersData?.email}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Box height={"24px"} />
              <Grid container>
                <Grid item md={2}>
                  <Typography>{t("ATTACHED_DOCUMENTS")}:</Typography>
                </Grid>
                <Grid className={classes.documentsList} item md={9}>
                  {usersData?.ophthalmologist_documents.map((file) => {
                    return (
                      <Grid
                        className={classes.documentName}
                        onClick={() => handleClickDocumentName(file.id)}
                      >
                        {file.file_name}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Box height={"24px"} />
              <Grid container>
                <Grid item md={2}>
                  <Typography>{t("NOTE")}:</Typography>
                </Grid>
                <Grid item md={9}>
                  <TextField
                    name="moderatorCommet"
                    value={usersData?.moderator_comment}
                    inputRef={register({ required: true })}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  {errors.moderatorCommet && (
                    <p className={classes.error}>{t("REQUIRED_FIELD")}</p>
                  )}
                </Grid>
              </Grid>
              <Box height={"24px"} />

              {usersData.ophthalmologist_status !== "TEKEN" && (
                <Grid className={classes.footer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => confirmDoctor({ id: usersData.id })}
                  >
                    {t("CONFIRM")}
                  </Button>
                  <Button variant="contained" color="secondary" type="submit">
                    {t("REJECT")}
                  </Button>
                </Grid>
              )}
            </form>
          </>
        )}
      </Paper>
    </>
  );
}
