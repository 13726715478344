import React, { useState, useContext } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { RESEARCH_STATUS } from "../constants";
import { Field } from "formik";
import { useSwipeable } from "react-swipeable";
import {
  renderSelect,
  renderTextField,
} from "../../../components/FormComponents";
import Buttons from "./Buttons";
import NoImage from "../NoImage";
import { ROLES } from "../../../config/constants";
import Roled from "../../../components/Roled";
import { mapDictionionaryForSelect } from "../../../utils";
import { DictionariesContext } from "../../../globalContexts/DictionariesContext";
import EyeContainer from "../PatientForm/EyeContainer";
import ConfirmationCode from "./ConfirmationCode";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  eye: {
    flex: "0 0 100%",
    height: "320px",
    display: "flex",
    flexDirection: "column",
  },
  handlerWrapper: {
    overflow: "hidden",
  },
}));

const handlerStyle = (slide) => ({
  display: "flex",
  transform: `translateX(${-slide * 100}%)`,
  transition: "transform .3s ease",
});

export default function RecommendationMobile({
  editMode,
  id,
  rightEyes,
  leftEyes,
  status,
  t,
  formikStatus,
  code,
}) {
  const classes = useStyles();
  const [slide, setSlide] = useState(0);
  const { recommendations } = useContext(DictionariesContext)[0];
  const { i18n } = useTranslation();

  // function onSwipedLeft(eventType) {
  //   if (slide === 1) return;
  //   setSlide(1);
  //   eventType.event.stopPropagation();
  // }

  // function onSwipedRight(eventType) {
  //   if (slide === 0) return;
  //   setSlide(0);
  //   eventType.event.stopPropagation();
  // }

  const isFinished = status === RESEARCH_STATUS.FINISHED;
  const isCanceled = status === RESEARCH_STATUS.CANCELLED;
  // const handlers = useSwipeable({ onSwipedRight, onSwipedLeft });
  return (
    <Roled roles={[ROLES.DOCTOR]} forcedShow={isCanceled || isFinished}>
      <>
        <Typography>{t("RECOMENDATION")}</Typography>
        <Field
          name="recommendationCodenames"
          component={renderSelect}
          placeholder="Выберите консультацию"
          options={mapDictionionaryForSelect(recommendations, i18n.language)}
          isMulti
          isDisabled={!editMode}
        />
        <Field
          name="recommendationText"
          className="mt-10 pl-10 pr-10"
          label={t("RECOMENDATION_TEXT")}
          component={renderTextField}
          multiline
          rows="5"
          fullWidth
          variant="outlined"
          disabled={!editMode}
        />
        <Buttons
          t={t}
          id={id}
          editMode={editMode}
          status={status}
          // isDirty={isDetailsDirty}
        />
        {formikStatus && formikStatus.isCodeRequired && (
          <ConfirmationCode code={code} codeLabel={formikStatus.codeLabel} />
        )}
      </>
    </Roled>
  );
}
