import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { LANGUAGES } from "./constants";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "50px",
    width: "50px",
    [theme.breakpoints.down("sm")]: {
      height: "35px",
      width: "35px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px",
  },
  wrapper: {
    position: "relative",
    height: "50px",
    width: "50px",
    [theme.breakpoints.down("sm")]: {
      height: "35px",
      width: "35px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mouseController: {
    position: "absolute",
    height: ({ showLanguages }) => (showLanguages ? "120px" : "50px"),
    width: ({ showLanguages }) => (showLanguages ? "180px" : "50px"),
    [theme.breakpoints.down("sm")]: {
      height: ({ showLanguages }) => (showLanguages ? "120px" : "35px"),
      width: ({ showLanguages }) => (showLanguages ? "180px" : "35px"),
    },
    display: "flex",
    top: 0,
    right: 0,
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  activeLanguage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    height: "100%",
    width: "100%",
    border: "1px solid " + theme.palette.common.white,
    cursor: "pointer",
  },
  inactiveLanguage: {
    transform: ({ showLanguages }) => (showLanguages ? "scale(1)" : "scale(0)"),
    transition: "all 0.3s ease",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: "70%",
    height: "70%",
    background: theme.palette.background.paper,
    border: "1px solid black",
    color: "black",
    cursor: "pointer",
  },
  typography: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
  },
  ru: {
    right: "140%",
  },
  kk: {
    top: "100%",
    right: "100%",
  },
  en: {
    top: "140%",
  },
}));

function LanguageSelectorUnauthed() {
  const { i18n } = useTranslation();
  const [showLanguages, setShowLanguages] = useState(false);
  const classes = useStyles({ showLanguages });
  const handleOnClick = (lang) => {
    i18n.changeLanguage(lang.value);
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.mouseController}
        onMouseEnter={() => setShowLanguages(true)}
        onMouseLeave={() => setShowLanguages(false)}
      >
        <div className={classes.wrapper}>
          <div className={classes.activeLanguage}>
            <Typography className={classes.typography}>
              {
                LANGUAGES.find((lang) => i18n.language.includes(lang.value))
                  ?.shortened
              }
            </Typography>
          </div>
          {LANGUAGES.map((lang) => (
            <div
              key={lang.value}
              className={`${classes.inactiveLanguage} ${classes[lang.value]}`}
              onClick={() => handleOnClick(lang)}
            >
              <Typography className={classes.typography}>
                {lang.shortened}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LanguageSelectorUnauthed;
