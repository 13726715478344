import React from "react";
import { RadialBarChart, RadialBar, PolarAngleAxis } from "recharts";

export function DashboardRadialBars({ data }) {
  const circleSize = 70;

  return (
    <>
      <RadialBarChart
        width={circleSize}
        height={circleSize}
        innerRadius={30}
        outerRadius={50}
        barSize={6}
        data={data}
        startAngle={90}
        endAngle={-270}
      >
        <PolarAngleAxis type="number" domain={[0, 100]} tick={false} />
        <RadialBar background dataKey="value" cornerRadius={circleSize / 2} />
        <text
          x={circleSize / 2}
          y={circleSize / 2}
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {`${data[0].value}%`}
        </text>
      </RadialBarChart>
    </>
  );
}
