import React, { useState, useEffect, useContext } from "react";
import { Formik } from "formik";
import Recommendation from "./Recommendation";
import Buttons from "./Buttons";
import Loader from "../../../components/Loader";
import { Paper, Grid, Hidden, makeStyles, Button } from "@material-ui/core";
import EyeLayout from "./EyeLayout";
import { RESEARCH_STATUS } from "../constants";
import MobileConclusionFormTabs from "./MobileConclusionFormTabs";
import { useIsPatient, useIsMine } from "../../../globalContexts/UserContext";
import ConfirmationCode from "./ConfirmationCode";
import { getDefaultRecommendations } from "./defaultDescriptionSetterUtils";
import { DownloadFeportForNurseAndPatientMobileTeleporter } from "./mobile";
import { fetchReport } from "../ResearchesList/ResearchJournalTable";
import { MessagesContext } from "../../../globalContexts/MessagesContext";
import { GetApp } from "@material-ui/icons";
import { ROLES } from "../../../config/constants";
import Roled from "../../../components/Roled";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "70%",
      margin: "20px auto",
      padding: "20px",
    },
    downloadButton: {
      width: "100%",
      position: "fixed",
      bottom: 0,
      background: "#fff",
    },
  };
});

function ConclusionsForm({ formikProps, mobileFormStyle, t, isLoading }) {
  const classes = useStyles();
  //#region =====================EXTRACT VALUES=======================
  const {
    rightEyeConclusion,
    leftEyeConclusion,
    id,
    status,
    rightEyes,
    leftEyes,
    responsibleDoctorUsername,
    code,
  } = formikProps.values;
  //#endregion =====================EXTRACT VALUES=======================

  const isTakenToWork = status === RESEARCH_STATUS.TAKEN_TO_WORK;
  const isFinished = status === RESEARCH_STATUS.FINISHED;
  const isPatient = useIsPatient();
  const isMine = useIsMine(responsibleDoctorUsername);
  const dispatchMessage = useContext(MessagesContext)[1];

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const isCodeRequired =
      formikProps.status && formikProps.status.isCodeRequired;
    if (
      formikProps.isSubmitting ||
      (formikProps.status && formikProps.status.error)
    )
      return;
    setEditMode(isTakenToWork && isMine && !isCodeRequired);
  }, [
    formikProps.isSubmitting,
    formikProps.status,
    isPatient,
    isTakenToWork,
    isMine,
  ]);

  //#region =====================CHECK IF FIELDSET CHANGED=============================
  // this component is available for non doctor users
  // so we do not need to check for changes
  useEffect(() => {
    if (!isFinished) {
      const {
        recommendationCodenames,
        recommendationText,
      } = getDefaultRecommendations([
        rightEyeConclusion.conclusionResult,
        leftEyeConclusion.conclusionResult,
      ]);
      formikProps.setFieldValue(
        "recommendationCodenames",
        recommendationCodenames
      );
      formikProps.setFieldValue("recommendationText", recommendationText);
    }
  }, [
    rightEyeConclusion?.conclusionResult,
    leftEyeConclusion?.conclusionResult,
  ]);
  //#endregion =====================CHECK IF FIELDSET CHANGED=============================
  return (
    <form
      onSubmit={formikProps.handleSubmit}
      style={mobileFormStyle}
      id="conclusionsForm"
    >
      <Hidden only="xs" implementation="js">
        <Grid container>
          <EyeLayout
            eyeValue={rightEyes}
            label={t("RIGHT")}
            eyeName="rightEyes"
            isLeft={false}
            status={status}
            isLoading={isLoading}
            setFieldValue={formikProps.setFieldValue}
            descriptionValues={rightEyeConclusion.description}
            conclusionResultValues={rightEyeConclusion.conclusionResult}
            editMode={editMode}
            responsibleDoctorUsername={responsibleDoctorUsername}
          />
          <EyeLayout
            eyeValue={leftEyes}
            label={t("LEFT")}
            eyeName="leftEyes"
            isLeft
            status={status}
            isLoading={isLoading}
            setFieldValue={formikProps.setFieldValue}
            descriptionValues={leftEyeConclusion.description}
            conclusionResultValues={leftEyeConclusion.conclusionResult}
            editMode={editMode}
            responsibleDoctorUsername={responsibleDoctorUsername}
          />
        </Grid>
        <Paper className={classes.root} elevation={3}>
          <Recommendation
            t={t}
            hasEyesImage={rightEyes.length > 0 || leftEyes.length > 0}
            status={status}
            editMode={editMode}
          />
        </Paper>
        <Buttons t={t} id={id} status={status} editMode={editMode} />
        {formikProps.status && formikProps.status.isCodeRequired && (
          <ConfirmationCode
            code={code}
            codeLabel={formikProps.status.codeLabel}
          />
        )}
      </Hidden>
      <Hidden smUp implementation="js">
        <MobileConclusionFormTabs
          editMode={editMode}
          researchId={id}
          rightEyes={rightEyes}
          leftEyes={leftEyes}
          status={status}
          rightEyeConclusion={rightEyeConclusion}
          leftEyeConclusion={leftEyeConclusion}
          setFieldValue={formikProps.setFieldValue}
          t={t}
          formikStatus={formikProps.status}
          code={code}
          responsibleDoctorUsername={responsibleDoctorUsername}
        />
        <DownloadFeportForNurseAndPatientMobileTeleporter.Source>
          {isFinished && (
            <div className={classes.downloadButton}>
              <Button
                color="secondary"
                fullWidth
                onClick={() => {
                  return fetchReport(id, dispatchMessage, t);
                }}
              >
                {t("CONCLUSION")}
                <GetApp />
              </Button>
            </div>
          )}
        </DownloadFeportForNurseAndPatientMobileTeleporter.Source>
      </Hidden>
      {formikProps.isSubmitting && <Loader isFullscreen />}
    </form>
  );
}

function ConclusionsFormWrapper({
  t,
  initialValues,
  mobileFormStyle,
  handleSubmit,
  isLoading,
}) {
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize
      render={(props) => (
        <ConclusionsForm
          t={t}
          mobileFormStyle={mobileFormStyle}
          formikProps={props}
          isLoading={isLoading}
        />
      )}
    />
  );
}

export default ConclusionsFormWrapper;
