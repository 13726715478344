import React, { useContext } from "react";
import { downloadFileObject } from ".";
import { getReport } from "../api";
import AsyncButton from "../components/AsyncButton";
import { TableContext } from "../globalContexts/TableContext";
import { RESEARCH_STATUS } from "../modules/ResearchesPage/constants";

export function DownloadAllButton() {
  const tableContext = useContext(TableContext)[0];
  async function _fetchAllReportsUtil() {
    const researches = tableContext.researches.data.filter(
      (research) => research.status === RESEARCH_STATUS.FINISHED
    );
    try {
      const results = await Promise.all(
        researches.map(async (research) => ({
          file: await getReport(research.id),
          id: research.id,
        }))
      );
      results.forEach((res) => {
        const file = new File([res.file.data], `report_${res.id}`, {
          type: "application/pdf",
        });
        downloadFileObject(file);
      });
    } catch (error) {
      alert(error.message);
    }
  }
  return <AsyncButton onClick={_fetchAllReportsUtil}>All</AsyncButton>;
}
