import React, { useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import history from "../../config/history";
import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";
import { HeaderLeftTeleporter, HeaderRightTeleporter } from "../../components/Header";
import { Link } from "react-router-dom";
import { ScientificRegistryTable } from "./ScientificRegistryTable";
import kc from "../../config/keycloak";
import SupportTelephoneNumber from "../../components/SupportTelephoneNumber";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5px 15px",
  },
  toggleButton: {
    margin: "15px",
  },
  center: {
    textAlign: "center",
    marginLeft: "100px",
  },
  select: {
    width: 100,
    border: "2px solid #f2f2f2",
    borderRadius: "6px",
    padding: 0,
  },
  age: {
    width: "300px",
    marginLeft: "24px",
  },
  date: {
    marginLeft: "50px",
  },
  rightFilters: {
    paddingTop: "44px",
  },
}));

export function ScientificRegistryPage() {
  const { t } = useTranslation("scientificAnalytics");
  const classes = useStyles();
  const [togglebutton, setToggleButton] = useState("REGISTRY");
  const isSm = useMediaQuery("(max-width:732px)");
  const isMd = useMediaQuery("(max-width:854px)");
  const API_URL = "/sci_analytics_api";
  const handleToggleButton = (event, newAlignment) => {
    if (newAlignment === "DASHBOARD") {
      setToggleButton(newAlignment);
      history.push("/scientificDashboard");
    }
  };

  const cubejsApi = cubejs(async () => await kc.token, {
    apiUrl: `${API_URL}/cubejs-api/v1`,
  });
  // const cubejsApi = cubejs({ apiUrl: "http://localhost:4000/cubejs-api/v1" });

  return (
    <>
      <HeaderLeftTeleporter.Source>
        <Typography
          variant={isMd ? "body1" : "h6"}
          color="inherit"
          component={Link}
          to="/"
        >
          {isSm ? t("SYSTEM_UDDR_SHORTENED") : t("SYSTEM_UDDR")}
        </Typography>
      </HeaderLeftTeleporter.Source>
      <HeaderRightTeleporter.Source>
            <SupportTelephoneNumber/>
        </HeaderRightTeleporter.Source>
      <ToggleButtonGroup
        value={togglebutton}
        onChange={handleToggleButton}
        exclusive
        className={classes.toggleButton}
        size="small"
      >
        <ToggleButton value="REGISTRY">{t("REGISTRY")}</ToggleButton>
        <ToggleButton value="DASHBOARD">{t("DASHBOARD")}</ToggleButton>
      </ToggleButtonGroup>

      <CubeProvider cubejsApi={cubejsApi}>
        <ScientificRegistryTable />
      </CubeProvider>
    </>
  );
}
