import React, { useContext, useMemo, useState } from "react";
import {
  makeStyles,
  ListItemText,
  ListItemSecondaryAction,
  ListItem,
  ListItemAvatar,
  Avatar,
  Grid,
  Chip,
  IconButton,
  Button,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import RemoteMuiTable from "../../../lib/MaterialTable";
import {
  formatDateAndTime,
  downloadFileObject,
  getFileFromDataUrl,
} from "../../../utils";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  getResearches,
  getReport,
  takeResearchRequest,
  getOptimaReport,
  getConclusionText,
  askPatientInfoPermissionRequest,
} from "../../../api";
import { RESEARCH_STATUS } from "../constants";
import ResearchJournalFilters, {
  getStatusIcon,
} from "./ResearchJournalFilters";
import {
  MessagesContext,
  createToast,
} from "../../../globalContexts/MessagesContext";
import { MOBILE_WIDTH, ROLES } from "../../../config/constants";
import { getErrorMessage } from "../../../utils/errorHandlers";
import { useIsPatient, UserContext } from "../../../globalContexts/UserContext";
import history from "../../../config/history";
import { ResearchContext } from "../ResearchContext";
import { DownloadAllButton } from "../../../utils/DownloadAllButton";
import { ResearchJournalTableActions } from "./ResearchJournalTableActions";
import { queryClient } from "../../../globalContexts/queryContext";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    padding: `0 10px`,
  },
  tableHead: {
    backgroundColor: "unset",
  },
  chipMine: {
    margin: "0 0 10px 10px",
    backgroundColor: "#438649",
    color: "white",
    textTransform: "uppercase",
  },
  menu: {
    margin: "35px 0 0 50px",
  },
  editIIN: {
    alignItems: "center",
    display: "flex",
  },
  [`@media (max-width: ${MOBILE_WIDTH})`]: {
    root: {
      padding: 0,
    },
    mobileSecondaryAction: {
      maxWidth: "65px",
      userSelect: "none",
    },
    infiniteScroll: {
      overflow: "unset!important",
    },
  },
}));

async function fetchResearches(query, dispatchMessage) {
  try {
    const res = await getResearches(query);
    return {
      data: res.result.researches.map((obj) => ({ ...obj, _id: obj.id })),
      page: res.result.pageNum - 1,
      total: res.result.count,
    };
  } catch (error) {
    console.error(error);
    dispatchMessage(createToast(getErrorMessage(error), "error"));
    return {
      data: [],
      page: 0,
      total: 0,
    };
  }
}
export async function fetchConclusionText(id, messageDispatch, t) {
  try {
    const res = await getConclusionText(id);
    navigator.clipboard.writeText(res.conclusionText);
    messageDispatch(createToast(t("COPIED_CONCLUSION_TEXT"), "success"));
  } catch (error) {
    messageDispatch(createToast(t("ERROR_CONCLUSION_TEXT"), "error"));
  }
}

export async function getImagesPdf(id, messageDispatch, t) {
  try {
    const res = await getOptimaReport(id);
    const file = getFileFromDataUrl(
      `data:application/pdf;base64,${res.report}`,
      `Снимки_${id}`
    );
    downloadFileObject(file);
  } catch (error) {
    messageDispatch(createToast(t("DOWNLOAD_FILE_ERROR"), "error"));
  }
}

export async function fetchReport(id, messageDispatch, t) {
  try {
    const res = await getReport(id);
    const file = new File([res.data], `Заключение_${id}`, {
      type: "application/pdf",
    });
    downloadFileObject(file);
  } catch (error) {
    messageDispatch(createToast(t("DOWNLOAD_FILE_ERROR"), "error"));
  }
}

export async function takeResearch(id, messageDispatch, t) {
  try {
    await takeResearchRequest(id);
    await queryClient.invalidateQueries(["researchDetails", id]);
    history.push(`/researches/${id}`);
  } catch (error) {
    messageDispatch(createToast(getErrorMessage(error), "error"));
  }
}

export async function askPatientCardPermission(patientIin, messageDispatch, t) {
  await askPatientInfoPermissionRequest(patientIin);
  messageDispatch(createToast(t("ASK_PATIENT_CARD_SUCCESS"), "success"));
}

const AvatarStatusColor = {
  [RESEARCH_STATUS.FINISHED]: "bg-green",
  [RESEARCH_STATUS.IN_PROGRESS]: "bg-orange",
  [RESEARCH_STATUS.CANCELLED]: "bg-red",
  [RESEARCH_STATUS.CREATED]: "bg-blue",
  [RESEARCH_STATUS.TAKEN_TO_WORK]: "bg-blueGrey",
};

export default function ResearchJournalTable() {
  const { t } = useTranslation(["research", "serviceNamesDict"]);
  const dispatchMessage = useContext(MessagesContext)[1];
  const researchContext = useContext(ResearchContext)[0];
  const researchStatus = researchContext?.researchFilter?.status;
  const researchStatusMine = [
    RESEARCH_STATUS.FINISHED,
    RESEARCH_STATUS.TAKEN_TO_WORK,
    RESEARCH_STATUS.CANCELLED,
  ];
  const isPatient = useIsPatient();

  const { user } = useContext(UserContext);
  const columns = useMemo(() => {
    const id = { title: "№", field: "id" },
      iin = {
        title: t("PATIENT_IIN"),
        field: "iin",
      },
      fio = { title: t("PATIENT_FULL_NAME"), field: "fullName" },
      phone = { title: t("PHONE"), field: "phone" },
      docFio = {
        title: t("RECEPTION_DOCTOR_FULL_NAME"),
        field: "receptionDoctorFullName",
      },
      recepTime = {
        title: isPatient ? t("APPOINTMENT_TIME") : t("RECEPTION_TIME"),
        field: "receptionDate",
        render: formatDateAndTime,
      },
      serviceName = {
        title: t("SERVICE"),
        field: "serviceName",
        render: (serviceCodename) =>
          serviceCodename
            ? t("serviceNamesDict:" + serviceCodename)
            : t("NOT_SPECIFIED"),
      },
      status = {
        title: t("STATUS"),
        field: "status",
        render: (val, row) => (
          <Grid container alignItems="center">
            <span>{t(`STATUS_${val}`)} </span>
            {row.hasPathalogy && (
              <span title={t("HAS_PATHALOGY")} className="ml-4 lh-0">
                <ErrorOutlineIcon color="secondary" />
              </span>
            )}
            {user.name === row.responsibleDoctorId &&
              user?.role === ROLES.DOCTOR &&
              researchStatus !== "MINE" &&
              researchStatusMine.includes(row.status) && (
                <Chip
                  label={t("STATUS_MINE")}
                  size="small"
                  className={classes.chipMine}
                />
              )}
          </Grid>
        ),
      },
      actions = {
        title: user.name === "albina" ? <DownloadAllButton /> : "",
        field: "",
        render(_, row) {
          return (
            <ResearchJournalTableActions
              row={row}
              researchStatus={RESEARCH_STATUS}
              user={user}
            />
          );
        },
        thProps: {
          style: {
            width: "15%",
            minWidth: "152px",
          },
        },
      };
    if (isPatient) {
      return [id, docFio, recepTime, status, actions];
    }
    return [
      id,
      iin,
      fio,
      phone,
      docFio,
      recepTime,
      serviceName,
      status,
      actions,
    ];
  }, [dispatchMessage, isPatient, t, researchStatus]);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <RemoteMuiTable
        query={{ order: "desc", orderBy: "receptionDate" }}
        columns={columns}
        filters={
          isPatient
            ? undefined
            : {
                searchPlaceholder: t("IIN_OR_FULL_NAME"),
                filtersComponent: (
                  <ResearchJournalFilters
                    t={t}
                    searchPlaceholder={t("IIN_OR_FULL_NAME")}
                  />
                ),
              }
        }
        headerProps={{
          sortableFields: ["id", "receptionDate"],
          tableHeadProps: {
            className: classes.tableHead,
          },
        }}
        mobileProps={{
          renderRow: (item) => (
            <ListItem
              key={item.id}
              button
              divider
              dense
              component={Link}
              to={
                isPatient
                  ? `/my-researches/${item.id}`
                  : `/researches/${item.id}`
              }
              // store scroll y position to be able to restore scroll on go back
              onClick={() =>
                localStorage.setItem("researches_scrollY", window.scrollY)
              }
            >
              <ListItemAvatar>
                <Avatar className={AvatarStatusColor[item.status]}>
                  {getStatusIcon(item.status)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`№: ${item.id}`}
                secondary={isPatient ? item.receptionDoctorFullName : item.iin}
              />
              <ListItemSecondaryAction
                className={classes.mobileSecondaryAction}
              >
                {formatDateAndTime(item.appointmentTimestamp)}
              </ListItemSecondaryAction>
            </ListItem>
          ),
          className: classes.infiniteScroll,
        }}
        fetchData={(query) => fetchResearches(query, dispatchMessage)}
        tableName="researches"
      />
    </div>
  );
}
