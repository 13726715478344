import { formatTime } from "../../utils";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { FixedSizeList as NotificationList } from "react-window";
import { queryClient } from "../../globalContexts/queryContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  Paper,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Close, ExpandMore, Notifications } from "@material-ui/icons";
import { getDataSla, postToggleSla, postReadSla } from "../../api/SlaApi";
import { DictionariesContext } from "../../globalContexts/DictionariesContext";
import { SlaCard } from "./SlaCard";

const useStyle = makeStyles({
  menu: {
    inset: "40px -40px!important",
    // height: "700px",
  },
  menuInfo: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  moreFlex: {
    position: "sticky",
    display: "flex",
    bottom: "10px",
    justifyContent: "flex-end",
  },
  moreButton: {
    position: "sticky",
    border: "1px solid #ccc",
    bottom: "10px",
    background: "#fff",
    padding: 0,
    marginRight: "15px",
  },
  cardHeader: {
    display: "flex",
    position: "sticky",
    top: 0,
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#fff",
  },
  "@media (max-width: 600px)": {
    menu: {
      inset: "20px -20px!important",
    },
  },
});

const viewedEntries = new Set();
export function NotificationCardList({
  handleClose,
  dataSla,
  openMenu,
  t,
  notificationEndRef,
  scrollToBottom,
}) {
  const classes = useStyle();
  const isSm = useMediaQuery("(max-width:732px)");
  useEffect(() => {
    if (openMenu) {
      scrollToBottom();
    }
  }, [openMenu]);

  return (
    <NotificationList
      height={isSm ? 400 : 500}
      width={isSm ? 300 : 400}
      itemCount={dataSla.length}
      itemSize={130}
      ref={notificationEndRef}
    >
      {({ index, style }) => {
        const notification = dataSla[index];
        return (
          <Grid
            key={notification.id}
            className={classes.menuInfo}
            style={style}
          >
            <SlaCard
              notification={notification}
              handleClose={handleClose}
              open={openMenu}
              viewedEntries={viewedEntries}
              t={t}
            />
            <Typography variant="caption">
              {formatTime(notification.created_at)}
            </Typography>
          </Grid>
        );
      }}
    </NotificationList>
  );
}

export function SlaNotification() {
  let id;
  const classes = useStyle();
  const notificationEndRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const { t } = useTranslation("notification", { useSuspense: false });
  const {
    authUserConfig: { notificationDisabled: notificationDisabledInitial },
  } = useContext(DictionariesContext)[0];
  const [notificationDisabled, setNotificationDisabled] = useState(false);
  const { data: dataSla, error: errorSla, isLoading: isLoadingSla } = useQuery(
    "notifications",
    () => getDataSla(),
    {
      refetchInterval: 10000,
    }
  );
  const { mutate: mutateDisable } = useMutation(postToggleSla);

  const { mutate: mutateRead } = useMutation(postReadSla, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("notifications");
    },
  });
  useEffect(() => {
    clearTimeout(id);
    if (viewedEntries.size !== 0) {
      id = setTimeout(() => mutateRead([...viewedEntries], 50));
      return () => clearTimeout(id);
    }
  }, [viewedEntries.size]);
  useEffect(() => {
    setNotificationDisabled(notificationDisabledInitial);
  }, [notificationDisabledInitial]);
  function scrollToBottom() {
    dataSla
      .filter((e) => e.is_read === false)
      .forEach((data) => viewedEntries.add(data.id));
    notificationEndRef.current.scrollToItem(
      isLoadingSla ? 0 : dataSla.length,
      "end"
    );
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const countNotification =
    dataSla?.filter((e) => e.is_read === false).length || 0;
  return (
    <div>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={countNotification} color="secondary">
          <Notifications />
        </Badge>
      </IconButton>
      <Menu
        open={openMenu}
        anchorEl={anchorEl}
        className={classes.menu}
        onClose={handleClose}
      >
        <Grid className={classes.cardHeader}>
          <Typography variant="caption">{t("DISABLE")}:</Typography>
          <Checkbox
            checked={notificationDisabled}
            onChange={(e) => {
              setNotificationDisabled(e.target.checked);
              mutateDisable(e.target.checked);
            }}
            size="small"
          />
          <IconButton onClick={handleClose}>
            <Close fontSize="small" />
          </IconButton>
        </Grid>

        {isLoadingSla ? (
          <CircularProgress />
        ) : (
          <NotificationCardList
            handleClose={handleClose}
            dataSla={dataSla}
            openMenu={openMenu}
            notificationEndRef={notificationEndRef}
            scrollToBottom={scrollToBottom}
            t={t}
          />
        )}
        <Grid className={classes.moreFlex}>
          <IconButton className={classes.moreButton} onClick={scrollToBottom}>
            <Badge badgeContent={countNotification} color="primary">
              <ExpandMore />
            </Badge>
          </IconButton>
        </Grid>
      </Menu>
    </div>
  );
}
