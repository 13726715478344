import { initialState } from ".";
import { CREATE_TOAST, DESTROY_TOAST } from "./messagesActions";

export default function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_TOAST:
      return {
        id: new Date().getTime().toString(),
        message: action.message,
        open: true,
        status: action.status || "success"
      };
    case DESTROY_TOAST:
      return null;
    default:
      return state;
  }
}
