import React, { memo, useContext, useEffect, useState } from "react";
import {
  Grid,
  Paper,
  FormLabel,
  FormHelperText,
  Input,
  Button,
  makeStyles,
  TextField,
  Checkbox,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Hidden,
  Box,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Field, ErrorMessage, FastField } from "formik";
import { onlyNums } from "../../../components/FormComponents/form_normalizers";
import { parseIIN, formatDateAndTime, mobileMaskUtils } from "../../../utils";
import MuiSelect from "../../../components/MuiSelect";
import { getRefferers } from "../../../api";
import { usePatientsFromMisRequest } from "../../../hooks/requestHooks";
import LoadingInput from "../../../components/LoadingInput";
import { MobileMaskInput } from "../../../components/FormComponents";
import { useIsPatient, useIsDoctor } from "../../../globalContexts/UserContext";
import { DictionariesContext } from "../../../globalContexts/DictionariesContext";
import VisitHistory from "./VisitHistory";
import AsyncButton from "../../../components/AsyncButton";
import { SaveTeleporter } from "../ResearchDetails/Breadcrumbs";
import { checkIinBinSum } from "../../../utils/iin";
import VisitHistoryMyResearch from "./VisitHistoryMyResearch";

const useStyles = makeStyles(() => ({
  root: {
    padding: "10px",
    margin: "0 10px",
    border: "none",
    minWidth: 0,
  },
  saveWrapper: {
    textAlign: "end",
  },
  patientWrapper: {
    padding: "6px",
    border: "1px dashed #aaa",
  },
  refraction: {
    marginBottom: "20px",
  },
  radio: {
    color: "#808080",
  },
  refractionValue: {
    width: "100px",
    margin: "15px 20% 20px 10px",
  },
  errorIin: {
    fontSize: "11px",
    color: "red",
  },
  "@media (max-width: 600px)": {
    root: {
      padding: "4px",
      margin: "0 4px",
    },
  },
}));

async function fetchReferrers() {
  const { referrers } = await getRefferers();
  return referrers.map((item) => ({
    value: item.id,
    label: item.fullName || "",
  }));
}

function Layout({
  field,
  form: { setFieldValue, status },
  cpt: Component,
  cptProps,
  label,
  validation,
  normalize,
  afterChange,
}) {
  function handleChange(e) {
    if (afterChange) afterChange(e.target.value);
    if (normalize) return setFieldValue(field.name, normalize(e.target.value));
    return field.onChange(e);
  }
  // if there is a normalize function normalize value!
  const value = normalize ? normalize(field.value) : field.value;
  return (
    <Grid container alignItems="center" className="mt-10">
      <Grid item xs={12} sm={4}>
        <FormLabel htmlFor={field.name}>{label}:</FormLabel>
      </Grid>
      <Grid item xs={12} sm={8}>
        {Component !== Checkbox ? (
          <Component
            {...field}
            onChange={handleChange}
            {...cptProps}
            value={value}
            fullWidth
          />
        ) : (
          <Checkbox
            {...field}
            {...cptProps}
            checked={Boolean(field.value)}
            value={String(field.value)}
          />
        )}
        {validation && (
          <ErrorMessage component={FormHelperText} error name={field.name} />
        )}
        {status && status[field.name] && (
          <FormHelperText error>{status[field.name]}</FormHelperText>
        )}
      </Grid>
    </Grid>
  );
}

Layout.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  cpt: PropTypes.any.isRequired,
  cptProps: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  validation: PropTypes.bool,
  normalize: PropTypes.func,
  afterChange: PropTypes.func,
};

function PatientFields({
  t,
  isDetails,
  isCreated,
  isInProgress,
  iin,
  setFieldValue,
  setStatus,
  dirty,
  isSubmitting,
  initialValues,
  handleTakeResearch,
}) {
  const [isFullnameEditable, setIsFullnameEditable] = useState(false);
  const [isCheckIin, setIsCheckIin] = useState(true);
  // если это детали проекта, то форма редактируема только если его статус CREATED
  const formDisabled = isDetails && !isCreated;
  const classes = useStyles();
  const {
    authUserConfig: { smsEnabled },
  } = useContext(DictionariesContext)[0];
  const [
    { loading: pateintsLoading, data: patientsData, error: patientsError },
    getPatients,
    cancelPatientRequest,
  ] = usePatientsFromMisRequest();

  const isPatient = useIsPatient();
  const isDoctor = useIsDoctor();

  useEffect(() => {
    // populate value for patient data from mis if there is one
    if (patientsData) {
      // if patientsData.personFullName is empty let fullName to be editable
      if (!patientsData.personFullName) {
        setIsFullnameEditable(true);
      } else {
        setIsFullnameEditable(false);
      }

      // isLocal = from keycloak
      if (patientsData.isLocal) {
        setFieldValue("fullName", patientsData.personFullName);
        setFieldValue("phone", mobileMaskUtils.formatValue(patientsData.phone));
        setFieldValue("diabetes", patientsData.diabetes);
        setFieldValue(
          "arterialHypertension",
          patientsData.arterialHypertension
        );
      } else {
        setFieldValue("fullName", patientsData.personFullName);
        setFieldValue("complaints", patientsData.treatmentReasonName);
        setFieldValue("diabetes", patientsData.diabetes);
        setFieldValue(
          "arterialHypertension",
          patientsData.arterialHypertension
        );
        setFieldValue("receptionDoctorId", {
          value: patientsData.doctorId,
          label: patientsData.doctorFullName,
        });
      }
    }
  }, [patientsData, setFieldValue]);

  useEffect(() => {
    if (patientsError) {
      setIsFullnameEditable(true);
    } else {
      setIsFullnameEditable(false);
    }
  }, [patientsError]);

  useEffect(() => {
    setStatus(null);
    cancelPatientRequest && cancelPatientRequest();
    if (iin.length === 12) {
      try {
        const { age, gender } = parseIIN(iin);
        setFieldValue("age", age);
        setFieldValue("gender", gender);
        const isCheckIinBin = checkIinBinSum(iin);
        setIsCheckIin(isCheckIinBin);

        // бери пациентов если это не детали
        // потому что эти данные нужны при создании нового
        !isDetails && getPatients(iin);
      } catch (err) {
        console.error(err);
        setStatus({ iin: t("INCORRECT_IIN") });
      }
    } else {
      // empty value for patient data from mis
      if (!formDisabled) {
        setFieldValue("fullName", "");
        setFieldValue("phone", initialValues.phone);
        setFieldValue("complaints", initialValues.complaints);
        setFieldValue(
          "receptionDoctorId",
          initialValues.receptionDoctorId
            ? {
                value: initialValues.receptionDoctorId,
                label: initialValues.receptionDoctorFullName,
              }
            : null
        );
      }
      setFieldValue("age", "");
      setFieldValue("gender", "");
    }
  }, [
    initialValues,
    formDisabled,
    getPatients,
    // FOCUS
    iin,
    // FOCUS
    setFieldValue,
    setStatus,
    t,
  ]);

  return (
    <Paper
      className={classes.root}
      component="fieldset"
      disabled={formDisabled}
    >
      <Grid container justify="space-between">
        <Grid item xs={12} md={6} className="pr-4">
          <div className={classes.patientWrapper}>
            <Grid container>
              <Grid item xs md={6}>
                <Typography variant="h6">{t("PATIENT")}</Typography>
              </Grid>
              <Grid container item xs md={6} justify="flex-end">
                {isDoctor && isInProgress && (
                  <AsyncButton
                    color="primary"
                    variant="outlined"
                    onClick={handleTakeResearch}
                  >
                    {t("TAKE_RESEARCH")}
                  </AsyncButton>
                )}
              </Grid>
            </Grid>
            <Field
              name="iin"
              component={Layout}
              validation
              normalize={onlyNums}
              cpt={LoadingInput}
              label={`${t("IIN")}*`}
              cptProps={{
                loading: pateintsLoading,
                id: "iin",
              }}
            />
            {!isCheckIin && (
              <Typography className={classes.errorIin}>
                {t("INCORRECT_IIN")}
              </Typography>
            )}
            <Field
              name="fullName"
              component={Layout}
              cpt={Input}
              label={t("FULL_NAME")}
              cptProps={{
                readOnly: !isFullnameEditable,
                id: "fullName",
              }}
            />
            <Field
              name="age"
              component={Layout}
              cpt={Input}
              label={t("AGE")}
              cptProps={{
                readOnly: true,
                id: "age",
              }}
            />
            <Field
              name="gender"
              component={Layout}
              cpt={Input}
              label={t("GENDER")}
              cptProps={{
                readOnly: true,
                id: "gender",
              }}
            />
            <Field
              name="phone"
              component={Layout}
              cpt={Input}
              label={`${t("PHONE")}${smsEnabled ? "*" : ""}`}
              validation={smsEnabled}
              cptProps={{
                id: "phone",
                inputComponent: MobileMaskInput,
              }}
            />
          </div>
          <FastField
            name="referrerId"
            component={Layout}
            cpt={MuiSelect}
            validation
            label={`${t("REFERRER")}*`}
            cptProps={{
              isDisabled: formDisabled || isCreated,
              name: "referrerId",
              inputId: "referrerId",
              isAsync: !isDetails,
              fetchData: fetchReferrers,
              placeholder: t("CHOOSE"),
              onChange: (val) => setFieldValue("referrerId", val),
            }}
          />
          <Field
            name="receptionDoctorId"
            component={Layout}
            cpt={MuiSelect}
            validation
            label={t("RECEPTION_DOCTOR")}
            cptProps={{
              isDisabled: formDisabled,
              name: "receptionDoctorId",
              inputId: "receptionDoctorId",
              isAsync: !isDetails,
              fetchData: fetchReferrers,
              placeholder: t("CHOOSE"),
              onChange: (val) => setFieldValue("receptionDoctorId", val),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6} className="pl-10">
          <Typography>{`${t("SERVICE_TYPE")}*:`}</Typography>
          <Field name="serviceType">
            {({ field }) => (
              <RadioGroup {...field}>
                <Grid container>
                  <Grid item md={3}>
                    <FormControlLabel
                      value="POLYCLINIC"
                      control={<Radio />}
                      label={t("POLYCLINIC")}
                      className={classes.radio}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormControlLabel
                      value="HOSPITAL"
                      control={<Radio />}
                      label={t("HOSPITAL")}
                      className={classes.radio}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <FormControlLabel
                      value="PAID"
                      control={<Radio />}
                      label={t("PAID")}
                      className={classes.radio}
                    />
                  </Grid>
                  <FormControlLabel
                    value="SCREENING"
                    control={<Radio />}
                    label={t("SCREENING")}
                    className={classes.radio}
                  />
                </Grid>
              </RadioGroup>
            )}
          </Field>
          <ErrorMessage component={FormHelperText} error name="serviceType" />

          <Field
            name="complaints"
            component={Layout}
            cpt={TextField}
            label={t("COMPLAINTS")}
            cptProps={{
              id: "complaints",
              variant: "outlined",
              margin: "dense",
            }}
          />
          <Field
            name="diabetes"
            component={Layout}
            cpt={Checkbox}
            label={t("DIABETES")}
            cptProps={{
              id: "diabetes",
            }}
          />
          <Field
            name="arterialHypertension"
            component={Layout}
            cpt={Checkbox}
            label={t("ARTERIAL_HYPERTENSION")}
            cptProps={{
              id: "arterialHypertension",
            }}
          />
          <SaveTeleporter.Source>
            <div className={classes.saveWrapper}>
              {!formDisabled && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  form="patientForm"
                  type="submit"
                  disabled={!dirty || isSubmitting}
                >
                  {t("SAVE")}
                </Button>
              )}
            </div>
          </SaveTeleporter.Source>
          <Hidden smUp implementation="js">
            <div className={classes.saveWrapper}>
              {!formDisabled && (
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  form="patientForm"
                  type="submit"
                  disabled={!dirty || isSubmitting || !isCheckIin}
                >
                  {t("SAVE")}
                </Button>
              )}
            </div>
          </Hidden>

          {isPatient ? (
            <VisitHistoryMyResearch
              t={t}
              iin={iin}
              dirty={dirty}
              id={initialValues.id}
            />
          ) : (
            <VisitHistory t={t} iin={iin} dirty={dirty} id={initialValues.id} />
          )}
        </Grid>
      </Grid>
      <Box marginBottom={"24px"}></Box>
    </Paper>
  );
}

export default memo(PatientFields);
