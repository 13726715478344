import React from "react";
import { Typography } from "@material-ui/core";
import eyePlaceholder from "../../assets/images/eye-placeholder.jpg";
import { useTranslation } from "react-i18next";

export default function NoImage() {
  const { t } = useTranslation("research");
  return (
    <>
      <img width="100%" src={eyePlaceholder} alt="placeholder" />
      <Typography align="center" variant="h6">
        {t("IMG_ABSENT")}
      </Typography>
    </>
  );
}
