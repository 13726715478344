import React, { memo } from "react";
import RecommendationMobile from "./RecommendationMobile";
import EyeLayout from "./EyeLayout";

const flex100 = {
  flex: "0 0 100vw",
  overflow: "hidden",
};
function MobileConclusionFormTabs({
  editMode,
  researchId,
  rightEyes,
  leftEyes,
  status,
  rightEyeConclusion,
  leftEyeConclusion,
  setFieldValue,
  t,
  formikStatus,
  code,
  responsibleDoctorUsername,
}) {
  return (
    <>
      <div style={flex100}>
        <EyeLayout
          editMode={editMode}
          eyeValue={rightEyes}
          label={t("RIGHT")}
          eyeName="rightEyes"
          isLeft={false}
          status={status}
          setFieldValue={setFieldValue}
          descriptionValues={rightEyeConclusion.description}
          conclusionResultValues={rightEyeConclusion.conclusionResult}
          responsibleDoctorUsername={responsibleDoctorUsername}
        />
      </div>
      <div style={flex100}>
        <EyeLayout
          editMode={editMode}
          eyeValue={leftEyes}
          label={t("LEFT")}
          eyeName="leftEyes"
          isLeft
          status={status}
          setFieldValue={setFieldValue}
          descriptionValues={leftEyeConclusion.description}
          conclusionResultValues={leftEyeConclusion.conclusionResult}
          responsibleDoctorUsername={responsibleDoctorUsername}
        />
      </div>
      <div style={flex100}>
        <RecommendationMobile
          editMode={editMode}
          t={t}
          id={researchId}
          rightEyes={rightEyes}
          leftEyes={leftEyes}
          status={status}
          formikStatus={formikStatus}
          code={code}
        />
      </div>
    </>
  );
}

export default memo(MobileConclusionFormTabs);
