import React from "react";
import { Resizable } from "re-resizable";
import EyeContainer from "../PatientForm/EyeContainer";
import { makeStyles, Step, StepLabel, Stepper } from "@material-ui/core";
import { DragHandle } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    position: "relative",
  },
  viewer: {
    height: "70%",
  },
  form: {
    position: "absolute!important",
    width: "100%!important",
    bottom: 0,
    background: theme.palette.common.white,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  handler: {
    width: "100px",
    margin: "0 auto",
    textAlign: "center",
  },
  stepper: {
    padding: theme.spacing(1),
    width: "100%",
    maxWidth: "600px",
  },
}));

function FormModalMobile({ children, image, activeStep }) {
  const classes = useStyles();
  const { t } = useTranslation("research");
  return (
    <div className={classes.root}>
      <div className={classes.viewer}>
        <EyeContainer value={image} />
      </div>
      <Resizable
        dragAxis="y"
        // bounds="window"
        handleComponent={{
          top: (
            <div className={classes.handler}>
              <DragHandle />
            </div>
          ),
        }}
        enable={{
          top: true,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        defaultSize={{ height: "30%" }}
        minHeight="20%"
        className={classes.form}
      >
        <Stepper className={classes.stepper} activeStep={activeStep}>
          <Step>
            <StepLabel>{t("CONCLUSION")}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t("DESCRIPTION")}</StepLabel>
          </Step>
        </Stepper>
        {children}
      </Resizable>
    </div>
  );
}

export default FormModalMobile;
