import * as Yup from "yup";
import { KZPhoneRegex } from "../../../components/FormComponents/form_normalizers";
import { checkIinBinSum } from "../../../utils/iin";
import { autorefractomyServiceSchema } from "../../PatientRegistry/SideMenu/Service/Forms/validationSchemas";

const newResearchValidationSchema = (smsEnabled) =>
  Yup.object().shape(newResearchValidationSchemaShape(smsEnabled));

export default newResearchValidationSchema;

export function newResearchValidationSchemaShape(smsEnabled) {
  return {
    iin: Yup.string()
      .length(12, "INCORRECT_IIN")
      .test("IIN_CHECKSUM", "INCORRECT_IIN", checkIinBinSum)
      .required("REQUIRED"),
    fullName: Yup.string().required("REQUIRED"),
    phone: smsEnabled
      ? Yup.string()
          .matches(KZPhoneRegex, "MOBILE_FORMAT_INVALID")
          .required("REQUIRED")
      : null,
    // age: Yup.number().required('REQUIRED').typeError('REQUIRED'),
    // gender: Yup.string().required('REQUIRED'),
    serviceName: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required("REQUIRED")
      .typeError("REQUIRED"),
    serviceType: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required("REQUIRED")
      .typeError("REQUIRED"),
    referrer: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required("REQUIRED")
      .typeError("REQUIRED"),
  };
}

export const newResearchValidationSchemaWithAutorefractometry = (smsEnabled) =>
  autorefractomyServiceSchema.concat(newResearchValidationSchema(smsEnabled));
