import React, { useContext } from "react";
import { MessagesContext } from "../../globalContexts/MessagesContext";
import { FORCE_UPDATE, TableContext } from "../../globalContexts/TableContext";
import useStyle from "./slaCardStyles";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { takeResearch } from "../ResearchesPage/ResearchesList/ResearchJournalTable";
import { getTimeFromMilliseconds } from "../../utils";
import { useLocation } from "react-router-dom";
import { takeResearchRequest } from "../../api";
import { queryClient } from "../../globalContexts/queryContext";

const SlaCardResearch = React.forwardRef(
  ({ notification, handleClose, t }, ref) => {
    const dispatchMessage = useContext(MessagesContext)[1];
    const dispatchTable = useContext(TableContext)[1];
    const classes = useStyle();
    const location = useLocation();

    async function handleResearchRequest(id) {
      await takeResearchRequest(id);
      queryClient.invalidateQueries(["researchDetails", id]);
    }

    return (
      <Paper className={classes.card} ref={ref} id={notification.object_id}>
        <Typography
          variant="caption"
          className={classes.cardTitle}
          title={t(notification.notification_type, {
            researchId: notification.object_id,
          })}
        >
          {t(notification.notification_type, {
            researchId: notification.object_id,
          })}
        </Typography>
        <Grid className={classes.cardInfo}>
          <Button
            size="small"
            variant="outlined"
            className={classes.cardButton}
            onClick={async () => {
              if (
                location.pathname !== `/researches/${notification.object_id}`
              ) {
                await takeResearch(notification.object_id, dispatchMessage);
                dispatchTable({ type: FORCE_UPDATE });
              } else {
                await handleResearchRequest(notification.object_id);
              }
              await queryClient.invalidateQueries("notifications");
              handleClose();
            }}
          >
            {t("TAKE_RESEARCH")}
          </Button>
          <Typography
            variant="caption"
            className={notification.deadline < 600000 ? classes.deadline : ""}
          >
            {getTimeFromMilliseconds(notification.deadline, t)}
          </Typography>
        </Grid>
      </Paper>
    );
  }
);

export default SlaCardResearch;
