import {Grid, makeStyles, Modal, Paper, Typography} from "@material-ui/core";
import {Add, Close, Edit} from "@material-ui/icons";
import React, {useContext, useEffect, useState} from "react";
import CreateFormModal from "./CreateFormModal";
import {useQuery} from "react-query";
import {useParams} from "react-router-dom";
import {deleteDiseaseHistory, deleteOphthalmologyStatus, getDiseaseHistories} from "../../../../api/patientRegistry";
import OphthalmologyStatusList from "./OphthalmologyStatusList";
import ConfirmActionModal from "../../../../components/ConfirmActionModal";
import {queryClient} from "../../../../globalContexts/queryContext";
import {useTranslation} from "react-i18next";
import {createToast, MessagesContext} from "../../../../globalContexts/MessagesContext";
import {deleteRecommendation, getOphthalmologyStatusFields, setRecommendation} from "../../../../api";
import {ROLES} from "../../../../config/constants";
import Roled from "../../../../components/Roled";
import {RESEARCH_STATUS} from "../../../ResearchesPage/constants";

const useStyles = makeStyles((theme) => ({
    icon: {
        border: '1px solid black',
        color: theme.palette.primary.main
    },
}));

const componentStates = {
    IDLE: 'IDLE',
    CREATING: 'CREATING',
    EDITING: 'EDITING',
    REVIEWING: 'REVIEWING',
    DELETING: 'DELETING'
}

const OphthalmologyStatus = () => {
    const classes = useStyles();
    const {t} = useTranslation('patient-registry')
    const [state, setState] = useState(componentStates.IDLE);

    const dispatchMessage = useContext(MessagesContext)[1];
    const { id: researchId } = useParams();
    useQuery('ophthalmologyStatusFields', () => getOphthalmologyStatusFields(), {
        refetchOnWindowFocus: false
    });
    const {data: research} = useQuery(['researchDetails', researchId], {
        enabled: false
    });
    const isFinished = research.status === RESEARCH_STATUS.FINISHED;
    const ophthalmologyStatusExists = research?.ophthalmologyStatuses?.reduce((accum, item) => item.isCurrent || accum, false);
    const [chosen, setChosen] = useState(null);
    const [editingItem, setEditingItem] = useState(null);
    const [deletingItem, setDeletingItem] = useState(null);


    const handleCloseModal = () => {
        setState(componentStates.IDLE);
        setChosen(null);
        setEditingItem(null);
        setDeletingItem(null);
    }

    const handleDeleteOphthalmologyStatus = async () => {
        try {
            await deleteOphthalmologyStatus(deletingItem.id);
            handleCloseModal()
            dispatchMessage(createToast(t('OPHTHALMOLOGY_STATUS_SUCCESSFULLY_DELETED')));
            await queryClient.invalidateQueries(['researchDetails', researchId]);
            return;
        } catch(error) {
            console.error(error);
        }
        handleCloseModal()
    }

    return  <Grid container direction={'column'}>
        <Grid container>
            {!ophthalmologyStatusExists && !isFinished && (<Roled roles={[ROLES.DOCTOR]}>
                <Add className={classes.icon} onClick={() => setState(componentStates.CREATING)} />
            </Roled>)}
        </Grid>
        <OphthalmologyStatusList ophthalmologyStatuses={research?.ophthalmologyStatuses}
                                 onChooseOphthalmologyStatus={(item) => {
                                     setChosen(item);
                                     setState(componentStates.REVIEWING)
                                 }}
                                 onEditOphthalmologyStatus={(item) => {
                                     setEditingItem(item);
                                     setState(componentStates.EDITING)
                                 }}
                                 onDeleteOphthalmologyStatus={(item) => {
                                     setDeletingItem(item)
                                     setState(componentStates.DELETING)
                                 }}
                                 isFinished={isFinished}
        />
        <CreateFormModal open={state === componentStates.CREATING || state === componentStates.REVIEWING || state === componentStates.EDITING}
                         onClose={handleCloseModal}
                         chosen={chosen}
                         editingItem={editingItem}
        />
        <ConfirmActionModal open={state === componentStates.DELETING}
                            label={t('CONFIRM_DELETION')}
                            onClose={handleCloseModal}
                            onConfirm={handleDeleteOphthalmologyStatus}/>
    </Grid>
}

export default OphthalmologyStatus;