import { Typography } from "@material-ui/core";
import React from "react";
import { lastNameDotFirstName, parseIIN } from "../../utils";
import { PatientsActions } from "./PatientsActions";

export function PatientsMobileTable({tableData}) {
  return (
    <>
      {tableData.map((patient, idx) => {
        return (
          <div
            style={{
              height: "60px",
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #CACFD2",
            }}
          >
            <div style={{ width: "60px" }}>
              <PatientsActions patient={patient} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography style={{ fontSize: "14px" }}>
                  {patient.username}
                </Typography>
                <Typography
                  style={{
                    color: "rgba(0, 0, 0, 0.54)",
                    fontSize: "14px",
                  }}
                >
                  {lastNameDotFirstName(
                    patient.firstName || "",
                    patient.lastName || ""
                  )}
                </Typography>
              </div>
              <div style={{ textAlign: "right", marginRight: "15px" }}>
                <Typography style={{ fontSize: "14px" }}>
                  {parseIIN(patient.username).birthday}
                </Typography>
                <Typography flex={"end"} style={{ fontSize: "14px" }}>
                  {parseIIN(patient.username).gender}
                </Typography>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}
