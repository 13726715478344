import {
  Paper,
  Input,
  InputAdornment,
  useMediaQuery,
  LinearProgress,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Search } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useRouterQuery } from "../../hooks/useRouterQuery";
import { useQuery } from "react-query";
import { getPatientIinOrFullName } from "../../api/patientsApi";
import { PatientsMobileTable } from "./PatientsMobileTable";
import { PatientsDesktopTable } from "./PatientsDesktopTable";
import TablePaginationComponent from "../../lib/MaterialTable/TablePaginationComponent";

export function PatientJournal() {
  const isMd = useMediaQuery("(max-width:854px)");
  const { t } = useTranslation("research");

  const [query, handleReplace] = useRouterQuery();

  const rowsPerPage = Number(query.get("rowsPerPage")) || 20;
  const page = Number(query.get("page")) || 0;
  const iinOrFullName = query.get("iinOrFullName");
  // const [iinOrFullName, setIinOrFullName] = useState(
  //   () => query.get("iinOrFullName") || ""
  // );

  const { data, isLoading, error } = useQuery(
    ["getPatientIinOrFullName", iinOrFullName, page, rowsPerPage],
    () => getPatientIinOrFullName({ iinOrFullName, page, rowsPerPage })
  );

  return (
    <Paper style={{ marginTop: "30px" }}>
      <Input
        type="search"
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        value={iinOrFullName}
        onChange={(e) => {
          handleReplace("iinOrFullName", e.target.value);
          handleReplace("page");
        }}
        placeholder={t("IIN_OR_FULL_NAME")}
        style={{
          maxWidth: "600px",
          width: "100%",
          height: "50px",
          marginBottom: "10px",
        }}
      />
      <div style={{ minHeight: "500px" }}>
        {isLoading ? (
          <LinearProgress />
        ) : isMd ? (
          <PatientsMobileTable tableData={data?.result.data || []} />
        ) : (
          <PatientsDesktopTable tableData={data?.result.data || []} />
        )}
      </div>

      <TablePaginationComponent
        rowsPerPageOptions={isMd ? [10] : [20, 50, Number(data?.result.length)]}
        total={data?.result.length || -1}
        page={page}
        per_page={rowsPerPage}
        handleChangePage={(newPage) => handleReplace("page", newPage)}
        handleChangeRowsPerPage={(e) => {
          handleReplace("rowsPerPage", e.target.value);
          handleReplace("page");
        }}
      />
    </Paper>
  );
}
