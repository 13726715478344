import { initialState } from ".";
import { CREATE_MODAL, CLOSE_MODAL, DESTROY_MODAL } from "./modalActions";

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_MODAL:
      return {
        dialogProps: { open: true, ...action.dialogProps },
        dialogTitle: action.dialogTitle,
        dialogContent: action.dialogContent,
        dialogFooter: action.dialogFooter
      };
    case DESTROY_MODAL: {
      return null;
    }
    case CLOSE_MODAL: {
      const props = { ...state.dialogProps, open: false };
      return { ...state, dialogProps: props };
    }
    default:
      return state;
  }
}
