import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSwipeable } from "react-swipeable";
import PinchZoom from "../../lib/Pinch-Zoom";

const useStyles = makeStyles(() => ({
  eye: {
    flex: "0 0 100%",
    minHeight: "260px",
    height: "80vw",
    display: "flex",
    flexDirection: "column"
  },
  handlerWrapper: {
    overflow: "hidden",
    position: "relative"
  },
  indicators: {
    position: "absolute",
    bottom: "4px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .dot": {
      display: "inline-block",
      margin: "0 2px",
      width: "6px",
      height: "6px",
      borderRadius: "50%",
      background: "#bbb",
      transition: "all .5s"
    },
    "& .dot.active": {
      background: "#999",
      width: "8px",
      height: "8px"
    }
  }
}));

const handlerStyle = slide => ({
  display: "flex",
  transform: `translateX(${-slide * 100}%)`,
  transition: "transform .3s ease"
});

export default function MobileEyesViewer({
  activeIndex,
  onIndexChange,
  images
}) {
  const classes = useStyles();

  function onSwipedLeft(eventType) {
    if (activeIndex === images.length - 1) return;
    onIndexChange(activeIndex + 1);
    eventType.event.stopPropagation();
  }

  function onSwipedRight(eventType) {
    if (activeIndex === 0) return;
    onIndexChange(activeIndex - 1);
    eventType.event.stopPropagation();
  }

  const handlers = useSwipeable({ onSwipedRight, onSwipedLeft });
  return (
    <div className={classes.handlerWrapper}>
      <div {...handlers} style={handlerStyle(activeIndex)}>
        {images.map(img => (
          <div key={img.src} className={classes.eye}>
            <PinchZoom src={img.src} />
          </div>
        ))}
      </div>
      <div className={classes.indicators}>
        {images.map((_, idx) => (
          <span className={`dot ${idx === activeIndex ? "active" : ""}`} />
        ))}
      </div>
    </div>
  );
}
