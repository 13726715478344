import React from 'react';
import {Box, Button, Grid, makeStyles, Modal, Paper, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Close} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        padding: '32px',
        position: 'relative'
    },
    closeIcon: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        cursor: 'pointer',
    },
}));

const ConfirmActionModal = ({open, onClose, onConfirm, label}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    return <Modal open={open} className={classes.modal}>
        <Paper className={classes.modalContent}>
            <Close className={classes.closeIcon} onClick={onClose} />
            <Grid container direction={'column'}>
                <Typography>
                    {label || t('CONFIRM_CONTINUE')}
                </Typography>
                <Box height={'24px'} />
                <Grid container direction={'row'} justify={'flex-end'}>
                    <Button  variant={'contained'} color={'primary'} onClick={onConfirm}>
                        {t('CONFIRM')}
                    </Button>
                    <Box width={'6px'}/>
                    <Button onClick={onClose} variant={'contained'} color={'secondary'}>
                        {t('CANCEL')}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    </Modal>
}

export default ConfirmActionModal;